import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { IContractFilterOptions, IContractFilterParams } from '@fragus/sam-types'
import DateRangePicker, { IOnDatesChangeArgs } from 'components/DateRangePicker'
import Typography from 'components/Typography'
import moment, { Moment } from 'moment'
import React from 'react'
import { t } from 'translations/translationFunctions'
import { trackEvent } from 'utils/analytics'
import { addTimeZoneOffset } from 'utils/date'
import DetailsField from './DetailsField'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(3),
    },
    title: {
      marginBottom: theme.spacing(3),
    },
    subtitle: {
      marginBottom: theme.spacing(2),
    },
    section: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: theme.spacing(3),
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      flexBasis: '100%',
      '& > *': {
        flexBasis: '33.3333%',
        marginRight: theme.spacing(4),
        '&:last-child': {
          marginRight: 0,
        },
        '&:first-child': {
          marginLeft: 0,
        },
      },
    },
    sectionColumn: {
      flexBasis: '50%',
      '&:first-child': {
        marginRight: theme.spacing(3),
      },
      '&:last-child': {
        marginLeft: theme.spacing(3),
      },
    },
  })

export interface IFilterDetailsShow {
  creationDateRanges?: boolean
  exiredDateRanges?: boolean
  contractOptions?: boolean
  templateNAmes?: boolean
  sellers?: boolean
  subProviders?: boolean
}
const filterDetailsShowDefault: IFilterDetailsShow = {
  creationDateRanges: true,
  exiredDateRanges: true,
  contractOptions: true,
  templateNAmes: true,
  sellers: true,
  subProviders: true,
}

interface IProps {
  onChange: (values: Partial<IContractFilterParams>) => void
  filterDetailsShow: IFilterDetailsShow
  activeFilters: IContractFilterParams
  getFilterOptions: () => Promise<any>
}

type DatePickerType = 'created' | 'expired'

type Props = IProps & WithStyles<typeof styles>

interface IState {
  loading: boolean
  options: Partial<IContractFilterOptions>
  dateMinValue: {
    created: string
    expired: string
  }
  filterDetailsShow: IFilterDetailsShow
}

class FilterDetails extends React.Component<Props, IState> {
  public state: IState = {
    filterDetailsShow: { ...filterDetailsShowDefault, ...this.props.filterDetailsShow },
    loading: false,
    options: {
      sellers: [],
      templates: [],
      options: [],
      subProviders: [],
      created: {
        min: new Date().toISOString(),
        max: new Date().toISOString(),
      },
      expired: {
        min: new Date().toISOString(),
        max: new Date().toISOString(),
      },
    },
    dateMinValue: {
      created: new Date().toISOString(),
      expired: new Date().toISOString(),
    },
  }

  public componentDidMount() {
    this.initOptions()
  }

  public render() {
    const { created, expired, sellers, templates, options, subProviders } = this.state.options
    const { activeFilters, classes } = this.props
    const dateValues = {
      created: {
        min: activeFilters.created.min ? moment(activeFilters.created.min) : null,
        max: activeFilters.created.max ? moment(activeFilters.created.max) : null,
        minRange: (created && moment(created.min).startOf('day')) || moment().subtract(1, 'month').startOf('day'),
        maxRange: (created && moment(created.max).endOf('day')) || moment().endOf('day'),
      },
      expired: {
        min: activeFilters.expired.min ? moment(activeFilters.expired.min) : null,
        max: activeFilters.expired.max ? moment(activeFilters.expired.max) : null,
        minRange: (expired && moment(expired.min).startOf('day')) || moment().subtract(1, 'month').startOf('day'),
        maxRange: (expired && moment(expired.max).endOf('day')) || moment().endOf('day'),
      },
    }

    return (
      <div className={classes.root} data-e2e="filterDetails-root">
        <Typography className={classes.title} variant="title" tag="h3">
          {t('Filters')}
        </Typography>
        <form>
          <Typography className={classes.subtitle} variant="subtitle" tag="h4">
            {t('Contract details')}
          </Typography>
          <section className={classes.section}>
            <div className={classes.details}>
              {this.state.filterDetailsShow.sellers && (
                <div>
                  <DetailsField
                    helperText={t('Filter by one or more contract sales person(s)')}
                    label={t('Contract salesperson')}
                    name="sellers"
                    onChange={this.handleDetailsChange}
                    options={sellers || []}
                    selectedValues={activeFilters.sellers}
                  />
                </div>
              )}
              {this.state.filterDetailsShow.templateNAmes && (
                <div>
                  <DetailsField
                    helperText={t('Filter by template name')}
                    label={t('Contract template')}
                    name="templates"
                    onChange={this.handleDetailsChange}
                    options={templates || []}
                    selectedValues={activeFilters.templates}
                  />
                </div>
              )}
              {this.state.filterDetailsShow.contractOptions && (
                <div>
                  <DetailsField
                    helperText={t('Filter by options')}
                    label={t('Contract options')}
                    name="options"
                    onChange={this.handleDetailsChange}
                    options={options || []}
                    selectedValues={activeFilters.options}
                  />
                </div>
              )}
              {this.state.filterDetailsShow.subProviders && !!subProviders && !!subProviders.length && (
                <div>
                  <DetailsField
                    helperText={t('Filter by sub dealers')}
                    label={t('Sub dealers')}
                    name="subProviders"
                    onChange={this.handleDetailsChange}
                    options={subProviders || []}
                    selectedValues={activeFilters.subProviders}
                  />
                </div>
              )}
            </div>
          </section>
          <div className={classes.section}>
            {this.state.filterDetailsShow.creationDateRanges && (
              <div className={classes.sectionColumn}>
                <Typography className={classes.subtitle} variant="subtitle" tag="h4">
                  {t('Contract creation date ranges')}
                </Typography>
                <DateRangePicker
                  startDate={dateValues.created.min}
                  endDate={dateValues.created.max}
                  minDate={dateValues.created.minRange}
                  maxDate={dateValues.created.maxRange}
                  startDateId="filter-created-start-date"
                  endDateId="filter-created-end-date"
                  // tslint:disable-next-line jsx-no-lambda
                  onDatesChange={({ startDate, endDate }: IOnDatesChangeArgs) => {
                    this.handleDatesChange('created', startDate, endDate)
                  }}
                  isDisabled={created && (created.min === null || created.max === null)}
                />
              </div>
            )}
            {this.state.filterDetailsShow.exiredDateRanges && (
              <div className={classes.sectionColumn}>
                <Typography className={classes.subtitle} variant="subtitle" tag="h4">
                  {t('Contract expiration date ranges')}
                </Typography>
                <DateRangePicker
                  startDate={dateValues.expired.min}
                  endDate={dateValues.expired.max}
                  minDate={dateValues.expired.minRange}
                  maxDate={dateValues.expired.maxRange}
                  startDateId="filter-expired-start-date"
                  endDateId="filter-expired-end-date"
                  // tslint:disable-next-line jsx-no-lambda
                  onDatesChange={({ startDate, endDate }: IOnDatesChangeArgs) => {
                    this.handleDatesChange('expired', startDate, endDate)
                  }}
                  isDisabled={created && (created.min === null || created.max === null)}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    )
  }

  private initOptions = async () => {
    const fetchedOptions = await this.fetchOptions()

    if (fetchedOptions) {
      const { contractStates, ...options } = fetchedOptions
      const dateMinValue = {
        created: options.created.min || this.state.dateMinValue.created,
        expired: options.expired.min || this.state.dateMinValue.expired,
      }

      this.setState({ options, dateMinValue })
    }
  }

  private fetchOptions = async () => {
    let options: IContractFilterOptions | undefined

    if (!this.state.loading) {
      this.setState({ loading: true })

      const response = await this.props.getFilterOptions()

      if (response && response.data) {
        options = response.data
      }

      this.setState({ loading: false })
    }

    return options
  }

  private handleDetailsChange = (name: string, value: any) => {
    const { onChange, activeFilters } = this.props
    const newValues = { ...activeFilters }
    newValues[name] = value
    trackEvent('Contract advanced filter', 'Filter changed', name)
    onChange(newValues)
  }

  private handleDatesChange = (type: DatePickerType, minDate: Moment | null, maxDate: Moment | null) => {
    const { onChange, activeFilters } = this.props

    const isoMinDate = minDate ? addTimeZoneOffset(minDate.startOf('day')).toISOString() : ''
    const isoMaxDate = maxDate ? addTimeZoneOffset(maxDate.endOf('day')).toISOString() : ''

    switch (type) {
      case 'created': {
        onChange({
          ...activeFilters,
          created: { ...activeFilters.created, min: isoMinDate, max: isoMaxDate },
        })
        break
      }

      case 'expired': {
        onChange({
          ...activeFilters,
          expired: { ...activeFilters.expired, min: isoMinDate, max: isoMaxDate },
        })
        break
      }
    }
  }
}

export default withStyles(styles)(FilterDetails)
