import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { DayPickerSingleDateController, SingleDatePicker, SingleDatePickerShape } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import './index.css'
import { Button, InputLabel, Typography } from '@material-ui/core'
import { ArrowLeftOutlined as ArrowLeft, ArrowRightOutlined as ArrowRight } from '@material-ui/icons'
import { getDateFormatInCapitalLetters } from '@omnicar/sam-format'
import { getLocale } from '@omnicar/sam-translate'
import { IsoLocale } from '@fragus/sam-types'
import classNames from 'classnames'
import moment, { Moment } from 'moment'
import { compose } from 'recompose'
import { t } from 'translations/translationFunctions'
import { localeToLanguage } from 'utils/locale'

interface IOwnProps {
  minDate?: Moment
  maxDate?: Moment
  showLabel?: boolean
  locale?: IsoLocale | string
  disableUnderline?: boolean // Prop named the same as in Material Input.
  isDisabled?: boolean
  placeholder?: string
  datePickerWithoutInput?: boolean
  onOutsideClick?: () => void
  initialDate?: Moment
  dateRangeLength?: number
  labelText?: string
  changeByYearDisabled?: boolean
  onMonthYearChange?: ((currentDate: moment.Moment) => void) | undefined
}

type TDatePickerProps = 'displayFormat' | 'date' | 'id' | 'onDateChange' | 'isDayBlocked'

type OwnProps = IOwnProps & Pick<SingleDatePickerShape, TDatePickerProps>

type TProps = IOwnProps & OwnProps & WithStyles<typeof styles>

interface IState {
  focused: boolean | null
  currentLocale: string | undefined
}

const styles = () =>
  createStyles({
    root: {
      position: 'relative',
      borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
    },
    label: {
      position: 'absolute',
      top: -6,
      color: 'rgba(0, 0, 0, 0.54)',
      transform: 'translate(0, 1.5px) scale(0.75)',
      zIndex: 1,
    },
    focused: {
      borderBottom: `2px solid rgba(0, 0, 0, 0.87)`,
    },
    noUnderline: {
      bottomBorder: 'none',
      borderBottomSstyle: 'none',
      borderStyle: 'none',
      // '&&&:before': {
      //   borderBottom: 'none',
      // },
      // '&&:after': {
      //   borderBottom: 'none',
      // },
    },
    monthElement: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: '-10',
      justifyItems: 'center',
    },
    monthdiv: {
      width: '30%',
    },
    yearSelect: {
      display: 'flex',
      flexDirection: 'row',
      width: '80px',
      paddingBottom: '0px',
      paddingTop: '0px',
    },
    yearSelectButton: {
      minHeight: '10px',
      height: '27px',
      padding: '0',
      minWidth: '10px',
      color: '#484848',
    },
    pickerLabel: {
      fontWeight: 'bolder',
      fontSize: '1em',
      color: '#484848',
    },
    datePicker: {
      paddingLeft: '70px',
    },
  })

const getInitialStateFromProps = (props: TProps) => ({
  focused: false,
  currentLocale: undefined,
})

class DatePicker extends Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)
    this.state = getInitialStateFromProps(props)
  }

  public componentDidMount() {
    const localeOrLanguage = getLocale()
    if (localeOrLanguage) {
      this.initLocale(localeOrLanguage)
    }
  }

  public componentDidUpdate(oldProps: TProps) {
    const { date } = this.props

    if (oldProps.date && date === null) {
      // This would mean that the date was cleared in the parent
      this.setState(getInitialStateFromProps(this.props))
    }

    const localeOrLanguage = getLocale()
    if (localeOrLanguage && localeOrLanguage !== this.state.currentLocale) {
      this.initLocale(localeOrLanguage)
    }
  }

  public render() {
    const {
      classes,
      id,
      date,
      showLabel,
      labelText,
      disableUnderline,
      isDisabled,
      placeholder,
      datePickerWithoutInput,
      onOutsideClick,
      onDateChange,
      isDayBlocked,
      dateRangeLength,
    } = this.props

    const { focused } = this.state

    // Controlled no input DatePicker version
    if (datePickerWithoutInput)
      return (
        <div>
          <DayPickerSingleDateController
            date={date}
            onDateChange={onDateChange}
            focused={true} // Force focused state to always be truthy so that date is always selectable
            onFocusChange={() => {}}
            numberOfMonths={1}
            onOutsideClick={onOutsideClick}
            renderMonthElement={({ month, onYearSelect }) =>
              this.renderMonthElement(month, onYearSelect, this.resolveOutsideRage)
            }
            onNextMonthClick={this.handleMonthYearChange}
            onPrevMonthClick={this.handleMonthYearChange}
            isOutsideRange={dateRangeLength ? this.resolveOutsideRage : this.isOutsideRange}
            isDayBlocked={isDayBlocked}
          />
        </div>
      )

    return (
      <div
        className={classNames(
          classes.root,
          focused ? classes.focused : '',
          disableUnderline ? classes.noUnderline : '',
        )}
      >
        {showLabel && (
          <InputLabel className={classes.label}>{labelText ? labelText : t('Registration Date')}</InputLabel>
        )}
        <SingleDatePicker
          // TODO: Later fix that this element fits/takes up the whole width of its parent element, so the whole placeholder text can fit into it (if there are space left.)
          // classes={classes.datePicker}
          id={id}
          disabled={isDisabled}
          date={date}
          onDateChange={this.handleDateChange}
          focused={!!focused}
          onFocusChange={this.handleFocusChange}
          displayFormat={getDateFormatInCapitalLetters()}
          isOutsideRange={this.isOutsideRange}
          noBorder={true}
          onNextMonthClick={this.handleMonthYearChange}
          onPrevMonthClick={this.handleMonthYearChange}
          renderMonthElement={({ month, onYearSelect }) => this.renderMonthElement(month, onYearSelect)}
          showDefaultInputIcon={true}
          placeholder={!placeholder ? t('Date') + '...' : placeholder}
          isDayBlocked={isDayBlocked}
        />
      </div>
    )
  }

  private handleMonthYearChange = (date: moment.Moment) => {
    const { onMonthYearChange } = this.props
    !this.isOutsideRange(date) && onMonthYearChange && onMonthYearChange(date)
  }

  private renderMonthElement = (
    month: Moment,
    onYearSelect: (currentMonth: moment.Moment, newYearVal: string) => void,
    isOutOfRange?: (day: moment.Moment) => boolean,
  ) => {
    const { classes, changeByYearDisabled } = this.props

    return (
      <div className={classes.monthElement}>
        <div className={classes.monthdiv}>
          <Typography className={classes.pickerLabel}>{month.format('MMMM')}</Typography>
        </div>
        {!changeByYearDisabled && (
          <div className={classNames(classes.monthdiv, classes.yearSelect)}>
            <Button
              className={classes.yearSelectButton}
              onClick={() => {
                onYearSelect(month, `${month.year() - 1}`)
                this.handleMonthYearChange(month.subtract(1, 'year'))
              }}
              disabled={!!isOutOfRange && isOutOfRange(moment(`${month.year() - 1}-12-31`))}
            >
              <ArrowLeft />
            </Button>
            <Typography className={classes.pickerLabel}>{month.year()}</Typography>
            <Button
              className={classes.yearSelectButton}
              onClick={() => {
                onYearSelect(month, `${month.year() + 1}`)
                this.handleMonthYearChange(month.add(1, 'year'))
              }}
              disabled={!!isOutOfRange && isOutOfRange(moment(`${month.year() + 1}-01-01`))}
            >
              <ArrowRight />
            </Button>
          </div>
        )}
      </div>
    )
  }

  private resolveOutsideRage = (day: Moment) => {
    const { dateRangeLength, initialDate, maxDate } = this.props
    const lengthBasedOnMax = maxDate && maxDate.diff(moment(), 'years')
    const length = dateRangeLength || lengthBasedOnMax || 3

    return initialDate
      ? day.isBefore(initialDate, 'day') || day.isAfter(moment(initialDate).add(length, 'years'))
      : day.isBefore(moment(), 'day') || day.isAfter(moment().add(length, 'years'))
  }

  private initLocale = (locale: IsoLocale | string) => {
    const localeOrLanguage = locale.toLowerCase()

    // workaround for the '$$' locale, which is used by superadmins to force key values for translations
    if (localeOrLanguage === '$$') {
      moment.locale('en')
      this.setState({ currentLocale: localeOrLanguage })
      return
    }
    // TODO: figure out whether we need such processing for the locale

    try {
      // First, try to load with a possible full locale code ('xx-yy').
      require('moment/locale/' + localeOrLanguage)
      moment.locale(localeOrLanguage)

      return
    } catch (error) {}

    try {
      // Then if that fails, try load with only the language part ('xx').
      const language = localeToLanguage(localeOrLanguage)

      require('moment/locale/' + language)
      moment.locale(language)
      this.setState({ currentLocale: locale })

      return
    } catch (error) {
      // If both attempts fail, use 'en' as the default
      console.warn('DatePicker: initLocale(..) failed for locale = ' + locale + ', falling back to en')

      moment.locale('en')
      this.setState({ currentLocale: 'en' })
    }
  }

  private isOutsideRange = (date: Moment, min?: Moment | undefined, max?: Moment | undefined) => {
    const minDate = min || this.props.minDate
    const maxDate = max || this.props.maxDate
    if ((minDate && date.isBefore(minDate)) || (maxDate && date.isAfter(maxDate))) {
      return true
    }

    return false
  }

  private handleDateChange = (date: any) => {
    this.props.onDateChange(date)
  }

  private handleFocusChange = (arg: { focused: boolean | null }) => {
    this.setState({ focused: arg.focused || null })
  }
}

export default compose<TProps, OwnProps>(withStyles(styles))(DatePicker)
