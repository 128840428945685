import { VehicleUpdateResponse } from '../api/api'
import { Other } from '@fragus/sam-types'

export const isVehicleUpdateResponseObject = (object: any): object is VehicleUpdateResponse => {
  return 'vin' in object
}

export const isOtherObject = (object: any): object is Other => {
  return 'itemNumber' in object
}
