import {
  Button,
  Checkbox,
  createStyles,
  Dialog,
  DialogContent,
  IconButton,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import {
  DeleteOutlineOutlined as DeleteIcon,
  Input as DownloadIcon,
  Edit as EditIcon,
  WarningRounded as WarningIcon,
} from '@material-ui/icons'
import { IPaginatedQueryParams } from '@fragus/sam-types'
import { deleteVehicleBlacklist, exportBlacklistFileUrl, updateVehicleBlacklist } from 'api/api'
import { LayoutActions, LayoutActionsLeft, LayoutActionsRight } from 'components/Mui/Layout'
import SpinnerButton from 'components/Mui/SpinnerButton'
import SearchBar from 'components/SearchBar'
import TableLoadingIndicator from 'components/TableLoadingIndicator'
import Typography from 'components/Typography'
import React, { ChangeEvent } from 'react'
import ReactTable, { Column, SortingRule } from 'react-table'
import { theme as customTheme } from 'theme'
import { t, tFuelType } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import { defaultTableState, ITableState } from 'utils/react-table'
import BlacklistInput, { IBlacklistedVehicle } from './BlacklistInput'

export interface IBlacklistedVehicleResponse {
  id: number
  brand: string
  model: string
  fuelType: string
  parentRecord?: boolean
}

export type BlacklistedVehicleOrderByType = 'id' | 'brand' | 'model' | 'fuelType'

const headerKeys: { [key: string]: TranslationKey } = {
  id: 'ID',
  brand: 'Brand',
  model: 'Model',
  fuelType: 'Fuel Type',
}

const headerText = (data: any): string => {
  if (data && data.column && data.column.id && typeof data.column.id === 'string') {
    return t(headerKeys[data.column.id])
  } else {
    return ''
  }
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    delete: {
      color: customTheme.palette.context.warning[500],
    },
    deleteMultiple: {
      color: 'white',
      backgroundColor: customTheme.palette.context.warning[500],
      '&:hover': {
        backgroundColor: customTheme.palette.context.warning[700],
      },
      marginTop: spacing(1),
    },
    icon: {
      marginRight: spacing(1),
      fontSize: 14,
    },
    visibleOverflowY: {
      overflowY: 'visible',
    },
  })

interface IProps {
  onDeletedRecord: () => Promise<void>
  blackListedVehicles: IBlacklistedVehicleResponse[]
  tableQuery: IPaginatedQueryParams<BlacklistedVehicleOrderByType>
  tableState: ITableState
  searchQuery: string
  onUpdateTableQueryAndState: (state?: Partial<ITableState>) => void
  onUpdateSearchQuery: (searchQuery: string) => void
  providerId: number
  isParentUser: boolean
  readOnly: boolean
}

type TProps = IProps & WithStyles<typeof styles>

interface IState {
  blackListedVehicles: IBlacklistedVehicleResponse[]
  loading: boolean
  columns: Column[]
  tableQuery: IPaginatedQueryParams<BlacklistedVehicleOrderByType>
  tableState: ITableState
  searchQuery: string
  searching: boolean
  selectedIds: number[]
  deleteDialogOpen: boolean
  vehicleToDelete?: IBlacklistedVehicleResponse
  editedVehicle?: IBlacklistedVehicleResponse
  editDialogOpen: boolean
}

class BlacklistTable extends React.Component<TProps, IState> {
  private columns: Column[] = [
    {
      Header: headerText,
      accessor: 'id',
    },
    {
      Header: headerText,
      accessor: 'brand',
      Cell: ({ value }: { value: string }) => <div>{value}</div>,
    },
    {
      Header: headerText,
      accessor: 'model',
      Cell: ({ value }: { value: string }) => <div>{value}</div>,
    },
    {
      Header: headerText,
      accessor: 'fuelType',
      Cell: ({ value }: { value: string }) => <div>{tFuelType(value)}</div>,
    },
    {
      Header: headerText,
      accessor: 'Edit',
      headerStyle: { textAlign: 'center' },
      sortable: false,
      Cell: ({ original }: { original: IBlacklistedVehicleResponse }) => {
        if (this.props.readOnly) {
          return <></>
        }

        const selected = this.state.selectedIds.some((id) => id === original.id)
        return original.parentRecord ? (
          // Editing of parent provider records is not allowed
          <div></div>
        ) : (
          <div>
            <IconButton onClick={(e: any) => this.handleUpdateEditDialogOpen(e, original)}>
              <EditIcon />
            </IconButton>
            <IconButton
              className={this.props.classes.delete}
              onClick={(e: any) => this.handleUpdateDeleteDialogOpen(e, original)}
            >
              <DeleteIcon />
            </IconButton>
            <Checkbox
              checked={selected}
              onChange={(e) => {
                this.handleCheckboxPressed(e, original.id)
              }}
            />
          </div>
        )
      },
    },
  ]

  state: Readonly<IState> = {
    blackListedVehicles: [],
    loading: false,
    columns: this.columns,
    searchQuery: '',
    tableState: {
      ...defaultTableState(10),
      sorted: [{ desc: true, id: 'id' }],
      resized: [],
    },
    tableQuery: {
      pagination: {
        limit: 10,
        offset: 0,
        orderBy: 'id',
        orderDirection: 'DESC',
      },
      search: '',
    },
    selectedIds: [],
    searching: false,
    deleteDialogOpen: false,
    editDialogOpen: false,
  }

  render() {
    const { classes, searchQuery, blackListedVehicles, tableState, providerId, readOnly, onDeletedRecord } = this.props
    const {
      columns,
      loading,
      selectedIds,
      vehicleToDelete,
      deleteDialogOpen,
      editDialogOpen,
      editedVehicle,
    } = this.state

    return (
      <>
        <LayoutActions>
          <LayoutActionsLeft>
            <Button
              disabled={!blackListedVehicles.filter((v) => !v.parentRecord).length}
              color="primary"
              variant="contained"
              href={exportBlacklistFileUrl(providerId, 'xls')}
            >
              <DownloadIcon className={classes.icon} />
              {t('Export to xls')}
            </Button>
          </LayoutActionsLeft>
          <LayoutActionsRight>
            <SearchBar
              searchQuery={searchQuery}
              searchFieldLabel={t('Search blacklisted vehicle')}
              onChange={this.handleSearchUpdate}
              onClear={this.handleSearchUpdate}
            />
          </LayoutActionsRight>
        </LayoutActions>
        <ReactTable
          className="react-table"
          columns={columns}
          data={blackListedVehicles}
          defaultPageSize={10}
          defaultSorted={tableState.sorted}
          loading={loading}
          LoadingComponent={TableLoadingIndicator}
          noDataText={!loading ? t('No Results Found') : ''}
          manual={true}
          onPageChange={this.handlePageChange}
          onPageSizeChange={this.handlePageSizeChange}
          onSortedChange={this.handleSortChange}
          page={tableState.page}
          pageSize={tableState.pageSize}
          pages={tableState.pages}
          showPaginationBottom={blackListedVehicles.length > 0}
          minRows={3}
          pageText={t('Page')}
          ofText={t('of')}
          rowsText={t('rows')}
        />
        <LayoutActions>
          {!readOnly && (
            <LayoutActionsRight>
              <Button
                className={classes.deleteMultiple}
                variant="contained"
                disabled={!selectedIds || !selectedIds.length}
                onClick={this.handleUpdateDeleteDialogOpen}
              >
                {t('Delete selected (%selectedCount)', { selectedCount: selectedIds.length })}
              </Button>
            </LayoutActionsRight>
          )}
        </LayoutActions>
        <Dialog open={deleteDialogOpen}>
          <DialogContent>
            {vehicleToDelete ? (
              <>
                <WarningIcon />
                <>
                  <Typography>
                    {t('Are you sure you want to delete the %brand %model %fuelType record?', {
                      brand: vehicleToDelete.brand,
                      model: vehicleToDelete.model,
                      fuelType: vehicleToDelete.fuelType,
                    })}
                  </Typography>
                </>
              </>
            ) : (
              <>
                <Typography>
                  {t('Are you sure you want to delete %nrOfRecords records?', {
                    nrOfRecords: selectedIds ? selectedIds.length : 0,
                  })}
                </Typography>
              </>
            )}
            <SpinnerButton onClick={this.handleDelete}>{t('Delete')}</SpinnerButton>
            <Button onClick={this.handleUpdateDeleteDialogOpen}>{t('Cancel')}</Button>
          </DialogContent>
        </Dialog>
        <Dialog
          classes={{
            paper: classes.visibleOverflowY,
          }}
          open={editDialogOpen}
        >
          <DialogContent className={classes.visibleOverflowY}>
            <BlacklistInput
              handleSubmit={this.handleEdit}
              onCancel={this.handleUpdateEditDialogOpen}
              onCreated={onDeletedRecord}
              initialBlacklist={editedVehicle}
              saveButtonLabel={t('Save')}
              hideFileUpload
              disableReset
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }

  private handleUpdateDeleteDialogOpen = (e?: any, record?: IBlacklistedVehicleResponse) => {
    this.setState({ deleteDialogOpen: !this.state.deleteDialogOpen, vehicleToDelete: record })
  }

  private handleDelete = async () => {
    const { providerId } = this.props
    const { loading, vehicleToDelete, selectedIds } = this.state
    if (!loading) {
      if (vehicleToDelete) {
        const res = await deleteVehicleBlacklist([vehicleToDelete.id], providerId)
        if (res.data) {
          const newSelectedIds = selectedIds.filter((id) => id !== vehicleToDelete.id)
          this.setState({
            loading: false,
            selectedIds: newSelectedIds,
            vehicleToDelete: undefined,
            deleteDialogOpen: false,
          })
          this.props.onDeletedRecord()
          return
        }
      } else if (selectedIds.length) {
        const res = await deleteVehicleBlacklist(selectedIds, providerId)
        if (res.data) {
          this.setState(
            { loading: false, selectedIds: [], vehicleToDelete: undefined, deleteDialogOpen: false },
            () => {
              this.props.onDeletedRecord()
            },
          )
          return
        }
      }
      this.setState({ loading: false })
    }
  }

  private handleUpdateEditDialogOpen = (e?: any, record?: IBlacklistedVehicleResponse) => {
    this.setState({ editDialogOpen: !this.state.editDialogOpen, editedVehicle: record })
  }

  private handleEdit = async (values: IBlacklistedVehicle) => {
    const { providerId } = this.props
    const { loading, editedVehicle } = this.state
    if (!loading && editedVehicle) {
      const res = await updateVehicleBlacklist(editedVehicle.id, values, providerId)
      if (res.data) {
        this.setState({ loading: false, editedVehicle: undefined, editDialogOpen: false })
        this.handlePageChange(this.props.tableState.page)
        return true
      }
      this.setState({ loading: false })
    }

    return false
  }

  private handleCheckboxPressed = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    let selectedIds: number[] = Object.assign(this.state.selectedIds, [])
    if (e.target.checked) {
      selectedIds.push(id)
    } else {
      selectedIds = selectedIds.filter((selected) => selected !== id)
    }

    this.setState({ selectedIds })
  }

  private handleSearchUpdate = (e?: ChangeEvent<HTMLInputElement>) => {
    this.setState({ searching: true })
    const value = e ? e.target.value : ''
    this.props.onUpdateSearchQuery(value)
    this.setState({ searching: false })
  }

  private handlePageChange = (page: number) => {
    this.setState({ selectedIds: [] })
    this.props.onUpdateTableQueryAndState({ ...{ page } })
  }

  private handlePageSizeChange = (pageSize: number, page: number) => {
    this.setState({ selectedIds: [] })
    this.props.onUpdateTableQueryAndState({ ...{ page, pageSize } })
  }

  private handleSortChange = (sorted: SortingRule[]) => {
    this.setState({ selectedIds: [] })
    this.props.onUpdateTableQueryAndState({ ...{ sorted } })
  }
}

export default withStyles(styles)(BlacklistTable)
