import {
  ICustomContractCreationRequest,
  IStandardContractCreationRequest,
  IStandardV4PricingToolContractCreationRequest,
  IVehicleInfo,
  PaymentGateway,
  PriceSource,
  ProductAlongItsContracts,
  Vehicle,
  VehicleAlongItsContracts,
  IContractPrintCreationRequest,
  IContractAdjustmentRequest,
} from '@fragus/sam-types'
import { vehicleToVehicleInfo } from 'utils/vehicle'
import { IOwnProps, IState } from '../types'

export const createCreateOfferRequest = (
  { contract, freeContract, providerInfo }: IOwnProps,
  offerExpiresAt: IState['offerExpiresAt'],
  newContractPaymentGateway?: PaymentGateway,
) => {
  const { value } = contract
  const isVehicle = 'vin' in contract.product

  const vehicleForRequest: VehicleAlongItsContracts | ProductAlongItsContracts = isVehicle
    ? { ...(contract.product! as VehicleAlongItsContracts) }
    : { ...(contract.product! as ProductAlongItsContracts) }
  vehicleForRequest.contracts = { contracts: [], durations: [], mileageDurationsMap: null }
  const parsedStartDateISOString = !contract.contractStartDateISO
    ? ''
    : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

  if (freeContract) {
    const request: ICustomContractCreationRequest = {
      type: 'CUSTOM',
      contractProviderId: providerInfo!.providerId,
      priceSource: contract.template!.priceSource,
      contractProductType: contract.v4ProductType,
      contractTemplateName: '' + contract.template?.description,
      contractTemplateId: contract.template!.id,
      serviceVariantId: contract.template!.serviceVariantId,
      serviceVariantName: contract.template!.serviceVariantName,
      duration: contract.duration.value,
      mileage: contract.mileage.value,
      startDateISOString: parsedStartDateISOString,
      value: value ? value.value : value,
      valueType: contract.valueType,
      startValue: contract.startValue,
      startValueType: contract.startValueType,
      product: vehicleForRequest,
      paymentGateway: newContractPaymentGateway || contract.paymentGateway,
      optionIds: contract.options.map((option) => option.id),
      amountPerPayment: contract.payments.customAmountPrPayment,
      providerPayments: contract.payments.providerPayments,
      providerShare: contract.payments.providerShare.priceInclVat,
      // Only add the customer if customerId is not set
      customer: contract.customerId ? undefined : contract.customer,
      invoiceCustomer: contract.invoiceCustomerId ? undefined : contract.customer,
      customerId: contract.customerId,
      invoiceCustomerId: contract.invoiceCustomerId,
      reference: contract.reference,
      startMileage: contract.startMileage,
      isDownpaymentDistributed: contract.payments.isDownpaymentDistributed,
      offerExpiresAt: offerExpiresAt?.toDate(),
    }

    return request
  } else {
    const priceSource: PriceSource = contract.template!.priceSource
    const parsedStartDateISOString = !contract.contractStartDateISO
      ? ''
      : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

    const request: IStandardContractCreationRequest = {
      type: 'STANDARD',
      contractProviderId: providerInfo!.providerId,
      contractTemplateName: '' + contract.template?.description,
      contractTemplateId: contract?.template?.id || null,
      serviceVariantId: contract.template!.serviceVariantId,
      serviceVariantName: contract.template!.serviceVariantName,
      duration: contract.duration.value,
      mileage: contract.mileage.value,
      startDateISOString: parsedStartDateISOString,
      product: vehicleForRequest,
      paymentGateway: newContractPaymentGateway || contract.paymentGateway,
      optionIds: contract.options.map((option) => option.id),
      providerPayments: contract.payments.providerPayments,
      providerShare: contract.payments.providerShare.priceInclVat,
      customer: contract.customerId ? undefined : contract.customer,
      invoiceCustomer: contract.invoiceCustomerId ? undefined : contract.customer,
      customerId: contract.customerId,
      invoiceCustomerId: contract.invoiceCustomerId,
      reference: contract.reference,
      startMileage: contract.startMileage,
      startValue: contract.startValue,
      startValueType: contract.startValueType,
      value: value ? value.value : value,
      valueType: contract.valueType,
      isDownpaymentDistributed: contract.payments.isDownpaymentDistributed,
      priceSource: contract.template?.priceSource as PriceSource,
      contractProductType: contract.v4ProductType,
      offerExpiresAt: offerExpiresAt?.toDate(),
    }

    switch (priceSource) {
      case 'V4PricingTool':
      case 'Autoexperten':
        if (priceSource === 'V4PricingTool') {
          if (!contract.template?.v4ProductId) {
            throw new Error('PriceCalculation is missing v4ProductId in template: ' + !contract.template?.v4ProductId)
          }
        }

        const vehicle: Vehicle = contract.product! as Vehicle
        const vehicleInfo: IVehicleInfo = vehicleToVehicleInfo(vehicle)
        const parsedStartDateISOString = !contract.contractStartDateISO
          ? ''
          : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

        const v4PTRequest: IStandardV4PricingToolContractCreationRequest = {
          ...request,
          vehicleInfo: vehicleInfo,
          v4ProductId: contract.template?.v4ProductId || 0,
          startDateISOString: parsedStartDateISOString,
        }
        return v4PTRequest
      default:
        return request
    }
  }
}

export const createProductPrintOfferRequest = (
  { contract, freeContract, prettyIdentifier, providerInfo }: IOwnProps,
  offerExpiresAt: IState['offerExpiresAt'],
) => {
  const { duration, value, startValue, startValueType, valueType } = contract

  const vehicleForRequest: VehicleAlongItsContracts = { ...(contract.product! as VehicleAlongItsContracts) }
  vehicleForRequest.contracts = { contracts: [], durations: [], mileageDurationsMap: null }
  const parsedStartDateISOString = !contract.contractStartDateISO
    ? ''
    : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

  const request: IContractPrintCreationRequest = {
    type: freeContract ? 'CUSTOM' : 'STANDARD',
    contractProviderId: providerInfo!.providerId,
    priceSource: contract.template!.priceSource,
    contractTemplateName: '' + contract.template?.description,
    contractTemplateId: contract.template!.id,
    serviceVariantId: contract.template!.serviceVariantId,
    serviceVariantName: contract.template!.serviceVariantName,
    value: value ? value.value : value,
    valueType,
    duration: duration.value,
    startDateISOString: parsedStartDateISOString,
    product: vehicleForRequest,
    paymentGateway: contract.paymentGateway,
    optionIds: contract.options.map((option) => option.id),
    amountPerPayment:
      contract.payments.customAmountPrPayment > 0
        ? contract.payments.customAmountPrPayment
        : contract.payments.amountPrPayment.priceInclVat, // contract.payments.customAmountPrPayment,
    providerPayments: contract.payments.providerPayments,
    providerShare: contract.payments.providerShare.priceInclVat,
    customer: contract.customerId ? undefined : contract.customer,
    invoiceCustomer: contract.invoiceCustomerId ? undefined : contract.customer,
    customerId: contract.customerId,
    invoiceCustomerId: contract.invoiceCustomerId,
    reference: contract.reference,
    startMileage: contract.startMileage,
    startValue: startValue,
    startValueType: startValueType,
    isDownpaymentDistributed: contract.payments.isDownpaymentDistributed,
    adjustedFrom: prettyIdentifier,
    isAdjustment: contract.flowType === 'ADJUST',
    contractProductType: null,
    offerExpiresAt: offerExpiresAt?.toDate(),
  }
  return request
}

export const createPrintOfferRequest = (
  { contract, freeContract, prettyIdentifier, providerInfo }: IOwnProps,
  offerExpiresAt: IState['offerExpiresAt'],
) => {
  const { value } = contract

  const vehicleForRequest: VehicleAlongItsContracts = { ...(contract.product! as VehicleAlongItsContracts) }
  vehicleForRequest.contracts = { contracts: [], durations: [], mileageDurationsMap: null }
  const parsedStartDateISOString = !contract.contractStartDateISO
    ? ''
    : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

  const request: IContractPrintCreationRequest = {
    type: freeContract ? 'CUSTOM' : 'STANDARD',
    contractProviderId: providerInfo!.providerId,
    priceSource: contract.template!.priceSource,
    contractTemplateId: contract.template!.id,
    contractTemplateName: '',
    serviceVariantId: contract.template!.serviceVariantId,
    serviceVariantName: contract.template!.serviceVariantName,
    duration: contract.duration.value,
    mileage: contract.mileage.value,
    startDateISOString: parsedStartDateISOString,
    value: value ? value.value : value,
    product: vehicleForRequest,
    paymentGateway: contract.paymentGateway,
    optionIds: contract.options.map((option) => option.id),
    amountPerPayment:
      contract.payments.customAmountPrPayment >= 0 // (!) NOTE: Important that zero is included!
        ? contract.payments.customAmountPrPayment
        : contract.payments.amountPrPayment.priceInclVat, // contract.payments.customAmountPrPayment,
    providerPayments: contract.payments.providerPayments,
    providerShare: contract.payments.providerShare.priceInclVat,
    customer: contract.customerId ? undefined : contract.customer,
    invoiceCustomer: contract.invoiceCustomerId ? undefined : contract.customer,
    customerId: contract.customerId,
    invoiceCustomerId: contract.invoiceCustomerId,
    reference: contract.reference,
    startMileage: contract.startMileage,
    startValue: contract.startValue,
    valueType: contract.valueType,
    startValueType: contract.startValueType,
    isDownpaymentDistributed: contract.payments.isDownpaymentDistributed,
    adjustedFrom: prettyIdentifier,
    isAdjustment: contract.flowType === 'ADJUST',
    contractProductType: null,
    offerExpiresAt: offerExpiresAt?.toDate(),
  }

  const priceSource: PriceSource = contract.template!.priceSource

  switch (priceSource) {
    case 'V4PricingTool':
      if (!contract.template?.v4ProductId) {
        throw new Error('PriceCalculation is missing v4ProductId in template: ' + !contract.template?.v4ProductId)
      }

      const vehicle: Vehicle = contract.product! as Vehicle
      const vehicleInfo: IVehicleInfo = vehicleToVehicleInfo(vehicle)

      const v4PTRequest: IStandardV4PricingToolContractCreationRequest = {
        ...(request as IStandardContractCreationRequest),
        v4ProductId: contract.template.v4ProductId,
        serviceVariantName: '',
        vehicleInfo: vehicleInfo,
      }
      return v4PTRequest
    default:
      return request
  }
}

export const createAdjustOfferRequest = (
  { contract, providerInfo }: IOwnProps,
  offerExpiresAt: IState['offerExpiresAt'],
): IContractAdjustmentRequest => {
  const { value, payments } = contract

  const parsedStartDateISOString = !contract.contractStartDateISO
    ? ''
    : new Date(contract.contractStartDateISO).toISOString() // NOTE: Important to call toISOString() on a created Date object.

  return {
    contractProviderId: providerInfo!.providerId,
    contractTemplateId: contract.template!.id,
    serviceVariantId: contract.template!.serviceVariantId,
    serviceVariantName: contract.template!.serviceVariantName,
    duration: contract.duration.value,
    mileage: contract.mileage.value,
    startDateISOString: parsedStartDateISOString,
    value: value ? value.value : value,
    optionIds: contract.options.map((option) => option.id),
    providerPayments: payments.providerPayments,
    providerShare: payments.providerShare.priceInclVat,
    startMileage: contract.startMileage,
    startValue: contract.startValue,
    valueType: contract.valueType,
    startValueType: contract.startValueType,
    type: contract.contractType,
    amountPerPayment: payments.customAmountPrPayment,
    contractProductType: null,
    offerExpiresAt: offerExpiresAt?.toDate(),
  }
}
