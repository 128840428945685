import {
  CircularProgress,
  createStyles,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons'
import { formatPhone } from '@omnicar/sam-format'
import { IAdminCustomer } from '@fragus/sam-types'
import { getCustomerFromEmail } from 'api/api'
import classNames from 'classnames'
import Paper from 'components/Mui/Paper'
import Typography from 'components/Typography'
import Downshift, { ControllerStateAndHelpers } from 'downshift'
import debounce from 'lodash.debounce'
import React from 'react'
import { t } from 'translations/translationFunctions'

interface IOwnProps {
  onChange: (customer: IAdminCustomer) => void
  onClear: () => void
  filledFromSearch: boolean
  customer?: IAdminCustomer
  customerUpdates: number
}

type TProps = WithStyles<typeof styles> & IOwnProps

interface IState {
  searchQuery: string
  customers: IAdminCustomer[]
  searching: boolean
  hasPerformedSearch: boolean
}

const styles = (theme: Theme) =>
  createStyles({
    cssLabel: {
      color: theme.palette.primary.contrastText,
      '&:focus': {
        color: theme.palette.primary.contrastText,
      },
    },
    cell: {
      lineHeight: '1.4em',
    },
    phone: {
      whiteSpace: 'nowrap',
    },
    root: {
      position: 'relative',
      margin: theme.spacing(0, 2, 3), // = '0px 2*spacing.unit px 3*spacing.unit px'
    },
    icon: {
      color: theme.palette.primary.dark,
    },
    clearIcon: {
      cursor: 'pointer',
    },
    results: {
      position: 'absolute',
      zIndex: 200,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      maxHeight: '200px',
      overflowY: 'scroll',
    },
    focusedSearchResult: {
      backgroundColor: theme.palette.secondary[100],
    },
    searchResult: {
      cursor: 'pointer',
    },
    noResults: {
      // padding: `${theme.spacing( 4}px 0`,
      padding: theme.spacing(4, 0),
      textAlign: 'center',
    },
  })

const initialState: IState = {
  searchQuery: '',
  searching: false,
  customers: [],
  hasPerformedSearch: false,
}

class ContractFlowCustomerLookup extends React.Component<TProps, IState> {
  private searchInput: any

  public constructor(props: TProps) {
    super(props)
    this.searchCustomers = debounce(this.searchCustomers, 500)
    const state = { ...initialState }
    if (props.filledFromSearch && props.customer && props.customer.name.length > 0) {
      state.searchQuery = props.customer.name
    }
    this.state = state
  }

  public componentDidUpdate() {
    const { customer, filledFromSearch } = this.props
    if (filledFromSearch && customer && customer.name.length > 0 && customer.name !== this.state.searchQuery) {
      this.setState({ searchQuery: customer.name })
    }
  }

  public render() {
    const { classes, onClear } = this.props
    const { customers, searchQuery, hasPerformedSearch, searching } = this.state
    const hasResults = customers && customers.length > 0

    return (
      <Downshift
        onChange={this.itemSelected}
        onInputValueChange={this.inputChanged}
        // tslint:disable-next-line:jsx-no-lambda
        itemToString={() => searchQuery}
        selectedItem={searchQuery}
      >
        {({ getLabelProps, getInputProps, getItemProps, highlightedIndex, isOpen, openMenu, clearSelection }) => (
          <main className={classes.root}>
            <FormControl margin="dense" fullWidth={true}>
              <InputLabel htmlFor="customer-search" {...getLabelProps()}>
                {t('Search for customer')}
              </InputLabel>
              <Input
                id="customer-search"
                // tslint:disable-next-line jsx-no-lambda
                inputRef={(el: any) => (this.searchInput = el)}
                {...getInputProps()}
                // tslint:disable-next-line:jsx-no-lambda
                onFocus={() => {
                  if (hasResults) {
                    openMenu()
                  }
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <>
                      {searching && <CircularProgress /*className={classes.progress}*/ color="secondary" size={20} />}
                      {searchQuery ? (
                        <ClearIcon
                          // tslint:disable-next-line jsx-no-lambda
                          onClick={() => {
                            this.setState({ ...initialState })
                            clearSelection()
                            onClear()
                          }}
                          className={classes.clearIcon}
                        />
                      ) : (
                        <SearchIcon className={classes.icon} />
                      )}
                    </>
                  </InputAdornment>
                }
              />
            </FormControl>
            {isOpen && hasPerformedSearch && (
              <Paper className={classes.results}>
                <Table>
                  <TableBody>
                    {hasResults ? (
                      customers.map((item: IAdminCustomer, index: number) => (
                        <TableRow
                          {...getItemProps({ item, key: item.prettyIdentifier })}
                          className={classNames(
                            index === highlightedIndex ? classes.focusedSearchResult : classes.cssLabel,
                            classes.searchResult,
                          )}
                        >
                          <TableCell className={classes.cell}>{item.name}</TableCell>
                          <TableCell className={classes.cell}>{item.email}</TableCell>
                          <TableCell className={classNames(classes.cell, classes.phone)}>
                            {formatPhone(item.phone)}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={3}>
                          <Typography className={classes.noResults} variant="subtitle">
                            {t('No Results Found')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Paper>
            )}
          </main>
        )}
      </Downshift>
    )
  }

  private inputChanged = (value: string, { isOpen, closeMenu }: ControllerStateAndHelpers<IAdminCustomer>) => {
    const { searchQuery } = this.state
    if (searchQuery !== value) {
      this.setState({ searchQuery: value }, () => {
        if (value.length > 0) {
          this.searchCustomers(value)
        } else if (value.length === 0) {
          this.setState({ ...initialState }, isOpen ? closeMenu : undefined)
        }
      })
    }
  }

  private itemSelected = (selectedItem?: IAdminCustomer) => {
    if (selectedItem) {
      this.searchInput.blur()
      this.props.onChange(selectedItem)
    }
  }

  private searchCustomers = (query: string) => {
    // In case a new search has started, don't continue
    if (this.state.searchQuery !== query) {
      return
    }
    this.setState({ searching: true }, async () => {
      const result = await getCustomerFromEmail(query)
      // In case a new search has started, don't continue
      if (this.state.searchQuery !== query) {
        return
      }
      const newState: IState = { ...this.state, searching: false }
      if (result.data) {
        newState.hasPerformedSearch = true
        newState.customers = result.data.result
      } else {
        // TODO: show error - perhaps instead of no results?
      }
      this.setState(newState)
    })
  }
}

export default withStyles(styles)(ContractFlowCustomerLookup)
