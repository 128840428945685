import { Button, InputAdornment, TextField } from '@material-ui/core'
import { AccountBox } from '@material-ui/icons'
import React, { ChangeEvent } from 'react'
import { RouteComponentProps } from 'react-router'
import { t } from 'translations/translationFunctions'
import history from 'utils/history'
import { validEmail } from 'utils/regex'
import LogInPage from '../LogInPage'
import './LogInPanel.css'
import { Formik } from 'formik'
import { loginAuthPath, loginForgotPath } from 'routes/paths'

interface IFormValues {
  user: string
}

interface IParams {
  user?: string
  location: Location
}

interface IState {
  loading: boolean
  initialFormValues: IFormValues
  invalidUser: boolean
}

class User extends React.Component<RouteComponentProps<IParams>, IState> {
  constructor(props: RouteComponentProps<IParams>) {
    super(props)

    const user = props.match.params.user || ''

    this.state = {
      loading: false,
      initialFormValues: {
        user,
      },
      invalidUser: false,
    }
  }

  public render() {
    const { submit, validate } = this
    const { initialFormValues, loading, invalidUser } = this.state

    return (
      <div className="LogInPanel LogInPanel-User">
        <div className="LogInPanel__inner">
          <div className="LogInPanel__header">
            <h2 className="LogInPanel__title">{t('Please enter your email to continue')}</h2>
          </div>
          <Formik initialValues={initialFormValues} onSubmit={submit} validate={validate}>
            {({ handleChange, handleSubmit, handleBlur, values, touched, errors }) => (
              <form onSubmit={handleSubmit}>
                <div className="LogInPanel__main">
                  <TextField
                    autoFocus={true}
                    disabled={loading}
                    error={(touched.user && errors.user) || invalidUser ? true : false}
                    fullWidth={true}
                    helperText={
                      (touched.user && errors.user && errors.user) || (invalidUser && t('Not able to find user'))
                    }
                    label={t('Enter your email')}
                    name="user"
                    // tslint:disable-next-line jsx-no-lambda
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      this.setState({ invalidUser: false })
                      handleChange(e)
                    }}
                    onBlur={handleBlur}
                    value={values.user}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountBox />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="LogInPanel__actions-wrapper">
                  <div className="LogInPanel__action-secondary">
                    <Button variant="text" onClick={this.handleCustomerLogin} disabled={loading}>
                      {t('Looking for customer login? Go here')}
                    </Button>
                  </div>
                  <div className="LogInPanel__action">
                    <div className="LogInPanel__action-primary">
                      <Button
                        variant="contained"
                        color="primary"
                        // tslint:disable-next-line:jsx-no-lambda
                        onClick={() => handleSubmit}
                        type="submit"
                      >
                        {t('Next')}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    )
  }

  private validate = (values: IFormValues) => {
    const errors: Partial<IFormValues> = {}

    if (!validEmail(values.user)) {
      errors.user = t('Must be a valid email address')
    }

    return errors
  }

  private handleCustomerLogin = () => {
    window.location.href = `${process.env.REACT_APP_MY_PAGE_URL}/login`
  }

  private submit = (values: IFormValues) => {
    const { user } = values

    this.setState({ loading: true, invalidUser: false }, async () => {
      const valid = await LogInPage.validUser(user)
      const destination = this.getDestination()
      this.setState({ loading: false }, () => {
        if (valid) {
          if (valid.username && valid.password) {
            history.replace(loginAuthPath(user, destination), { internal: true })
          } else if (valid.username && !valid.password) {
            history.replace(loginForgotPath(user), { internal: true })
          } else {
            this.setState({ invalidUser: true })
          }
        } else {
          this.setState({ invalidUser: true })
        }
      })
    })
  }

  private getDestination = () => {
    const query = new URLSearchParams(this.props.location.search)
    return query.get('dest') || undefined
  }
}

export default User
