import { t } from 'translations/translationFunctions'
import { Page, PageContent, PageHeader, PageNavigation, PageTitle } from 'components/Mui/Page'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { ITab } from 'components/Mui/Page/PageNavigation'
import UsersTab from './Tabs/Users'
import FinanceTab from './Tabs/Finance'
import ReportsTab from './Tabs/Reports'
import { AppContext } from 'store/appContext'
import { getStartingAdministrationTab, getAdministrationTabFiltering } from 'utils/providerAndRoleTabAccess'
import { UserRole } from '@fragus/sam-types'
import { Theme, WithStyles, withStyles } from '@material-ui/core'
import { createPanelStyles } from 'theme'
import BlacklistTab from './Tabs/Blacklist'
import { getAuth } from 'utils/localStorage'

interface IOwnProps {
  role?: UserRole
  inactiveProvider?: boolean
}

type TProps = IOwnProps & RouteComponentProps<never> & WithStyles<typeof styles>

const styles = (theme: Theme) => createPanelStyles(theme, {})

export type AdministrationTab = 'USERS' | 'FINANCE' | 'REPORTS' | 'VEHICLE-BLACKLIST'

interface IState {
  activeTab: AdministrationTab
}

class AdministrationPage extends React.Component<TProps, IState> {
  public state: IState = {
    activeTab: 'REPORTS',
  }

  public componentDidMount = () => {
    const { role, inactiveProvider } = this.props
    this.setStartActiveTab(role, !!inactiveProvider)
  }

  /**
   * Note: Using and having this method instead of a class property makes the title values
   * dynamically translatable (without a page reload) when switching locale.
   */
  private getHeaderTabs = (): Array<ITab<AdministrationTab>> => {
    const { role, inactiveProvider } = this.props
    const headerTabs = [
      { title: t('Users'), name: 'USERS', e2e: 'administration-page__tabs--users' },
      { title: t('Finance'), name: 'FINANCE', e2e: 'administration-page__tabs--finance' },
      { title: t('Reports'), name: 'REPORTS', e2e: 'administration-page__tabs--reports' },
      { title: t('Vehicle Blacklist'), name: 'VEHICLE-BLACKLIST', e2e: 'administration-page__tabs--blacklist' },
    ]

    const samAdminAuth = getAuth()
    // hide blacklist for everyone except superusers and one exception for Autoexperten admin users
    // this (ax exception) will probably need to be chnaged changed (in the future) therefore this code, samAdminAuth = getAuth().
    const isAutoexpertenAndAdmin = samAdminAuth?.roles?.some((m) => m.contractProvider.id === 255 && m.role === 'admin')

    samAdminAuth && !samAdminAuth?.isSuperAdmin && !isAutoexpertenAndAdmin && headerTabs.pop()

    const filters = getAdministrationTabFiltering(role, !!inactiveProvider)
    return headerTabs.filter((t) => filters.every((filter) => filter(t))) as Array<ITab<AdministrationTab>>
  }

  public render() {
    const { activeTab } = this.state
    const headerTabs = this.getHeaderTabs()

    return (
      <Page>
        <PageHeader>
          <PageTitle>{t('Administration')}</PageTitle>
          <PageNavigation active={activeTab} onChange={this.handleTabChange} tabs={headerTabs} />
        </PageHeader>
        <PageContent>
          <UsersTab active={activeTab === 'USERS'} />
          <FinanceTab active={activeTab === 'FINANCE'} />
          <ReportsTab active={activeTab === 'REPORTS'} />
          <BlacklistTab active={activeTab === 'VEHICLE-BLACKLIST'} />
        </PageContent>
      </Page>
    )
  }

  private setStartActiveTab = (role: UserRole | undefined, inactive: boolean) => {
    const activeTab = getStartingAdministrationTab(role, inactive)
    if (activeTab !== this.state.activeTab) this.setState({ activeTab: activeTab as AdministrationTab })
  }

  private handleTabChange = (tab: string) => {
    const targetTab = tab as AdministrationTab
    this.setState({ activeTab: targetTab })
  }
}

// Won't work without withStyles
export default withStyles(styles)((props) => (
  <AppContext.Consumer>
    {({ role, providerInfo }) => (
      <AdministrationPage {...props} role={role} inactiveProvider={!!providerInfo && providerInfo.inactive} />
    )}
  </AppContext.Consumer>
))
