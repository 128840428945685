import { IconButton } from '@material-ui/core'
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { CalendarToday as CalendarIcon } from '@material-ui/icons'
import { IsoLocale } from '@fragus/sam-types/types/locale'
import classNames from 'classnames'
import moment from 'moment'
import React, { Component } from 'react'
import Picker from 'react-month-picker'
import 'react-month-picker/css/month-picker.css'
import { compose } from 'recompose'
import { t } from 'translations/translationFunctions'
import { dateLocalToIso, defaultDateFormat, localizedMonthName } from 'utils/date'
import styles from './styles'

const arrowIcon = String.fromCharCode(10230)

export interface ISingleMonth {
  year: number
  month: number
}

export interface IMonthRange {
  from: ISingleMonth
  to: ISingleMonth
}

interface IOwnProps {
  validRange: { min: ISingleMonth; max: ISingleMonth }
  defaultRange: IMonthRange
  locale: IsoLocale
  onChange: (range: IMonthRange) => any
  rawMonthFormat: string
  isDisabled: boolean
  labelSeperator?: string | number
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  ref: React.RefObject<any>
  selectedRange: IMonthRange
  isMounted: boolean
}

const months: string[] = [
  'January',
  'Febuary',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const dateRangeToIsoRange = (monthRange: IMonthRange): { min: string; max: string } => {
  let fromMonth = formatMonthNr(monthRange.from.month)
  let toMonth = formatMonthNr(monthRange.to.month)
  let fromTo = {
    min: moment(`${monthRange.from.year}-${fromMonth}-02`, defaultDateFormat.moment),
    max: moment(`${monthRange.to.year}-${toMonth}-02`, defaultDateFormat.moment),
  }
  return {
    min: dateLocalToIso(fromTo.min.format(defaultDateFormat.moment)),
    max: dateLocalToIso(fromTo.max.format(defaultDateFormat.moment)),
  }
}

const formatMonthNr = (month: number): string => {
  return month > 9 ? month.toString() : `0${month}`
}

class MonthPicker extends Component<TProps, IState> {
  public state: IState = {
    ref: React.createRef(),
    selectedRange: this.props.defaultRange,
    isMounted: false,
  }

  public componentDidUpdate(newProps: TProps) {
    const { defaultRange } = this.props
    if (newProps.defaultRange && defaultRange !== this.state.selectedRange) {
      this.setState({ selectedRange: newProps.defaultRange })
    }
  }

  private getLocaleMonths(locale: IsoLocale, rawMonthFormat: string): string[] {
    return months.map((m, i) => {
      return localizedMonthName(i, rawMonthFormat, locale || 'en')
    })
  }

  public render() {
    const { classes, validRange, locale, rawMonthFormat, isDisabled, labelSeperator } = this.props
    const localeMonths = this.getLocaleMonths(locale, rawMonthFormat)
    let customLabelSeperator = !labelSeperator ? arrowIcon : labelSeperator

    if (labelSeperator && typeof labelSeperator === 'number') {
      customLabelSeperator = String.fromCharCode(labelSeperator)
    }

    const { ref, selectedRange } = this.state

    return (
      <div style={{ display: 'flex' }} title={t('Filter by months')}>
        <IconButton
          className={classNames(classes.iconButton)}
          data-e2e="MonthPicker__calender-btn"
          disabled={isDisabled && isDisabled}
          onClick={() => this.handleMonthBoxClick()}
        >
          <CalendarIcon />
        </IconButton>
        <Picker
          ref={ref}
          years={validRange}
          value={selectedRange}
          lang={localeMonths}
          onChange={this.handleMonthPickerChange}
          onDismiss={this.handleMonthPickerDissmiss}
        >
          {/* MonthBox (Picker Display heading) */}
          <h4
            className={classNames(classes.clickableText, classes.monthBox)}
            onClick={() => this.handleMonthBoxClick()}
          >
            {localeMonths[selectedRange.from.month - 1]} {selectedRange.from.year}
            {/* tab, LabelSeperator, tab*/}
            <span>
              &nbsp;
              {customLabelSeperator}
              &nbsp;
            </span>
            {localeMonths[selectedRange.to.month - 1]} {selectedRange.to.year}
          </h4>
        </Picker>
      </div>
    )
  }

  private handleMonthPickerChange = (value: number, text: number, listIndex: number) => {
    let range = this.state.selectedRange

    if (listIndex === 0) {
      range.from = { year: value, month: text }
    } else {
      range.to = { year: value, month: text }
    }

    this.setState({ selectedRange: range })
    this.props.onChange(range)
  }

  private handleMonthPickerDissmiss = (value: IMonthRange) => {
    this.setState({ selectedRange: value })
  }

  private handleMonthBoxClick = () => {
    this.state.ref.current.show()
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(MonthPicker)
