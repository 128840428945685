import ActionTypeKeys from './ActionTypeKeys'
import { IOutageResponse } from '@fragus/sam-types'

export interface ISetOutage {
  readonly type: ActionTypeKeys.SET_OUTAGE
  readonly outage: IOutageResponse | undefined | null
}
export const setReduxOutage = (outage: IOutageResponse | undefined | null): ISetOutage => {
  return {
    type: ActionTypeKeys.SET_OUTAGE,
    outage,
  }
}
