import { IPagination, IPaginatedQueryParams, IPaginationParams } from '@fragus/sam-types'
import { Resize, SortingRule } from 'react-table'

export interface ITableState {
  page: number
  pageSize: number
  pageSizeOptions: number[]
  pages: number
  resized: Resize[]
  sorted: SortingRule[]
}

const pageSizeOptions = [5, 10, 50, 100]

export function defaultTableState(
  pageSize: number,
): Pick<ITableState, 'page' | 'pageSize' | 'pageSizeOptions' | 'pages'> {
  return {
    page: 0,
    pageSize,
    pageSizeOptions: [...pageSizeOptions],
    pages: 0,
  }
}

export interface ITableQuery<TOrderBy, IFiltering = undefined> extends IPaginatedQueryParams<TOrderBy, IFiltering> {}

export function createTableStateFromPagination<TOrderBy>(pagination: IPagination<TOrderBy>): Partial<ITableState> {
  const { currentPage, pages, limit } = pagination
  return {
    page: currentPage,
    pages,
    pageSize: limit,
  }
}

/**
 * @param resetOffset is for resetting the offset when ordering/filtering/pageSize changes
 */
export function createQueryFromTable<TOrderBy, IFiltering = undefined>(
  tableState: ITableState,
  tableQuery: ITableQuery<TOrderBy, IFiltering>,
  resetOffset: boolean = false,
): ITableQuery<TOrderBy, IFiltering> {
  const offset = resetOffset ? 0 : tableState.page * tableState.pageSize
  const sortParams = getSortedParamsFromTableState<TOrderBy, IFiltering>(tableState, tableQuery)

  return {
    ...tableQuery,
    pagination: {
      ...tableQuery.pagination,
      ...sortParams,
      limit: tableState.pageSize,
      offset,
    },
  }
}

export function getSortedParamsFromTableState<TOrderBy, IFiltering = undefined>(
  tableState: ITableState,
  tableQuery: ITableQuery<TOrderBy, IFiltering>,
) {
  const { sorted } = tableState
  let tableSorted: SortingRule
  let querySorted: Partial<IPaginationParams<TOrderBy>> = {}

  if (tableQuery.pagination) {
    querySorted = {
      orderBy: tableQuery.pagination.orderBy,
      orderDirection: tableQuery.pagination.orderDirection,
    }

    // @NOTE - We do not support multiple sorting rules - so we get index 0
    if (sorted && sorted.length) {
      tableSorted = sorted[0]

      const sortedOrderBy = tableSorted.id as any
      const sortedOrderDirection = tableSorted.desc ? 'DESC' : 'ASC'

      if (sortedOrderDirection) {
        querySorted.orderBy = sortedOrderBy
        querySorted.orderDirection = sortedOrderDirection
      }
    }
  }

  return querySorted
}
