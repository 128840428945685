import React from 'react'
import { t } from 'translations/translationFunctions'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import { IOptionInfo } from '@fragus/sam-types'

interface IOptionsMap {
  [k: number]: boolean
}

interface IProps {
  termsOfTradeRef: string
  termsOfServiceRef: string
  onChangeAllTermsChecked: (x: boolean) => void
  options: IOptionInfo[]
  className?: string
}

interface IState {
  isToSAccepted: boolean
  isToTAccepted: boolean
  acceptedOptions: IOptionsMap
}

type TProps = IProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    section: {
      marginTop: spacing(2),
    },
    sectionItem: {
      textAlign: 'left',
      display: 'flex',
      justifyContent: 'space-between',
    },
  })

class TermsOfServiceSection extends React.Component<TProps, IState> {
  public state = {
    isToSAccepted: false,
    isToTAccepted: false,
    acceptedOptions: {} as IOptionsMap,
  }

  public render() {
    const { classes, termsOfTradeRef, termsOfServiceRef, options } = this.props
    const { isToSAccepted, isToTAccepted } = this.state

    return (
      <section className={classes.section}>
        <Typography className={classes.sectionItem} variant="body2">
          <FormControlLabel
            label={
              <span>
                {t(
                  'I have read and accepted the Terms and Conditions of the Subscription Agreement and allow the Subscription Agreement Provider to contact me by phone and/or email with information and services relevant to my car and Subscription Agreement',
                )}{' '}
                <a href={termsOfServiceRef} target="_blank" rel="noopener noreferrer">
                  {t('Terms of Service')}
                </a>
              </span>
            }
            control={<Checkbox checked={isToSAccepted} onChange={this.handleToSCheck} />}
          />
        </Typography>
        {termsOfTradeRef && (
          <Typography className={classes.sectionItem} variant="body2">
            <FormControlLabel
              label={
                <span>
                  {t('I have read and accepted the general')}{' '}
                  <a href={termsOfTradeRef} target="_blank" rel="noopener noreferrer">
                    {t('Terms of Trade')}
                  </a>
                </span>
              }
              control={<Checkbox checked={isToTAccepted} onChange={this.handleToTCheck} />}
            />
          </Typography>
        )}
        {options.map(this.renderOption)}
      </section>
    )
  }

  private renderOption = (o: IOptionInfo, id: number) => {
    const { classes } = this.props
    const { acceptedOptions } = this.state

    return (
      <Typography key={id} className={classes.sectionItem} variant="body2">
        <FormControlLabel
          label={
            <span>
              {t('I have read and accepted the Terms and Conditions of')}{' '}
              <a href={o.termsOfService ? o.termsOfService.ref : ''} target="_blank" rel="noopener noreferrer">
                {o.description}
              </a>
            </span>
          }
          control={<Checkbox checked={!!acceptedOptions[id]} value={id.toString()} onChange={this.handleOptionCheck} />}
        />
      </Typography>
    )
  }

  private handleToSCheck = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ isToSAccepted: e.currentTarget.checked }, this.handleAllChecked)
  private handleToTCheck = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ isToTAccepted: e.currentTarget.checked }, this.handleAllChecked)
  private handleOptionCheck = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState(
      {
        acceptedOptions: {
          ...this.state.acceptedOptions,
          [e.currentTarget.value]: e.currentTarget.checked,
        },
      },
      this.handleAllChecked,
    )
  private handleAllChecked = () => {
    const { isToSAccepted, isToTAccepted, acceptedOptions } = this.state
    const { options, termsOfTradeRef, onChangeAllTermsChecked } = this.props
    const acceptedOptionsList = Object.values(acceptedOptions)

    onChangeAllTermsChecked(
      (termsOfTradeRef ? isToTAccepted : true) &&
        isToSAccepted &&
        acceptedOptionsList.length === options.length &&
        acceptedOptionsList.every((x) => x),
    )
  }
}

export default withStyles(styles)(TermsOfServiceSection)
