import { Button, ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { TContractObject } from '@fragus/sam-types'
import classNames from 'classnames'
import Typography from 'components/Typography'
import React, { Component } from 'react'
import { t } from 'translations/translationFunctions'
import ImportInstructionsProduct from './Product'
import ImportInstructionsVehicle from './Vehicle'

interface IOwnProps {
  className?: string
  contractObjectType: TContractObject
  validImportHeaders: string[]
}
type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  expanded: boolean
}
const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    summary: {
      justifyContent: 'center',
      '& > :last-child': {
        padding: spacing(1), // 'expand button' padding
      },
    },
    details: {
      flexDirection: 'column',
    },
    imageWrapper: {
      position: 'relative',
    },
    image: { width: '100%' },
    blackbox: {
      padding: spacing(1),
      backgroundColor: 'rgba(0,0,0,.9)',
      position: 'absolute',
      top: '10%',
      bottom: '10%',
      left: '10%',
      right: '10%',
    },
    blackboxText: {
      color: 'white',
    },
    headers: {
      fontStyle: 'italic',
    },
    columnList: {
      listStyle: 'none',
      padding: 0,
      margin: `${spacing(1)} 0`,
    },
    verticalSpacing: {
      marginBottom: spacing(2),
    },
    iconRight: {
      marginLeft: spacing(1),
    },
    shrinkIcon: {
      transform: 'rotate(180deg)',
    },
    expandIcon: {
      transform: 'rotate(0deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
  })

class DialogWorkshopOperationsUploadInstructions extends Component<TProps, IState> {
  public state: IState = {
    expanded: false,
  }

  public render() {
    const { classes, className } = this.props
    const { expanded } = this.state

    return (
      <div className={classNames(classes.root, className)}>
        <ExpansionPanel
          elevation={0}
          expanded={expanded}
          // tslint:disable-next-line jsx-no-lambda
          onChange={(e: any, expanded: boolean) => {
            this.setState({ expanded })
          }}
        >
          <ExpansionPanelSummary classes={{ content: classes.summary }}>
            <Button>
              <Typography variant="body2">{expanded ? t('Close') : t('Upload instructions')}</Typography>
              <ExpandMoreIcon
                className={classNames(classes.iconRight, expanded ? classes.shrinkIcon : classes.expandIcon)}
              />
            </Button>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails classes={{ root: classes.details }}>
            <Typography variant="subheading">{t('Upload instructions')}</Typography>
            <Typography className={classes.verticalSpacing} variant="body1">
              {t('Add a CSV or XLS file which respects the structure presented below.')}
            </Typography>

            {this.renderInstructionsByProductType()}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }

  private renderInstructionsByProductType() {
    const { contractObjectType, classes, validImportHeaders } = this.props

    return contractObjectType === 'Product' ? (
      <ImportInstructionsProduct
        verticalSpacingClass={classes.verticalSpacing}
        validImportHeaders={validImportHeaders}
      />
    ) : (
      <ImportInstructionsVehicle
        verticalSpacingClass={classes.verticalSpacing}
        validImportHeaders={validImportHeaders}
      />
    )
  }
}

export default withStyles(styles)(DialogWorkshopOperationsUploadInstructions)
