import ConfirmDialog, { IProps as IConfirmDialogProps } from 'components/ConfirmDialog'
import React from 'react'
import { t } from 'translations/translationFunctions'
import { ContractFlowType } from 'types/contractFlow'
import { TConfirmationDialog } from '../types'

interface IProps {
  flowType: ContractFlowType
  type?: TConfirmationDialog
  onClose: () => void
  onConfirmSetting: {
    [key in TConfirmationDialog]: () => void
  }
  // at the current moment, renderOfferChildren used only in the ContractOffer dialog
  // if needed in other dialogs, it should be moved to the settings object similar to onConfirmSetting
  renderOfferChildren: () => React.ReactNode
}

type TConfirmationDialogSettings = {
  [key in TConfirmationDialog]: Pick<
    IConfirmDialogProps,
    'titleTrl' | 'contentTrlText' | 'onConfirm' | 'captionTrlConfirmButton'
  > & {
    renderChildren?: () => React.ReactNode
  }
}

const ConfirmationDialog: React.SFC<IProps> = ({ type, onClose, flowType, onConfirmSetting, renderOfferChildren }) => {
  const dialogSettings: TConfirmationDialogSettings = {
    ContractOffer: {
      titleTrl: t('Pick a expiration date for the offer'),
      onConfirm: onConfirmSetting.ContractOffer,
      captionTrlConfirmButton:
        flowType === 'CREATE' ? t('Yes, send contract offer') : t('Yes, send contract adjustment offer'),
      renderChildren: renderOfferChildren,
    },
    ActiveContract: {
      titleTrl: t('Buy one-time dealer payment'),
      contentTrlText: t('Buy this contract now as a dealer paid contract, are you sure?'),
      onConfirm: onConfirmSetting.ActiveContract,
      captionTrlConfirmButton: t('Yes, buy now'),
    },
    BuyNowV4: {
      titleTrl: t('Buy one-time dealer payment'),
      contentTrlText: t('Buy this contract now as a dealer paid contract, are you sure?'),
      onConfirm: onConfirmSetting.BuyNowV4,
      captionTrlConfirmButton: t('Yes, buy now'),
    },
    BuyNowStripe: {
      titleTrl: t('Buy customer monthly subscription'),
      contentTrlText: t('Buy this contract now as a customer monthly subscription contract, are you sure?'),
      onConfirm: onConfirmSetting.BuyNowStripe,
      captionTrlConfirmButton: t('Yes, buy now'),
    },
  }

  const dialogProps = type && dialogSettings[type]

  return (
    <ConfirmDialog {...dialogProps} open={!!dialogProps} onCancel={onClose} captionTrlCancelButton={t('No, Cancel')}>
      {dialogProps?.renderChildren && dialogProps?.renderChildren()}
    </ConfirmDialog>
  )
}

export default ConfirmationDialog
