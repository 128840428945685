import { formatLocalizedDate } from '@omnicar/sam-format'
import { getLocale } from '@omnicar/sam-translate'
import { IsoLocale } from '@fragus/sam-types'
import moment, { Moment } from 'moment'
import { IContractFlow } from 'types/contractFlow'
import { firstCharUpper } from './string'

// @TODO: this should be extracted from sam-format based on the locale
// @note
// We are currently using two different libraries for date and time
// manipulations. As it turns out (to no surprise), syntax for formatting
// strings are different.
//
// International format.
export const defaultDateFormat = {
  moment: 'YYYY-MM-DD', // Note: dateFormat for moment, https://momentjs.com/docs/
  'date-fns': 'yyyy-MM-dd', // Note: dateFormat for date-fns v2, https://date-fns.org/v2.11.1/docs/format
}

export const dateLocalToIso = (localDate?: string) => moment(localDate, defaultDateFormat.moment).toISOString()

/**
 * Add TimeZone offset to "Moment"
 *
 * See https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset
 */
export const addTimeZoneOffset = (date: Moment): Moment => moment(date.valueOf() + date.utcOffset() * 60000)

/**
 * Check if string is data format
 */
export const validDate = (date: string) => {
  if (!isNaN(Date.parse(date))) {
    return true
  } else {
    return false
  }
}

export function getContractProductAge(contract: IContractFlow) {
  if (!contract.template) {
    return 0
  }
  return getProductAge(contract.template.calculationMethod, contract.vehicleAlongItsContracts)
}

export function getProductAge(calculationMethod: number, product: { regDate?: string }): number {
  const regDate = productRegDate(product)
  if (calculationMethod === 100 || !regDate) {
    return 0
  }
  const atMoment = moment(new Date())
  const age = atMoment.diff(regDate, 'months')
  return age
}

export function productRegDate(product: { regDate?: string }): Date {
  return product.regDate ? new Date(product.regDate) : new Date()
}

export function addMonths(date: Date, nMonths: number): Date {
  const d = new Date(date)
  const d1 = d.getDate()
  const m1 = d.getMonth()
  const y1 = d.getFullYear()
  const ret = new Date(y1, m1 + nMonths, d1)
  // Handle that day-of-month leaps into upcoming month
  if (ret.getDate() < d1) {
    ret.setDate(0)
  }
  return ret
}

export function getMillisSinceEpoch(): number {
  return Date.now()
}

export function getSecondsSinceEpoch(): number {
  return Math.floor(Date.now() / 1000)
}

export function getDaysDifference(startDate: Date, endDate: Date): number {
  return moment(endDate).diff(moment(startDate), 'days')
}

export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
/**
 * parses Date from string with format 'dd.mm.yyyy'
 * @param dateString
 * @param country 'SE', 'DK', 'FI', 'NO'
 * @returns Date(UTC)
 */
export const parseDate = (dateString: string, country: string): Date | undefined => {
  try {
    let day, month, year
    if (country === 'SE') {
      ;[year, month, day] = dateString.split('-').map((e) => parseInt(e, 10))
    } else {
      ;[day, month, year] = dateString.split('.').map((e) => parseInt(e, 10))
    }
    const dateUTC = Date.UTC(year, month - 1, day)
    const date = new Date(dateUTC)
    return isNaN(date.getTime()) ? undefined : date
  } catch (error) {
    console.error(error)
    return
  }
}

export const localizedMonthName = (month: number, format: string, locale: IsoLocale): string => {
  locale = (locale as any) === '$$' ? 'en' : locale
  return firstCharUpper(formatLocalizedDate(new Date(0, month), format, locale))
}

export const formatYearMonthDate = (dateToFormat: string | Date, locale?: IsoLocale) => {
  locale = (locale as any) === '$$' ? 'en' : locale
  const date = new Date(dateToFormat)
  const month = localizedMonthName(date.getMonth(), 'MMMM', locale || getLocale() || 'en-GB')
  return `${date.getFullYear()}-${month}`
}
