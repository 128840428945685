import {
  Button,
  CircularProgress,
  FormControl,
  Input,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { StyleRules } from '@material-ui/core/styles'
import { Clear as ClearIcon, Search as SearchIcon } from '@material-ui/icons'
import { ContractOrderBy, IContractListRecord, IPagination } from '@fragus/sam-types'
import ActionTypes from 'actions/ActionTypes'
import { contractSearchUpdate, IContractSearchUpdate } from 'actions/contractSearchActions'
import { getContracts } from 'api/api'
import classNames from 'classnames'
import Paper from 'components/Mui/Paper'
import Typography from 'components/Typography'
import Downshift, { ControllerStateAndHelpers } from 'downshift'
import debounce from 'lodash.debounce'
import React, { Component } from 'react'
import { connect, Dispatch } from 'react-redux'
import { contractDetailsPath, contractListPath } from 'routes/paths'
import { theme as customTheme } from 'theme'
import { contractStateStyles } from 'theme/styles/contractState'
import { t, tContractState } from 'translations/translationFunctions'
import { trackEvent } from 'utils/analytics'
import browserHistory from 'utils/history'
import { isUseWhiteBGTheme } from 'utils/localStorage'
import { shortenPrettyIdentifier } from 'utils/regex'

const fontColorOnBackground = isUseWhiteBGTheme()
  ? customTheme.palette.background.regular
  : customTheme.paletteWhiteBG.background.regular

const styles = (theme: any): StyleRules => ({
  ...contractStateStyles({ color: true }),
  search: {
    color: isUseWhiteBGTheme() ? theme.palette.primary.main : theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
    width: 450,
    '&::placeholder': {
      color: 'red',
    },
    '&::ms-input-placeholder': {
      color: 'red',
    },
  },
  input: {
    '&::-ms-clear': {
      display: 'none',
    },
    color: fontColorOnBackground,
  },
  cssLabel: {
    color: isUseWhiteBGTheme() ? theme.palette.primary.main : theme.palette.primary.contrastText,
    '&$cssFocused': {
      color: isUseWhiteBGTheme() ? theme.palette.primary.main : theme.palette.primary.contrastText,
    },
  },
  cssFocused: {
    color: isUseWhiteBGTheme() ? theme.palette.primary.main : theme.palette.primary.contrastText,
  },
  cssUnderline: {
    color: isUseWhiteBGTheme() ? theme.palette.primary.main : theme.palette.primary.contrastText,
    '&:before': {
      borderBottomColor: isUseWhiteBGTheme() ? theme.palette.primary.main : theme.palette.primary.contrastText,
    },
    '&:after': {
      borderBottomColor: isUseWhiteBGTheme() ? theme.palette.primary.main : theme.palette.primary.contrastText,
    },
    '&:hover:before': {
      borderBottomColor: isUseWhiteBGTheme()
        ? `${theme.palette.primary.main} !important`
        : `${theme.palette.primary.contrastText} !important`,
    },
  },
  searchResult: {
    cursor: 'pointer',
  },
  focusedSearchResult: {
    backgroundColor: theme.palette.secondary[100],
  },
  clearIcon: {
    color: theme.palette.primary,
    cursor: 'pointer',
  },
  searchIcon: {
    color: theme.palette.text.light,
  },
  progress: {
    marginRight: theme.spacing(1),
  },
  cell: {
    lineHeight: '1.2em',
    minWidth: '150px',
  },
  container: {
    position: 'relative',
  },
  results: {
    position: 'absolute',
    zIndex: 200,
    marginTop: theme.spacing(1),
    right: 0,
  },
  noResults: {
    padding: `${theme.spacing(4)}px 0`,
    textAlign: 'center',
  },
  seeResults: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    cursor: 'pointer',
  },
})

const initialState: IState = {
  items: [],
  searchQuery: '',
  hasPerformedSearch: false,
  searching: false,
}

interface IActionProps {
  contractSearchUpdate: (query: string | undefined) => IContractSearchUpdate
  defaultPlaceholder: string | undefined
}

type Props = IActionProps & WithStyles<typeof styles>

interface IState {
  items: IContractListRecord[]
  pagination?: IPagination<ContractOrderBy>
  searchQuery: string
  hasPerformedSearch: boolean
  searching: boolean
}

class GlobalSearch extends Component<Props, IState> {
  public state = { ...initialState }
  private searchInput: any

  constructor(props: Props) {
    super(props)
    this.searchContracts = debounce(this.searchContracts, 200)
  }

  public render() {
    const { classes } = this.props
    const defaultPlaceholder = !this.props.defaultPlaceholder
      ? t('Search for contracts') // search for contracts
      : this.props.defaultPlaceholder
    const { items, hasPerformedSearch, searching, searchQuery, pagination } = this.state
    const hasResults = items && items.length > 0

    return (
      <Downshift
        // tslint:disable-next-line jsx-no-lambda
        itemToString={() => searchQuery}
        onChange={this.itemSelected}
        onInputValueChange={this.inputChanged}
      >
        {({
          getLabelProps,
          getInputProps,
          getItemProps,
          highlightedIndex,
          isOpen,
          closeMenu,
          openMenu,
          clearSelection,
        }) => (
          <div className={classes.container}>
            <FormControl>
              <Input
                tabIndex={100}
                placeholder={defaultPlaceholder}
                className={classNames(classes.search, 'AppHeader__search-field')}
                classes={{
                  underline: classes.cssUnderline,
                  input: classes.input,
                }}
                id="global-search"
                // tslint:disable-next-line jsx-no-lambda
                inputRef={(el: any) => (this.searchInput = el)}
                {...getInputProps({
                  onKeyDown: (event) => {
                    if (event.key === 'Enter' && highlightedIndex === null && hasResults) {
                      closeMenu()
                      this.seeAllResults()
                    }
                  },
                })}
                // tslint:disable-next-line jsx-no-lambda
                onBlur={(event) => {
                  if (searchQuery.length === 0) {
                    this.clearSelection(clearSelection)
                  }
                  this.searchInput.blur()
                }}
                // tslint:disable-next-line jsx-no-lambda
                onFocus={(event) => {
                  if (hasResults) {
                    openMenu()
                  }
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <>
                      {searching && <CircularProgress className={classes.progress} color="secondary" size={20} />}
                      {searchQuery ? (
                        <ClearIcon
                          // tslint:disable-next-line jsx-no-lambda
                          onClick={() => {
                            this.clearButtonClicked(clearSelection)
                          }}
                          className={classes.clearIcon}
                        />
                      ) : (
                        <SearchIcon className={classes.searchIcon} />
                      )}
                    </>
                  </InputAdornment>
                }
              />
            </FormControl>
            {isOpen && hasPerformedSearch && (
              <Paper className={classes.results}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.cell}>{t('Contract ID')}</TableCell>
                      <TableCell className={classes.cell}>{`${t('Item Number')} / ${t('License Plate')}`}</TableCell>
                      <TableCell className={classes.cell}>{t('Customer')}</TableCell>
                      <TableCell className={classes.cell}>{t('Contract Type')}</TableCell>
                      <TableCell className={classes.cell}>{t('Status')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hasResults ? (
                      items.map((item: IContractListRecord, index: number) => (
                        <TableRow
                          {...getItemProps({ item, key: item.prettyIdentifier })}
                          className={classNames(
                            index === highlightedIndex ? classes.focusedSearchResult : classes.cssLabel,
                            classes.searchResult,
                          )}
                        >
                          <TableCell className={classes.cell}>
                            {shortenPrettyIdentifier(item.prettyIdentifier)}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {item.registrationNumber ? item.registrationNumber : item.serialNumber}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {item.customerCompanyName ? item.customerCompanyName : item.customerName}
                          </TableCell>
                          <TableCell className={classes.cell}> {item.contractName}</TableCell>
                          <TableCell className={classNames(classes.cell, classes[`state${item.contractState}`])}>
                            {tContractState(item.contractState)}
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Typography className={classes.noResults} variant="subtitle">
                            {t('No Results Found')}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {hasResults && (
                    <TableFooter className={classes.footer}>
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Button
                            className={classes.seeResults}
                            // tslint:disable-next-line jsx-no-lambda
                            onClick={() => {
                              closeMenu()
                              this.seeAllResults()
                            }}
                          >
                            {`${t('See all results')}${pagination ? ` (${pagination!.totalCount})` : ''}`}
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  )}
                </Table>
              </Paper>
            )}
          </div>
        )}
      </Downshift>
    )
  }

  private clearSelection = (clear: () => void) => {
    clear()
    this.props.contractSearchUpdate(undefined)
    this.setState({ ...initialState })
  }

  private clearButtonClicked = (clear: () => void) => {
    this.clearSelection(clear)
    this.searchInput.focus()
  }

  private inputChanged = (value: string, { isOpen, closeMenu }: ControllerStateAndHelpers<IContractListRecord>) => {
    const trimmedValue = value.trim()
    const { searchQuery } = this.state
    if (searchQuery !== trimmedValue) {
      this.setState({ searchQuery: trimmedValue }, () => {
        if (trimmedValue.length > 0) {
          this.searchContracts(trimmedValue)
        } else if (trimmedValue.length === 0) {
          this.setState({ ...initialState }, isOpen ? closeMenu : undefined)
        }
      })
    }
  }

  private itemSelected = (selectedItem?: IContractListRecord) => {
    if (selectedItem) {
      trackEvent('Global search', 'Selected search result')
      this.searchInput.blur()
      browserHistory.push(contractDetailsPath(selectedItem.prettyIdentifier))
    }
  }

  private searchContracts = (query: string) => {
    // In case a new search has started, don't continue
    if (this.state.searchQuery !== query) {
      return
    }
    this.setState({ searching: true }, async () => {
      const result = await getContracts({
        search: query,
        pagination: {
          limit: 5,
        },
      })
      // In case a new search has started, don't continue (more likely)
      if (this.state.searchQuery !== query) {
        return
      }
      trackEvent('Global search', 'Searched')
      const newState: IState = { ...this.state, searching: false }
      if (result.data) {
        newState.hasPerformedSearch = true
        newState.items = result.data.result
        newState.pagination = result.data.pagination
      } else {
        // TODO: show error - perhaps instead of no results?
      }
      this.setState(newState)
    })
  }

  private seeAllResults = () => {
    trackEvent('Global search', 'Selected all results')
    this.searchInput.blur()
    const { searchQuery } = this.state
    this.props.contractSearchUpdate(searchQuery)
    browserHistory.push(contractListPath)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  contractSearchUpdate: (query: string) => dispatch(contractSearchUpdate(query)),
})

export default withStyles(styles)(connect(undefined, mapDispatchToProps)(GlobalSearch))
