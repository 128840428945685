import { withStyles, WithStyles } from '@material-ui/core/styles'
import React from 'react'
import 'react-vis/dist/style.css'
import { Card, CardContent, CircularProgress, InputLabel } from '@material-ui/core'
import {
  IContractChartsFilterParams as IAPIContractChartsFilterParams,
  IChartDateValue,
  IContractFilterDate,
  IContractFilterOptions,
  IDashboardContractCharts,
  IDashboardQueryParams,
} from '@fragus/sam-types'
import { getContractFilterOptions, getDashboardContractsData } from 'api/api'
import classNames from 'classnames'
import MonthPicker, { dateRangeToIsoRange, IMonthRange } from 'components/MonthPicker'
import { MultipleSelect, SingleSelect } from 'components/Select'
import Typography from 'components/Typography'
import debounce from 'lodash.debounce'
import moment, { Moment } from 'moment'
import { compose } from 'recompose'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import ContractsChart from './Chart'
import styles from './styles'

interface IOwnProps {
  className?: string
  onDateRangeChange?: (newDateRange: { min: string; max: string }) => any
}

type TProps = IOwnProps & WithStyles<typeof styles>
interface IState {
  activeFilters: {
    contractStates: number[]
    templates: number[]
    sellers: number[]
    created: IContractFilterDate
  }
  filters: Partial<IContractFilterOptions>
  chartData: IDashboardContractCharts[] | undefined
  loading: boolean
  pendingAPICalls: number
  defaultDateRange: IMonthRange
  isDisabled: boolean
}

class ContractsDetails extends React.Component<TProps, IState> {
  static contextType = AppContext

  public state: IState = {
    activeFilters: {
      contractStates: [],
      templates: [],
      sellers: [],
      created: { min: '', max: '' },
    },
    filters: {
      templates: [],
      sellers: [],
      created: { min: '', max: '' },
    },
    chartData: undefined,
    loading: false,
    pendingAPICalls: 0,
    defaultDateRange: {
      from: {
        year: moment().subtract(11, 'months').year(),
        month: moment().subtract(11, 'months').month(),
      },
      to: { year: moment().year(), month: moment().month() + 1 },
    },
    isDisabled: false,
  }

  public componentDidMount() {
    this.initOptions()
    this.fetchContractsData()
  }

  public render() {
    const { classes, className } = this.props
    const { activeFilters, filters, chartData, loading, defaultDateRange } = this.state

    return (
      <div className={className}>
        {defaultDateRange && filters.created && filters.created.min && filters.created.max ? (
          <div className={classNames(classes.monthPickerOverlay, classes.sectionCol, classes.sidePadding)}>
            <div className={classNames(classes.spaceBetween)}>
              <InputLabel className={classes.label} htmlFor={'picker'}>
                {`${t('Month range')}`}
              </InputLabel>
            </div>
            <MonthPicker
              validRange={{
                min: { year: moment(filters.created.min).year(), month: moment(filters.created.min).month() + 1 },
                max: { year: moment().year(), month: moment().month() + 1 },
              }}
              defaultRange={defaultDateRange}
              locale={this.context.locale}
              onChange={this.handleMonthPickerChange}
              rawMonthFormat={'MMM'}
              isDisabled={false}
            />
          </div>
        ) : (
          <span />
        )}
        <div className={classNames(classes.spaceBetween, classes.sidePadding)}>
          <Typography variant="subheading" style={{ marginBottom: 2 * 8 }}>
            {t('Contracts')}
          </Typography>
        </div>
        <Card className={classes.roundedCard}>
          <CardContent>
            <section className={classNames(classes.section, classes.bottomPaddig)}>
              <div className={classes.details}>
                <div>
                  <SingleSelect
                    helperText={t('Filter by template name')}
                    label={t('Contract template')}
                    name="templates"
                    onChange={this.handleDetailsChange}
                    options={filters.templates || []}
                    selectedValue={activeFilters.templates}
                    placeholder={t('All')}
                  />
                </div>
                <div>
                  <MultipleSelect
                    helperText={t('Filter by one or more contract sales person(s)')}
                    label={t('Contract salesperson')}
                    name="sellers"
                    onChange={this.handleDetailsChange}
                    options={filters.sellers || []}
                    selectedValues={activeFilters.sellers}
                    placeholder={t('All')}
                  />
                </div>
              </div>
            </section>
            <section className={classNames(classes.section, classes.sectionCol)}>
              {chartData && chartData[0] && (
                <ContractsChart data={chartData[0]} activeFilters={activeFilters} maxTicks={15} />
              )}
              {chartData && chartData[1] && (
                <ContractsChart data={chartData[1]} activeFilters={activeFilters} maxTicks={15} />
              )}
              {(!chartData || (chartData && !chartData.length)) && !loading && (
                <div className={classes.noDataPlaceholderContainer}>
                  <Typography className={classes.noDataPlaceholder} variant="title">
                    {t('No data found')}
                  </Typography>
                </div>
              )}
              {loading && (
                <div className={classNames(classes.overlay, classes.overlayWhite)}>
                  <CircularProgress className={classNames(classes.overlayIcon)} />
                </div>
              )}
            </section>
          </CardContent>
        </Card>
      </div>
    )
  }

  private handleMonthPickerChange = (range: IMonthRange) => {
    let isoRange = dateRangeToIsoRange(range)
    // call parent function with new value
    this.props.onDateRangeChange && this.props.onDateRangeChange(isoRange)
    this.handleDetailsChange('created', isoRange)
  }

  private initOptions = async () => {
    const fetchedOptions = await this.fetchOptions()
    if (fetchedOptions) {
      const filters = {
        templates: fetchedOptions.templates,
        sellers: fetchedOptions.sellers,
        created: fetchedOptions.created,
      }
      this.setState({ filters })
      this.initDefaultMonthRange(moment(filters.created.min))
    }
  }

  private fetchOptions = async () => {
    let options: IContractFilterOptions | undefined

    if (!this.state.loading) {
      this.setState({ loading: true })

      const response = await getContractFilterOptions()

      if (response && response.data) {
        options = response.data
      }

      this.setState({ loading: false })
    }

    return options
  }

  private initDefaultMonthRange(minDate: Moment) {
    const defaultMin = moment(
      `${this.state.defaultDateRange.from.year}-${this.state.defaultDateRange.from.month}-02`,
      'YYYY-MM-DD',
    )
    if (defaultMin.isBefore(minDate)) {
      let newDefault = {
        from: { year: minDate.year(), month: minDate.month() + 1 },
        to: this.state.defaultDateRange.to,
      }

      this.setState({ defaultDateRange: newDefault })

      let dateString = dateRangeToIsoRange(newDefault)
      this.handleDetailsChange('created', dateString)
      this.props.onDateRangeChange && this.props.onDateRangeChange(dateString)
    }
  }

  private fetchContractsData = async (newValues?: IAPIContractChartsFilterParams) => {
    const { activeFilters } = this.state
    if (newValues && activeFilters !== newValues) {
      // If we have newValues and they are different from the current ones the function is stoped
      return
    }
    this.setState({ pendingAPICalls: this.state.pendingAPICalls + 1, loading: true })
    const data: IDashboardQueryParams<IAPIContractChartsFilterParams> = {
      filtering: activeFilters,
    }
    const response = await getDashboardContractsData(data)
    const chartData = response.data && response.data.result ? response.data.result.contract : []
    const procesedChartData = chartData.map((data: any) => ({
      ...data,
      charts: data.charts.map((c: any) => ({ ...c, chartsData: this.transform(c.chartsData) })),
    }))
    this.setState({ chartData: procesedChartData, pendingAPICalls: this.state.pendingAPICalls - 1 })
    if (this.state.pendingAPICalls === 0) {
      this.setState({ loading: false })
    }
  }

  private handleDetailsChange = (name: string, value: any) => {
    const newValues = { ...this.state.activeFilters }

    if (newValues[name] !== value) {
      newValues[name] = value

      this.setState(
        { activeFilters: newValues },
        debounce(() => {
          this.fetchContractsData(newValues)
        }, 500),
      )
    }
  }

  private transform = (list: IChartDateValue[]): IChartDateValue[] =>
    list.map((value) => ({
      ...value,
      x: new Date(value.x),
    }))
}

export default compose<TProps, IOwnProps>(withStyles(styles))(ContractsDetails)
