import { Dialog, FormControlLabel, List, ListItem, ListItemIcon, ListItemText, Switch } from '@material-ui/core'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { ExitToApp as ExitToAppIcon, Person as PersonIcon } from '@material-ui/icons'
import { formatRoleName } from '@omnicar/sam-format'
import { UserRoleRecord, UserRole as UserRoleV1 } from '@fragus/sam-types'
import { IAdminUserInfo } from '@fragus/sam-types/types/admin/user/user'
import StyledSelect from 'components/StyledSelect'
import Typography from 'components/Typography'
import React from 'react'
import { ValueType } from 'react-select'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { getIncludeInactive, setIncludeInactive } from 'utils/localStorage'
import { renderContractProviderRow } from 'utils/react-select'
import { firstCharUpper, numPad } from 'utils/string'
import styles from './styles'

interface IOwnProps {
  open: boolean
  onToggle: () => void
  onLogOut: () => void
  onToggleAccountSettings: () => void
  updateUserRole: (roleId: number, contractProviderId: number) => Promise<void>
  role?: UserRoleV1
  roles?: UserRoleRecord[]
  isSuperAdmin?: boolean
  isDev?: boolean
  userInfo?: IAdminUserInfo
  contractProviderId?: number
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  includeInactiveProviders: boolean
}

export enum UserRole {
  System = 1,
  Admin,
  Seller,
  Customer,
  // To account for the missing reports role
  Observer = 6,
}

interface IUserRoleSelectOption {
  value: UserRoleRecord
  label: string
  inactive: boolean
}

const roleToRoleId = (role: UserRoleV1) => UserRole[firstCharUpper(role)] || -1

class AppHeaderMenuFloating extends React.Component<TProps> {
  public state: IState = {
    includeInactiveProviders: false,
  }

  public render() {
    const {
      classes,
      onToggle,
      onLogOut,
      onToggleAccountSettings,
      open,
      roles = [],
      role,
      isSuperAdmin,
      isDev,
      userInfo,
      contractProviderId,
    } = this.props

    const { includeInactiveProviders } = this.state
    const filteredRoles = includeInactiveProviders
      ? roles
      : roles.filter((r) => !r.contractProvider.inactive || this.isCurrentRole(r))

    const currentRoleIndex = filteredRoles.findIndex(
      (r) => r.role === role && r.contractProvider && r.contractProvider.id === contractProviderId,
    )

    const roleOptions: IUserRoleSelectOption[] = filteredRoles.map((r) => ({
      value: r,
      label: `${numPad(r.contractProvider.id, 3)} - ${r.contractProvider.administrativeName} - ${formatRoleName(
        r.role,
      )} ${isDev ? '(Dev)' : isSuperAdmin ? '(Superadmin)' : ''}`,
      inactive: !!r.contractProvider.inactive,
    }))

    return (
      <Dialog
        onClose={onToggle}
        open={open}
        classes={{ scrollPaper: classes.scrollPaper, paper: classes.scrollPaper }}
        data-e2e={'AppHeader__account-dialog'}
      >
        <div className={classes.infoWrapper}>
          <Typography className={classes.name} variant="title">
            {userInfo && userInfo.name}
          </Typography>
          <Typography variant="subtitle">{userInfo && userInfo.email}</Typography>
          <div className={classes.flexBox}>
            <Typography className={classes.roleCaption} variant="caption">
              {t('Role')}
            </Typography>
          </div>
          <StyledSelect
            autoFocus
            options={roleOptions}
            onChange={this.handleDetailsChange}
            value={roleOptions[currentRoleIndex]}
            formatOptionLabel={({ label, inactive }) => renderContractProviderRow(label, inactive, classes.grayedOut)}
          />
          <span>
            <FormControlLabel
              control={<Switch checked={includeInactiveProviders} />}
              label={t('Include deactivated')}
              onChange={this.handleFilterChanged}
            />
          </span>
        </div>
        <List className={classes.menu} component="nav">
          <ListItem className={classes.menuItem} button={true} disableGutters={true} onClick={onToggleAccountSettings}>
            <ListItemIcon classes={{ root: classes.menuItemIcon }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary={t('My account')} />
          </ListItem>
          <ListItem className={classes.menuItem} button={true} disableGutters={true} onClick={onLogOut}>
            <ListItemIcon classes={{ root: classes.menuItemIcon }}>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary={t('Sign out')} />
          </ListItem>
        </List>
      </Dialog>
    )
  }

  public componentDidMount = () => {
    const includeInactiveProviders = getIncludeInactive()
    this.setState({ includeInactiveProviders })
  }
  public componentWillUnmount = () => {
    setIncludeInactive(this.state.includeInactiveProviders)
  }

  private handleFilterChanged = () => {
    const { includeInactiveProviders } = this.state
    this.setState({ includeInactiveProviders: !includeInactiveProviders })
  }

  private handleDetailsChange = (option: ValueType<IUserRoleSelectOption>) => {
    if (!option) {
      return
    }
    const value = (option as IUserRoleSelectOption).value
    const { role, contractProviderId, updateUserRole } = this.props
    const newRole = value.role
    const newContractProviderId = value.contractProvider.id
    const newRoleId = roleToRoleId(newRole)

    if (newRole === role && newContractProviderId === contractProviderId) {
      return
    }

    this.setState({ isSubmitting: true }, () =>
      updateUserRole(newRoleId, newContractProviderId).catch(() => this.setState({ isSubmitting: false })),
    )
  }

  private isCurrentRole = (r: UserRoleRecord) => {
    const { role, contractProviderId } = this.props
    return r.role === role && r.contractProvider && r.contractProvider.id === contractProviderId
  }
}

export default withStyles(styles)((props) => (
  <AppContext.Consumer>
    {({ role, roles, userInfo, providerInfo, updateUserRole, isSuperAdmin, isDev }) => (
      <AppHeaderMenuFloating
        {...props}
        role={role}
        roles={roles}
        isSuperAdmin={isSuperAdmin}
        isDev={isDev}
        updateUserRole={updateUserRole}
        userInfo={userInfo}
        contractProviderId={providerInfo && providerInfo.providerId}
      />
    )}
  </AppContext.Consumer>
))
