import { Theme, createStyles } from '@material-ui/core'
import { theme } from 'theme'

export default ({ spacing, breakpoints }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      [breakpoints.up('sm')]: {
        width: '80%',
      },
      [breakpoints.up('md')]: {
        width: '40%',
      },
    },
    actionBar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: spacing(3),
      textTransform: 'none',
    },
    btnIcon: {
      marginRight: spacing(0.5),
    },
    btnCancel: {
      color: theme.palette.context.warning[500],
    },
    heading: {},
    form: {
      padding: `0 ${spacing(3)}px ${spacing(3)}px`,
    },
    input: {
      display: 'block',
      marginTop: spacing(1),
    },
    hr: {
      width: '100%',
      borderColor: theme.palette.border.light[500],
      borderTopWidth: 0,
    },
    changePassword: {
      color: theme.palette.primary[500],
    },
    btnSave: {
      marginLeft: spacing(2),
      marginRight: spacing(1),
      width: '40%',
      alignSelf: 'center',
      marginBottom: '2rem',
    },
  })
