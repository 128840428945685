import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import classNames from 'classnames'
import React, { Component } from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t } from 'translations/translationFunctions'

// import { TranslationKey } from 'translations/translationTypes'

const isDisableBackdropClick = true
const isCloseOnEscapeKeyDown = true

type TTextTranslated = string

/**
 * @titleTrl TITLE text (that has already been translated).
 * @contentTrlText CONTENT text (that has already been translated).
 * @captionTrlConfirmButton CONFIRMATION button text/label (that has already been translated).
 * @captionTrlCancelButton CANCEL button text/label (that has already been translated).
 * @titleClassName CSS class for the title.
 * @doHideCancelButton Hides the cancel button.
 * @children Additional content.
 */
export interface IProps {
  open: boolean
  titleTrl: TTextTranslated
  onConfirm: () => void
  onCancel: () => void

  // Optional attributes.
  contentTrlText?: TTextTranslated
  captionTrlConfirmButton?: TTextTranslated // Optional text for Ok / Yes button.
  captionTrlCancelButton?: TTextTranslated // Optional text for No / Cancel button.
  titleClassName?: string // Styles for dialog's title
  doHideCancelButton?: boolean
  children?: React.ReactNode
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    content: {
      overflowY: 'unset',
      marginBottom: '1vh',
    },
    actionButtons: {
      marginLeft: '2px',
      marginRight: '2px',
      marginBottom: '2px',
    },
    buttonOk: {
      fontSize: '0.775rem',
    },
    buttonCancel: {
      fontSize: '0.775rem',
      marginLeft: '12px',
    },
  })

type TProps = IProps & WithStyles<typeof styles>

class ConfirmDialog extends Component<TProps> {
  onClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick' && isDisableBackdropClick) {
      return
    } else if (reason === 'escapeKeyDown' && !isCloseOnEscapeKeyDown) {
      return
    }

    this.props.onCancel()
  }

  public render() {
    const {
      open,
      titleTrl,
      onCancel,
      onConfirm,
      contentTrlText,
      captionTrlCancelButton,
      captionTrlConfirmButton,
      titleClassName,
      doHideCancelButton,
      classes,
      children,
    } = this.props

    return (
      <Dialog
        open={open}
        maxWidth="xs"
        disableEscapeKeyDown={false}
        // disableBackdropClick={true}  // Disabled, due to soon not be supported any longer. -2024-03-07 - Replaced by this.onClose(..)
        // onEscapeKeyDown={onCancel}   // Disabled, due to soon not be supported any longer. -2024-03-07 - Replaced by this.onClose(..)
        onClose={this.onClose}
      >
        <DialogTitle classes={{ root: titleClassName ? titleClassName : '' }}>{titleTrl}</DialogTitle>

        {(contentTrlText || children) && (
          <DialogContent className={classNames(classes.content)}>
            {contentTrlText && (
              <span className={customTheme.palette.text.dark} style={{ fontSize: 14 }}>
                {contentTrlText}
              </span>
            )}
            {children}
          </DialogContent>
        )}

        <DialogActions className={classNames(classes.actionButtons)}>
          <Button className={classNames(classes.buttonOk)} onClick={onConfirm} variant="contained" color="secondary">
            {captionTrlConfirmButton || t('Ok')}
          </Button>
          {!doHideCancelButton && (
            <Button className={classNames(classes.buttonCancel)} onClick={onCancel} variant="contained" autoFocus>
              {captionTrlCancelButton || t('Cancel')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ConfirmDialog)
