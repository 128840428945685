import { IContractListRecord } from '@fragus/sam-types'
import { TranslationKey } from 'translations/translationTypes'

export interface AdditionalColumn {
  headerKey: string
  valueKey: keyof IContractListRecord
  position?: number
}

export interface Accessor {
  // If no position is choosen, a default position will be applied
  secondaryIdentifier: { headerKey: string; valueKey?: keyof IContractListRecord; position?: number }
  brand: { headerKey: string; valueKey?: keyof IContractListRecord; position?: number }
  additionals?: AdditionalColumn[]
}

export type AccessorRecord = Record<string, Accessor>

export const headerKeys: {
  [key: string]: { tran: TranslationKey; replacer?: { key: string; replacement: string } }
} = {
  prettyIdentifier: { tran: 'Contract ID' },
  registrationNumber: { tran: 'License Plate' },
  itemNumber: { tran: 'Item Number' },
  serialNumber: { tran: 'Serial Number' },
  customerName: { tran: 'Customer' },
  contractName: { tran: 'Contract Type' },
  brand: { tran: 'Brand' },
  productBrand: { tran: 'Product Brand' },
  contractCreationDate: { tran: 'Creation Date' },
  contractEndDate: { tran: 'Expiration Date' },
  sellerName: { tran: 'Seller' },
  createdByProvider: { tran: 'Created by' },
  contractState: { tran: 'Status' },
}

// headerKey: decides which translation to use from the 'headerKeys' constant
// valueKey: name of a property in the records, used to get the column value from the records; obj[valueKey]
// position: optional, used to sort the order of the records in the table, ex: if you
//          have additional columns that you don't want to automatically be placed at the end of
//          the column list
export const Accessors: AccessorRecord = {
  // Vehicle
  default: {
    secondaryIdentifier: { headerKey: 'registrationNumber' },
    brand: { headerKey: 'brand' },
  },
  Product: {
    secondaryIdentifier: { headerKey: 'itemNumber', valueKey: 'itemNumber', position: 2 },
    brand: { headerKey: 'productBrand', valueKey: 'brand' },
    additionals: [{ headerKey: 'serialNumber', valueKey: 'serialNumber', position: 3 }],
  },
}
