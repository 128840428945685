import { Card, CardContent } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { IContractProviderInfo } from '@fragus/sam-types/types/admin/contractProvider'
import { getMainProvider } from 'api/api'
import classNames from 'classnames'
import Typography from 'components/Typography'
import React from 'react'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { getProvider, isShowLogoOnWeb } from 'utils/localStorage'
import SubProviderTable from './SubProviderTable'

const styles = ({ palette, breakpoints, spacing }: Theme) =>
  createStyles({
    infoBarWrapper: {
      display: 'flex',
      [breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    logoCutout: {
      position: 'absolute',
      top: '1px',
      bottom: '1px',
      right: '-25px',
      borderRadius: '100px',
      backgroundColor: 'white',
      width: '100px',
      height: '100px',
      overflow: 'hidden',
    },
    logoWrapper: {
      overflow: 'hidden',
      height: '90%',
      margin: '5%',
      borderRadius: '200px',
      border: `2px solid ${palette.primary[500]}`,
      display: 'flex',
      alignItems: 'center',
    },
    brandLogo: {
      display: 'block',
      maxWidth: '85px',
    },
    mainProviderChip: {
      borderTopRightRadius: '30px',
      borderBottomRightRadius: '30px',
      minWidth: '290px',
      maxWidth: '300px',
      minHeight: '100px',
      marginRight: '80px',
      position: 'relative',
      padding: spacing(2),
      backgroundColor: palette.primary.light,
      color: 'white',
    },
    chipText: {
      maxWidth: '190px',
    },
    header: {
      marginBottom: spacing(1),
    },
  })

type TProps = WithStyles<typeof styles>

interface IState {
  mainProvider?: IContractProviderInfo
  loading: boolean
}

class ProviderInfoBar extends React.Component<TProps, IState> {
  state: Readonly<IState> = {
    mainProvider: undefined,
    loading: false,
  }

  async componentDidMount() {
    await this.initData()
  }

  render(): React.ReactNode {
    const { classes } = this.props
    const { mainProvider } = this.state

    return (
      <>
        <div className={''}>
          <Typography variant="subheading" style={{ marginBottom: '8px' }}>
            {t('Dealer info')}
          </Typography>
        </div>
        <Card>
          <CardContent className={classNames(classes.infoBarWrapper)}>
            <AppContext.Consumer>
              {({ providerInfo }) => (
                <>
                  <div>{this.renderLogoTitleDiv(providerInfo, mainProvider)}</div>
                  {providerInfo && !providerInfo.parentProviderId && (
                    <SubProviderTable mainProviderId={providerInfo.providerId} />
                  )}
                </>
              )}
            </AppContext.Consumer>
          </CardContent>
        </Card>
      </>
    )
  }

  private initData = async () => {
    this.setState({ loading: true })
    const provider = getProvider()
    const mainProvider = provider && provider.parentProviderId ? await this.getMainProvider() : undefined
    this.setState({ loading: false, mainProvider: mainProvider })
  }

  private getMainProvider = async () => {
    const res = await getMainProvider()
    return res.data || undefined
  }

  private renderLogoTitleDiv(providerInfo: IContractProviderInfo | undefined, mainProvider?: IContractProviderInfo) {
    const { classes } = this.props
    if (!providerInfo) return

    const parentProvider = mainProvider
    const titleToRender = providerInfo.administrativeName
    const titleToRenderParent = mainProvider && mainProvider.administrativeName
    const logo = mainProvider
      ? mainProvider.isShowLogoOnWeb && mainProvider.webLogoBanner
      : isShowLogoOnWeb() && providerInfo.webLogoBanner
    return (
      <div>
        <Typography variant="body1" className={classes.header}>
          {t('Main dealer')}
        </Typography>
        <div className={classes.mainProviderChip}>
          <div className={classes.chipText}>
            {parentProvider ? (
              <Typography variant="subheader">
                {t('%providerName is a sub dealer for %parentProviderName', {
                  providerName: titleToRender,
                  parentProviderName: titleToRenderParent || '',
                })}
              </Typography>
            ) : (
              <Typography variant="subheader">
                {t('%providerName is a main dealer', { providerName: titleToRender })}
              </Typography>
            )}
          </div>
          {!!logo && (
            <div className={classes.logoCutout}>
              <div className={classes.logoWrapper}>
                <img className={classes.brandLogo} src={logo} alt="Logo" />
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(ProviderInfoBar)
