import ActionTypeKeys from 'actions/ActionTypeKeys'
import ActionTypes from 'actions/ActionTypes'
import { contractDisplayConfigInitialState } from './initialState'

export const contractDisplayConfigReducer = (state = contractDisplayConfigInitialState, action: ActionTypes) => {
  switch (action.type) {
    case ActionTypeKeys.SET_HIDDEN_VAT:
      return {
        ...state,
        hiddenVAT: action.props.hiddenVAT,
      }

    case ActionTypeKeys.SET_SHOW_MILEAGE_INFO:
      return {
        ...state,
        showMileageInfo: action.props.showMileageInfo,
      }
    default:
      return state
  }
}
