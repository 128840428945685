import React from 'react'
import { IReleaseNoteResponse, IsoLocale, ReleaseTag } from '@fragus/sam-types'
import { AppContext } from 'store/appContext'
import { tUntyped } from '@omnicar/sam-translate'

interface IProps {
  notes: IReleaseNoteResponse[]
}

export const getDescriptionByLocale = (locale: IsoLocale, obj: IReleaseNoteResponse): string | undefined => {
  const descriptions = obj.descriptions.find((d) => d.locale === locale)?.description
  return descriptions || obj.descriptions.find((d) => d.locale === 'en-GB')?.description
}

const ReleaseNotes: React.SFC<IProps> = ({ notes }) => (
  <AppContext.Consumer>
    {({ locale }) => {
      return (
        <ul className="ReleaseNote__list">
          {notes.map((note) => {
            const tag = ReleaseTag[note.releaseTag]
            const description = getDescriptionByLocale(locale, note)
            return (
              <li key={note.releaseNoteId}>
                <span className={`ReleaseNote__badge ReleaseNote__${tag}`}>{tUntyped(tag)}</span>
                <h5>{description} </h5>
              </li>
            )
          })}
        </ul>
      )
    }}
  </AppContext.Consumer>
)

export default ReleaseNotes
