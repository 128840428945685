import { Button, Collapse, IconButton, Tooltip } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import {
  GetApp as FileDownloadIcon,
  FilterList as FilterListIcon,
  Refresh as RefreshIcon,
  Sort as SortIcon,
} from '@material-ui/icons'
import { formatDate } from '@omnicar/sam-format'
import {
  ApiError,
  ContractListOrderByType,
  ContractOrderBy,
  ContractState,
  IColumnUserConfiguration,
  IContractFilterParams,
  IContractListRecord,
  IPaginatedQueryParams,
  IPaginatedResponse,
  ITableUserConfiguration,
  UserConfiguration,
} from '@fragus/sam-types'
import classNames from 'classnames'
import Typography from 'components/Typography'
import { CONFIG } from 'config'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import ReactTable, { Column, SortingRule } from 'react-table'
import { t, tContractState } from 'translations/translationFunctions'
import 'react-table/react-table.css'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import { TContractObject } from '@fragus/sam-types/types/admin/product/product'
import ActionTypes from 'actions/ActionTypes'
import {
  contractFilterReset,
  contractFilterUpdate,
  IContractFilterParamsReset,
  IContractFilterParamsUpdate,
} from 'actions/contractFilterActions'
import { archiveAllContracts, exportShallowContracts } from 'api/api'
import Paper from 'components/Mui/Paper'
import TableLoadingIndicator from 'components/TableLoadingIndicator'
import moment from 'moment'
import { connect } from 'react-redux'
import { contractFilterInitialState, IContractFilters } from 'reducers/contractFilterParams/contractFilterInitialState'
import { IContractSearchState } from 'reducers/contractSearch/contractSearchInitialState'
import { IRootState } from 'reducers/initialState'
import { Dispatch } from 'redux'
import { contractDetailsPath } from 'routes/paths'
import { selectContractFilterParams } from 'selectors/contractFilterParams'
import { AppContext } from 'store/appContext'
import { contractStateStyles } from 'theme/styles/contractState'
import { Accessor, Accessors, headerKeys } from 'types/contractFilterTypes'
import { trackEvent } from 'utils/analytics'
import { sortColumns } from 'utils/contractFiltering'
import browserHistory from 'utils/history'
import { getProvider } from 'utils/localStorage'
import notify from 'utils/notify/notify'
import {
  createQueryFromTable,
  createTableStateFromPagination,
  defaultTableState,
  ITableQuery,
  ITableState,
} from 'utils/react-table'
import { shortenPrettyIdentifier } from 'utils/regex'
import { LayoutActions, LayoutActionsLeft, LayoutActionsRight, LayoutContent } from '../../../Mui/Layout'
import DialogColumnsManagment from './Dialog/ColumnsManagment'
import FilterDetails, { IFilterDetailsShow } from './FilterDetails'
import StateFilters, { IFilter } from './StateFilters'

interface IOwnProps {
  filterList: IFilter[]
  filterDetailsShow: IFilterDetailsShow
  getItemsPaginated: (
    qry: IPaginatedQueryParams<ContractOrderBy, IContractFilterParams>,
  ) => Promise<IJsonStatus<IPaginatedResponse<IContractListRecord[], ContractListOrderByType>, ApiError>>
  getUserConfigurationItemTable: (
    contractObjectType: TContractObject,
  ) => Promise<IJsonStatus<UserConfiguration<ContractOrderBy>, ApiError>>
  getUserProductConfigurationItemTable: () => Promise<IJsonStatus<UserConfiguration<ContractOrderBy>, ApiError>>
  putUserConfigurationItemTable: (
    body: ITableUserConfiguration<ContractOrderBy>,
    contractObjectType?: TContractObject,
  ) => Promise<IJsonStatus<any, ApiError>>
  getFilterOptions: () => Promise<any>
  contractObjectType: TContractObject | undefined
}

interface IReducerProps extends IContractSearchState {
  filterParams: IContractFilters
}

interface IActionProps {
  contractFilterReset: () => IContractFilterParamsReset
  contractFilterUpdate: (filterParams: Partial<IContractFilters>) => IContractFilterParamsUpdate
}

interface IQuery extends ITableQuery<ContractOrderBy, IContractFilterParams> {
  filtering: IContractFilterParams
}

interface IState {
  data: IContractListRecord[]
  filtersOpen: boolean
  loading: boolean
  savingColumns: boolean
  tableColumns: Column[]
  tableQuery: IQuery
  tableState: ITableState
  userConfiguration: ITableUserConfiguration<ContractOrderBy>
  numberOfFilters: number
  dialog: {
    manageColumns: boolean
  }
}

type TProps = IReducerProps & IActionProps & RouteComponentProps<any> & WithStyles<typeof styles> & IOwnProps

interface ITranslatedContractListRecord extends IContractListRecord {
  translatedContractState: string
}

const styles = (theme: Theme) =>
  createStyles({
    ...contractStateStyles({ color: true }),
    filters: {
      padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px ${theme.spacing(6)}px`,
    },
    filterButton: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(1),
    },
    filterButtonActive: {
      color: theme.palette.secondary[500],
    },
    buttonClearContracts: {
      backgroundColor: 'orange',
      color: '#FFFFFF',
      '&:hover': {
        backgroundColor: 'orange',
      },
    },
    alertIcon: {
      position: 'relative',
      top: -6,
      left: -6,
      borderRadius: '100%',
      color: '#FFFFFF',
      backgroundColor: theme.palette.secondary.main,
      width: 18,
      height: 18,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: theme.typography.fontWeightMedium,
    },
    columnLabel: {
      wordBreak: 'break-word',
    },
    closeToExpiration: {
      color: theme.palette.error.main,
    },
    label: {
      color: 'grey',
      marginRight: theme.spacing(1),
    },
  })

const initialTableQuery: IQuery = {
  search: '',
  pagination: {
    limit: 50,
    offset: 0,
    orderBy: 'prettyIdentifier',
    orderDirection: 'DESC',
  },
  filtering: contractFilterInitialState.params,
}

const initialState: IState = {
  data: [],
  filtersOpen: false,
  loading: false,
  savingColumns: false,
  tableColumns: [],
  tableState: {
    ...defaultTableState(50),
    sorted: [{ desc: true, id: 'prettyIdentifier' }],
    resized: [],
  },
  tableQuery: { ...initialTableQuery },
  userConfiguration: {
    columns: [],
    orderBy: 'prettyIdentifier',
    orderDirection: 'DESC',
    rowCount: 50,
  },
  numberOfFilters: 0,
  dialog: {
    manageColumns: false,
  },
}

const headerText = (data: any): string => {
  if (data && data.column && data.column.id && typeof data.column.id === 'string' && headerKeys[data.column.id]) {
    const headerKey = headerKeys[data.column.id]
    return headerKey.replacer
      ? t(headerKey.tran, { [headerKey.replacer.key]: headerKey.replacer.replacement })
      : t(headerKey.tran)
  } else {
    return ''
  }
}
const checkIsCloseToExpiration = (date: Date, state: ContractState): { isCloseTo: boolean; days: number } => {
  const diff = moment(date).diff(moment(), 'days')
  const isCloseTo =
    diff < 90 && diff > -1 && [ContractState.Active, ContractState.ActivePendingTermination].includes(state)
  return { isCloseTo, days: diff }
}

export const getColumns = (classes: Record<string, string>, specificAccessor?: Accessor): Column[] => {
  const accessors = specificAccessor || Accessors.default
  const columns = [
    {
      Header: headerText,
      accessor: 'prettyIdentifier',
      headerClassName: 'e2e__header__prettyIdentifier',
      Cell: ({ value }: { value: string }) => <div className="prettyIdentifier">{shortenPrettyIdentifier(value)}</div>,
      position: 1,
    },
    {
      Header: headerText,
      accessor: accessors.secondaryIdentifier.headerKey || 'registrationNumber',
      headerClassName: 'e2e__header__registrationNumber',
      Cell: ({ original }: { original: IContractListRecord }) => (
        <div className="columnLabel">
          {accessors.secondaryIdentifier.valueKey
            ? original[accessors.secondaryIdentifier.valueKey] || ''
            : original.registrationNumber}
        </div>
      ),
      position: accessors.secondaryIdentifier.position,
    },
    {
      Header: headerText,
      accessor: 'customerName',
      headerClassName: 'e2e__header__customerName',
      Cell: ({ original }: { original: IContractListRecord }) => (
        <div className="customerName">{original.customerCompanyName || original.customerName}</div>
      ),
    },
    {
      Header: headerText,
      accessor: 'contractName',
      headerClassName: 'e2e__header__contractName',
    },
    {
      Header: headerText,
      accessor: accessors.brand.headerKey || 'brand',
      headerClassName: 'e2e__header__brand',
      Cell: ({ original }: { original: IContractListRecord }) => (
        <div className="prettyIdentifier">
          {accessors.brand.valueKey ? original[accessors.brand.valueKey] || '-' : original[accessors.brand.headerKey]}
        </div>
      ),
    },
    {
      Header: headerText,
      accessor: 'contractCreationDate',
      headerClassName: 'e2e__header__contractCreationDate',
      Cell: ({ value }: { value: string }) => <div className="date">{formatDate(new Date(value))}</div>,
    },
    {
      Header: headerText,
      accessor: 'contractEndDate',
      headerClassName: 'e2e__header__contractEndDate',
      Cell: ({ value, original }: { value: string; original: IContractListRecord }) => {
        const closeToExp = checkIsCloseToExpiration(new Date(value), original.contractState)
        return (
          <div
            title={
              closeToExp.isCloseTo
                ? t(`The contract will expire in %days`, {
                    days: `${closeToExp.days} ${closeToExp.days === 1 ? t('day') : t('days')}`,
                  })
                : ''
            }
            className={classNames('date', closeToExp.isCloseTo ? classes['closeToExpiration'] : '')}
          >
            {formatDate(new Date(value))}
          </div>
        )
      },
    },
    {
      Header: headerText,
      accessor: 'sellerName',
      headerClassName: 'e2e__header__sellerName',
    },
    {
      Header: headerText,
      accessor: 'createdByProvider',
      headerClassName: 'e2e__header_createdByProvider',
    },
    {
      Header: headerText,
      accessor: 'contractState',
      headerClassName: 'e2e__header__contractState',
      Cell: (row: { value: number; original: ITranslatedContractListRecord }) => (
        <div className={classNames(classes[`state${row.value}`])}>
          {row.original.translatedContractState || tContractState(row.value)}
        </div>
      ),
    },
  ]

  if (accessors.additionals) {
    for (let additional of accessors.additionals) {
      const column = {
        Header: headerText,
        accessor: additional.headerKey || '',
        headerClassName: `e2e__header__${additional.headerKey}`,
        Cell: ({ original }: { original: IContractListRecord }) => (
          <div className="columnLabel">{original[additional.valueKey] || ''}</div>
        ),
        position: additional.position,
      }
      columns.push(column)
    }
    sortColumns(columns)
  }

  return columns
}

class ItemList extends React.Component<TProps, IState> {
  public state: IState = { ...initialState }

  public componentDidMount() {
    // Initialize the columns then initialize the pagination params and for this we need to fetch data
    // and initialize that for first run since that holds info needed in tableConfiguration.pages
    this.initFiltersProduct()
    this.initDataAndTablePreparations()
    this.setState({
      numberOfFilters: this.getNumberOfFilters(this.props.filterParams.params),
    })
  }

  public componentDidUpdate(prevProps: TProps) {
    const { filterParams } = this.props
    if (prevProps.query !== this.props.query) {
      this.setState(
        {
          tableQuery: {
            ...initialTableQuery,
            search: this.props.query,
          },
        },
        this.loadData,
      )
    }
    if (filterParams.params.contractObjectType !== this.props.contractObjectType) {
      this.initFiltersProduct()
      this.initDataAndTablePreparations()
    }
  }

  public render() {
    const {
      data,
      tableColumns,
      loading,
      savingColumns,
      tableState,
      filtersOpen,
      numberOfFilters,
      userConfiguration,
      dialog,
      tableQuery,
    } = this.state
    const { classes, filterList, contractObjectType } = this.props

    const translatedData: ITranslatedContractListRecord[] = this.translateData(data)
    const env = CONFIG.env

    return (
      <AppContext.Consumer>
        {({ locale }) => (
          <Paper key={locale}>
            <LayoutContent>
              {this.renderClearAllButton(env)}

              <LayoutActions>
                <LayoutActionsLeft>
                  <StateFilters
                    onChange={this.handleStateFilterChange}
                    activeStates={tableQuery.filtering.contractStates}
                    filterList={filterList}
                  />
                </LayoutActionsLeft>
                <LayoutActionsRight>
                  {numberOfFilters > 0 && (
                    <Tooltip title={t('Clear all filters')} placement="top">
                      <Button onClick={this.clearAllFilters}>{t('Clear all filters')}</Button>
                    </Tooltip>
                  )}
                  <Tooltip title={t('Refresh List Data')} placement="top">
                    <IconButton onClick={this.loadData} data-e2e={'contractList-refresh-btn'}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('All Filters')} placement="top">
                    <IconButton
                      className={classNames(classes.filterButton, filtersOpen && classes.filterButtonActive)}
                      onClick={this.handleFilterOpen}
                      data-e2e={!filtersOpen ? 'contractList-allFilters-btn' : 'contractList-allFilters-btn-active'}
                    >
                      <FilterListIcon />
                      {numberOfFilters > 0 && (
                        <Typography className={classes.alertIcon} variant="caption" tag="span">
                          {numberOfFilters}
                        </Typography>
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Arrange columns')} placement="top">
                    <IconButton onClick={this.handleColumnSortingOpen} data-e2e={'contractList-arrangeCols-btn'}>
                      <SortIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Export to xls')} placement="top">
                    <IconButton data-e2e={'contractList-exportContracts-btn'} href={exportShallowContracts(tableQuery)}>
                      <FileDownloadIcon />
                    </IconButton>
                  </Tooltip>
                </LayoutActionsRight>
              </LayoutActions>
              <Collapse in={filtersOpen} data-e2e={'filterCollapse'}>
                <FilterDetails
                  filterDetailsShow={this.props.filterDetailsShow}
                  onChange={this.handleFilterDetailsChange}
                  activeFilters={tableQuery.filtering}
                  getFilterOptions={this.props.getFilterOptions}
                />
              </Collapse>
            </LayoutContent>
            <ReactTable
              className={classNames('react-table react-table--clickable', classes.columnLabel)}
              columns={tableColumns}
              data={translatedData}
              defaultPageSize={10}
              defaultSorted={tableState.sorted}
              loading={loading}
              LoadingComponent={TableLoadingIndicator}
              noDataText={!loading ? t('No Results Found') : ''}
              manual={true}
              minRows={3}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              sorted={[...tableState.sorted]}
              page={tableState.page}
              pageSize={tableState.pageSize}
              pages={tableState.pages}
              pageSizeOptions={tableState.pageSizeOptions}
              showPaginationBottom={data.length > 0}
              // tslint:disable-next-line jsx-no-lambda
              getTrProps={(state: any, rowInfo: any) => ({
                onClick: () => {
                  this.handleRowClick(rowInfo.row)
                },
              })}
              pageText={t('Page')}
              ofText={t('of')}
              rowsText={t('rows')}
            />
            <DialogColumnsManagment
              open={dialog.manageColumns}
              columns={userConfiguration.columns}
              onClose={this.handleManageColumnsClose}
              onSave={this.handleManageColumnsSave}
              saving={savingColumns}
              activeType={contractObjectType}
            />
          </Paper>
        )}
      </AppContext.Consumer>
    )
  }

  private renderClearAllButton = (env: string) => {
    const { classes } = this.props

    if (env !== 'demo') {
      return
    }

    console.warn(`Invoked ClearAllContracts (should ONLY be allowed in demo), env: ` + env)
    return (
      <LayoutActionsRight>
        <div title={`${t('Clear all contracts')} - ${t("This button doesn't show up on live")}`}>
          <label className={classes.label}>{`${t('NOTE')}: ${t("This button doesn't show up on live")} ->`}</label>
          <Button className={classes.buttonClearContracts} variant="contained" onClick={this.handleArchiveAllContracts}>
            {t('Clear all contracts')}
          </Button>
          <br />
          <br />
        </div>
      </LayoutActionsRight>
    )
  }

  private handleFilterOpen = () => {
    this.setState({ filtersOpen: !this.state.filtersOpen })
  }

  private handleColumnSortingOpen = () => {
    this.setState({
      dialog: {
        ...this.state.dialog,
        manageColumns: true,
      },
    })
  }

  private handleManageColumnsClose = () => {
    this.setState({
      dialog: {
        ...this.state.dialog,
        manageColumns: false,
      },
    })
  }

  private clearAllFilters = () => {
    trackEvent('Contract advanced filter', 'Clear filters')

    const userConfiguration = this.state.userConfiguration
    const { tableQuery, tableColumns, tableState } = this.createTableSettings(userConfiguration)
    const clearedTableQuery: IQuery = {
      ...tableQuery,
      filtering: contractFilterInitialState.params,
    }

    this.props.contractFilterReset()
    this.setState(
      {
        tableQuery: clearedTableQuery,
        tableColumns,
        tableState,
        userConfiguration,
        numberOfFilters: 0,
      },
      this.loadData,
    )
  }

  private handleStateFilterChange = (contractStates: ContractState[], filterTitle: string) => {
    const { filterParams, contractFilterUpdate } = this.props
    const tableQuery: IQuery = {
      ...this.state.tableQuery,
      filtering: {
        ...this.state.tableQuery.filtering,
        contractStates,
      },
    }
    const activeStates = filterParams.activeStates.includes(filterTitle)
      ? [...filterParams.activeStates.filter((s) => s !== filterTitle)]
      : [...filterParams.activeStates, filterTitle]

    contractFilterUpdate({
      params: { ...filterParams.params, contractStates },
      activeStates,
    })

    const tableState = { ...this.state.tableState }
    const newTableQuery = createQueryFromTable(tableState, tableQuery, true) as IQuery
    const numberOfFilters = this.getNumberOfFilters(tableQuery.filtering, activeStates.length)

    this.setState({ tableQuery: newTableQuery, numberOfFilters }, this.loadData)
  }

  private handleFilterDetailsChange = (detailsFiltering: Partial<IContractFilterParams>) => {
    const tableQuery: IQuery = {
      ...this.state.tableQuery,
      filtering: {
        ...this.state.tableQuery.filtering,
        ...detailsFiltering,
      },
    }

    this.props.contractFilterUpdate({ params: { ...this.props.filterParams.params, ...detailsFiltering } })

    const tableState = { ...this.state.tableState }
    const newTableQuery = createQueryFromTable(tableState, tableQuery, true) as IQuery

    const numberOfFilters = this.getNumberOfFilters(tableQuery.filtering)
    this.setState({ tableQuery: newTableQuery, numberOfFilters }, this.loadData)
  }

  private handlePageChange = (page: number) => {
    const tableState = { ...this.state.tableState, ...{ page } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery) as IQuery
    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handlePageSizeChange = (pageSize: number, page: number) => {
    const { contractObjectType } = this.props
    const tableState = { ...this.state.tableState, ...{ page, pageSize } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery, true) as IQuery
    this.setState({ tableState, tableQuery }, this.loadData)

    const userConfiguration: ITableUserConfiguration<ContractOrderBy> = {
      ...this.state.userConfiguration,
      rowCount: pageSize,
    }

    this.setState({ userConfiguration })

    this.props.putUserConfigurationItemTable(userConfiguration, contractObjectType)
  }

  private handleManageColumnsSave = async (columns: IColumnUserConfiguration[]) => {
    const { contractObjectType } = this.props
    const userConfiguration = {
      ...this.state.userConfiguration,
      columns,
    }

    this.setState({ savingColumns: true })
    const response = await this.props.putUserConfigurationItemTable(userConfiguration, contractObjectType || 'Vehicle')

    if (!response.data) {
      return
    }

    const { tableQuery, tableColumns, tableState } = this.createTableSettings(userConfiguration)

    this.setState({
      dialog: {
        ...this.state.dialog,
        manageColumns: false,
      },
      savingColumns: false,
      tableQuery,
      tableColumns,
      tableState,
      userConfiguration,
    })
  }

  private handleSortChange = (sorted: SortingRule[]) => {
    const tableState = { ...this.state.tableState, sorted }

    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery, true) as IQuery
    this.setState({ tableState, tableQuery }, this.loadData)

    const userConfiguration: ITableUserConfiguration<ContractOrderBy> = {
      ...this.state.userConfiguration,
      orderBy: tableState.sorted[0].id as ContractOrderBy,
      orderDirection: tableState.sorted[0].desc ? 'DESC' : 'ASC',
    }

    this.props.putUserConfigurationItemTable(userConfiguration)
  }

  private handleRowClick = (data: IContractListRecord) => {
    browserHistory.push(contractDetailsPath(data.prettyIdentifier))
  }

  private createTableColumnsFromUserConfig = (userConfig: ITableUserConfiguration<ContractOrderBy>) => {
    const columns: Column[] = []

    let accessor: Accessor | undefined = Accessors[this.props.contractObjectType || 'Vehicle']

    userConfig.columns.forEach((configColumn) => {
      let tableColumn: Column | undefined
      const tableColumns = getColumns(this.props.classes, accessor)

      tableColumns.some((someTableColumn) => {
        if (someTableColumn.accessor === configColumn.name) {
          tableColumn = { ...someTableColumn }
          return true
        } else {
          return false
        }
      })

      if (tableColumn) {
        tableColumn.show = configColumn.enabled
        columns.push(tableColumn)
      }
    })

    return columns
  }

  private handleArchiveAllContracts = async () => {
    const providerId: number = getProvider()!.providerId
    const response = await archiveAllContracts(providerId)

    if (response?.data) {
      if (response?.data?.isSuccess) {
        notify.success({ message: t('Success') })
        console.info('Deleted all contracts on provider ' + providerId)

        window.location.reload()
      } else {
        console.warn('Failed deleting all contracts on provider ' + providerId)
        console.warn('Error: data:')
        console.warn('data')
      }
    } else {
      console.warn('Error: response:')
      console.warn('response')
    }
  }

  private createUserConfigurationColumnsFromTableColumns = (
    activeType: TContractObject,
  ): IColumnUserConfiguration[] => {
    return getColumns(this.props.classes, Accessors[activeType]).map((column) => ({
      enabled: true,
      name: column.accessor as IColumnUserConfiguration['name'],
    }))
  }

  private createTableQueryFromUserConfig = ({
    orderBy,
    orderDirection,
    rowCount,
  }: ITableUserConfiguration<ContractOrderBy>): IQuery => ({
    ...this.state.tableQuery,
    search: this.props.query || '',
    pagination: {
      ...this.state.tableQuery.pagination,
      orderBy,
      orderDirection,
      limit: rowCount,
    },
  })

  private createTableStateFromUserConfig = (userConfig: ITableUserConfiguration<ContractOrderBy>): ITableState => {
    const desc = userConfig.orderDirection.toUpperCase() === 'DESC'
    const id = userConfig.orderBy

    return {
      ...this.state.tableState,
      sorted: [{ desc, id } as SortingRule],
      pageSize: userConfig.rowCount,
    }
  }

  private initDataAndTablePreparations = async () => {
    const { contractObjectType, filterParams } = this.props
    const userConfigResponse = await this.props.getUserConfigurationItemTable(contractObjectType || 'Vehicle')
    let userConfiguration = userConfigResponse.data

    if (!userConfiguration) {
      userConfiguration = this.state.userConfiguration

      userConfiguration.columns = this.createUserConfigurationColumnsFromTableColumns(contractObjectType || 'Vehicle')
      // Upload back the gotten columns to the userconfig via API
      this.props.putUserConfigurationItemTable(userConfiguration, contractObjectType || 'Vehicle')
    }

    const { tableQuery, tableColumns, tableState } = this.createTableSettings(userConfiguration)
    const productFilter = contractObjectType
      ? {
          activeTypes: [contractObjectType],
          contractObjectType: contractObjectType,
        }
      : {}

    this.setState(
      {
        tableQuery: {
          ...tableQuery,
          filtering: { ...filterParams.params, ...productFilter },
        },
        tableColumns,
        tableState,
        userConfiguration,
      },
      this.loadData,
    )
  }

  private createTableSettings = (
    userConfiguration: ITableUserConfiguration<ContractOrderBy> = this.state.userConfiguration,
  ) => ({
    tableColumns: this.createTableColumnsFromUserConfig(userConfiguration),
    tableQuery: this.createTableQueryFromUserConfig(userConfiguration),
    tableState: this.createTableStateFromUserConfig(userConfiguration),
  })

  private getNumberOfFilters = (
    values: Partial<IContractFilterParams>,
    numberOfActiveStates: number = this.props.filterParams.activeStates.length,
  ) => {
    let numberOfFilters: number = 0

    Object.keys(values).forEach((key) => {
      const value = values[key]
      // Dates are handled differntly
      if (key === 'created' || key === 'expired') {
        if (value.min || value.max) {
          numberOfFilters += 1
        }
      } else if (key === 'contractStates') {
        // there are no filters for these keys
      } else if (key === 'contractObjectType' || key === 'activeTypes') {
        return
      } else {
        numberOfFilters += value.length
      }
    })
    // every contract state filter can cover multiple filters but should only count as one each
    numberOfFilters += numberOfActiveStates
    return numberOfFilters
  }

  private loadData = async () => {
    const { loading, tableQuery } = this.state
    if (!loading) {
      this.setState({ loading: true })

      const response = await this.props.getItemsPaginated(tableQuery)

      if (response.data) {
        const { result, pagination } = response.data
        const newTableState = createTableStateFromPagination(pagination)
        const tableState = { ...this.state.tableState, ...newTableState }
        this.setState({ loading: false, data: result, tableState })
      } else {
        this.setState({ loading: false })
      }
    }
  }

  private translateData = (records: IContractListRecord[]): ITranslatedContractListRecord[] => {
    return records.map((record) => ({
      ...record,
      translatedContractState: tContractState(record.contractState),
    }))
  }

  private initFiltersProduct = () => {
    const { contractObjectType, filterParams, contractFilterUpdate } = this.props

    if (contractObjectType) {
      contractFilterUpdate({
        ...filterParams,
        params: { ...filterParams.params, contractObjectType: contractObjectType, activeTypes: [contractObjectType] },
      })
    }
  }
}

const mapStateToProps = (state: IRootState): IReducerProps => ({
  query: state.contractSearch.query,
  filterParams: selectContractFilterParams(state),
})

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>): IActionProps => ({
  contractFilterReset: () => dispatch(contractFilterReset()),
  contractFilterUpdate: (values: Partial<IContractFilters>) => dispatch(contractFilterUpdate(values)),
})

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ItemList))
