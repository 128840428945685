import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { withContractDisplayConfig } from 'components/ContractDisplayConfig/withContractDisplayConfig'
import Typography, { HTMLTagType, VariantType } from 'components/Typography'
import React from 'react'
import { compose } from 'recompose'
import { t } from 'translations/translationFunctions'
import { IContractDisplayConfig } from 'reducers/contractDisplayConfig/initialState'

interface IOwnProps {
  tag?: HTMLTagType
  variant?: VariantType
  className?: string
  locale: string
}

type TProps = IOwnProps & WithStyles<typeof styles> & IContractDisplayConfig

const styles = (theme: Theme) =>
  createStyles({
    root: {
      fontStyle: 'italic',
      textAlign: 'right',
    },
  })

const LabelIncludingVat: React.SFC<TProps> = ({
  classes,
  className,
  variant = 'caption',
  tag = 'div',
  hiddenVAT,
  ...rest
}) => {
  return hiddenVAT ? null : (
    <Typography className={classNames(classes.root, className)} variant={variant} tag={tag} {...rest}>
      {t('Prices include VAT')}
    </Typography>
  )
}

export default compose<TProps, IOwnProps>(withContractDisplayConfig, withStyles(styles))(LabelIncludingVat)
