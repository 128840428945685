import { ContractState, TContractObject } from '@fragus/sam-types'
import {
  getContractFilterOptions,
  getContracts,
  getContractStatusCount,
  getNumberOfContractsCloseToExpiration,
  getNumberOfContractsNeedSettlement,
  getUserConfigurationServiceContractTable,
  putUserConfigurationServiceContractTable,
} from 'api/api'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import ItemList from '../List'
import { IFilter } from '../List/StateFilters'

type TProps = RouteComponentProps<any> & { contractObjectType?: TContractObject }

interface IState {
  filterList: IFilter[]
  contractObjectType: TContractObject | undefined
}

export default class ContractListWrapper extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      filterList: [
        {
          title: 'Offer',
          count: 0,
          keys: [100, 110, 120, 130],
          explanation: "Meaning: 'Offer' or 'Offer Expired'",
        },
        {
          title: 'Active',
          count: 0,
          keys: [200, 210, 250],
        },
        {
          title: 'Payment Failed',
          count: 0,
          keys: [400],
        },
        {
          title: 'Closed',
          count: 0,
          keys: [500, 600],
          explanation: "Meaning: 'Expired' or 'Terminated'",
        },
        {
          title: 'Settled',
          count: 0,
          keys: [900, 950],
        },
      ],

      contractObjectType: undefined,
    }
  }

  public componentDidMount() {
    this.setFilterListCounts()
    this.setNotifications()
  }

  public render() {
    const { filterList } = this.state
    const contractObjectType = this.props.contractObjectType || 'Vehicle'

    return (
      <ItemList
        filterList={filterList}
        filterDetailsShow={{}}
        contractObjectType={contractObjectType}
        getItemsPaginated={getContracts}
        getUserConfigurationItemTable={getUserConfigurationServiceContractTable}
        putUserConfigurationItemTable={putUserConfigurationServiceContractTable}
        getFilterOptions={getContractFilterOptions}
      />
    )
  }
  private setNotifications = async () => {
    const contractObjectType = this.props.contractObjectType || 'Vehicle'
    const expiringContractsAmount = await getNumberOfContractsCloseToExpiration(contractObjectType)
    const contractsForSettlementAmount = await getNumberOfContractsNeedSettlement(contractObjectType)

    this.setState((state) => ({
      filterList: state.filterList.map((f) => {
        let element = f

        if (f.title === 'Active' && expiringContractsAmount.data) {
          element = {
            ...f,
            notification: {
              title: {
                text: 'There are contracts that will expire in less than %days days. Extend them before they expire.',
                replacements: { days: 90 },
              },
              amount: expiringContractsAmount.data,
            },
          }
        }

        if (f.title === 'Closed' && contractsForSettlementAmount.data) {
          element = {
            ...f,
            notification: {
              title: {
                text: 'You should settle some contracts because they are more than %days days old.',
                replacements: { days: 30 },
              },
              amount: contractsForSettlementAmount.data,
            },
          }
        }

        return element
      }),
    }))
  }

  private setFilterListCounts = async () => {
    const { contractObjectType } = this.props
    const contractStatusCount = await getContractStatusCount(contractObjectType || 'Vehicle')
    const { filterList } = this.state
    if (contractStatusCount.data) {
      // add cancelled subscription filter if there are any contracts with that status
      if (contractStatusCount.data.find((i) => i.contractState === ContractState.CancelledSubscription)?.count) {
        filterList.push({
          title: 'Cancelled Subscription',
          count: 0,
          keys: [ContractState.CancelledSubscription],
        })
      }
      // add count to each filter
      const filterListWithCounts = filterList.map((filter) => {
        if (filter.count > 0) return filter

        const count = contractStatusCount.data!.reduce((acc, item) => {
          return filter.keys.includes(item.contractState) ? item.count + acc : acc
        }, 0)
        return { ...filter, count }
      })

      this.setState({
        filterList: filterListWithCounts,
      })
    }
  }
}
