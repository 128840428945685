import { CardContent, Input, InputAdornment, Typography } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import WarningRoundedIcon from '@material-ui/icons/WarningRounded'
import { formatDate } from '@omnicar/sam-format'
import { Brand, FuelType, ICarCollection, IModelCollection, Model, Vehicle, VehiclePartial } from '@fragus/sam-types'
import { getFuelTypes } from 'api/api'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import isShowHybridAsFuelTypeInFinland from 'config/showHybridAsFuelTypeInFinland'
import isEqualWith from 'lodash.isequalwith'
import moment from 'moment-timezone'
import React from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t, tFuelType } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import { defaultDateFormat } from 'utils/date'
import { getProviderCountry } from 'utils/localStorage'
import notify from 'utils/notify/notify'
import { REQUIRED_VIN_LENGTH } from 'utils/regex'
import { hasRequiredInfo } from 'utils/vehicle'
import { IFueltypeOption, IVehicleTypeOption, SearchFieldDataType } from '../types'
import PrefillAndSelectorField from './PrefillAndSelectorField'

export type FormModeTypes = 'default' | 'Manual-Search' | 'Custom-Contract'

// -- NOTE: Deactivated for now. ------------
// import { getLicenseLookup } from 'api/api'
// ------------------------------------------

const TRANSLATION_KEY_DEFAULT: TranslationKey =
  'Please check the information about the car, if any information is missing or incorrect, then please add or correct the information.'

const TRANSLATION_KEY_MISSING_DATA: TranslationKey =
  //'The information we have received about the car is incomplete, please add missing information. Please also correct if something is incorrect.'
  'The information we have received about the car is incomplete, please add missing information.'

// @TODO: The id:s needs to be present for prices, either do country specific fallbacks, or scrap the whole fallbacks (the id:s might change in future or be different on different databases (which they most likely are), so suggestion is to scrap the fallbacks and error out or leave it without the id's and forbid payment.) /marko
const fallbackFuelTypes = [
  {
    // "fuel_type_id": 1,   // NOTE: This ID might change in future or be different on different databases (which they most likely are)!
    name: 'Petrol',
  },
  {
    // "fuel_type_id": 2,   // NOTE: This ID might change in future or be different on different databases (which they most likely are)!
    name: 'Diesel',
  },
  {
    // "fuel_type_id": 10,  // NOTE: This ID might change in future or be different on different databases (which they most likely are)!
    name: 'Electric',
  },
  {
    name: 'Cng',
  },
]

type InputTypes = 'vin' | 'plate' | SearchFieldDataType | 'regDate'
type SearchErrors = Record<Partial<InputTypes>, string | undefined>

interface IOwnProps {
  formModeType?: FormModeTypes
  vehicle: Vehicle // The vehicle that is selected.
  vehicleLookedUp: VehiclePartial // The vehicle that will be prefilled.
  cars: ICarCollection
  isoCountry: string | null
  hasAvailableWarranty: boolean
  onVehicleChange: (vehicle: Vehicle) => void
  onTemplatesLookup: (vehicle: Vehicle, wasModelNameReMappedAtStartup?: boolean) => void // Note: We are interested of wasModelNameReMappedAtStartup since if all info could be mapped AUTOMATICALLY at START_UP, then we want to show another component instead of this VehicleMapper.
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  brandInput: string
  brands: Brand[] // Stored with the brands for React-Select.
  isLockBrandSelection: boolean // Disable the possibility to use the Selector.
  modelInput: string
  isLockModelSelection: boolean // Disable the possibility to use the Selector.
  fuelTypeInput: string
  commonFuelTypes: FuelType[] | null
  modelFuelTypes: FuelType[] | null
  isLockFuelTypeSelection: boolean // Disable the possibility to use the Selector.
  regDateInput: string // The new changed date is stored here. NOTE: This date is a LOCAL date (passed on from DatePicker).
  regDate: string | null // The original date.
  isLockRegDateSelection: boolean // Disable the possibility to use the Picker.
  brandModelsMap: Map<string, IModelCollection[]> | null // Stored with all models (arrays) for all brands, brand name as key.
  brandsMap: Map<string, Brand> | null // Stored with all brands (incl. with id:s), brand name as key.
  existsModelMap: Map<string, IModelCollection> | null // Stored with the current models for current brand, brand name as key.
  isInitializing: boolean
  wasModelNameReMappedAtStartup?: boolean
}

const initialState: IState = {
  brandInput: '',
  brands: [],
  isLockBrandSelection: true,
  modelInput: '',
  isLockModelSelection: true,
  fuelTypeInput: '',
  commonFuelTypes: null,
  modelFuelTypes: null,
  isLockFuelTypeSelection: true,
  regDateInput: '',
  regDate: null,
  isLockRegDateSelection: true,
  brandsMap: null,
  brandModelsMap: null,
  existsModelMap: null,
  isInitializing: true,
  wasModelNameReMappedAtStartup: undefined,
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    subheading: {
      fontSize: '1.1em',
      letterSpacing: '1px',
      opacity: 0.95,
    },
    iconInfo: {
      color: customTheme.palette.primary[500],
    },
    iconWarning: {
      color: customTheme.palette.context.attention[500],
    },
    noOverflow: {
      overflow: 'visible',
    },
  })

class VehicleMapperForm extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.initCommonFuelTypes(this.props.isoCountry)
    let state: any = initialState

    if (props.formModeType === 'Manual-Search') {
      state.isLockBrandSelection = false
      state.isLockModelSelection = false
      state.isLockFuelTypeSelection = false
      state.isLockRegDateSelection = false
    }

    this.state = state
  }

  public componentDidMount() {
    const { cars, vehicleLookedUp, formModeType } = this.props

    if (!cars) {
      console.warn('VehicleMapperForm: "cars" prop is empty')
    } else {
      const brandModelsMap: Map<string, IModelCollection[]> | null = this.initBrandModelsData(cars)

      if (!vehicleLookedUp) {
        if (formModeType !== 'Manual-Search') {
          console.error('VehicleMapperForm: In componentDidMount() vehicleLookedUp prop is empty')
        }
      } else {
        this.setStartUpVehicleInputStates(vehicleLookedUp, brandModelsMap)
      }
    }
  }

  public componentDidUpdate(oldProps: TProps) {
    const newProps: TProps = this.props
    const vehicleLookedUp: VehiclePartial = newProps.vehicleLookedUp

    if (newProps.cars && !oldProps.cars) {
      const brandModelsMap: Map<string, IModelCollection[]> | null = this.initBrandModelsData(newProps.cars)

      if (!vehicleLookedUp) {
        console.error('VehicleMapperForm: In componentDidUpdate(..) vehicle prop is empty')
      } else {
        this.setStartUpVehicleInputStates(vehicleLookedUp, brandModelsMap)
      }
    } else {
      const { brandModelsMap } = this.state
      const vehicleOnContract: Vehicle = newProps.vehicle

      let existsModelMap: Map<string, IModelCollection> | null = null

      // The below case happens when user returns back to the Mapper with ex.
      // the "Back"-button (the vehicle was previously selected already).
      if (vehicleOnContract && vehicleLookedUp) {
        const brandSelected: string = this.state.brandInput
        const modelSelected: string = this.state.modelInput
        const fuelTypeSelected: string = this.state.fuelTypeInput
        const regDateSelected: string = !this.state.regDateInput ? '' : this.dateLocalToIso(this.state.regDateInput)

        // --- If a Brand was previously selected for the car and the user returned back. ---------
        if (
          (!brandSelected ||
            (brandSelected !== newProps.vehicle.brand.name &&
              (oldProps.vehicle && oldProps.vehicle.brand.name) === newProps.vehicle.brand.name)) &&
          (vehicleLookedUp.brand && this.toMapKey(vehicleLookedUp.brand.name)) !==
            this.toMapKey(vehicleOnContract.brand.name)
        ) {
          existsModelMap = !brandModelsMap
            ? null
            : this.initExistsModelMap(vehicleOnContract.brand.name, brandModelsMap)
          this.setState({ brandInput: vehicleOnContract.brand.name })
        }

        // --- If a Model was previously selected for the car and the user returned back. ---------
        if (
          !modelSelected &&
          this.toMapKey(vehicleLookedUp.model!.name) !== this.toMapKey(vehicleOnContract.model.name)
        ) {
          let newModelInput: string = !vehicleOnContract.model.name
            ? initialState.modelInput
            : vehicleOnContract.model.name

          this.initModelFuelTypes(!newModelInput ? null : newModelInput, existsModelMap)
          this.setState({ modelInput: newModelInput })
        }

        // --- If a FuelType was previously selected for the car and the user returned back. ---------
        if (this.toMapKey(fuelTypeSelected) !== this.toMapKey(vehicleOnContract.fuelType.name)) {
          this.setState({ fuelTypeInput: vehicleOnContract.fuelType.name })
        }

        // --- If a RegDate was previously selected for the car and the user returned back. ---------
        if (vehicleOnContract.regDate && this.toMapKey(regDateSelected) !== this.toMapKey(vehicleOnContract.regDate)) {
          const formattedLocalDate = this.formatRegDate(vehicleOnContract.regDate)

          if (this.state.regDateInput !== formattedLocalDate) {
            this.setState({ regDateInput: formattedLocalDate })
          }
        }
      }
    }
  }

  public render() {
    const { handleBrandChange, handleModelChange, handleFuelTypeChange, handleDateChange } = this
    const { classes, formModeType, vehicleLookedUp, hasAvailableWarranty } = this.props
    const {
      brandInput,
      brands,
      isLockBrandSelection,
      brandModelsMap,
      modelInput,
      isLockModelSelection,
      commonFuelTypes,
      modelFuelTypes,
      fuelTypeInput,
      isLockFuelTypeSelection,
      regDateInput,
      regDate,
      isLockRegDateSelection,
      isInitializing,
    } = this.state

    const errors = this.getErrorMessages()
    const hasRequiredInfo = this.hasRequiredVehicleInfo(this.props.vehicle)
    const isAllFieldsLocked = this.isAllFieldsLocked()

    const subheading = !hasRequiredInfo ? t(TRANSLATION_KEY_MISSING_DATA) : t(TRANSLATION_KEY_DEFAULT)
    const models = !brandModelsMap ? null : brandModelsMap.get(this.toMapKey(brandInput))
    const fuelTypes: FuelType[] | null = !modelFuelTypes ? commonFuelTypes : modelFuelTypes

    const isHideRegNumber = formModeType === 'Manual-Search' ? true : false
    const isHideVIN = formModeType === 'Manual-Search' ? true : false

    // --- Set prefilled values. ---------
    const prefilledBrand = !vehicleLookedUp || !vehicleLookedUp.brand ? '—' : vehicleLookedUp.brand.name
    const prefilledModel = !vehicleLookedUp || !vehicleLookedUp.model ? '—' : vehicleLookedUp.model.name
    const prefilledFuelType = !vehicleLookedUp || !vehicleLookedUp.fuelType ? '—' : vehicleLookedUp.fuelType.name
    const prefilledRegDate = !regDate ? '—' : formatDate(new Date(regDate)) // Note, we only need to country format once it is presented to the user.

    // --- Set do strike prefilled values. ---------
    const strikeBrand =
      !vehicleLookedUp || !vehicleLookedUp.brand
        ? false
        : this.toMapKey(vehicleLookedUp.brand.name) !== this.toMapKey(brandInput)
    const strikeModel =
      !vehicleLookedUp || !vehicleLookedUp.model
        ? false
        : this.toMapKey(vehicleLookedUp.model.name) !== this.toMapKey(modelInput)
    const strikeFuelType =
      !vehicleLookedUp || !vehicleLookedUp.fuelType
        ? false
        : this.toMapKey(vehicleLookedUp.fuelType.name) !== this.toMapKey(fuelTypeInput)

    let strikeRegDate = false
    if (regDate) {
      // ---------------------------------------------------------------------
      // IMPORTANT that these two dates are in the same timezone
      // and has the same date format.
      const date1 = this.formatRegDate(new Date(regDate).toDateString())
      const date2 = this.formatRegDate(regDateInput)
      // ---------------------------------------------------------------------

      // NOTE: When the regDate is OK and is correct, both these date strings shall match exactly character by character.
      // For instance:
      // date1 = '2019-10-01'
      // date2 = '2019-10-01'
      // Otherwise regDate will be treated as not correct.
      strikeRegDate = date1 !== date2 || date1 === 'Invalid date'
    }

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>{t('Vehicle')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Card className={classNames(classes.cardActive, classes.noOverflow)} data-e2e="VehicleMapperForm">
            <CardContent>
              {!isAllFieldsLocked && (
                <>
                  <Typography className={classes.subheading} paragraph={true}>
                    <Input
                      className={classes.subheading}
                      value={subheading}
                      readOnly={true}
                      fullWidth={true}
                      multiline={true}
                      disableUnderline={true}
                      startAdornment={
                        <InputAdornment position="start">
                          {!hasRequiredInfo ? (
                            <WarningRoundedIcon className={classes.iconWarning} fontSize="large" />
                          ) : (
                            <InfoOutlinedIcon className={classes.iconInfo} fontSize="large" />
                          )}
                        </InputAdornment>
                      }
                    />
                  </Typography>
                  {hasAvailableWarranty && (
                    <Typography className={classes.subheading} paragraph={true}>
                      <Input
                        className={classes.subheading}
                        value={
                          !hasRequiredInfo
                            ? t(
                                'However, there are Dealer Paid Warranties available for this vehicle (with this registration number)',
                              )
                            : t('There are Dealer Paid Warranties available for this vehicle')
                        }
                        readOnly={true}
                        fullWidth={true}
                        multiline={true}
                        disableUnderline={true}
                      />
                    </Typography>
                  )}
                </>
              )}

              {!isHideRegNumber && (
                <PrefillAndSelectorField
                  fieldType={'string'}
                  inputLabel={t('License Plate')}
                  e2eTestAttribute={'VehicleMapperForm__regNumber'}
                  //placeholder={placeholder} /* TODO: Not yet implemented. */
                  //prefilledValue={prefilledValue} /* TODO: Not yet implemented. */
                  inputValue={!(vehicleLookedUp && vehicleLookedUp.regNumber) ? '—' : vehicleLookedUp.regNumber}
                  error={!!(!isInitializing && errors.plate)}
                  helperText={errors.plate}
                  data-e2e={'VehicleMapperForm__regNumber'}
                />
              )}

              {!isHideVIN && (
                <PrefillAndSelectorField
                  className={isHideVIN && classes.hidden}
                  fieldType={'string'}
                  inputLabel={t('VIN number')}
                  e2eTestAttribute={'VehicleMapperForm__vin'}
                  //placeholder={placeholder} /* TODO: Not yet implemented. */
                  //prefilledValue={prefilledValue} /* TODO: Not yet implemented. */
                  inputValue={!(vehicleLookedUp && vehicleLookedUp.vin) ? '—' : vehicleLookedUp.vin}
                  error={!!(!isInitializing && errors.vin)}
                  helperText={errors.vin}
                />
              )}

              <PrefillAndSelectorField
                fieldType={'options'}
                type={'brand'}
                inputLabel={t('Brand')}
                optionsLabel={t('Brands')}
                placeholder={t('Please select Brand...')}
                e2eTestAttribute={'VehicleMapperForm__brand'}
                inputValue={brandInput}
                prefilledValue={prefilledBrand}
                defaultValue={brandInput}
                options={brands}
                onChange={handleBrandChange}
                error={!isInitializing && errors.brand}
                helperText={errors.brand}
                translateCaption={false}
                isDisabled={isLockBrandSelection}
                strikePrefilledValue={strikeBrand}
              />
              <PrefillAndSelectorField
                fieldType={'options'}
                type={'model'}
                inputLabel={t('Model')}
                optionsLabel={t('Models')}
                placeholder={t('Please select Model...')}
                e2eTestAttribute={'VehicleMapperForm__model'}
                inputValue={modelInput}
                prefilledValue={prefilledModel}
                defaultValue={modelInput}
                options={models}
                onChange={handleModelChange}
                error={!isInitializing && errors.model}
                helperText={errors.model}
                translateCaption={false}
                isDisabled={isLockModelSelection}
                strikePrefilledValue={strikeModel}
              />
              <PrefillAndSelectorField
                fieldType={'options'}
                type={'fuelType'}
                inputLabel={t('Fuel Type')}
                optionsLabel={t('Fuel Types')}
                placeholder={t('Please select Fuel Type...')}
                e2eTestAttribute={'VehicleMapperForm__fuelType'}
                inputValue={fuelTypeInput}
                prefilledValue={prefilledFuelType}
                defaultValue={fuelTypeInput}
                options={fuelTypes}
                onChange={handleFuelTypeChange}
                error={!isInitializing && errors.fuelType}
                helperText={errors.fuelType}
                translateCaption={true}
                isDisabled={isLockFuelTypeSelection}
                strikePrefilledValue={strikeFuelType}
              />
              <PrefillAndSelectorField
                fieldType={'date'}
                inputLabel={t('First Registration Date')}
                e2eTestAttribute={'VehicleMapperForm__regDate'}
                inputValue={regDateInput}
                prefilledValue={prefilledRegDate}
                onChange={handleDateChange}
                error={!isInitializing && errors.regDate}
                helperText={errors.regDate}
                translateCaption={true}
                isDisabled={isLockRegDateSelection}
                strikePrefilledValue={strikeRegDate}
              />
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private toMapKey = (str: string): string => str.trim().toLowerCase()

  private dateLocalToIso = (localDate?: string) => moment(localDate, defaultDateFormat.moment).toISOString()

  private formatRegDate = (date?: string) => moment(date).format(defaultDateFormat.moment)

  private setStartUpVehicleInputStates = (
    vehicle: Vehicle | VehiclePartial,
    brandModelsMap: Map<string, IModelCollection[]> | null,
  ) => {
    if (!vehicle) {
      return
    }

    let existsModelMap: Map<string, IModelCollection> | null = null
    let { brandInput, modelInput, fuelTypeInput, regDate, regDateInput, wasModelNameReMappedAtStartup } = initialState

    // *** Brand *********
    if (!vehicle.brand) {
      brandInput = ''
    } else {
      existsModelMap = !brandModelsMap ? null : this.initExistsModelMap(vehicle.brand.name, brandModelsMap) // Init and also get it for later use.
      brandInput = !vehicle.brand ? '' : vehicle.brand.name
    }

    // *** Model names selections. *********
    if (vehicle.model) {
      const doesModelExists = existsModelMap && this.existsModel(vehicle.model.name, existsModelMap)

      if (doesModelExists) {
        modelInput = vehicle.model.name
      } else {
        const mappedModelName = vehicle.model.name

        if (!mappedModelName) {
          modelInput = ''
          wasModelNameReMappedAtStartup = false
        } else {
          modelInput = mappedModelName
          wasModelNameReMappedAtStartup = true
        }
      }

      this.initModelFuelTypes(!modelInput ? null : modelInput, existsModelMap)
    }

    // *** Fuel-Type selection. *********
    if (vehicle.fuelType) {
      fuelTypeInput = vehicle.fuelType.name
    }

    // *** Registration Date selection. *********
    if (vehicle.regDate && moment(new Date(vehicle.regDate)).isValid()) {
      // -----------------------------------------------------------------------------------
      // NOTE: This is important, so dates can be compared at a later change. Also note that
      // the server uses UTC, datapicker internally uses local time, and dates are presented
      // to the user in local time plus in a country's own date format.
      // Eventually, we need to compare dates and convert one timezone to another, plus compare
      // dates in the same defined date format.
      regDate = moment(new Date(vehicle.regDate)).toString()
      // -----------------------------------------------------------------------------------

      regDateInput = moment(vehicle.regDate).format(defaultDateFormat.moment)
    }

    this.setState(
      {
        brandInput,
        isLockBrandSelection: !!(brandModelsMap && this.existsBrand(brandInput, brandModelsMap)),
        modelInput,
        isLockModelSelection: !!(existsModelMap && this.existsModel(modelInput, existsModelMap)),
        fuelTypeInput,
        isLockFuelTypeSelection: !!(existsModelMap && this.existsFuelType(modelInput, fuelTypeInput, existsModelMap)),
        regDate,
        regDateInput,
        isLockRegDateSelection: !!(vehicle.regDate && moment(new Date(vehicle.regDate)).isValid()),
        isInitializing: false,
        wasModelNameReMappedAtStartup,
      },
      () => {
        this.updateVehicle()

        // this.listAllElectricCars() // For debugging purposes.
      },
    )
  }

  /**
   * Saves the information about all the cars available in a Map indexed by their Brand name.
   */
  private initBrandModelsData = (cars: ICarCollection): Map<string, IModelCollection[]> | null => {
    if (cars) {
      // For all models (arrays) for all brands, brand name as key.
      const brandModelsMap: Map<string, IModelCollection[]> = new Map()

      // For all brands (incl. with id:s), brand name as key.
      const brandsMap: Map<string, Brand> = new Map()

      // For all brands for React-Select.
      const brands: Brand[] = []

      cars.brands.forEach((brandWithModels) => {
        let key: string = this.toMapKey(brandWithModels.name)
        let value: IModelCollection[] = brandWithModels.models
        const brand: Brand = { name: brandWithModels.name, id: brandWithModels.id }

        brandModelsMap.set(key, value)
        brandsMap.set(key, brand)
        brands.push({ id: brand.id, name: brand.name })
      })

      this.setState({ brandModelsMap, brandsMap, brands })

      return brandModelsMap
    }

    return null
  }

  private initExistsModelMap = (
    newBrandName: string | null,
    brandModelsMap: Map<string, IModelCollection[]>,
  ): Map<string, IModelCollection> | null => {
    if (!newBrandName) {
      console.warn('In initExistsModelMap(..) newBrandName is empty!')
      return null
    }
    if (!brandModelsMap) {
      console.error('In initExistsModelMap(..) brandModelsMap is null!')
      return null
    }

    const models: IModelCollection[] | null | undefined = !brandModelsMap
      ? null
      : brandModelsMap.get(this.toMapKey(newBrandName))
    const existsModelMap: Map<string, IModelCollection> = new Map()

    if (!models) {
      console.warn(
        'In initExistsModelMap(..) models is empty! newBrand: ' + newBrandName + ', brandModelsMap: ' + brandModelsMap,
      )

      return null
    }

    models.forEach((model: IModelCollection) => {
      const key: string = this.toMapKey(model.name)
      existsModelMap.set(key, model)
    })

    this.setState({ existsModelMap: existsModelMap })

    return existsModelMap
  }

  /**
   * Initiates the country specific Fuel-Types.
   *
   * @param isoCountry The country code is needed for country specific Fuel-Types.
   */
  private async initCommonFuelTypes(isoCountry: string | null) {
    const country: string | null = !isoCountry ? getProviderCountry() : isoCountry
    let fuelTypes: FuelType[] | null = null
    let fuelTypes2: IFueltypeOption[] | null = null

    // Fuel Types.
    if (!isoCountry) {
      console.warn('"isoCountry" prop is empty in VehicleMapperForm, cannot use country specific Fuel-Types')

      fuelTypes = fallbackFuelTypes // Use fallback fuel-types.
      console.warn('Falling back on hardcoded default Fuel-Types, instead of country specific')
    } else {
      let response: any

      try {
        response = await getFuelTypes(isoCountry)
        fuelTypes = response.data
      } catch (e) {
        console.warn('Fetching fuelTypes failed, response:')
        console.warn(response)
        console.warn('Message: ' + e)

        fuelTypes = fallbackFuelTypes // Use fallback fuel-types.
        console.warn('Falling back on hardcoded default Fuel-Types, instead of country specific')
      }

      if (!((country && country.toUpperCase()) === 'FI' && isShowHybridAsFuelTypeInFinland)) {
        try {
          // ---- Filter out the "Hybrid" fuel type ---------------------------------------------------------
          /*
            Note: Since the fuel type "Hybrid" hangs in a state of flux (whether
            we should maybe have it, or not, for sure).

            Update: 2020 Oct 21, we will allow it for Finland for time being.
          */
          if (!fuelTypes) {
            throw Error('No Fuel-Types, fuelType: ' + fuelTypes)
          } else {
            fuelTypes = fuelTypes.filter((ft: FuelType) => ft.name.toLowerCase() !== 'hybrid')
          }
          // ------------------------------------------------------------------------------------------
        } catch (e) {
          console.warn('Filtering out fuelType "hybrid" failed: ' + country)
          console.warn('Message: ' + e)
        }
      }
    }

    // Remap structure for React-Select and also index the Fuel-Type as existing.
    fuelTypes2 =
      fuelTypes &&
      fuelTypes.map((ft: FuelType) => {
        return { name: ft.name, caption: tFuelType(ft.name) }
      })

    this.setState({ commonFuelTypes: fuelTypes2 })
  }

  /**
   * Initiates the Fuel-Types for a specific model.
   *
   * @param isoCountry The country code is needed for country specific Fuel-Types.
   * @return Will return the initiated Fuel-Types if initiated successfully, otherwise returns null.
   */
  private initModelFuelTypes = (
    modelName: string | null,
    existsModelMap?: Map<string, IModelCollection> | null,
  ): FuelType[] | null => {
    const map: Map<string, IModelCollection> | null = !existsModelMap ? this.state.existsModelMap : existsModelMap
    const key: string | null = !modelName ? null : this.toMapKey(modelName)

    let modelFuelTypes: FuelType[] | null | undefined = null

    if (!map || !modelName || !key) {
      modelFuelTypes = null
    } else {
      const model = map.get(key)

      if (model && model.fuelTypes) {
        modelFuelTypes = model.fuelTypes
      }
    }

    const returnValue: FuelType[] | null = !modelFuelTypes ? null : modelFuelTypes

    this.setState({
      modelFuelTypes: returnValue,
    })

    return returnValue
  }

  /**
   * @param brandModelsMap If provided, use this lookup map instead, of the one from state.
   */
  private existsBrand = (brandName: string | null, brandModelsMap?: Map<string, IModelCollection[]>): boolean => {
    const map = !brandModelsMap ? this.state.brandModelsMap : brandModelsMap

    if (!map) {
      console.error('VehicleMapperForm: In existsBrand(..) brandModelsMap is null')

      return false
    }

    return !!(brandName && map.has(this.toMapKey(brandName)))
  }

  /**
   * @param existsModelMap If provided, use this lookup map instead, of the one from state.
   */
  private existsModel = (modelName: string | null, existsModelMap?: Map<string, IModelCollection>): boolean => {
    const map = !existsModelMap ? this.state.existsModelMap : existsModelMap

    if (!map) {
      console.warn('VehicleMapperForm: In existsModel(..) existsModelMap is null')

      return false
    }

    return !!(modelName && map.has(this.toMapKey(modelName)))
  }

  /**
   * @param existsModelMap If provided, use this lookup map instead, of the one from state.
   */
  private existsFuelType = (
    modelName: string | null,
    fuelTypeName: string | null,
    existsModelMap?: Map<string, IModelCollection>,
  ): boolean => {
    const map = !existsModelMap ? this.state.existsModelMap : existsModelMap

    if (!map) {
      console.warn('VehicleMapperForm: In existsFuelType(..) existsModelMap is null')

      return false
    }

    if (!modelName || !fuelTypeName) {
      return false
    } else {
      const model: IModelCollection | undefined = map.get(this.toMapKey(modelName))

      if (!model) {
        return false
      } else {
        const fuelTypeArray: FuelType[] = model.fuelTypes

        if (fuelTypeArray.length <= 0) {
          console.error('No Fuel Types found for this Model: ' + modelName)
        } else {
          return fuelTypeArray.some((ft: FuelType) => this.toMapKey(fuelTypeName) === this.toMapKey(ft.name))
        }

        return false
      }
    }
  }

  /*
  // NOTE: Deactivated for now.
  //
  private searchRegNoOrVIN = async (value: string) => {
    const lookup = await getLicenseLookup(value)

    if (lookup.data) {
      const { data } = lookup
      const { brand, model, fuelType } = data
      this.setState(
        {
          brandInput: brand.name,
          modelInput: model.name,
          fuelTypeInput: fuelType.name,
        },
        () => this.onVehicleChange(data),
      )
    }
  }
  */

  private handleBrandChange = (newBrand: IVehicleTypeOption | null) => {
    const { brandModelsMap } = this.state

    if (!brandModelsMap) {
      console.warn('VehicleMapperForm: In handleBrandChange(..) brandModelsMap is null')
    } else {
      if (newBrand && newBrand.value) {
        this.initExistsModelMap(newBrand.value, brandModelsMap)

        this.setState({
          brandInput: newBrand.value,
          modelInput: '',
          modelFuelTypes: null,
        })
      }
    }
  }

  private handleModelChange = (newModel: IVehicleTypeOption | null) => {
    const { existsModelMap, fuelTypeInput } = this.state

    if (newModel && newModel.value) {
      const fuelTypes: FuelType[] | null = this.initModelFuelTypes(newModel.value, existsModelMap)
      const doesSelectedFuelTypeExist = this.existsFuelType(newModel.value, fuelTypeInput)

      this.setState(
        {
          modelInput: newModel.value,
          modelFuelTypes: fuelTypes,
          fuelTypeInput: !doesSelectedFuelTypeExist ? '' : fuelTypeInput,
        },
        this.updateVehicle,
      )
    }
  }

  private handleFuelTypeChange = (newFuelType: IVehicleTypeOption | null) => {
    if (newFuelType && newFuelType.value) {
      this.setState({ fuelTypeInput: newFuelType.value }, this.updateVehicle)
    }
  }

  /**
   * @param newDate The new date selected by user, NOTE: that this date is a LOCAL date (passed on from DatePicker).
   */
  private handleDateChange = (newDate: any) => {
    if (newDate) {
      if (newDate.isValid()) {
        this.setState({ regDateInput: newDate.format(defaultDateFormat.moment) }, this.updateVehicle)
      } else {
        console.error('Date not valid: ' + newDate)
        notify.error({ message: t('The date is not valid, sorry something went wrong') + '.' })
      }
    }
  }

  private updateVehicle = () => {
    const vehicleOrNull: Vehicle | null = this.getCompleteVehicle()

    if (vehicleOrNull) {
      this.onVehicleChange(vehicleOrNull)
    }
  }

  private getErrorMessages = () => {
    const errors: SearchErrors = {
      plate: undefined,
      vin: undefined,
      brand: undefined,
      model: undefined,
      fuelType: undefined,
      regDate: undefined,
    }
    const { vehicleLookedUp } = this.props
    const { brandInput, modelInput, fuelTypeInput, regDateInput } = this.state

    if (vehicleLookedUp) {
      const { regNumber, vin } = vehicleLookedUp

      // Rudimentary regNumber validator.
      // @TODO: In future perhaps extend this or update it to use the or an already existing validator for this.
      if (!regNumber || !(regNumber && regNumber.length >= 5 && regNumber.length <= 7)) {
        errors.plate = t('Invalid License Plate number')
      }

      if (!vin || (vin && vin.length !== REQUIRED_VIN_LENGTH)) {
        errors.vin = t('Invalid VIN number')
      }

      // --- Brand. ------
      if (!brandInput) {
        errors.brand = t('Missing information, please select the Brand of the car')
      } else if (!this.existsBrand(this.toMapKey(brandInput))) {
        errors.brand = t('Sorry, that Brand is not supported in our price catalogue')
      }

      // --- Model. ------
      if (!modelInput) {
        errors.model = t('Missing information, please select the Model of the car')
      } else if (!this.existsModel(this.toMapKey(modelInput))) {
        errors.model = t('Sorry, that Model is not supported in our price catalogue')
      }

      // --- Fuel Type. ------
      if (modelInput) {
        // Only show error for fuelType if a model is selected.
        if (!fuelTypeInput) {
          errors.fuelType = t('Missing information, please select the Fuel Type of the car')
        } else if (!this.existsFuelType(modelInput, fuelTypeInput)) {
          errors.fuelType = t('Sorry, that Fuel Type is not supported in our price catalogue')
        }
      }

      if (!regDateInput) {
        errors.regDate = t('Missing information, please select the First Registration Date of the car')
      }
    }

    return errors
  }

  private getCompleteVehicle = (): Vehicle | null => {
    const { vehicleLookedUp } = this.props
    const vin = !vehicleLookedUp ? '' : vehicleLookedUp.vin
    const regNumber = !vehicleLookedUp ? '' : vehicleLookedUp.regNumber

    const {
      brandInput,
      modelInput,
      fuelTypeInput,
      regDateInput,
      brandsMap,
      brandModelsMap,
      modelFuelTypes,
    } = this.state

    if (!brandsMap || !brandModelsMap || !modelFuelTypes) {
      console.warn(
        `No lookup Map(s)! brandsMap: ${brandsMap}, brandModelsMap: ${brandModelsMap}, modelFuelTypes: ${modelFuelTypes}`,
      )
      return null
    } else {
      const brand: Brand | undefined = brandsMap.get(this.toMapKey(brandInput))
      const models: IModelCollection[] | undefined = brandModelsMap.get(this.toMapKey(brandInput))

      // Get the proper Model (including the id for later pricing).
      let model: Model | undefined = undefined
      if (models) {
        // Note: array shouldn't be too large (and should go pretty quickly),
        // otherwise have to resort to a Map instead.
        models.forEach((modelC: IModelCollection) => {
          const key1 = this.toMapKey(modelC.name)
          const key2 = this.toMapKey(modelInput)

          if (key1 === key2) {
            model = { name: modelC.name, id: modelC.id }
          }
        })
      }

      // Get the proper FuelType (including the id for later pricing).
      let fuelType: FuelType | undefined = undefined
      if (model) {
        // Note: The array shouldn't be too large (and should go pretty quickly),
        // otherwise have to resort to a Map instead.
        modelFuelTypes.forEach((ft: FuelType) => {
          const key1 = this.toMapKey(ft.name)
          const key2 = this.toMapKey(fuelTypeInput)

          if (key1 === key2) {
            fuelType = ft
          }
        })
      }

      const vehiclePartial: VehiclePartial = {
        vin: vin,
        regNumber: regNumber,
        brand: brand,
        model: model,
        fuelType: fuelType,
        regDate:
          !regDateInput || !moment(regDateInput, defaultDateFormat.moment).isValid()
            ? undefined
            : moment(regDateInput, defaultDateFormat.moment).toDate().toISOString(),
        modelYear: vehicleLookedUp ? vehicleLookedUp.modelYear : undefined,
        vehicleType: vehicleLookedUp ? vehicleLookedUp.vehicleType : undefined,
        engineMaxPower: vehicleLookedUp ? vehicleLookedUp.engineMaxPower : undefined,
        originalEnginePowerKWFromLookup: vehicleLookedUp ? vehicleLookedUp?.originalEnginePowerKWFromLookup : undefined,
        cylinderVolume: vehicleLookedUp ? vehicleLookedUp.cylinderVolume : undefined,
        transmissionType: vehicleLookedUp ? vehicleLookedUp.transmissionType : undefined,
        driveType: vehicleLookedUp ? vehicleLookedUp.driveType : undefined,
        hasFourWheelDrive: vehicleLookedUp ? vehicleLookedUp.hasFourWheelDrive : undefined,
      }

      if (!this.hasRequiredVehicleInfo(vehiclePartial)) {
        return null
      } else {
        return {
          ...vehiclePartial,
          modelYear: 0,
        } as Vehicle
      }
    }
  }

  private hasRequiredVehicleInfo = (vehicle: Vehicle | VehiclePartial): boolean => {
    const { formModeType } = this.props

    if (formModeType === 'Manual-Search') {
      return hasRequiredInfo(vehicle, true)
    } else {
      return hasRequiredInfo(vehicle)
    }
  }

  /**
   * @return Returns true if all fields with Selectors and DatePicker are locked.
   */
  private isAllFieldsLocked = (): boolean => {
    const { isLockBrandSelection, isLockModelSelection, isLockFuelTypeSelection, isLockRegDateSelection } = this.state

    return isLockBrandSelection && isLockModelSelection && isLockFuelTypeSelection && isLockRegDateSelection
  }

  private onVehicleChange = async (vehicle: Vehicle) => {
    if (vehicle.brand.name && vehicle.model.name && vehicle.fuelType.name && vehicle.regDate) {
      // Only do a lookup if relevant info has changed
      // Scope hax are required to remove vin/regNumber from vehicle objects
      let vehicle1: Partial<Vehicle>
      let vehicle2: Partial<Vehicle>

      {
        const { vin, regNumber, ...oldVehicle } = { ...this.props.vehicleLookedUp }
        vehicle1 = oldVehicle
      }
      {
        const { vin, regNumber, ...newVehicle } = { ...vehicle }
        vehicle2 = newVehicle
      }

      if (!isEqualWith(vehicle1, vehicle2)) {
        await this.onTemplatesLookup(vehicle) // Note: The await is important here (especially when updating any vehicle info)!
      }
    }

    this.props.onVehicleChange(vehicle) // Change the vehicle in parent component as well.
  }

  private onTemplatesLookup = async (vehicle: Vehicle) => {
    const { wasModelNameReMappedAtStartup } = this.state

    // Note: We are interested of wasModelNameReMappedAtStartup since if all info
    // could be mapped AUTOMATICALLY at START_UP, then we want to show
    // another component instead of this VehicleMapper.
    vehicle && this.props.onTemplatesLookup(vehicle, wasModelNameReMappedAtStartup)

    if (wasModelNameReMappedAtStartup) {
      this.setState({ wasModelNameReMappedAtStartup: false })
    }
  }
}

export default withStyles(styles)(VehicleMapperForm)
