import { FuelType, IVehicleInfo, Vehicle, VehicleAlongItsContracts, VehiclePartial } from '@fragus/sam-types'
import { DirectionsCar } from '@material-ui/icons'
import { tFuelType } from 'translations/translationFunctions'

export function localFuelTypeName(vehicle?: { fuelType: FuelType | undefined } | null) {
  return (vehicle && vehicle.fuelType && tFuelType(vehicle.fuelType.name || '')) || ''
}

/**
 * @return Returns true if all fields has values.
 */
export const hasRequiredInfo = (
  vehicle: Vehicle | VehiclePartial | undefined | null,
  isIgnoreVIN: boolean = false,
): boolean => {
  if (isIgnoreVIN) {
    if (
      // NOTE: vin omitted!
      !vehicle ||
      !vehicle.brand ||
      !vehicle.model ||
      !vehicle.fuelType ||
      !vehicle.regDate ||
      vehicle.regDate === 'Invalid date'
    ) {
      return false
    } else {
      if (!vehicle) {
        return false
      }

      try {
        return (
          // NOTE: vin omitted!
          vehicle.brand &&
          vehicle.brand.name.length > 0 &&
          vehicle.model &&
          vehicle.model.name.length > 0 &&
          vehicle.fuelType &&
          vehicle.fuelType.name.length > 0 &&
          vehicle.regDate.length > 0
        )
      } catch (err) {
        return false
      }
    }
  } else {
    if (
      !vehicle ||
      !vehicle.vin ||
      !vehicle.brand ||
      !vehicle.model ||
      !vehicle.fuelType ||
      !vehicle.regDate ||
      vehicle.regDate === 'Invalid date'
    ) {
      return false
    } else {
      try {
        return (
          vehicle.vin.length > 0 &&
          vehicle.brand.name.length > 0 &&
          vehicle.model.name.length > 0 &&
          vehicle.fuelType.name.length > 0 &&
          vehicle.regDate.length > 0
        )
      } catch (err) {
        return false
      }
    }
  }
}

export const vehicleToVehicleInfo = (vehicle: Vehicle | VehicleAlongItsContracts): IVehicleInfo => {
  return {
    vehicleRegistryId: undefined,
    registration: vehicle.regNumber || '',
    vin: vehicle.vin || '',
    // type: string // NOTE: Not same as vehicleType, this is vehicle type description like 'GTE Plug-In Hybrid 150 kW (204 hv) DSG-automaatti 4-ovinen'.
    // @note param "type" renamed to "versionModelType" to avoid confusion with vehicleType
    versionModelType: vehicle.versionModelType || '', // NOTE: Not same as vehicleType, this is vehicle type description like 'GTE Plug-In Hybrid 150 kW (204 hv) DSG-automaatti 4-ovinen'.
    brand: vehicle.brand.name || '',
    model: vehicle.model.name || '', // model: '208',
    fuel_type: vehicle.fuelType.name || '', // fuel_type: 'Benzin',
    first_registration_date: vehicle.regDate || '', // first_registration_date: '2016-10-31',
    expire_date: null,
    model_year: vehicle.modelYear,
    engineMaxPower: vehicle.engineMaxPower || null, // Maximum power in kW, integer or decimal number like 115.5 or 132. NOTE: For now, engineMaxPower is the highest power of any of the engines on a multi-engine vehicle.
    vehicleType: vehicle.vehicleType || '', // Note: Not same as type (now versionModelType).
    cylinderVolume: vehicle.cylinderVolume, // Engine size in cc or cm3.
    transmissionType: vehicle.transmissionType,
    driveType: vehicle.driveType, // Front, rear, or all wheel drive.
    hasFourWheelDrive: vehicle.hasFourWheelDrive,
    lookupCacheStatus: undefined,
  }
}

// TODO: marko, possibly Vehicle to VehicleAndContracts.
export const partialVehicleToVehicle = (vehiclePartial: VehiclePartial): Vehicle => {
  return {
    ...vehiclePartial,
    modelYear: vehiclePartial.modelYear || 0,
  } as Vehicle
}

export const partialVehicleToVehicleAlongItsContracts = (vehiclePartial: VehiclePartial): VehicleAlongItsContracts => {
  return {
    ...vehiclePartial,
    modelYear: vehiclePartial.modelYear || 0,
  } as VehicleAlongItsContracts
}

export const hasVinAndRegNum = (vehicle: { regNumber?: string; vin?: string }) => {
  return vehicle.regNumber && vehicle.vin
}

export const kW2horsepower = (kWatts: number): number => {
  const metricHorsepower = 1.3596216173 // One kWatt to metric horsepower.
  return kWatts * metricHorsepower
}

export const horsepower2kW = (hp: number): number => {
  const metricHorsepower = 1.3596216173 // One kWatt to metric horsepower.
  if (hp <= 0) {
    return 0
  }

  return hp / metricHorsepower
}

export const CarIcon = DirectionsCar
