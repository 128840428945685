import { CircularProgress } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatMileage } from '@omnicar/sam-format'
import { IProviderStats } from '@fragus/sam-types'
import SingleValue from 'components/admin/Overview/Shared/SingleValue'
import format from 'format-number'
import React, { SFC } from 'react'
import { compose } from 'recompose'
import { t } from 'translations/translationFunctions'
import { isUseWhiteBGTheme } from 'utils/localStorage'

interface IOwnProps {
  data: IProviderStats | undefined
}
type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      lineHeight: '2em',
    },
    overviewSingle: {
      '&:not(:last-child)': {
        marginRight: spacing(6),
      },
    },
    white: {
      color: isUseWhiteBGTheme() ? palette.primary.main : '#eeeeee',
    },
    loadingIconContainer: {
      display: 'flex',
      minHeight: '60px',
      alignItems: 'center',
    },
  })

const numberFormatOpts = {
  truncate: 1,
  decimal: ',',
}

const ContractsTotals: SFC<TProps> = ({ classes, data }) => (
  <div className={classes.root}>
    {data ? (
      <>
        <SingleValue
          title={t('Contract Offers')}
          value={format(numberFormatOpts)(data.offers)}
          styling={{ root: classes.overviewSingle, title: classes.white, value: classes.white }}
        />
        <SingleValue
          title={t('Activated in the past 5 days')}
          value={format(numberFormatOpts)(data.created)}
          styling={{ root: classes.overviewSingle, title: classes.white, value: classes.white }}
        />
        <SingleValue
          title={t('Avg. Duration')}
          value={format(numberFormatOpts)(data.avgDuration)}
          styling={{ root: classes.overviewSingle, title: classes.white, value: classes.white }}
        />
        <SingleValue
          title={t('Avg. Mileage')}
          value={formatMileage(data.avgMileage)}
          styling={{ root: classes.overviewSingle, title: classes.white, value: classes.white }}
        />
      </>
    ) : (
      <div className={classes.loadingIconContainer}>
        <CircularProgress className={classes.white} />
      </div>
    )}
  </div>
)

export default compose<TProps, IOwnProps>(withStyles(styles))(ContractsTotals)
