import { Button } from '@material-ui/core'
import { WithStyles, withStyles } from '@material-ui/core/styles'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import {
  AddCircleOutline,
  ArrowDropDown,
  ArrowDropUp,
  Dashboard,
  Edit as EditIcon,
  Receipt,
  Settings,
  SettingsBackupRestore as SettingsBackupRestoreIcon,
  SupervisorAccount,
} from '@material-ui/icons'
import { formatNumber } from '@omnicar/sam-format'
import { IContractProductTypeCountResponse } from '@fragus/sam-types'
import { IContractProviderInfo } from '@fragus/sam-types/types/admin/contractProvider'
import ActionTypes from 'actions/ActionTypes'
import { contractFlowReset, IContractFlowReset } from 'actions/contractFlowActions'
import { getContractCount, patchAndRefetchImportantData } from 'api/api'
import LogoFragus from 'assets/images/JustGO-logo.png'
import classNames from 'classnames'
import React, { CSSProperties } from 'react'
import { connect, Dispatch } from 'react-redux'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import { IRootState } from 'reducers/initialState'
import { IReactRouterState } from 'reducers/reactRouter/reactRouterInitialState'
import {
  administrationPath,
  contractDetailsBaseRoute,
  contractFlowPath,
  contractListPath,
  customerListPath,
  overviewPagePath,
  productContractListPath,
} from 'routes/paths'
import { AppContext } from 'store/appContext'
import { activeTab as activeTabColor } from 'theme'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import { IContractFlow } from 'types/contractFlow'
import { isShowLogoOnWeb, isUseWhiteBGTheme } from 'utils/localStorage'
import LinkButtonTabs from '../../../../components/admin/LinkButtonTabs'
import styles from './styles'

const contractCountUpdateAndRequestTreshholdTimeMs: number = 8000
const labelColor = activeTabColor

const styleActiveLink: CSSProperties = {
  paddingLeft: 24,
  borderLeft: '4px solid ' + labelColor,
  borderRadius: '0 4px 4px 0',
}

// In order to prevent needless unmount
// https://material-ui.com/guides/composition/#component-property
const CreateNewLink: React.SFC<any> = (props: any) => {
  return <NavLink to={contractFlowPath({ offerOnly: true })} {...props} />
}

const OverviewLink: React.SFC<any> = (props: any) => {
  return <NavLink to={overviewPagePath} activeStyle={styleActiveLink} {...props} />
}

const ContractLink: React.SFC<any> = (props: any) => {
  return (
    <NavLink
      to={contractListPath}
      activeStyle={styleActiveLink}
      isActive={(_match, { pathname }) =>
        pathname === contractListPath || pathname.startsWith(`/${contractDetailsBaseRoute}/`)
      }
      {...props}
    />
  )
}

const ProductContractLink: React.SFC<any> = (props: any) => {
  return <NavLink to={productContractListPath} activeStyle={styleActiveLink} {...props} />
}

const CustomerLink: React.SFC<any> = (props: any) => {
  return <NavLink to={customerListPath} activeStyle={styleActiveLink} {...props} />
}

const AdministrationLink: React.SFC<any> = (props: any) => {
  return <NavLink to={administrationPath} activeStyle={styleActiveLink} {...props} />
}

interface IDropDownLinkButton {
  tab: { title: TranslationKey; type: string; endAdornment?: number | undefined }
  linkComponent: React.SFC<any>
  icon?: React.ComponentType<SvgIconProps>
}

export interface ILinkProps {
  classes: {
    label: string
  }
  fullWidth: boolean
  className: string
}

interface IActionProps {
  contractFlowReset: () => IContractFlowReset
}

interface IReducerProps {
  contractFlow: IContractFlow
  router: IReactRouterState
}

interface IOwnProps {
  providerInfo: IContractProviderInfo
}

interface IState {
  offersRequestNotification: boolean
  subscriptionDropDownOpen: boolean
  contractCountByProductType: IContractProductTypeCountResponse | undefined
}

type TProps = WithStyles<typeof styles> & IReducerProps & IActionProps & RouteComponentProps<any> & IOwnProps

class AppNavigation extends React.Component<TProps, IState> {
  contractCountLastAboutToUpdateTimeMs: number = performance.now()
  millisOld: number = 0

  public state: IState = {
    offersRequestNotification: false,
    subscriptionDropDownOpen: true,
    contractCountByProductType: undefined,
  }

  public componentDidMount() {
    this.updateContractCountForProductTypes()
  }

  public componentDidUpdate(prevProps: TProps) {
    this.updateContractCountForProductTypes(contractCountUpdateAndRequestTreshholdTimeMs)
  }

  public render() {
    const { classes, contractFlow, router } = this.props
    const { subscriptionDropDownOpen } = this.state
    const isCreating =
      (contractFlow.state.active > 1 && contractFlow.flowType === 'CREATE') ||
      (contractFlow.contractType === 'CUSTOM' && contractFlow.flowType === 'CREATE')

    const linkProps = {
      classes: { label: classes.label },
      fullWidth: true,
      className: classes.link,
    }
    const iconProps = { className: classNames(classes.leftIcon, classes.iconSmall) }
    const buttonIconProps = { className: classNames(classes.leftButtonIcon, classes.iconSmall) }
    const buttons: IDropDownLinkButton[] = [
      { tab: { title: 'Vehicle', type: 'Vehicle' }, linkComponent: ContractLink /*, icon: VehicleIcon*/ },
      { tab: { title: 'Product', type: 'Product' }, linkComponent: ProductContractLink /*, icon: ProductIcon*/ },
    ]
    const partialTabs = buttons.map((b) => b.tab)

    return (
      <div className={classNames('AppNavigation', classes.root)}>
        <div
          className={classNames(
            classes.logoWrapperFragus,
            !isUseWhiteBGTheme() && isShowLogoOnWeb() && classes.topNavigationHasExtraHeight,
          )}
        >
          <a href="https://www.fragus.com/" target="_blank" rel="noopener noreferrer">
            <img className={classes.logoFragus} src={LogoFragus} alt="Fragus Group" />
          </a>
        </div>

        <div className={classes.fab}>
          <AppContext.Consumer>
            {({ providerInfo, role }) => (
              <>
                {!providerInfo ||
                  (!providerInfo.inactive &&
                    (router.location.pathname === contractFlowPath({ offerOnly: true }) ? (
                      <Button
                        className={classes.fabItem}
                        variant="contained"
                        color="secondary"
                        onClick={this.onClickRestart}
                        data-e2e={'ContractFlowActions__restart'}
                      >
                        <SettingsBackupRestoreIcon {...buttonIconProps} />
                        {t('Restart')}
                      </Button>
                    ) : (
                      <Button
                        className={classes.fabItem}
                        variant="contained"
                        color="secondary"
                        component={CreateNewLink}
                        onClick={this.onClickCreate}
                      >
                        {isCreating ? (
                          <React.Fragment>
                            <EditIcon {...buttonIconProps} />
                            <span>{t('Creating')}</span>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <AddCircleOutline {...buttonIconProps} />
                            <span>{t('Create New')}</span>
                          </React.Fragment>
                        )}
                      </Button>
                    )))}
              </>
            )}
          </AppContext.Consumer>
        </div>

        <nav className="AppNavigation__menu">
          <Button {...linkProps} component={OverviewLink} onClick={this.reUpdateFromServer}>
            <Dashboard {...iconProps} />
            {t('Overview')}
          </Button>

          <div>
            <Button {...linkProps} onClick={this.handleDropDownUpdate}>
              <Receipt {...iconProps} />
              {t('Contracts')}
              {subscriptionDropDownOpen ? <ArrowDropUp {...iconProps} /> : <ArrowDropDown {...iconProps} />}
            </Button>

            {subscriptionDropDownOpen && (
              <LinkButtonTabs
                onBlur={this.handleDropDownUpdate}
                active={subscriptionDropDownOpen}
                partialTabs={partialTabs}
                rootClass={classes.dropDownMenu}
                tabClass={classes.tabClass}
                linksButtons={this.getDropDownButtons(buttons, linkProps)}
              />
            )}
          </div>
          <Button {...linkProps} component={CustomerLink} onClick={this.reUpdateFromServer}>
            <SupervisorAccount {...iconProps} />
            {t('Customers')}
          </Button>
          <Button {...linkProps} component={AdministrationLink} onClick={this.reUpdateFromServer}>
            <Settings {...iconProps} />
            {t('Administration')}
          </Button>
        </nav>
      </div>
    )
  }

  /**
   * On resetContract / restartContract.
   */
  private onClickRestart = () => {
    this.reUpdateFromServer()
    this.props.contractFlowReset()
  }

  private onClickCreate = () => {
    this.reUpdateFromServer()
  }

  private handleDropDownUpdate = () => {
    const { subscriptionDropDownOpen } = this.state
    this.setState({ subscriptionDropDownOpen: !subscriptionDropDownOpen })
  }

  private getDropDownButtons = (partialButtons: IDropDownLinkButton[], linkProps: ILinkProps) => {
    const buttons = []
    for (let b of partialButtons) {
      buttons.push({
        tab: {
          ...b.tab,
          endAdornment: this.getEndAdornmentForCounter(b.tab.type, b.tab.title),
        },
        component: Button,
        linkComponent: b.linkComponent,
        linkProps: linkProps,
        icon: b.icon,
      })
    }

    return buttons
  }

  private getEndAdornmentForCounter = (type: string, typeTitle: TranslationKey) => {
    let counter = this.getCounterForType(type) as number | undefined
    let formattedCounter = counter && formatNumber(counter, false)
    return (
      counter !== undefined && {
        content: `(${formattedCounter})`,
        tooltip: t(
          counter === 1
            ? '%type: %counter active subscription agreement'
            : '%type: %counter active subscription agreements',
          { type: t(typeTitle), counter: formattedCounter || counter },
        ),
      }
    )
  }

  private getCounterForType = (type: string, format?: boolean) => {
    let counters = this.state.contractCountByProductType
    counters = counters && counters[type] !== undefined && counters[type] !== undefined ? counters : undefined

    return counters && (format ? formatNumber(counters && counters[type], false) : counters[type])
  }

  /**
   *
   * @param updateAndRequestTreshholdTimeMs A time treshhold in ms, that defines how long time must
   * pass before another request to the server is made to fetch the product count.
   * Omitting this value will fetch immediately and then update the product count.
   * Note: This prevents too many fetches during a short timespan during page loads/updates.
   */
  private async updateContractCountForProductTypes(updateAndRequestTreshholdTimeMs: number = 0) {
    const lastAboutToUpdateTimeMs = this.contractCountLastAboutToUpdateTimeMs
    const nowMs = performance.now()

    if (nowMs >= lastAboutToUpdateTimeMs + updateAndRequestTreshholdTimeMs) {
      this.contractCountLastAboutToUpdateTimeMs = nowMs // Note: Important that this is measured before the request below.
      const contractCount = await getContractCount()

      if (
        contractCount.data &&
        (!this.state.contractCountByProductType ||
          this.isNewContractCount(this.state.contractCountByProductType, contractCount.data))
      ) {
        this.setState({ contractCountByProductType: contractCount.data })
        const oldState = this.state
        this.state = { ...oldState, contractCountByProductType: contractCount.data }
      }
    }
  }

  private isNewContractCount(
    oldContractCounts: IContractProductTypeCountResponse,
    newContractCounts: IContractProductTypeCountResponse,
  ) {
    const types = Object.keys(oldContractCounts)
    let isDifferent = types.length !== Object.keys(newContractCounts).length
    for (let type of types) {
      if (oldContractCounts[type] !== newContractCounts[type]) {
        isDifferent = true
        break
      }
    }
    return isDifferent
  }

  private reUpdateFromServer = (): void => {
    const { providerInfo } = this.props

    patchAndRefetchImportantData(providerInfo.providerId)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<ActionTypes>) => ({
  contractFlowReset: () => dispatch(contractFlowReset()),
})

const mapStateToProps = (state: IRootState) => ({
  contractFlow: state.contractFlow,
  router: state.router,
})

export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(AppNavigation)))
