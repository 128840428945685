import { Card, CardContent, CircularProgress } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatCurrency, formatLocalizedDate } from '@omnicar/sam-format'
import { IBalanceChart, IChartDateValue } from '@fragus/sam-types'
import classNames from 'classnames'
import Typography from 'components/Typography'
import React from 'react'
import { compose } from 'recompose'
import { AppContext } from 'store/appContext'
import { theme } from 'theme'
import { t } from 'translations/translationFunctions'
import { ITransformNumberResponse } from 'types/overviewTypes'
import { getCurrency } from 'utils/localStorage'
import { firstCharUpper } from 'utils/string'
import SingleValue from '../../Shared/SingleValue'
import EconomyBalanceChart from './Chart'
import SVGMark, { BalanceChartShape } from './Chart/SVGMark'

interface IOwnProps {
  className?: string
  cardClass?: string
  data: IBalanceChart[] | undefined
  economyTooltipChartData: IChartDateValue[][] | undefined
  transformNumber: (v: number) => ITransformNumberResponse
}
type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  hoverDate: Date | undefined
  hoverValues: { invoiced: number; costs: number } | undefined
}

export interface IBalanceLines {
  title?: string
  color: string
  shape?: BalanceChartShape
}

export interface IChartLines {
  lines: IBalanceLines[]
}

const styles = ({ spacing, palette, typography }: Theme) =>
  createStyles({
    root: {},
    tooltip: {
      display: 'flex',
    },
    exVat: {
      marginTop: spacing(3),
      textAlign: 'right',
      color: theme.palette.text.light,
      fontStyle: 'italic',
    },
    sidePadding: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    spaceBetween: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    cardTitle: {
      marginBottom: spacing(3),
    },
    activeHeader: {
      marginBottom: spacing(4),
    },
    balanceHeader: {
      marginBottom: spacing(4),
    },
    balanceHeaderInner: {
      display: 'flex',
      alignItems: 'center',
      '&:first-child': {
        marginRight: spacing(6),
      },
    },
    balanceSingle: {
      '&:not(:last-child)': {
        marginRight: spacing(6),
      },
    },
    balanceSingleInvoicedValue: {
      color: theme.palette.primary[300],
    },
    balanceSingleCostsValue: {
      color: '#ad350e',
    },
    balanceSingleValuationValue: {
      color: theme.palette.secondary[500],
    },
    balanceSingleTotalValue: {
      color: theme.palette.primary[500],
    },
    monthlyBalanceSingleInvoicedValue: {
      color: theme.palette.secondary[300],
    },
    monthlyBalanceSingleCostsValue: {
      color: '#e36c54',
    },
    roundedCard: {
      borderRadius: 10,
    },
    cardContent: {
      position: 'relative',
      minHeight: '100px',
    },
    overlay: {
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      animation: 'enter 0.3s ease-in forwards',
    },
    '@keyframes enter': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    overlayWhite: {
      backgroundColor: 'rgba(255,255,255,0.8)',
    },
    overlayIcon: {
      margin: spacing(2),
      color: palette.secondary.main,
    },
    label: {
      fontSize: typography.button.fontSize,
      color: palette.grey['700'],
      fontWeight: typography.fontWeightLight,
      padding: '0',
      margin: '0',
    },
  })

class EconomyBalance extends React.Component<TProps, IState> {
  static contextType = AppContext

  public state: IState = {
    hoverDate: undefined,
    hoverValues: undefined,
  }

  render() {
    const { cardClass, classes, className, data, economyTooltipChartData, transformNumber } = this.props

    const { hoverDate, hoverValues } = this.state

    const totalLines: IBalanceLines[] = [
      { title: t('Invoiced'), color: theme.palette.primary[300], shape: 'outlinedCircle' },
      { title: t('Workshop costs'), color: '#ad350e', shape: 'outlinedSquare' },
    ]

    const monthlyLines: IBalanceLines[] = [
      { title: t('Invoiced'), color: theme.palette.secondary[300], shape: 'circle' },
      { title: t('Workshop costs'), color: '#e36c54', shape: 'square' },
    ]

    const totalSeriesInvoiced = data && transformNumber(data[0].seriesInvoiced.total)
    const totalSeriesCosts = data && transformNumber(data[0].seriesCosts.total)
    const hoverInvoice = hoverValues && transformNumber(hoverValues.invoiced)
    const hoverCost = hoverValues && transformNumber(hoverValues.costs)

    const currency = !getCurrency() ? '' : getCurrency()

    return (
      <div className={classNames(classes.root, className)}>
        <div className={classNames(classes.spaceBetween, classes.sidePadding)}>
          <Typography variant="subheading" style={{ marginBottom: 2 * 8 }}>
            {' '}
            {t('Balance')}
          </Typography>
        </div>
        <Card className={classNames(classes.roundedCard, cardClass)}>
          <CardContent className={classes.cardContent}>
            {data && economyTooltipChartData && data.length > 0 ? (
              <React.Fragment>
                <div className={classNames(classes.spaceBetween, classes.balanceHeader)}>
                  <div className={classes.balanceHeaderInner}>
                    <SingleValue
                      leadingSVG={
                        <SVGMark
                          shape={totalLines[0].shape}
                          fill={totalLines[0].color}
                          hollow
                          strokeWidth={3}
                          positionInPixels={{ x: 0, y: 0 }}
                        />
                      }
                      title={t('Total invoiced') + `  (${currency})`}
                      value={totalSeriesInvoiced && this.numberToCurrency(totalSeriesInvoiced)}
                      styling={{ root: classes.balanceSingle, value: classes.balanceSingleInvoicedValue }}
                    />
                    <SingleValue
                      leadingSVG={
                        <SVGMark
                          shape={totalLines[1].shape}
                          fill={totalLines[1].color}
                          hollow
                          strokeWidth={3}
                          positionInPixels={{ x: 0, y: 0 }}
                        />
                      }
                      title={t('Total workshop costs') + `  (${currency})`}
                      value={totalSeriesCosts && this.numberToCurrency(totalSeriesCosts)}
                      styling={{ root: classes.balanceSingle, value: classes.balanceSingleCostsValue }}
                    />
                    {
                      <div className={classes.balanceHeaderInner}>
                        <SingleValue
                          leadingSVG={
                            <SVGMark
                              shape={monthlyLines[0].shape}
                              fill={monthlyLines[0].color}
                              hollow
                              strokeWidth={3}
                              positionInPixels={{ x: 0, y: 0 }}
                            />
                          }
                          title={this.getMonthlyTitle(t('Invoiced'), hoverDate, ` (${currency})`)}
                          value={this.numberToCurrency(hoverInvoice, '-')}
                          styling={{ root: classes.balanceSingle, value: classes.monthlyBalanceSingleInvoicedValue }}
                        />
                        <SingleValue
                          leadingSVG={
                            <SVGMark
                              shape={monthlyLines[1].shape}
                              fill={monthlyLines[1].color}
                              hollow
                              strokeWidth={3}
                              positionInPixels={{ x: 0, y: 0 }}
                            />
                          }
                          title={this.getMonthlyTitle(t('Workshop costs'), hoverDate, ` (${currency})`)}
                          value={this.numberToCurrency(hoverCost, '-')}
                          styling={{ root: classes.balanceSingle, value: classes.monthlyBalanceSingleCostsValue }}
                        />
                      </div>
                    }
                  </div>
                </div>
                <EconomyBalanceChart
                  data={[
                    { title: '', monthAsTitle: true, chartData: data[1], lineData: monthlyLines },
                    { title: t('Total'), chartData: data[0], lineData: totalLines },
                  ]}
                  economyTooltipChartData={economyTooltipChartData}
                  transformNumber={transformNumber}
                  onGraphHover={this.onGraphHover}
                />
                <Typography className={classes.exVat} variant="body2">
                  {currency + ' ' + t('ex. VAT')}
                </Typography>
              </React.Fragment>
            ) : (
              <div className={classNames(classes.overlay, classes.overlayWhite)}>
                <CircularProgress className={classNames(classes.overlayIcon)} />
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    )
  }

  private getMonthlyTitle(title: string, date?: Date, endText: string = ''): string {
    if (date) {
      let month = firstCharUpper(formatLocalizedDate(date, 'MMMM', this.context.locale))
      return `${title}: ${month} ${endText}`
    }
    return title + '...'
  }

  private numberToCurrency(
    price: ITransformNumberResponse | undefined,
    defaultString: string = '',
    currency: string = '',
  ): string {
    return price
      ? formatCurrency(price.value, { symbolDisplayType: 'NONE' }) + ` ${price.text} ${currency}`
      : defaultString
  }

  private onGraphHover = (hoverDate: Date, values: { title: IBalanceLines; value: IChartDateValue }[]) => {
    let invoiced = values[0].value.y
    let costs = values[1].value.y
    this.setState({ hoverDate, hoverValues: { invoiced, costs } })
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(EconomyBalance)
