import React from 'react'
import StyledSelect from 'components/StyledSelect'
import Typography from 'components/Typography'
import { ValueType } from 'react-select'
import { createStyles, FormControlLabel, Switch, Theme, withStyles, WithStyles } from '@material-ui/core'
import { t, translateItem } from 'translations/translationFunctions'
import { UserRole, UserRoleRecord } from '@fragus/sam-types'
import { TranslationItem, TranslationKey } from 'translations/translationTypes'
import { RoleProvider } from 'pages/admin/AdministrationPage/Tabs/Blacklist/BlacklistComponent'
import { getAuth } from 'utils/localStorage'
import { renderContractProviderRow } from 'utils/react-select'

interface IOwnProps {
  className?: string
  header?: TranslationKey
  initialProvider: UserRoleRecord
  providers: RoleProvider[]
  allowAllProviders: boolean
  onActiveProviderChanged: (id: number | undefined) => void
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    tableControlsLabel: { marginBottom: spacing(1) },
    flexBox: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    grayedOut: {
      backgroundColor: 'rgba(0,0,0,0.2)',
      fontStyle: 'italic',
      padding: '5px',
      borderRadius: '5px',
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  currentUserRole: UserRole | null
  // currentUserRolesMap: IUserRoleMap
  includeInactiveProviders: boolean
  contractProviderOptions: IContractProviderOption[]
  allProvidersOption: IContractProviderOption
  activeContractProviderOption: IContractProviderOption
}

interface IContractProviderOption {
  value: RoleProvider | null
  label?: string
  labelItem: TranslationItem
  inactive?: boolean
}

class ContractProviderSelect extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)
    const { providers } = props
    const authData = getAuth()
    const rawContractProviderOptions: IContractProviderOption[] = providers.map((p) => ({
      value: p,
      labelItem: { value: `${p.id} - ${p.administrativeName}` },
      inactive: !!p.inactive,
    }))
    const allProvidersOption = { value: null, labelItem: { key: 'All providers' } } as IContractProviderOption
    const contractProviderOptions = this.props.allowAllProviders
      ? [allProvidersOption].concat(rawContractProviderOptions)
      : rawContractProviderOptions
    const activeContractProvider =
      !this.props.allowAllProviders && authData && authData.providerInfo
        ? rawContractProviderOptions.find((r) => r.value && r.value.id === authData.providerInfo?.providerId)
        : allProvidersOption

    const currentUserRole = (authData && authData.role) || null
    // const currentUserRolesMap: IUserRoleMap =
    //   (authData &&
    //     authData.roles &&
    //     authData.roles.reduce(
    //       (acc, r) => ({
    //         ...acc,
    //         [r.contractProvider.id]: r.role,
    //       }),
    //       {},
    //     )) ||
    //   {}

    const { initialProvider } = this.props
    this.state = {
      currentUserRole,
      // currentUserRolesMap,
      allProvidersOption,
      contractProviderOptions,
      activeContractProviderOption: activeContractProvider
        ? activeContractProvider
        : {
            value: initialProvider.contractProvider,
            labelItem: {
              value: `${initialProvider.contractProvider.id} - ${initialProvider.contractProvider.administrativeName}`,
            },
            inactive: !!initialProvider.contractProvider.inactive,
          },
      includeInactiveProviders: false,
    }
  }

  render(): React.ReactNode {
    const { classes, header, className } = this.props
    const {
      includeInactiveProviders,
      contractProviderOptions,
      allProvidersOption,
      activeContractProviderOption,
    } = this.state

    const filteredProviderOptions = includeInactiveProviders
      ? contractProviderOptions
      : contractProviderOptions.filter((c) => !c.inactive)

    const contractProviderOptionsWithTranslations = filteredProviderOptions.map((option) => {
      if (option.label === allProvidersOption.label) {
        option.label = translateItem(option.labelItem)
      } else {
        option.label = option.labelItem && option.labelItem.value
      }

      return option
    })

    return (
      <div className={className}>
        <div className={classes.flexBox}>
          {header && (
            <Typography variant="body" className={classes.tableControlsLabel}>
              {t(header)}:
            </Typography>
          )}
          <span>
            <FormControlLabel
              control={<Switch checked={includeInactiveProviders} />}
              label={t('Include deactivated')}
              onChange={this.handleFilterChanged}
            />
          </span>
        </div>
        <StyledSelect
          options={contractProviderOptionsWithTranslations}
          // tslint:disable-next-line jsx-no-lambda
          onChange={(value: ValueType<IContractProviderOption>) =>
            this.handleProviderChange(value as IContractProviderOption)
          }
          value={activeContractProviderOption}
          formatOptionLabel={({ label, inactive }) => renderContractProviderRow(label, !!inactive, classes.grayedOut)}
        />
      </div>
    )
  }

  private handleFilterChanged = () => {
    const { includeInactiveProviders } = this.state
    this.setState({ includeInactiveProviders: !includeInactiveProviders })
  }

  private handleProviderChange = (provider: IContractProviderOption) => {
    this.setState({ activeContractProviderOption: provider })
    this.props.onActiveProviderChanged(provider.value ? provider.value.id : undefined)
  }
}

export default withStyles(styles)(ContractProviderSelect)
