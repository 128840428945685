import { formatDate } from '@omnicar/sam-format'
import { IReleaseNotesResponse } from '@fragus/sam-types'
import { getReleaseVersions } from 'api/api'
import ReleaseNotes from 'components/admin/ReleaseNotes/ReleaseNotes'
import { Page, PageContent, PageHeader, PageTitle } from 'components/Mui/Page'
import React from 'react'
import { RouteComponentProps } from 'react-router'
import { t } from 'translations/translationFunctions'
import './index.css'
import { Box, Button, CircularProgress, Grid } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import Paper from 'components/Mui/Paper'

interface IState {
  data: IReleaseNotesResponse[]
  disableLoadMore: boolean
}

type TProps = RouteComponentProps<never>

class ReleaseNotesPage extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      data: [],
      disableLoadMore: false,
    }
  }

  public componentDidMount() {
    this.loadData()
  }

  render() {
    const { data, disableLoadMore } = this.state

    return (
      <Page>
        <PageHeader>
          <PageTitle>{t(`What's new`)}</PageTitle>
        </PageHeader>
        <PageContent noPadding>
          {data.length > 0 ? (
            <Paper className="ReleaseVersion__paper">
              {data.map(({ version, releaseDate, releaseNotes }) => (
                <div className="ReleaseVersion__row" key={version}>
                  <header className="ReleaseVersion__header">
                    <span className="ReleaseVersion__badge">{version}</span>
                    <h3>{formatDate(new Date(releaseDate))}</h3>
                  </header>
                  <ReleaseNotes notes={releaseNotes} />
                </div>
              ))}
              <Grid container justify="center">
                <Box sx={{ m: 2 }}>
                  <Button variant="contained" color="secondary" onClick={this.loadData} disabled={disableLoadMore}>
                    {t('View more')}
                    <ExpandMoreIcon />
                  </Button>
                </Box>
              </Grid>
            </Paper>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <CircularProgress color="secondary" size={20} />
            </div>
          )}
        </PageContent>
      </Page>
    )
  }

  loadMore = () => this.loadData()

  private loadData = async () => {
    const response = await getReleaseVersions(this.state.data.length)

    this.setState(({ data }) => {
      const releaseNotes = (response.statusCode === 200 && response.data) || []
      return { data: [...data, ...releaseNotes], disableLoadMore: !releaseNotes.length }
    })
  }
}

export default ReleaseNotesPage
