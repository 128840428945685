import { ContractActionType } from '@fragus/sam-types'

export type Resource<T> = { id: number } & T

export interface Hash<T> {
  [k: number]: Resource<T>
}

export type TAttachment = {
  id: number
  name: string
  url: string
  type: string
}

export type TSettlementAttachments = Hash<TAttachment>

export interface ISettlementActionFilters {
  type: ContractActionType[]
  sharedComments: boolean
  unsharedComments: boolean
}

export const TYPE = 'type'
export const SHARED_COMMENTS = 'sharedComments'
export const UNSHARED_COMMENTS = 'unsharedComments'

export type TActionFilterName = typeof TYPE | typeof SHARED_COMMENTS | typeof UNSHARED_COMMENTS
