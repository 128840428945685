enum ActionTypeKeys {
  // ResetAction - will reset store to the initalState
  STORE_RESET = 'STORE_RESET',

  // Application Actions
  APPLICATION_UPDATE = 'APPLICATION_UPDATE',
  APPLICATION_RESET = 'APPLICATION_RESET',
  APPLICATION_TRANSACTION_UPDATE = 'APPLICATION_TRANSACTION_UPDATE',
  APPLICATION_TRANSACTION_RESET = 'APPLICATION_TRANSACTION_RESET',

  // Contract Offer Actions
  CONTRACTFLOW_UPDATE = 'CONTRACTFLOW_UPDATE',
  CONTRACTFLOW_UPDATE_ACTIVE_PANEL = 'CONTRACTFLOW_UPDATE_ACTIVE_PANEL',
  CONTRACTFLOW_UPDATE_ACTIVE_STEP = 'CONTRACTFLOW_UPDATE_STEP',
  CONTRACTFLOW_RESET = 'CONTRACTFLOW_RESET',
  CONTRACTFLOW_UPDATE_CONTRACT_TYPE = 'CONTRACTFLOW_UPDATE_CONTRACT_TYPE',
  CONTRACTFLOW_UPDATE_STATE = 'CONTRACTFLOW_UPDATE_STATE',

  // Contract Search Actions
  CONTRACTSEARCH_UPDATE_QUERY = 'CONTRACTSEARCH_UPDATE_QUERY',

  // Warranty Search Actions
  WARRANTYSEARCH_UPDATE_QUERY = 'WARRANTYSEARCH_UPDATE_QUERY',

  // Navigation actions
  NAVIGATION_GET_OFFERS_REQUEST_NOTIFICATION_DATA = 'NAVIGATION_GET_OFFERS_REQUEST_NOTIFICATION_DATA',

  SET_OUTAGE = 'SET_OUTAGE',

  // Contract Settlement
  SAVE_CONTRACT_STATISTICS = 'SAVE_CONTRACT_STATISTICS',
  RESET_CONTRACT_STATISTICS = 'RESET_CONTRACT_STATISTICS',
  SAVE_SETTLE_ACTIONS = 'SAVE_SETTLE_ACTIONS',
  UPDATE_SETTLE_ACTIONS = 'UPDATE_SETTLE_ACTIONS',
  SAVE_SETTLEMENT_ATTACHMENTS = 'SAVE_SETTLEMENT_ATTACHMENTS',
  REMOVE_SETTLEMENT_ATTACHMENT = 'REMOVE_SETTLEMENT_ATTACHMENT',
  SET_SETTLEMENT_ACTIONS_FILTER = 'SET_SETTLEMENT_ACTIONS_FILTER',
  RESET_SETTLEMENT_ACTION_FILTERS = 'RESET_SETTLEMENT_ACTION_FILTERS',

  // Contract Filter Actions
  CONTRACT_FILTER_UPDATE = 'CONTRACT_FILTER_UPDATE',
  CONTRACT_FILTER_RESET = 'CONTRACT_FILTER_RESET',

  // Contract DisplayConfig Actions
  SET_HIDDEN_VAT = 'SET_HIDDEN_VAT',
  SET_SHOW_MILEAGE_INFO = 'SET_SHOW_MILEAGE_INFO',
}

export default ActionTypeKeys
