import { IMileageDurations, IMileageDurationItem } from '@fragus/sam-types'
import { TMarks } from './Slider'
import { debugPrint } from '../../../../../utils/miscs'

// A Km-Months (Mileage-Duration) pair / data-point.
export interface IKmMonths {
  mileageKm: number
  durationMonths: number
}

export interface IMinMax {
  min: number
  max: number
}

const IS_DEBUG_PRINT: boolean = false // Note: 'false' is the default.
const PRE_STR: string = 'MileageDurationsOO: '

export class MileageDurationsOO {
  public static readonly MILEAGE_STEP: number = 5000 // NOTE: Mileage must be multiples of this value specified here!
  public static readonly DURATION_STEP: number = 1 // NOTE: Duration must be multiples of this value specified here!

  private isValidMap: Map<string, boolean>
  private minMaxDurationsMap: Map<number, IMinMax> // Km, DurationArray
  private minMaxMileagesMap: Map<number, number[]> // Month, MileageArray.
  private mileageDurations: IMileageDurations

  private absMileageMin: number // Min mileages (km) for the whole product.
  private absMileageMax: number // Max mileages (km) for the whole product.
  private absDurationMin: number = Number.MAX_SAFE_INTEGER // Min duration (months) for the whole product.
  private absDurationMax: number = 0 // Max duration (months) for the whole product.

  public static isMileageRangeCheck(mileageKm: number): boolean {
    if (mileageKm % MileageDurationsOO.MILEAGE_STEP !== 0) {
      throw Error(
        `Mileage not within range: Mileage km is not multiple of ${MileageDurationsOO.MILEAGE_STEP}, mileageKm: ` +
          mileageKm,
      )
    }

    return true
  }

  public static isDurationRangeCheck(durationMonths: number): boolean {
    if (durationMonths % MileageDurationsOO.DURATION_STEP !== 0) {
      throw Error(
        `Duration not within range: Duration durationMonths is not multiple of ${MileageDurationsOO.DURATION_STEP}, durationMonths: ` +
          durationMonths,
      )
    }

    return true
  }

  public static getMiddleMileage(options: IMileageDurationItem[]) {
    const numberOfMileages: number = options.length
    const middleOptions = options[Math.floor(numberOfMileages / 2)]
    const middleMileage = middleOptions.mileage

    return middleMileage
  }

  public static getMiddleDuration(options: IMileageDurationItem[]) {
    const numberOfMileages: number = options.length
    const middleOptions = options[Math.floor(numberOfMileages / 2)]

    const numberOfDurations = middleOptions.months.length // Of that mileage.
    const middleDuration = middleOptions.months[Math.floor(numberOfDurations / 2)]

    return middleDuration
  }

  public isValid(mileageKm: number, duraionMonths: number) {
    const key: string = this.makeIndexKey(mileageKm, duraionMonths)
    return this.isValidMap.has(key)
  }

  // ==============================================================================================
  public constructor(mileageDurations: IMileageDurations) {
    if (!mileageDurations || mileageDurations.options.length <= 0) {
      throw Error('Empty or no mileageDurations to initialize the constructor with')
    }

    this.isValidMap = new Map<string, boolean>()
    this.minMaxDurationsMap = new Map<number, IMinMax>()
    this.minMaxMileagesMap = new Map<number, number[]>()

    this.initMileageDurations(mileageDurations)

    this.mileageDurations = mileageDurations // Stored for later use.
    this.absMileageMin = mileageDurations.options[0].mileage
    this.absMileageMax = mileageDurations.options[mileageDurations.options.length - 1].mileage
    debugPrint(IS_DEBUG_PRINT, `${PRE_STR}mileage-MIN km  = ` + this.absMileageMin)
    debugPrint(IS_DEBUG_PRINT, `${PRE_STR}mileage-MAX km  = ` + this.absMileageMax)
    debugPrint(IS_DEBUG_PRINT, '\n')
  }
  // ==============================================================================================

  private makeIndexKey(mileageKm: number, durationMonths: number) {
    MileageDurationsOO.isMileageRangeCheck(mileageKm)
    MileageDurationsOO.isDurationRangeCheck(durationMonths)

    const key: string = mileageKm + '-' + durationMonths

    return key
  }

  private initMileageDurations(mileageDurations: IMileageDurations) {
    debugPrint(IS_DEBUG_PRINT, PRE_STR + 'initMileageDurations(..)')

    mileageDurations.options.forEach((item: IMileageDurationItem) => {
      const currentMileage: number = item.mileage

      item.months.forEach((months: number) => {
        debugPrint(IS_DEBUG_PRINT, PRE_STR + 'mileage = ' + currentMileage + ', ' + months + ' months')

        const key: string = this.makeIndexKey(currentMileage, months)
        this.isValidMap.set(key, true)

        let mileagesArray: number[] | undefined = this.minMaxMileagesMap.get(months)
        if (!mileagesArray) mileagesArray = new Array<number>()
        mileagesArray.push(currentMileage)
        this.minMaxMileagesMap.set(months, mileagesArray)

        if (months < this.absDurationMin) this.absDurationMin = months
        if (months > this.absDurationMax) this.absDurationMax = months
      })

      const durationMin: number = item.months[0]
      const durationMax: number = item.months[item.months.length - 1]

      const value = { min: durationMin, max: durationMax }
      this.minMaxDurationsMap.set(currentMileage, value)
      debugPrint(IS_DEBUG_PRINT, PRE_STR + `At ${currentMileage}: has min=${durationMin}, max=${durationMax}`)
      debugPrint(IS_DEBUG_PRINT, '--------------------------')
    })

    this.minMaxMileagesMap.forEach((mileages: number[], months: number) => {
      debugPrint(IS_DEBUG_PRINT, PRE_STR + 'For month: ' + months)
      debugPrint(IS_DEBUG_PRINT, PRE_STR + 'Mileages:')
      // console.debug(mileages)
      debugPrint(IS_DEBUG_PRINT, '-------------------------')
    })
  }

  /** Get min max mileages for the whole product. */
  public getAbsMinMaxMileages(): IMinMax {
    const minAndMax: IMinMax = { min: this.absMileageMin, max: this.absMileageMax }
    return minAndMax
  }

  /** Get min max durations for the whole product. */
  public getAbsMinMaxDurations(): IMinMax {
    const minAndMax: IMinMax = { min: this.absDurationMin, max: this.absDurationMax }
    return minAndMax
  }

  public getMinMaxMileages(ofDurationMonths: number): IMinMax {
    MileageDurationsOO.isDurationRangeCheck(ofDurationMonths)

    const mileagesArray: number[] | undefined = this.minMaxMileagesMap.get(ofDurationMonths)
    const sortedMileagesArray: number[] = mileagesArray!.sort((a: number, b: number) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })

    const minAndMax: IMinMax = { min: sortedMileagesArray[0], max: sortedMileagesArray[sortedMileagesArray.length - 1] }
    debugPrint(IS_DEBUG_PRINT, `${ofDurationMonths}: min = ${minAndMax.min}, max = ${minAndMax.max}`)

    return minAndMax
  }

  /** Returns the Min/Max duration of a particular mileage. */
  public getMinMaxDurations(mileageKm: number): IMinMax {
    MileageDurationsOO.isMileageRangeCheck(mileageKm)

    this.minMaxDurationsMap.forEach((item, key) => {
      debugPrint(IS_DEBUG_PRINT, PRE_STR + 'key=' + key + ' - item:')
      // console.debug(item)
      debugPrint(IS_DEBUG_PRINT, PRE_STR + '-----------')
    })

    const durationMinMax: IMinMax | undefined = this.minMaxDurationsMap.get(mileageKm)
    if (!durationMinMax) {
      throw Error('Min/Max duration for this mileage not found: ' + mileageKm)
    }

    return durationMinMax
  }

  public requestClosestMileage(wantedMileageKm: number): number {
    const MILEAGE_STEP: number = MileageDurationsOO.MILEAGE_STEP
    const MILEAGE_MAX: number = this.getAbsMinMaxMileages().max
    const MILEAGE_MIN: number = this.getAbsMinMaxMileages().min
    const mileageDurations = this.mileageDurations

    const hasMileage: number[] = []
    mileageDurations.options.forEach((item: IMileageDurationItem) => (hasMileage['' + item.mileage] = true))

    if (hasMileage['' + wantedMileageKm]) return wantedMileageKm
    let foundWantedMileage: number = wantedMileageKm
    let higherWantedKm: number
    let lowerWantedKm: number
    let isInsideUpperBounds: boolean
    let isInsideLowerBounds: boolean
    let i = 1

    do {
      higherWantedKm = wantedMileageKm + i * MILEAGE_STEP
      isInsideUpperBounds = higherWantedKm <= MILEAGE_MAX
      if (isInsideUpperBounds && hasMileage['' + wantedMileageKm]) {
        foundWantedMileage = higherWantedKm
        break
      }

      lowerWantedKm = wantedMileageKm - i * MILEAGE_STEP
      isInsideLowerBounds = lowerWantedKm >= MILEAGE_MIN
      if (isInsideLowerBounds && hasMileage['' + wantedMileageKm]) {
        foundWantedMileage = lowerWantedKm
        break
      }

      if (!isInsideLowerBounds && !isInsideUpperBounds) {
        console.warn(
          `LowerKm and higherKm are both outside of range/bounds, lowerKm: ${lowerWantedKm} (MIN=${MILEAGE_MIN}), higherKm: ${higherWantedKm} (MAX=${MILEAGE_MAX}`,
        )
        return MileageDurationsOO.getMiddleMileage(mileageDurations.options)
      }
    } while (i++)

    return foundWantedMileage
  }

  public requestClosestDuration(wantedDurationMonths: number, ofMileageKm: number): number {
    const kmMonths: IKmMonths = this.requestDurationOfMileage(wantedDurationMonths, ofMileageKm)

    return kmMonths.durationMonths
  }

  public requestMileageOfDuration(wantedMileageKm: number, ofDurationMonths: number): IKmMonths {
    const DURATION: number = ofDurationMonths
    const MILEAGE_STEP: number = MileageDurationsOO.MILEAGE_STEP
    const MILEAGE_MAX: number = this.getAbsMinMaxMileages().max
    const MILEAGE_MIN: number = this.getAbsMinMaxMileages().min

    if (this.isValid(wantedMileageKm, DURATION)) {
      return {
        mileageKm: wantedMileageKm,
        durationMonths: DURATION,
      }
    }

    let foundWantedMileage: number = wantedMileageKm
    let higherWantedKm: number
    let lowerWantedKm: number
    let isInsideUpperBounds: boolean
    let isInsideLowerBounds: boolean
    let i = 1

    do {
      higherWantedKm = wantedMileageKm + i * MILEAGE_STEP
      isInsideUpperBounds = higherWantedKm <= MILEAGE_MAX
      if (isInsideUpperBounds && this.isValid(higherWantedKm, DURATION)) {
        foundWantedMileage = higherWantedKm
        break
      }

      lowerWantedKm = wantedMileageKm - i * MILEAGE_STEP
      isInsideLowerBounds = lowerWantedKm >= MILEAGE_MIN
      if (isInsideLowerBounds && this.isValid(lowerWantedKm, DURATION)) {
        foundWantedMileage = lowerWantedKm
        break
      }

      if (!isInsideLowerBounds && !isInsideUpperBounds) {
        throw Error(
          `LowerKm and higherKm are both outside of range/bounds, lowerKm: ${lowerWantedKm} (MIN=${MILEAGE_MIN}), higherKm: ${higherWantedKm} (MAX=${MILEAGE_MAX}`,
        )
      }
    } while (i++)

    debugPrint(IS_DEBUG_PRINT, PRE_STR + '\n!!! - foundWantedMileage = ' + foundWantedMileage)
    const response: IKmMonths = {
      mileageKm: foundWantedMileage,
      durationMonths: DURATION,
    }
    return response
  }

  public requestDurationOfMileage(wantedDurationMonths: number, ofMileageKm: number): IKmMonths {
    const MILEAGE: number = ofMileageKm
    const DURATION_STEP: number = MileageDurationsOO.DURATION_STEP
    const DURATION_MAX: number = this.getMinMaxDurations(ofMileageKm).max
    const DURATION_MIN: number = this.getMinMaxDurations(ofMileageKm).min

    if (this.isValid(MILEAGE, wantedDurationMonths)) {
      return {
        mileageKm: MILEAGE,
        durationMonths: wantedDurationMonths,
      }
    }

    let foundWantedDuration: number = wantedDurationMonths
    let higherWantedMonths: number
    let lowerWantedMonths: number
    let isInsideUpperBounds: boolean
    let isInsideLowerBounds: boolean
    let i = 1

    do {
      higherWantedMonths = wantedDurationMonths + i * DURATION_STEP
      isInsideUpperBounds = higherWantedMonths <= DURATION_MAX
      if (isInsideUpperBounds && this.isValid(MILEAGE, higherWantedMonths)) {
        foundWantedDuration = higherWantedMonths
        break
      }

      lowerWantedMonths = wantedDurationMonths - i * DURATION_STEP
      isInsideLowerBounds = lowerWantedMonths >= DURATION_MIN
      if (isInsideLowerBounds && this.isValid(MILEAGE, lowerWantedMonths)) {
        foundWantedDuration = lowerWantedMonths
        break
      }

      if (!isInsideLowerBounds && !isInsideUpperBounds) {
        throw Error(
          `LowerMonths and higherMonths are both outside of range/bounds, lowerMonths: ${lowerWantedMonths} (MIN=${DURATION_MIN}), higherMonths: ${higherWantedMonths} (MAX=${DURATION_MAX}`,
        )
      }
    } while (i++)

    debugPrint(IS_DEBUG_PRINT, PRE_STR + '\n!!! - foundWantedDuration = ' + foundWantedDuration)
    const response: IKmMonths = {
      mileageKm: MILEAGE,
      durationMonths: foundWantedDuration,
    }
    return response
  }

  public getMileageMarks(forDurationMonths: number): TMarks {
    MileageDurationsOO.isDurationRangeCheck(forDurationMonths)

    if (this.mileageDurations.options.length === 1) {
      // Has only one mileage (!).
      return [{ value: 0, label: '' }] // Returns an empty TMarks (that will be disabled).
    }

    const arrayMileages: number[] = []
    this.mileageDurations.options.forEach((item: IMileageDurationItem) => {
      if (item.months.includes(forDurationMonths)) {
        arrayMileages.push(item.mileage)
      }
    })

    if (arrayMileages.length <= 0) {
      throw Error('Array of mileages is empty')
    }

    const mileageMarks: TMarks = arrayMileages.map((mileage: number) => {
      return { value: mileage, label: '' }
    })

    // Set end-mark labels.
    mileageMarks[0].label = 'Min'
    mileageMarks[mileageMarks.length - 1].label = 'Max'

    debugPrint(IS_DEBUG_PRINT, `${PRE_STR}getMileageMarks(..) for = ${forDurationMonths} months, mileageMarks:`)
    debugPrint(IS_DEBUG_PRINT, PRE_STR + mileageMarks)

    return mileageMarks
  }

  public getDurationMarks(forMileageKm: number): TMarks {
    MileageDurationsOO.isMileageRangeCheck(forMileageKm)

    let arrayDuration: number[] = []
    this.mileageDurations.options.forEach((item: IMileageDurationItem) => {
      if (item.mileage === forMileageKm) {
        arrayDuration = item.months
      }
    })

    if (arrayDuration.length === 1) {
      // Has only one duration (!).
      return [{ value: 0, label: '' }] // Returns an empty TMarks (that will be disabled).
    }

    const durationMarks: TMarks = arrayDuration.map((mileage: number) => {
      return { value: mileage, label: '' }
    })

    // Set end-mark labels.
    durationMarks[0].label = 'Min'
    durationMarks[durationMarks.length - 1].label = 'Max'

    debugPrint(IS_DEBUG_PRINT, `${PRE_STR}getDurationMarks(..) for = ${forMileageKm} km, durationMarks:`)
    debugPrint(IS_DEBUG_PRINT, PRE_STR + durationMarks)

    return durationMarks
  }
}
