import GA from 'react-ga'
import { getCookiesConsent } from './cookies'

export type EventCategory =
  | 'Contract advanced filter'
  | 'Contract columns'
  | 'Contract details'
  | 'Contract offer'
  | 'Contract draft'
  | 'Contract state filter'
  | 'Customer portal'
  | 'File export'
  | 'File import'
  | 'Global search'
  | 'Warranty advanced filter'
  | 'Warranty columns'
  | 'Warranty details'
  | 'Warranty offer'
  | 'Warranty state filter'

export type EventAction =
  | 'Add credit card error'
  | 'Add credit card success'
  | 'Adjust contract'
  | 'Buy now'
  | 'Clear filters'
  | 'Close contract'
  | 'Copy offer'
  | 'Close offer'
  | 'Columns changed'
  | 'Contracts'
  | 'Download invoice'
  | 'Filter changed'
  | 'Filter disabled'
  | 'Filter enabled'
  | 'Financial'
  | 'Navigate to activation'
  | 'Print'
  | 'Resend offer'
  | 'Searched'
  | 'Select contract'
  | 'Select tab'
  | 'Selected all results'
  | 'Selected search result'
  | 'Send adjustment offer'
  | 'Send offer'
  | 'Vehicle blacklist upload'
  | 'Vehicle lookup error'
  | 'Vehicle lookup success'
  | 'Workshop operations'
  | 'Settle contract'
  | 'Create pending termination'
  | 'Activate contract'

const initTracking = () => {
  const testWindow = window as any
  const cookiesConsentAllowed = getCookiesConsent()?.analytics

  if (!testWindow.ga && cookiesConsentAllowed) {
    GA.initialize(process.env.REACT_APP_GA_KEY!)
  }
}

export const trackPageView = (name: string) => {
  initTracking()
  GA.pageview(name)
}

export const trackEvent = (
  category: EventCategory,
  action: EventAction,
  label?: string,
  value?: number,
  nonInteraction: boolean = false,
) => {
  initTracking()
  GA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
  })
}

const trackDimension = (dimension: string, value: string) => {
  initTracking()
  const dim = {}
  dim[dimension] = value
  GA.set(dim)
}

export const trackSessionProviderId = (providerId: number) => {
  // "dimension1" is the provider id for all GA properties
  trackDimension('dimension1', `${providerId}`)
}
