import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ResponseErrors } from '@fragus/sam-types'
import { t } from 'translations/translationFunctions'
import { NetworkError } from '@omnicar/sam-tfetch'
import errors from './errors'

export type ErrorType = ResponseErrors | NetworkError

/**
 * message: just a string (should come translated)
 * errorType: Specific error type (ex: 'GENERIC_PROVIDER_NOT_FOUND)
 */
export interface INotificationParams {
  message?: string
  errorType?: ErrorType
  time?: number
}

enum ToastId {
  Default = 'default-toast-id',
  Information = 'information-toast-id',
  InformmationWithoutClosing = 'information-wo-closing-toast-id',
  Warning = 'warning-toast-id',
  Error = 'error-toast-id',
  Success = 'success-toast-id',
  Outage = 'outage-toast-id',
}

enum NotificationSeverity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
}

/**
 * Generate Notification text
 * Message priority first, errorType second
 * NB! Message should come translated, errorType is translated here
 */
export const getNotificationText = (params?: INotificationParams) => {
  let result = t(errors.GENERIC_ERROR)

  if (params) {
    if (params.message && errors.hasOwnProperty(params.message)) {
      result = t(errors[params.message])
    } else if (params.message) {
      result = params.message
    } else if (params.errorType && errors.hasOwnProperty(params.errorType)) {
      result = t(errors[params.errorType])
    }
  }
  return result
}

/**
 * Generate notification
 */
const showNotification = (severity: NotificationSeverity, toastId: ToastId, params?: INotificationParams) => {
  // if no params -> default to 'GENERIC_ERROR
  const message: string = getNotificationText(params)

  toast(message, {
    toastId: toastId,
    type: severity,
  })
}

const showWarning = (params?: INotificationParams) =>
  showNotification(NotificationSeverity.Warning, ToastId.Warning, params)
const showError = (params?: INotificationParams) => showNotification(NotificationSeverity.Error, ToastId.Error, params)
const showInfo = (params?: INotificationParams) =>
  showNotification(NotificationSeverity.Info, ToastId.Information, params)
const showSuccess = (params?: INotificationParams) =>
  showNotification(NotificationSeverity.Success, ToastId.Success, params)

export default {
  warning: showWarning,
  error: showError,
  info: showInfo,
  success: showSuccess,
}
