import React, { ChangeEvent } from 'react'
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles'
import { Panel, PanelHeader, PanelTitle, PanelContent } from '../../../../../Mui/Panel'
import { t } from '../../../../../../translations/translationFunctions'
import { CardContent, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { createPanelStyles, theme as customTheme } from '../../../../../../theme'
import { ContractFlowActivePanel } from '../../../../../../types/contractFlow'
import classNames from 'classnames'
import { Card } from '../../../../../Mui/Card'
import { TranslationKey } from '../../../../../../translations/translationTypes'
import { ContractValueType } from '@fragus/sam-types'

interface IOwnProps {
  active: ContractFlowActivePanel
  activeEnum: ContractFlowActivePanel
  hidden: boolean
  startValue: number | undefined
  valueType: ContractValueType
  freeContract: boolean
  disabledValueTypes: ContractValueType[]
  onValueChange: (startValue: number | undefined) => void
  onValueTypeChange: (valueType: string) => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    card: {
      height: '100%',
    },
    textFeild: {
      width: '70%',
      minWidth: '220px',
    },
    fullSize: {
      width: '30%',
    },
    dropDown: {
      width: '100%',
      heigth: '100%',
    },
    oneThirdWidth: {
      marginTop: '0.355em',
      heigth: '100%',
      width: '30%',
      minWidth: '100px',
    },
  })

class ContractFlowStartValues extends React.Component<TProps, never> {
  public render() {
    const { active, activeEnum, classes, startValue, valueType, hidden, freeContract, disabledValueTypes } = this.props
    const isActive = active >= activeEnum
    const valueTypes: { key: TranslationKey; value?: string }[] = [
      { key: 'Hours' },
      { key: 'Mileage' },
      { key: 'Annual services', value: 'Services' },
    ]

    const startValueDisabled = valueType === 'Services'

    return (
      <Panel disabled={!isActive} hidden={hidden}>
        <PanelHeader>
          <PanelTitle>{t('Start conditions')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Card
            className={
              isActive ? classNames(classes.cardActive, classes.card, freeContract && classes.freeContractActive) : ''
            }
            data-e2e={'ContractFlowStartConditions'}
          >
            <CardContent>
              <FormControl className={classNames(classes.oneThirdWidth)}>
                <InputLabel className={classNames(classes.inputLabel)}>{t('Value type')}</InputLabel>
                <Select
                  fullWidth={false}
                  className={classNames(classes.dropDown, classes.fullSize)}
                  value={valueType || ''}
                  onChange={(e: ChangeEvent<any>) => this.props.onValueTypeChange('' + e.target.value)}
                >
                  {valueTypes.map((vt) => {
                    return (
                      <MenuItem
                        key={`StartValueType-${vt.key}`}
                        disabled={disabledValueTypes.includes((vt.value || vt.key) as ContractValueType)}
                        value={vt.value || vt.key}
                      >
                        {t(vt.key)}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
              <TextField
                className={classes.textFeild}
                fullWidth={false}
                disabled={startValueDisabled}
                value={!startValueDisabled && startValue !== undefined && startValue >= 0 ? startValue : ''} // If we use value undefined, React will complain about controlled vs. uncontrolled input.
                margin="dense"
                label={(!startValueDisabled && t('Select start conditions for the product')) || ' '}
                onChange={this.handleValueChange}
                type="number"
                InputProps={{ inputProps: { min: 0 } }}
              />
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }
  private handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    // Only use 6 first digits in string
    const valueDigits: string = value
      .replace(/[^0-9]/g, '')
      .replace(/[e]/g, 'x')
      .substr(0, 6)

    if (valueDigits.length === 0) {
      this.props.onValueChange(undefined)
      return
    }
    const parsedValue = Number.parseFloat(valueDigits)
    // Only allow numbers between 0 and 999999
    if (!Number.isNaN(parsedValue) && parsedValue >= 0 && parsedValue < 1000000) {
      this.props.onValueChange(parsedValue)
    }
  }
}

export default withStyles(styles)(ContractFlowStartValues)
