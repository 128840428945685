import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { DragHandle as DragHandleIcon } from '@material-ui/icons'
import React, { ChangeEvent } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import Typography from 'components/Typography'
import { IColumnUserConfiguration } from '@fragus/sam-types'

interface IOwnProps {
  column: IColumnUserConfiguration
  index: number
  title: string
  onEnabledChange: (column: IColumnUserConfiguration, index: number) => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > span:last-child': {
        width: '100%',
        'white-space': 'nowrap',
      },
    },
    label: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    labelIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    labelText: {},
    icon: {
      fontSize: 18,
    },
  })

export class Item extends React.Component<TProps, never> {
  public render() {
    const { classes, column, index, title } = this.props

    return (
      <FormControlLabel
        className={classes.root}
        label={
          <div className={classes.label}>
            <Typography className={classes.labelText} variant="caption">
              {title}
            </Typography>
            <div className={classes.labelIcon}>
              <DragHandleIcon className={classes.icon} />
            </div>
          </div>
        }
        control={<Checkbox checked={column.enabled} onChange={this.handleEnabledChange} value={`${index}`} />}
      />
    )
  }

  private handleEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    const column = { ...this.props.column, enabled: !this.props.column.enabled }
    this.props.onEnabledChange(column, +e.target.value!)
  }
}

export default withStyles(styles)(Item)
