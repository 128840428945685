import { PaymentGateway, ResponseErrors } from '@fragus/sam-types'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { ArrowBackIos as ArrowBackIosIcon } from '@material-ui/icons'
import classNames from 'classnames'
import { LayoutBlock } from 'components/Mui/Layout'
import React from 'react'
import { compose } from 'recompose'
import { createDialogStyles } from 'theme'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import { IContractFlow } from 'types/contractFlow'
import errors from 'utils/notify/errors'

interface IOwnProps {
  contract: IContractFlow
  error: boolean
  errorMessage: ResponseErrors | ''
  open: boolean
  sending: boolean
  onClose: () => void
  onCreateNew: () => void
  onToOffer: () => void
  paymentGateway: PaymentGateway
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createDialogStyles(theme, {
    content: {
      minHeight: 111,
    },
    actions: {
      minHeight: 36,
    },
    text: {
      marginRight: theme.spacing(1),
    },
    buttonIcon: {
      marginRight: theme.spacing(1),
      fontSize: 16,
    },
    toOfferButton: {
      marginleft: theme.spacing(1),
      paddingleft: theme.spacing(1),
    },
    closeButton: {
      marginRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    createNewButton: {
      marginRight: theme.spacing(1),
    },
  })

type TSendingState = 'sending' | 'sent' | 'error'
type TContractType = 'offer' | 'contract' | 'adjustment'
type TKey = { [key in TContractType]: TranslationKey }

const translationKeys: {
  text: {
    [key in Exclude<TSendingState, 'error'>]: TKey
  }
  title: {
    [key in TSendingState]: TKey
  }
} = {
  text: {
    sending: {
      offer: 'Please hold on as we send the contract offer',
      contract: 'Please hold on as we send the contract',
      adjustment: 'Please hold on as we send the contract adjustment offer',
    },
    sent: {
      offer: 'Click the button to proceed to the contract offer',
      contract: 'Click the button to proceed to the contract',
      adjustment: 'Click the button to proceed to the contract adjustment offer',
    },
  },
  title: {
    error: {
      offer: 'An error occured while creating the contract offer',
      contract: 'An error occured while creating the contract',
      adjustment: 'An error occured while creating the contract adjustment offer',
    },
    sending: {
      offer: 'Sending the offer',
      contract: 'Sending the contract',
      adjustment: 'Sending adjustment offer',
    },
    sent: {
      offer: 'The offer has been sent',
      contract: 'The contract has been sent',
      adjustment: 'The adjustment offer has been sent',
    },
  },
}
const captionOnToOffer: TKey = {
  offer: 'Proceed to contract offer',
  contract: 'Proceed to contract',
  adjustment: 'Proceed to contract adjustment offer',
}

const getTitleAndText = (
  error: boolean,
  localizedErrorMessage: string,
  sending: boolean,
  contractType: TContractType,
): { title: TranslationKey; text: TranslationKey } => {
  const sendingState: TSendingState = error ? 'error' : sending ? 'sending' : 'sent'
  const text = error ? '' : translationKeys.text[sendingState][contractType]
  // we need to cast the title to TranslationKey because the type of title is inferred as string
  // translation for error keys with localizedErrorMessage MUST EXIST:
  // 'An error occured while creating the contract adjustment offer: %ERRMSG',
  // 'An error occured while creating the contract offer: %ERRMSG',
  // 'An error occured while creating the contract: %ERRMSG',
  const title = (translationKeys.title[sendingState][contractType] +
    (error && localizedErrorMessage ? `: %ERRMSG` : '')) as TranslationKey
  return { title, text }
}

const ContractFlowSendDialog: React.SFC<TProps> = ({
  open,
  classes,
  error,
  errorMessage,
  sending,
  contract,
  onClose,
  onToOffer,
  onCreateNew,
  paymentGateway,
}) => {
  const { flowType } = contract
  const localizedErrorMessage = (errorMessage && errors[errorMessage] && t(errors[errorMessage])) || errorMessage
  const contractType: TContractType =
    flowType === 'ADJUST' ? 'adjustment' : ['NONE', 'B2B'].includes(paymentGateway) ? 'contract' : 'offer'

  const { title, text } = getTitleAndText(error, localizedErrorMessage, sending, contractType)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      data-e2e={'ContractFlowSendDialog__dialog'}
    >
      <DialogTitle className={classes.title}>{t(title, { ERRMSG: localizedErrorMessage })}</DialogTitle>
      <DialogContent className={classes.content}>
        {sending ? (
          <React.Fragment>
            <LayoutBlock dense={true}>{text && t(text)}</LayoutBlock>
            <CircularProgress size={32} color="secondary" />
          </React.Fragment>
        ) : (
          <>
            <span className={classes.text}>{text && t(text)}</span>

            {!error && (
              <Button
                className={classNames(classes.button, classes.toOfferButton)}
                onClick={onToOffer}
                color="secondary"
                variant="contained"
                autoFocus={true}
                data-e2e={'ContractFlowSendDialog__dialog-proceed-button'}
              >
                {t(captionOnToOffer[contractType])}
              </Button>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.actions}>
        {!sending && (
          <React.Fragment>
            <Button
              className={classNames(classes.button, classes.closeButton)}
              onClick={onClose}
              variant="outlined"
              data-e2e={'ContractFlowSendDialog__dialog-back-button'}
            >
              <ArrowBackIosIcon className={classes.buttonIcon} />
              {t('Back')}
            </Button>

            {!error && (
              <Button
                className={classNames(classes.button, classes.createNewButton)}
                onClick={onCreateNew}
                color="primary"
                variant="contained"
                data-e2e={'ContractFlowSendDialog__dialog-createnew-button'}
              >
                {t('Create a new contract')}
              </Button>
            )}
          </React.Fragment>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default compose<TProps, IOwnProps>(withStyles(styles))(ContractFlowSendDialog)
