import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import {
  ContractState,
  IContractBalanceStatistics,
  IContractDetailsRecord,
  ISettlementInfo,
  UserRole,
} from '@fragus/sam-types'
import ContractBalanceStatistics from 'components/admin/Contract/Details/BalanceStatistics'
import ContractSettleHeader from 'components/admin/Contract/Details/SettleHeader'
import ContractSettleInfoMessage from 'components/admin/Contract/Details/SettleInfoMessage'
import SettlementRegulationActions from 'components/admin/Contract/Details/SettlementActions'
import EnhancedSettlementRegulationForm from 'components/admin/Contract/Details/SettlementForm'
import SettleTabLockingDialog from 'components/admin/Contract/Details/SettleTabLockingDialog'
import ContractUnitsInfo from 'components/admin/Contract/Details/UnitsInfo'
import { LayoutRow } from 'components/Mui/Layout'
import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { IRootState } from 'reducers/initialState'
import { selectBalanceStatistics, selectSettlementInfo } from 'selectors/contractSettlement'
import { AppContext } from 'store/appContext'

interface IProps {
  contractPrettyId: string
  contractState: ContractState
  isSettleMessageShown: boolean
  userRole?: UserRole
  contractSpecifics: IContractDetailsRecord
  serviceCount?: number
  customerName: string
  workshopOperationsUpdateCounter: number
  isReadOnly: boolean
  initContractSpecifics: () => Promise<void>
}

interface IState {
  settlementOperationAmount: number
  lockedSettleTab: boolean
}

interface IReduxStateProps {
  balanceStatistics: IContractBalanceStatistics
  settlementInfo?: ISettlementInfo
}

type TProps = IProps & IReduxStateProps & WithStyles<typeof styles>

const styles = (theme: Theme) =>
  createStyles({
    settleContainerRight: {
      flexBasis: '65%',
    },
    descWrapper: {
      width: theme.spacing(50),
    },
    lockedSettleTab: {
      position: 'relative',
      pointerEvents: 'none',
      opacity: 0.5,
    },
  })

class SettleTab extends React.Component<TProps & IReduxStateProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.state = {
      lockedSettleTab: false,
      settlementOperationAmount: 0,
    }
  }

  public componentDidMount() {
    this.props.initContractSpecifics()
  }

  render() {
    const {
      contractPrettyId,
      userRole,
      workshopOperationsUpdateCounter,
      classes,
      contractSpecifics,
      settlementInfo,
      balanceStatistics,
      contractState,
      isSettleMessageShown,
      customerName,
      isReadOnly,
      serviceCount,
      initContractSpecifics,
    } = this.props
    const { lockedSettleTab, settlementOperationAmount } = this.state
    const isSettled = contractState === ContractState.Settled

    return (
      <div>
        {lockedSettleTab && <SettleTabLockingDialog />}
        <ContractSettleInfoMessage
          settlementInfo={settlementInfo}
          isSettleMessageShown={isSettleMessageShown}
          isSettled={isSettled}
        />
        <LayoutRow classes={{ root: lockedSettleTab ? classes.lockedSettleTab : '' }} columns={2}>
          <div>
            <div>
              {!isSettled && userRole !== 'observer' && (
                <AppContext.Consumer>
                  {({ locale }) => (
                    <>
                      <ContractSettleHeader descWrapperClassName={classes.descWrapper} />
                      <ContractUnitsInfo
                        id={contractPrettyId}
                        initContractSpecifics={initContractSpecifics}
                        settledUnits={contractSpecifics.settledUnits}
                        isDisabled={false}
                        valueType={contractSpecifics.valueType}
                        serviceCount={serviceCount}
                      />
                      <EnhancedSettlementRegulationForm
                        id={contractPrettyId}
                        isSettled={isSettled}
                        customerName={customerName}
                        balance={settlementOperationAmount}
                        settledUnits={contractSpecifics.settledUnits}
                        calculationMethod={contractSpecifics.calculationMethod}
                        lockSettleTab={this.handleSettleTabLock}
                        isRefund={
                          !!(
                            balanceStatistics.underOverUnitsBalance &&
                            balanceStatistics.underOverUnitsBalance.cost.price < 0
                          )
                        }
                        contractState={contractState}
                      />
                    </>
                  )}
                </AppContext.Consumer>
              )}
              {isSettled && <SettlementRegulationActions id={contractPrettyId} isSettled={isSettled} />}
            </div>
          </div>
          <div className={classes.settleContainerRight}>
            <ContractBalanceStatistics
              id={contractPrettyId}
              statistics={balanceStatistics}
              contractDetails={contractSpecifics}
              setSettlementOperationAmount={this.handleSettlementAmountChange}
              underCharge={this.getUnderOverCharge()}
              overCharge={this.getUnderOverCharge(false)}
              valueType={contractSpecifics.valueType}
              updateCounter={workshopOperationsUpdateCounter}
              isSettleTab={true}
              isSettled={isSettled}
              isReadOnly={isReadOnly}
              contractState={contractState}
            />
            {!isSettled && (
              <SettlementRegulationActions
                id={contractPrettyId}
                isSettled={isSettled}
                isReadOnly={userRole === 'observer'}
              />
            )}
          </div>
        </LayoutRow>
      </div>
    )
  }

  private handleSettleTabLock = (lockedSettleTab: boolean) => this.setState({ lockedSettleTab })

  private handleSettlementAmountChange = (amount: number) => this.setState({ settlementOperationAmount: amount })

  private getUnderOverCharge = (isUnderCharge: boolean = true): number | undefined => {
    const {
      valueType,
      underHoursCharge,
      underdrivenCharge,
      underServicesCharge,
      overHoursCharge,
      overServicesCharge,
      overdrivenCharge,
    } = this.props.contractSpecifics

    switch (valueType) {
      case 'Hours':
        return isUnderCharge ? underHoursCharge : overHoursCharge
      case 'Mileage':
        return isUnderCharge ? underdrivenCharge : overdrivenCharge
      case 'Services':
        return isUnderCharge ? underServicesCharge : overServicesCharge
      default:
        return undefined
    }
  }
}

const mapState = (state: IRootState) => ({
  balanceStatistics: selectBalanceStatistics(state),
  settlementInfo: selectSettlementInfo(state),
})

export default compose<TProps, IProps>(withStyles(styles), connect(mapState))(SettleTab)
