import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { IJsonStatus } from '@omnicar/sam-tfetch'
import {
  ApiError,
  IBalanceChart,
  IChartCollection,
  IChartDateValue,
  IChartStringValue,
  IProviderStats,
} from '@fragus/sam-types'
import { IDashboardFilterParams, IDashboardQueryParams } from '@fragus/sam-types/types/admin/chart/dashboardCharts'
import * as api from 'api/api'
import classNames from 'classnames'
import React from 'react'
import { compose } from 'recompose'
import { AppContext } from 'store/appContext'
import { ITransformNumberResponse } from 'types/overviewTypes'
import { isUserSuperAdmin } from 'utils/localStorage'
import notify from 'utils/notify/notify'
import ProviderInfoBar from '../ProviderInfoBar'
import SuperAdminProviderInfo from '../SuperAdminProviderInfo'
import EconomyBalance from './Balance'
import ContractsDetails from './Details'
import ContractsTotals from './Totals'

interface IOwnProps {}
type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  totals: IProviderStats | undefined
  balance: IBalanceChart | undefined
  monthlyBalance: IBalanceChart | undefined
  topSellers: IChartStringValue[]
  activeContracts: IChartCollection[]
  filters: {
    dateRange: { min: string; max: string }
  }
}

const styles = ({ spacing, palette, typography }: Theme) =>
  createStyles({
    root: {},
    totals: {
      marginTop: spacing(-3),
      marginBottom: spacing(6.5),
    },
    componentMarginTop: {
      marginTop: spacing(5),
    },
    extraMarginTop: {
      marginTop: spacing(5),
    },
    extraPaddingTop: {
      paddingTop: spacing(12),
    },
    monthBox: {
      padding: '10px',
      margin: '0',
      height: 'fit-content',
    },
    clickableText: {
      cursor: 'pointer',
      fontWeight: 'normal',
    },
    monthPickerOverlay: {
      position: 'absolute',
      marginTop: '7.3em',
      paddingLeft: spacing(3),
      zIndex: 50,
    },
    label: {
      fontSize: typography.button.fontSize,
      color: palette.grey['700'],
      fontWeight: typography.fontWeightLight,
      padding: '0',
      margin: '0',
    },
    iconButton: {
      marginLeft: '-8px',
      padding: '10px',
    },
    sectionCol: {
      flexDirection: 'column',
    },
    sidePadding: {
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
  })

class OverviewContracts extends React.Component<TProps, IState> {
  static contextType = AppContext

  public state: IState = {
    totals: undefined,
    balance: undefined,
    monthlyBalance: undefined,
    topSellers: [],
    activeContracts: [],
    filters: {
      dateRange: { min: '', max: '' },
    },
  }

  public async componentDidMount() {
    const queryParams: IDashboardQueryParams<IDashboardFilterParams> = { filtering: this.state.filters }
    await this.setDashboardStats(queryParams)
  }

  public render() {
    const { classes } = this.props
    const { totals, balance, monthlyBalance } = this.state
    const economyTooltipChartData = balance &&
      monthlyBalance && [
        monthlyBalance.seriesInvoiced.months,
        monthlyBalance.seriesCosts.months,
        balance.seriesInvoiced.months,
        balance.seriesCosts.months,
      ]

    return (
      <div className={classes.root}>
        <div className={classes.totals}>
          <ContractsTotals data={totals} />
        </div>
        <div>
          <ProviderInfoBar />
        </div>

        {isUserSuperAdmin() && <SuperAdminProviderInfo className={classes.componentMarginTop} />}

        <ContractsDetails className={classes.componentMarginTop} onDateRangeChange={this.handleDateRangeChange} />
        {balance && monthlyBalance && (
          <EconomyBalance
            className={classNames(classes.componentMarginTop, classes.extraMarginTop)}
            data={[balance, monthlyBalance]}
            economyTooltipChartData={economyTooltipChartData}
            transformNumber={this.transformNumber}
          />
        )}
      </div>
    )
  }

  private async setDashboardStats(data: IDashboardQueryParams<IDashboardFilterParams>) {
    const totals = await api.getProviderOverviewStats()
    const isOutage = this.isOutageError(totals)
    if ((totals.errorData && !isOutage) || totals.networkError) {
      notify.error()
      return
    }

    const dashboardStats = await api.getDashboardStats(data)
    if ((dashboardStats.errorData && !isOutage) || totals.networkError) {
      notify.error()
      return
    }

    if (dashboardStats.data) {
      const { balanceChart, monthlyBalanceChart } = dashboardStats.data

      balanceChart.seriesInvoiced.months = this.transform(balanceChart.seriesInvoiced.months)
      balanceChart.seriesCosts.months = this.transform(balanceChart.seriesCosts.months)

      monthlyBalanceChart.seriesInvoiced.months = this.transform(monthlyBalanceChart.seriesInvoiced.months)
      monthlyBalanceChart.seriesCosts.months = this.transform(monthlyBalanceChart.seriesCosts.months)

      this.setState({
        totals: totals.data,
        balance: dashboardStats.data.balanceChart,
        monthlyBalance: dashboardStats.data.monthlyBalanceChart,
        activeContracts: dashboardStats.data.activeContractsChartCollection,
        topSellers: dashboardStats.data.sellerChart.seriesTopSellers,
      })
    }
  }

  private handleDateRangeChange = (range: { min: string; max: string }) => {
    let filters = this.state.filters
    if (filters.dateRange !== range) {
      filters.dateRange = range

      const queryParams: IDashboardQueryParams<IDashboardFilterParams> = { filtering: filters }
      this.setState({ filters })
      this.setDashboardStats(queryParams)
    }
  }

  private transformNumber = (value: number) => {
    const transformedNumber: ITransformNumberResponse = {
      value,
      text: '',
    }
    if (value >= 1000000) {
      transformedNumber.value = value / 1000000
      transformedNumber.text = 'M' // M for million.
    } else if (value >= 1000) {
      transformedNumber.value = value / 1000
      transformedNumber.text = 'K'
    }
    return transformedNumber
  }

  /**
   * Transforms x from string > Date
   */
  private transform = (list: IChartDateValue[]): IChartDateValue[] =>
    list.map((value) => ({
      ...value,
      x: new Date(value.x),
    }))

  private isOutageError = (error: IJsonStatus<IProviderStats, ApiError>) => {
    return !!error.errorData && error.errorData.params && 'headerText' in error.errorData.params
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(OverviewContracts)
