import { TIsoCountry } from '@fragus/sam-types'

const url = 'https://europe-west1-sam-non-production.cloudfunctions.net/zip-city'

export const getCityByZip = async (locale: TIsoCountry, zipCode: number | string): Promise<string | undefined> => {
  const res = await fetch(`${url}?locale=${locale}&zipCode=${zipCode}`)

  if (res.ok) {
    const parsedResponse: { city: string } = await res.json()
    return parsedResponse.city
  } else {
    console.warn('Failed to fetch city by zip code')
    return undefined
  }
}
