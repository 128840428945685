import { PaymentFailureReason } from '@fragus/sam-types'
import { t } from 'translations/translationFunctions'
import { IContractFlow } from '../types/contractFlow'
import { getProviderCountry } from './localStorage'

/**
 * A Warranty Contract is a contract having a warranty as option
 * (either as an included option or an additional option).
 *
 * @note This contract may also, or may not, have Service options
 *       attached to it as well.
 * @note Keep in mind, there also exists Warranty Contracts that are
 *       not Subscriptions. (The External Warranty Contract in V4.)
 * @returns True if the contract is a Warranty Contract, otherwise false is returned.
 */
export const isWarrantySubscriptionContract = (contract: IContractFlow): boolean => {
  return (
    contract?.template !== null &&
    !isExternalContract(contract) &&
    isContractOfVehicleType(contract) &&
    hasContractAnyWarranty(contract)
  )
}

/**
 * A (plain) Service Contract is a contract WITHOUT ANY warranty
 * as an option (neither included nor additional).
 *
 * @returns True if the contract is a Service Contract (without any warranty), otherwise false is returned.
 */
export const isPlainServiceSubscriptionContract = (contract: IContractFlow): boolean => {
  return (
    contract?.template !== null &&
    !isExternalContract(contract) &&
    isContractOfVehicleType(contract) &&
    !hasContractAnyWarranty(contract)
  )
}

export const isExternalContract = (contract: IContractFlow): boolean => {
  return contract.contractType === 'EXTERNAL'
}

export const isContractOfVehicleType = (contract: IContractFlow): boolean => {
  if (contract.template) {
    return contract.contractObjectType === 'Vehicle' && contract.template?.isProductTemplate === false
  } else {
    return contract.contractObjectType === 'Vehicle'
  }
}

export const hasContractAnyWarranty = (contract: IContractFlow): boolean => {
  let returnValue: boolean = false

  // Check if any INCLUDED option is a warranty.
  contract.template?.properties.forEach((option: any) => {
    if (option?.warranty) {
      returnValue = true
    }
  })

  // Check if any ADDITIONAL option is a warranty.
  contract.options.forEach((option: any) => {
    if (option?.warranty) {
      returnValue = true
    }
  })

  return returnValue
}
/**
 * Check if the contract is a warranty and the provider is from Norway
 * Note: don't change this function if you don't know what you are doing!!!
 * @param isWarranty
 * @param country
 * @param startDate
 */
export const checkWhetherHideVAT = (isWarranty: boolean, country?: string, startDate?: Date): boolean =>
  (country || getProviderCountry())?.toLowerCase() === 'no' &&
  isWarranty &&
  (startDate ? startDate > new Date('2024-01-01') : true)

export const getProviderIdByContractIdentifier = (prettyIdentifier: string) => Number(prettyIdentifier.split('-')[0])

export const getPaymentFailureReasonDescription = (reason: PaymentFailureReason): string => {
  const failedPaymentReasonDescriptions: {
    [key: string]: string
  } = {
    expiredCard: t('The card has expired.'),
    authenticationRequired: t('The card was declined as the transaction requires authentication.'),
    insufficientFunds: t('The card has insufficient funds to complete the purchase.'),
    withdrawalCountLimitExceeded: t('The customer has exceeded the balance or credit limit available on their card.'),
    invalidAmount: t(
      "The payment amount is invalid, or exceeds the amount that's allowed. If the amount appears to be correct, the customer needs to check with their card issuer.",
    ),
    fraudLostStolen: t(
      "Payment matches a value on the Stripe user's block list. Stripe suspects that it's fraudulent. The card is reported lost or stolen. The specific reason for the decline shouldn't be reported to the customer. Instead, it needs to be presented as a generic_decline.",
    ),
    attemptPaymentAgain: t(
      "Attempt the payment again. If you still can't process it, the customer needs to contact their card issuer. Recommended you not retry charges more than four times.",
    ),
    incorrectData: t('The customer needs try again using the correct card/payment data.'),
    contactCardIssuer: t('The customer needs to contact their card issuer for more information.'),
    unknownReason: t("Unknown Stripe reasons or reasons which shouldn't happen."),
  }

  return failedPaymentReasonDescriptions[PaymentFailureReason[reason]]
}
