import { IContractFlow, ContractFlowActiveStep, ContractFlowActivePanel, IContractFlowState } from 'types/contractFlow'
import ActionTypeKeys from '../actions/ActionTypeKeys'
import { ContractType } from '@fragus/sam-types'

export interface IContractFlowUpdate {
  readonly type: ActionTypeKeys.CONTRACTFLOW_UPDATE
  readonly values: Partial<IContractFlow>
}
export const contractFlowUpdate = (values: Partial<IContractFlow>) => {
  return {
    type: ActionTypeKeys.CONTRACTFLOW_UPDATE,
    values,
  }
}

export interface IContractFlowReset {
  readonly type: ActionTypeKeys.CONTRACTFLOW_RESET
}
export const contractFlowReset = (): IContractFlowReset => {
  return {
    type: ActionTypeKeys.CONTRACTFLOW_RESET,
  }
}

export interface IContractFlowUpdateActivePanel {
  readonly type: ActionTypeKeys.CONTRACTFLOW_UPDATE_ACTIVE_PANEL
  readonly active: ContractFlowActivePanel
}
export const contractFlowUpdateActivePanel = (active: ContractFlowActivePanel): IContractFlowUpdateActivePanel => {
  return {
    type: ActionTypeKeys.CONTRACTFLOW_UPDATE_ACTIVE_PANEL,
    active,
  }
}

export interface IContractFlowUpdateActiveStep {
  readonly type: ActionTypeKeys.CONTRACTFLOW_UPDATE_ACTIVE_STEP
  readonly step: ContractFlowActiveStep
}
export const contractFlowUpdateActiveStep = (step: ContractFlowActiveStep): IContractFlowUpdateActiveStep => {
  return {
    type: ActionTypeKeys.CONTRACTFLOW_UPDATE_ACTIVE_STEP,
    step,
  }
}

export interface IContractFlowUpdateContractType {
  readonly type: ActionTypeKeys.CONTRACTFLOW_UPDATE_CONTRACT_TYPE
  readonly contractType: ContractType
}
export const contractFlowUpdateContractType = (contractType: ContractType): IContractFlowUpdateContractType => {
  return {
    type: ActionTypeKeys.CONTRACTFLOW_UPDATE_CONTRACT_TYPE,
    contractType,
  }
}

export interface IContractFlowUpdateState {
  readonly type: ActionTypeKeys.CONTRACTFLOW_UPDATE_STATE
  readonly state: IContractFlowState
}
export const contractFlowUpdateState = (state: IContractFlowState): IContractFlowUpdateState => {
  return {
    type: ActionTypeKeys.CONTRACTFLOW_UPDATE_STATE,
    state,
  }
}
