import {
  Button,
  Card,
  CardContent,
  createStyles,
  Input,
  InputAdornment,
  Theme,
  Tooltip,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Close as CloseIcon,
  Info as InfoIcon,
  ListAlt as ListAltIcon,
  Search as SearchIcon,
} from '@material-ui/icons'
import {
  DbType,
  IOutageResponse,
  IReportDefinition,
  IReportDefinitionsResponse,
  IReportSearchRequest,
  IReportSearchResponse,
  IsoLocale,
  TContractObject,
} from '@fragus/sam-types'
import {
  getReportDefinition,
  getReportMap,
  getReportResult,
  isOldContractsDownloadEnabled,
  isSigmaPayoutMatchEnabled,
} from 'api/api'
import classNames from 'classnames'
import DialogContractsDownload from 'components/admin/Contract/List/Dialog/ContractsDownload'
import DialogInvoicesDownload from 'components/admin/Contract/List/Dialog/InvoicesDownload'
import SigmaSqlDownload from 'components/admin/Contract/List/Dialog/SigmaSqlDownload'
import Typography from 'components/Typography'
import React, { ChangeEvent, Component } from 'react'
import { connect } from 'react-redux'
import { IRootState } from 'reducers/initialState'
// import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'
import { AppContext } from 'store/appContext'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import { isUseWhiteBGTheme } from 'utils/localStorage'
import { getMappedTranslation } from 'utils/translation'
import { IDisplayConfigs, reportColor } from './ReportConstants'
import InnerTable from './ReportResultTable'
import ReportSearch from './ReportSearch'

interface IProps extends WithStyles<typeof styles> {
  isActive: boolean
  outage: IOutageResponse | null
}

interface IState {
  contractsDialog: boolean
  invoicesDialog: boolean
  sigmaDownloadDialog: boolean
  reportList: IReportDefinition[]
  reportMap: IReportDefinitionsResponse
  reportDefinition: IReportDefinition | null
  reportRequest: IReportSearchRequest
  reportData: IReportSearchResponse | null
  moreReportsFetched: boolean
  searchId: number
  fetchingData: boolean
  displayConfigs: IDisplayConfigs
  isOverflown: boolean
  useSigmaDialog: boolean
  useOldContractsDownload: boolean
  currentLocale: IsoLocale | undefined
  localeFormats: { [key in IsoLocale]?: string }
  displayReportTool: boolean
  activeRepportMenus: string[]
  searchQuery: string
  displayButtonMenu: boolean
}

type DefinitionButton = IReportDefinition & { label: string; matchesSearch: boolean }

const styles = (theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(1),
    },
    tooltip: {
      display: 'none',
      fontSize: 16,
      color: theme.palette.primary.light,
      marginLeft: theme.spacing(1),
    },
    button: {
      marginBottom: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: '150px',
      minWidth: 'fit-content',
    },
    buttonIcon: {
      fontSize: 16,
      marginRight: theme.spacing(2),
      color: reportColor.buttonFgColor,
    },
    icon: {
      color: theme.palette.primary.dark,
      marginRight: theme.spacing(1),
    },
    buttonText: {
      color: reportColor.buttonFgColor,
      fontSize: '11px',
    },
    buttonPrimary: {
      backgroundColor: isUseWhiteBGTheme() ? reportColor.buttonColor : theme.palette.primary[500],
      '&:hover': {
        backgroundColor: isUseWhiteBGTheme() ? reportColor.labelColor : theme.palette.primary[700],
      },
      marginBottom: '0.5em',
    },
    reportDataHeader: {
      height: '2em',
    },
    rowStyle: {
      marginTop: '10px',
    },
    rowstyleEven: {
      backgroundColor: 'lightgray',
    },
    rowstyleOdd: {
      backgroundColor: 'white',
    },
    flexColumnContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexRowContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    flexWrap: {
      flexWrap: 'wrap',
    },
    flexRowContainerFill: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
    },
    inputContainer: {
      width: '30%',
      minWidth: '150px',
      maxWidth: '300px',
      paddingBottom: '10px',
      marginTop: '1em',
      marginBottom: '1em',
    },
    clearIcon: {
      cursor: 'pointer',
    },
    fitContent: {
      width: 'fit-content',
    },
    table: {
      width: '100%',
    },
    activeMenu: {
      flexGrow: 0.2,
    },
    buttonMenuContainer: {
      position: 'relative',
      flexWrap: 'wrap',
    },
    buttonMenu: {
      display: 'flex',
      flexDirection: 'row',
      '@media (max-width:840px)': {
        flexDirection: 'column',
      },
    },
    buttonRow: {
      left: 'inherit',
      right: 'fixed',
      paddingBottom: '10px',
      overflowY: 'auto',
      position: 'absolute',
      zIndex: 10,
      backgroundColor: 'inherit',
      borderColor: 'black',
      borderRadius: '5px',
      maxHeight: '300px',
      paddingLeft: '20px',
    },
    dropDownButton: {
      whiteSpace: 'nowrap',
      verticalAlign: 'top',
      borderCollapse: 'collapse',
    },
  })

const emptyReportRequest = () => {
  return { name: '', search: [], groupBy: [], contractObjectType: 'Vehicle' as const }
}
const defaultDisplayConfigs = (): IDisplayConfigs => {
  return {
    showRownoCol: false,
    showLinks: true,
    locale: undefined,
    localeFormats: {},
  }
}

const buttonMenuClass = 'report-button-menu'

class ReportsComponent extends Component<IProps, IState> {
  public state: IState = {
    contractsDialog: false,
    invoicesDialog: false,
    sigmaDownloadDialog: false,
    reportList: [],
    reportMap: {},
    reportDefinition: null,
    reportRequest: emptyReportRequest(),
    reportData: null,
    fetchingData: false,
    moreReportsFetched: false,
    searchId: 0,
    displayConfigs: defaultDisplayConfigs(),
    isOverflown: false,
    useSigmaDialog: false,
    useOldContractsDownload: false,
    currentLocale: undefined,
    localeFormats: {},
    displayReportTool: false,
    activeRepportMenus: [''],
    searchQuery: '',
    displayButtonMenu: true,
  }

  private buttonsParentContainerRef = React.createRef<HTMLDivElement>()
  private buttonsChildContainerRef = React.createRef<HTMLDivElement>()

  private buttonsGroupRef: React.RefObject<HTMLDivElement>[] = []

  public componentDidMount() {
    this.getReports()
    this.updateOverflowState()
    window.addEventListener('resize', this.updateOverflowState)
    window.addEventListener('mouseup', this.handleButtonGroupOutsideClick)
    this.isSigmaDialogEnabled()
    this.isOldContractsDownloadEnabled()
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.isActive && !prevProps.isActive) {
      this.updateOverflowState()
    }
  }

  public UNSAFE_componentWillUnmount() {
    window.removeEventListener('resize', this.updateOverflowState)
  }

  // ==========================================================================
  // Main render
  // ==========================================================================

  public render() {
    const { classes } = this.props
    const { contractsDialog, invoicesDialog, sigmaDownloadDialog, displayReportTool, displayButtonMenu } = this.state

    return (
      <>
        <div>
          <div>
            <Typography className={classes.title} variant="subheading">
              {t('Reports')}
            </Typography>
            <Tooltip title={''} className={classes.tooltip}>
              <InfoIcon />
            </Tooltip>
          </div>
          <div className={classNames(classes.flexWrap, displayButtonMenu && classes.buttonMenuContainer)}>
            {this.renderTopButtonMenu()}

            {displayReportTool && (
              <Card>
                <CardContent>
                  <div>{this.renderReport()}</div>
                </CardContent>
              </Card>
            )}
          </div>
        </div>

        <DialogContractsDownload
          open={contractsDialog}
          // tslint:disable-next-line jsx-no-lambda
          onClose={() => this.toggleContractsDialog(false)}
        />
        <DialogInvoicesDownload
          open={invoicesDialog}
          // tslint:disable-next-line jsx-no-lambda
          onClose={() => this.toggleInvoicesDialog(false)}
        />
        <SigmaSqlDownload
          open={sigmaDownloadDialog}
          // tslint:disable-next-line jsx-no-lambda
          onClose={() => this.toggleSigmaSqlDialog(false)}
        />
      </>
    )
  }

  // ==========================================================================
  // Sub Rendering
  // ==========================================================================

  private renderTopButtonMenu = () => {
    const { classes } = this.props
    const { searchQuery } = this.state
    const nrOfReports = Object.keys(this.state.reportMap).length
    // Excluding first button row
    const nrOfSearchableReports = nrOfReports - 1

    return (
      <>
        <Card className={buttonMenuClass}>
          <CardContent>
            {!!nrOfReports ? (
              <>
                {nrOfSearchableReports > 0 && (
                  <div className={classes.inputContainer}>
                    <Typography variant="body">{t('Filter by report name')}:</Typography>
                    <Input
                      fullWidth={true}
                      type="text"
                      placeholder={t('E.g. Payments')}
                      value={searchQuery}
                      onChange={this.handleSearchQueryChange}
                      startAdornment={
                        <InputAdornment position="end" onClick={this.clearSearchQuery}>
                          <>
                            {searchQuery ? (
                              <CloseIcon className={classNames(classes.clearIcon, classes.icon)} />
                            ) : (
                              <SearchIcon className={classes.icon} />
                            )}
                          </>
                        </InputAdornment>
                      }
                    />
                  </div>
                )}
                <div>
                  <div>{this.renderFirstButtonRow()}</div>
                  <div>{this.renderButtonGroups()}</div>
                </div>
              </>
            ) : (
              <>
                {this.state.moreReportsFetched && (
                  <Typography variant="body">{t('Could not find any available reports')}</Typography>
                )}
              </>
            )}
          </CardContent>
        </Card>
      </>
    )
  }

  private renderFirstButtonRow = () => {
    const { classes, outage } = this.props
    const { useSigmaDialog, useOldContractsDownload } = this.state
    const firstRowReports = this.state.reportMap['']

    // Reports is among the disbaled features, wont get correct download
    const disbaleDownload = !!outage && this.areReportsDisabled(outage)

    return (
      <div>
        <div ref={this.buttonsParentContainerRef}>
          <div ref={this.buttonsChildContainerRef} className={classNames(classes.flexRowContainer, classes.flexWrap)}>
            {firstRowReports &&
              firstRowReports.map((report) => (
                <Button
                  key={report.name}
                  variant="contained"
                  className={classNames(classes.button, classes.buttonPrimary)}
                  name={report.name}
                  onClick={this.onSelectReportClick}
                  value={JSON.stringify(report)}
                  data-e2e={`AdministrationPage__Reports__report-${report.name.replace(' ', '_')}`}
                >
                  <Typography variant="button" className={classes.buttonText}>
                    {getMappedTranslation(report.descriptionMap || {}, report.defaultDescription)}
                  </Typography>
                </Button>
              ))}
            <AppContext.Consumer>
              {({ providerInfo }) =>
                providerInfo &&
                !providerInfo.parentProviderId && (
                  <React.Fragment>
                    {useSigmaDialog && (
                      <Button
                        className={classNames(classes.button, classes.buttonPrimary)}
                        onClick={() => this.toggleSigmaSqlDialog()}
                        data-e2e="AdministrationPage__Reports__sigma_sql_dialog"
                      >
                        <ListAltIcon className={classes.buttonIcon} />
                        <Typography variant="button" className={classes.buttonText}>
                          {'Sigma SQL'}
                        </Typography>
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      disabled={disbaleDownload}
                      className={classNames(classes.button, classes.buttonPrimary)}
                      onClick={() => this.toggleInvoicesDialog()}
                      data-e2e="AdministrationPage__Reports__download-economy-report"
                    >
                      <ListAltIcon className={classes.buttonIcon} />
                      <Typography variant="button" className={classes.buttonText}>
                        {t('Download All Invoices')}
                      </Typography>
                    </Button>
                    {useOldContractsDownload && (
                      <Button
                        variant="contained"
                        disabled={disbaleDownload}
                        className={classNames(classes.button, classes.buttonPrimary)}
                        onClick={() => this.toggleContractsDialog()}
                        data-e2e="AdministrationPage__Reports__download-contracts-report"
                      >
                        <ListAltIcon className={classes.buttonIcon} />
                        <Typography variant="button" className={classes.buttonText}>
                          {t('Download all contracts')}
                        </Typography>
                      </Button>
                    )}
                  </React.Fragment>
                )
              }
            </AppContext.Consumer>
            {!this.state.moreReportsFetched && (
              <Button
                variant="contained"
                className={classNames(classes.button, classes.buttonPrimary)}
                onClick={this.getReports}
                data-e2e="AdministrationPage__Reports__get-report-names"
              >
                <ListAltIcon className={classes.buttonIcon} />
                <Typography variant="button" className={classes.buttonText}>
                  {t('Get Report Names')}
                </Typography>
              </Button>
            )}
          </div>
        </div>
      </div>
    )
  }

  private areReportsDisabled = (outage: IOutageResponse): boolean => {
    return (
      (!!outage.disabledPaths && outage.disabledPaths.some((a) => a.includes('reports') || a === '*')) ||
      (!!outage.severityLevel && outage.severityLevel > 2)
    )
  }

  private handleSearchQueryChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const query = e.target.value
    this.setState({ searchQuery: query })
    query ? this.renderButtonGroups(query) : this.clearSearchQuery(undefined)
  }

  private clearSearchQuery = (e: any) => {
    if (this.state.searchQuery) {
      this.setState({ searchQuery: '', activeRepportMenus: [] }, () => this.renderButtonGroups(''))
    }
  }

  private renderButtonGroups = (search?: string) => {
    const { classes } = this.props

    const arEntries = Object.entries(this.state.reportMap)
    const reportGroups: { name: string; definitions: IReportDefinition[] }[] = []
    for (const entry of arEntries) {
      const name = entry[0]
      const definitions = entry[1] || []
      if (name) reportGroups.push({ name, definitions })
    }

    const nrOfGroups = reportGroups.length
    return (
      <div className={classNames(nrOfGroups > 8 && classes.flexColumnContainer, classes.table, classes.buttonMenu)}>
        {reportGroups.map((rg, ix) => this.renderButtonGroup(rg, ix, search))}
      </div>
    )
  }

  private reportMatchesSearch = (definition: DefinitionButton, search?: string) => {
    return (
      !!search &&
      (definition.label
        ? definition.label.toLowerCase().includes(search.toLowerCase())
        : definition.name.toLowerCase().includes(search.toLowerCase()))
    )
  }

  private mapReportDefinitions = (buttons: IReportDefinition[], search?: string) => {
    const definitions: DefinitionButton[] = []
    let hasMatchingDef = false

    for (let b of buttons) {
      const definition = {
        ...b,
        label: getMappedTranslation(b.descriptionMap || {}, b.defaultDescription),
        matchesSearch: false,
      }

      const matches = this.reportMatchesSearch(definition, search)
      definition.matchesSearch = matches

      if (!hasMatchingDef && matches) hasMatchingDef = true

      definitions.push(definition)
    }

    return { definitions, hasMatchingDef }
  }

  private renderButtonGroup = (
    params: { name: string; definitions: IReportDefinition[] },
    rowNumber: number,
    searchQuery?: string,
  ) => {
    const { classes } = this.props
    const { activeRepportMenus } = this.state
    const search = searchQuery || this.state.searchQuery
    const { definitions, hasMatchingDef } = this.mapReportDefinitions(params.definitions, search)
    const isActive = activeRepportMenus.includes(params.name)

    if (hasMatchingDef && !isActive) {
      const newActive = activeRepportMenus
      newActive.push(params.name)
      this.setState({ activeRepportMenus: newActive })
    }

    let ref: React.RefObject<HTMLDivElement> | undefined
    if (isActive || hasMatchingDef) {
      ref = React.createRef<HTMLDivElement>()
      this.buttonsGroupRef.push(ref)
    }

    return (
      <div
        ref={ref}
        className={classNames(
          classes.rowStyle,
          rowNumber % 2 === 1 ? classes.rowstyleEven : classes.rowstyleOdd,
          (hasMatchingDef || isActive) && classes.activeMenu,
        )}
        key={`buttonGroup-${params.name}`}
      >
        <Button
          className={classes.dropDownButton}
          onClick={() => {
            let newActive = activeRepportMenus
            if (isActive && search) newActive.splice(newActive.indexOf(params.name), 1)
            else if (!search && isActive) newActive = []
            else if (!search && !isActive) newActive = [params.name]
            else if (search && !isActive) newActive.push(params.name)

            this.setState({ activeRepportMenus: newActive })
          }}
        >
          {t(params.name as TranslationKey)} {isActive ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Button>
        {(isActive || hasMatchingDef) && (
          <div className={classNames(classes.buttonRow, classes.flexColumnContainer)}>
            {definitions &&
              definitions.map((report) => (
                <span key={`report-btn-${report.name}`}>
                  {(!search || report.matchesSearch) && (
                    <Button
                      variant="contained"
                      className={classNames(classes.button, classes.buttonPrimary)}
                      name={report.name}
                      onClick={this.onSelectReportClick}
                      value={JSON.stringify(report)}
                      data-e2e={`AdministrationPage__Reports__report-${report.name.replace(' ', '_')}`}
                    >
                      <Typography variant="button" className={classes.buttonText}>
                        {report.label}
                      </Typography>
                    </Button>
                  )}
                </span>
              ))}
          </div>
        )}
      </div>
    )
  }

  private isSigmaDialogEnabled = async () => {
    const response = await isSigmaPayoutMatchEnabled()
    if (response && response.data) {
      this.setState({ useSigmaDialog: response.data })
    }
  }

  private isOldContractsDownloadEnabled = async () => {
    const response = await isOldContractsDownloadEnabled()
    if (response && response.data) {
      this.setState({ useOldContractsDownload: response.data })
    }
  }

  private renderReport = () => {
    if (!this.state.reportDefinition) {
      return <div />
    }
    return (
      <>
        {this.state.displayReportTool && (
          <div>
            <div>{this.renderReportTool()}</div>
            <div>{this.renderReportData()}</div>
          </div>
        )}
      </>
    )
  }

  private renderReportTool = () => {
    const { reportDefinition, displayReportTool } = this.state

    if (!reportDefinition || !displayReportTool) {
      return <div />
    }

    return (
      <>
        <ReportSearch
          reportDefinition={reportDefinition}
          reportRequest={this.state.reportRequest}
          reportData={this.state.reportData}
          moreReportsFetched={this.state.moreReportsFetched}
          searchId={this.state.searchId}
          fetchingData={this.state.fetchingData}
          fetchReport={this.fetchReport}
          setReportSearchRequest={this.setReportSearchRequest}
          setProductTypeRequest={this.setProductTypeRequest}
          setConfig={this.handleSetConfig}
          displayConfigs={this.state.displayConfigs}
          onCloseWindow={this.closeReportTool}
        />
      </>
    )
  }

  private closeReportTool = () => {
    this.setState({ displayReportTool: false })
  }

  private handleSetConfig = (newDisplayConfigs: Partial<IDisplayConfigs>) => {
    this.setState({ displayConfigs: { ...this.state.displayConfigs, ...newDisplayConfigs } })
  }

  private renderReportData = () => {
    const { classes } = this.props
    const rd = this.state.reportData
    const nRows = rd ? rd.data.length : 0
    const infoText = t('%N_ROWS rows', { N_ROWS: nRows })
    return (
      <div>
        <div className={classes.reportDataHeader}>
          <h4>{infoText}</h4>
        </div>
        <InnerTable
          reportData={this.state.reportData}
          searchId={this.state.searchId}
          fetchingData={this.state.fetchingData}
          displayConfigs={this.state.displayConfigs}
        />
      </div>
    )
  }

  private handleButtonGroupOutsideClick = (event: any) => {
    const group = this.buttonsGroupRef
    if (group && !!group.length && !group.find((ref) => ref.current && ref.current.contains(event.target))) {
      // Close button menu
      this.setState({ displayButtonMenu: false, activeRepportMenus: [], searchQuery: '' })
      this.buttonsGroupRef = []
    }
  }

  // ==========================================================================
  // Action Handlers
  // ==========================================================================

  private updateOverflowState = () => {
    const parent = this.buttonsParentContainerRef.current
    const child = this.buttonsChildContainerRef.current
    if (!parent || !child) return
    const isOverflown = true
    this.setState({ isOverflown })
  }

  private setReportSearchRequest = async (reportRequest: IReportSearchRequest) => {
    reportRequest.contractObjectType = this.state.reportRequest.contractObjectType
    this.setState({ reportRequest })
  }

  private setProductTypeRequest = async (contractObjectType: TContractObject) => {
    const { reportDefinition, reportRequest } = this.state

    if (contractObjectType && reportRequest.contractObjectType !== contractObjectType && reportDefinition) {
      const result = await getReportDefinition(reportDefinition.name, contractObjectType)
      if (result.data) {
        const newColumns = result.data.columns
        // Keep searches that match the new fields
        const validSearches = reportRequest.search.filter((s) => newColumns.find((c) => c.name === s.col))
        const newRequest = { name: result.data.name, search: validSearches, groupBy: [], contractObjectType }
        this.setState({ reportDefinition: result.data, reportRequest: newRequest })
      }
    }
  }

  private getReports = async () => {
    const response = await getReportMap()
    if (response.data) {
      const reportMap: IReportDefinitionsResponse = response.data
      const reportList = reportMap[''] || []
      this.setState({ reportList, reportMap, moreReportsFetched: true })
    }
  }

  private onSelectReportClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const isDevelopment = process.env.NODE_ENV === 'development'

    // Close button menu
    this.setState({ displayButtonMenu: false, activeRepportMenus: [], searchQuery: '' })

    let reportDefinition: IReportDefinition | null = null
    let reportData: IReportSearchResponse | null = null
    let reportName: string = ''
    let reportRequest: IReportSearchRequest = emptyReportRequest()

    // If we're in development mode it might practical to re-fetch the definition while
    // testing new/changed reports
    // If we're NOT in development try use the already fetched definition first before re-fetch

    if (!isDevelopment) {
      const rDef: IReportDefinition = JSON.parse(event.currentTarget.value)
      if (rDef) {
        reportDefinition = rDef
        reportName = rDef.name
      }
    } else {
      const name = event.currentTarget.name
      const response = await getReportDefinition(name, reportRequest.contractObjectType)

      if (response.data) {
        reportDefinition = response.data
        reportName = name
      }
    }
    reportRequest.name = reportName

    this.setState({ displayReportTool: true, reportDefinition, reportData, reportRequest, activeRepportMenus: [] })
  }

  private toggleContractsDialog = (open?: boolean) => {
    // if open is set: open, else flip state
    const contractsDialog = typeof open !== 'undefined' ? open : !this.state.contractsDialog
    this.setState({ contractsDialog })
  }

  private toggleInvoicesDialog = (open?: boolean) => {
    // if open is set: open, else flip state
    const invoicesDialog = typeof open !== 'undefined' ? open : !this.state.invoicesDialog
    this.setState({ invoicesDialog })
  }

  private toggleSigmaSqlDialog = (open?: boolean) => {
    // if open is set: open, else flip state
    const sigmaDownloadDialog = typeof open !== 'undefined' ? open : !this.state.sigmaDownloadDialog
    this.setState({ sigmaDownloadDialog })
  }

  private fetchReport = async () => {
    if (this.state.fetchingData) {
      return
    }
    this.setState({ reportData: null, fetchingData: true })

    const req = this.state.reportRequest
    const response = await getReportResult(req)
    if (response.data) {
      const searchId = this.state.searchId + 1
      const reportData: IReportSearchResponse = this.addRowNumbers(response.data)
      this.setState({ reportData, searchId, fetchingData: false })
    } else {
      this.setState({ fetchingData: false })
    }
  }

  private addRowNumbers(rd: IReportSearchResponse): IReportSearchResponse {
    if (!rd || !rd.data.length || !this.state.displayConfigs.showRownoCol) return rd
    if (rd.cols['#']) return rd
    rd.cols = { '#': { header: '#', type: DbType.int }, ...rd.cols }
    rd.data.forEach((r, ix) => (r['#'] = ix + 1))
    return rd
  }
}

const mapStateToProps = (state: IRootState) => ({
  outage: state.outage,
})

export default withStyles(styles)(connect(mapStateToProps)(ReportsComponent))
