import { CardContent, Checkbox, FormControlLabel, InputLabel, Tooltip, Zoom } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { Info as InfoIcon } from '@material-ui/icons'
import { formatCurrency } from '@omnicar/sam-format'
import { IContractOptionResponse } from '@fragus/sam-types'
import { termsPdfUrl } from 'api/api'
import classNames from 'classnames'
import { Card } from 'components/Mui/Card'
import LabelIncludingVat from 'components/Mui/Label/IncludingVat'
import { PdfLinkButton } from 'components/Mui/LinkButton'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import Typography from 'components/Typography'
import React, { ChangeEvent } from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import { t, tCurrency } from 'translations/translationFunctions'
import { ContractFlowActivePanel } from 'types/contractFlow'
import { getAuth } from 'utils/localStorage'

interface IOwnProps {
  active: ContractFlowActivePanel
  activeEnum: ContractFlowActivePanel
  options: IContractOptionResponse[]
  value: IContractOptionResponse[]
  freeContract: boolean
  contractEndMileage: number
  contractEndAge: number
  onChange: (options: IContractOptionResponse[]) => void
  locale: string
}

interface IState {
  partOfOptionGroup: any
}

type TProps = WithStyles<typeof styles> & IOwnProps

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    optionsItemWrapper: {
      width: '100%',

      '& > span:last-child': {
        width: '100%',
        'white-space': 'nowrap',
      },
    },
    optionsGroupBg: {
      backgroundColor: '#DDDDDD',
    },
    optionsItem: {
      display: 'flex',
      'align-items': 'center',
    },
    optionsItemAbbreviation: {
      display: 'flex',
      'justify-content': 'space-between',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      minwidth: '10px',
    },
    optionsItemDescription: {
      display: 'flex',
      'justify-content': 'space-between',
      'flex-grow': 1,
      width: '80%',
      minwidth: '50px',
    },
    optionsItemTerms: {
      display: 'flex',
      //width: '20%',
    },
    optionsItemPrice: {
      display: 'flex',
      'justify-content': 'space-between',
      marginLeft: theme.spacing(2),
      whiteSpace: 'nowrap',
      //width: '20%',
    },
    infoIcon: {
      fontSize: theme.spacing(2),
    },
    tooltip: {
      color: 'white',
      lineHeight: 1.5,
    },
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    margLeft: {
      marginLeft: theme.spacing(0.5),
    },
  })

class ContractFlowOptions extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.state = { partOfOptionGroup: [] }
  }

  public UNSAFE_componentWillReceiveProps() {
    this.indexOptionGroups()
  }

  public shouldComponentUpdate(nextProps: TProps, nextState: IState) {
    if (!nextProps.contractEndMileage && !nextProps.contractEndAge) {
      return true
    }
    const optionsKeep = nextProps.value.filter(
      (o) =>
        (!o.maxEndMileage || !nextProps.contractEndMileage || o.maxEndMileage >= nextProps.contractEndMileage) &&
        (!o.maxEndAge || !nextProps.contractEndAge || o.maxEndAge >= nextProps.contractEndAge),
    )
    if (optionsKeep.length !== nextProps.value.length) {
      this.props.onChange(optionsKeep)
      return false
    }
    return true
  }

  public render() {
    const {
      active,
      activeEnum,
      classes,
      options,
      freeContract,
      contractEndMileage,
      contractEndAge,
      locale,
    } = this.props

    const isPanelActive = active >= activeEnum && options.length > 0
    const isOptionsActive = this.isActive()

    const auth = getAuth()
    const sortedOptions = [...options]
    sortedOptions.sort((a, b) => b.price.price - a.price.price) // Sorting from highest price first to lowest.
    sortedOptions.sort((a, b) => a.weight - b.weight) // Sorting based on weight, lowest to highest.

    return (
      <Panel disabled={!isPanelActive}>
        <PanelHeader>
          <PanelTitle>{t('Options')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Card
            className={isPanelActive ? classNames(classes.cardActive, freeContract && classes.freeContractActive) : ''}
            data-e2e="ContractFlowOptions"
          >
            <CardContent>
              <InputLabel className={classes.inputLabel}>{t('Choose one or more additional options')}</InputLabel>
              {sortedOptions.map((option) => {
                const status = this.checkOptionStatus(option.id)
                const isChecked = status && status.checked ? true : false
                const optionGroup = option.optionGroup
                const disableRow =
                  (option.maxEndMileage !== undefined && contractEndMileage > option.maxEndMileage) ||
                  (option.maxEndAge !== undefined && contractEndAge > option.maxEndAge) ||
                  (auth !== undefined && auth.role !== 'admin' && option.price.priceInclVat === 0)

                return (
                  <div
                    key={`FormControlLabel-container-${option.id}`}
                    className={!(optionGroup || optionGroup === 0) ? '' : classes.optionsGroupBg}
                  >
                    <FormControlLabel
                      key={`ContractFlowOptions-item-${option.id}`}
                      disabled={!isPanelActive || disableRow}
                      className={classes.optionsItemWrapper}
                      label={this.renderLabel(option)}
                      control={
                        <Checkbox
                          disabled={!isOptionsActive || option.price.priceInclVat <= 0}
                          checked={isChecked}
                          onChange={this.handleChange}
                          value={`${option.id}`}
                          data-e2e={`ContractFlowOptions__checkbox`}
                        />
                      }
                    />
                  </div>
                )
              })}
              {options.length > 0 && <LabelIncludingVat locale={locale} />}
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private isActive = () => {
    const { active, activeEnum, options, freeContract } = this.props

    if (freeContract) {
      return active >= activeEnum
    } else {
      return options.length
    }
  }

  private renderLabel = (option: IContractOptionResponse) =>
    option.conditionText ? (
      <Tooltip
        title={
          <Typography variant="body2" className={this.props.classes.tooltip}>
            {option.conditionText}
          </Typography>
        }
        placement="top-start"
        TransitionComponent={Zoom}
      >
        {this.renderOption(option)}
      </Tooltip>
    ) : (
      this.renderOption(option)
    )

  // private renderOption = (option: IContractOptionResponse) => (
  //   <div className={this.props.classes.optionsItem}>
  //     {option.conditionText && <InfoIcon className={this.props.classes.infoIcon} />}
  //     <div className={this.props.classes.optionsItemAbbreviation}>{option.abbreviation}</div>
  //     <div className={this.props.classes.optionsItemDescription}>{option.description}</div>
  //     <div className={this.props.classes.optionsItemDescription}>
  //       {option.termsOfService && option.termsOfService.ref && (
  //         <PdfLinkButton
  //           caption={t('Terms')}
  //           href={termsPdfUrl(option.termsOfService.ref)}
  //           title={option.termsOfService.name}
  //         />
  //       )}
  //     </div>
  //     <div className={this.props.classes.optionsItemPrice}>
  //       {`${formatCurrency(option.price.priceInclVat)} ${tCurrency(option.price.currency)}`}
  //     </div>
  //   </div>
  // )

  private renderOption = (option: IContractOptionResponse) => (
    <div className={this.props.classes.optionsItem}>
      {option.conditionText ? (
        <InfoIcon className={this.props.classes.infoIcon} />
      ) : (
        <div className={this.props.classes.infoIcon}> &nbsp;&nbsp;&nbsp;&nbsp; </div>
      )}
      <div className={this.props.classes.optionsItemAbbreviation}>
        {option.abbreviation ? option.abbreviation : ('   ' as any)}
      </div>
      <div className={this.props.classes.optionsItemDescription}>{option.description}</div>
      <div className={this.props.classes.optionsItemTerms}>
        {option.termsOfService && option.termsOfService.ref && (
          <PdfLinkButton
            caption={t('Terms')}
            href={termsPdfUrl(option.termsOfService.ref)}
            title={option.termsOfService.name}
          />
        )}
      </div>
      <div className={this.props.classes.optionsItemPrice}>
        {`${formatCurrency(option.price.priceInclVat)} ${tCurrency(option.price.currency)}`}
      </div>
    </div>
  )

  private checkOptionStatus = (id: number) => {
    const { value } = this.props
    let checkedValue: boolean = false
    let checkedIndex: number = 0

    value.forEach((val, index) => {
      if (val.id === +id) {
        checkedValue = true
        checkedIndex = index
      }
    })

    return checkedValue
      ? {
          checked: checkedValue,
          index: checkedIndex,
        }
      : undefined
  }

  private indexOptionGroups = () => {
    const partOfOptionGroup = this.state.partOfOptionGroup
    const options = [...this.props.options]

    for (const option of options) {
      partOfOptionGroup[option.id] = option.optionGroup
    }

    this.setState({ partOfOptionGroup })
  }

  private partOfOptionGroup = (optionId: number) => {
    return this.state.partOfOptionGroup[optionId]
  }

  private handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const optionId = +e.target.value
    const option = this.props.options.find((option) => option.id === optionId)
    const status = this.checkOptionStatus(optionId)
    const options = [...this.props.value]

    const optionGroup = option && option.optionGroup
    const isChecked = !(status && status.checked) ? false : true

    // Uncheck possible duplicate option part of an optionGroup.
    if (!isChecked && optionGroup !== null && optionGroup !== undefined && optionGroup >= 0) {
      for (let i = 0; i < options.length; i++) {
        if (optionGroup === this.partOfOptionGroup(options[i].id)) {
          options.splice(i, 1)
          break
        }
      }
    }

    if (status && status.checked && option) {
      options.splice(status.index, 1)
    } else if (((status && !status.checked) || !status) && option) {
      options.push(option)
    }

    this.props.onChange(options)
  }
}

export default withStyles(styles)(ContractFlowOptions)
