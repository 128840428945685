import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
} from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import {
  AttachFile as AttachFileIcon,
  Clear as ClearIcon,
  CloudUpload as CloudUploadIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import { IImportStatusReport, TContractObject } from '@fragus/sam-types'
import { uploadContractWorkshopOperationFile } from 'api/api'
import classNames from 'classnames'
import SpinnerButton from 'components/Mui/SpinnerButton'
import Typography from 'components/Typography'
import React, { ChangeEvent } from 'react'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import { trackEvent } from 'utils/analytics'
import DialogWorkshopOperationsUploadInstructions from './Instructions'

interface IOwnProps {
  className?: string
  open: boolean
  onClose: () => void
  contractObjectType: TContractObject
  validImportHeaders?: string[]
}

interface IState {
  isLoading: boolean
  uploadFile: File | undefined
  isShowImportSummary: boolean
  expanded: boolean
  importSummary: {
    totalNumber: number
    numberOfImported: number
    alreadyImported: number
    contractNotFound: number
    vehicleNotFound: number
    unableToFindVehicleOrContract: number
    allRows: IImportStatusReport[] | null
  } | null
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing }: Theme) =>
  createStyles({
    content: {
      maxWidth: '70vh',
      paddingBottom: 0,
    },
    row: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: spacing(3),
    },
    icon: {
      marginRight: spacing(1),
      fontSize: 14,
    },
    uploadButton: {
      marginRight: spacing(2),
    },
    instructions: {
      marginTop: spacing(2),
    },
    ImportSummaryContent: {
      padding: spacing(2),
      fontSize: 14,
    },
    root: {
      width: '100%',
    },
    summary: {
      justifyContent: 'center',
      '& > :last-child': {
        padding: spacing(1), // 'expand button' padding
      },
    },
    details: {
      maxHeight: '50vh',
      overflow: 'auto',
      flexDirection: 'column',
    },
    iconRight: {
      marginLeft: spacing(1),
    },
    shrinkIcon: {
      transform: 'rotate(180deg)',
    },
    expandIcon: {
      transform: 'rotate(0deg)',
      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    detailListItem: {
      fontSize: 14,
    },
    listItemNotImported: {
      color: 'red',
    },
    listItemImported: {
      color: 'black',
    },
  })

const responseCodeKeys: { [key: string]: TranslationKey } = {
  Already_imported: 'Already imported',
  Contract_not_found: 'Contract not found',
  Vehicle_not_found: 'Vehicle not found',
  Unable_to_find_vehicle_or_contract: 'Unable to find vehicle or contract',
}

const responseCodeText = (data: string): string => {
  if (data) {
    return t(responseCodeKeys[data])
  } else {
    return ''
  }
}

class DialogWorkshopOperationsUpload extends React.Component<TProps, IState> {
  public state: IState = {
    isLoading: false,
    uploadFile: undefined,
    isShowImportSummary: false,
    expanded: false,
    importSummary: {
      totalNumber: 0,
      numberOfImported: 0,
      alreadyImported: 0,
      contractNotFound: 0,
      vehicleNotFound: 0,
      unableToFindVehicleOrContract: 0,
      allRows: null,
    },
  }

  public render() {
    const { open, onClose, classes, contractObjectType, validImportHeaders } = this.props
    const { uploadFile, isLoading, isShowImportSummary } = this.state

    return (
      <>
        <Dialog open={open} onClose={onClose} data-e2e={'Dialog--upload-workshop-operations'}>
          <DialogTitle>
            <Typography variant="subtitle">{t('Upload Workshop Operations')}</Typography>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <div>
              <input
                id="file-picker"
                accept=".csv, .xls, .xlsx"
                type="file"
                hidden={true}
                onChange={this.handleFileSelect}
                data-e2e="DialogWorkshopOperationsUpload__file-upload-input"
              />
              <label htmlFor="file-picker">
                <Button variant="outlined" component="span" disabled={isLoading}>
                  <AttachFileIcon className={classes.icon} />
                  {t('Select file')}
                </Button>
              </label>
            </div>
            <div className={classes.row}>
              {
                uploadFile ? (
                  <FormControl>
                    <Input
                      value={uploadFile.name}
                      placeholder={t('No file selected...')}
                      disableUnderline={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() =>
                              this.setState({
                                // tslint:disable-next-line jsx-no-lambda
                                uploadFile: undefined,
                              })
                            }
                            data-e2e="DialogWorkshopOperationsUpload__clear-uploaded-file-button"
                          >
                            <ClearIcon className={classes.icon} />
                          </IconButton>
                        </InputAdornment>
                      }
                      data-e2e="DialogWorkshopOperationsUpload__filename-textfield"
                    />
                  </FormControl>
                ) : (
                  <div />
                ) // empty div to keep flexbox layout
              }
              <div>
                <SpinnerButton
                  className={classes.uploadButton}
                  color="secondary"
                  variant="contained"
                  disabled={!uploadFile || isLoading}
                  showSpinner={isLoading}
                  onClick={async () => {
                    await this.handleFileUpload()
                  }}
                  data-e2e="DialogWorkshopOperationsUpload__upload-button"
                >
                  <CloudUploadIcon className={classes.icon} />
                  {t('Upload')}
                </SpinnerButton>
                <Button
                  onClick={() => {
                    // tslint:disable-next-line jsx-no-lambda
                    this.setState({ uploadFile: undefined }, onClose)
                  }}
                  variant="outlined"
                >
                  <ClearIcon className={classes.icon} />
                  {t('Close')}
                </Button>
              </div>
            </div>

            <DialogWorkshopOperationsUploadInstructions
              className={classes.instructions}
              contractObjectType={contractObjectType}
              validImportHeaders={validImportHeaders}
            />
          </DialogContent>
          <DialogActions />
        </Dialog>
        {isShowImportSummary && this.renderImportSummary()}
      </>
    )
  }

  /**
   * Get file extension
   * '.xlsx' and '.xls' should both return '.xls'
   */
  private getFileType = (path: string) => {
    const type = path.substr(path.lastIndexOf('.') + 1)

    switch (type) {
      case 'xlsx':
        return 'xls'
      default:
        return type
    }
  }

  private handleFileSelect = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = event.currentTarget.files

    // if file selected
    if (fileList && fileList.length) {
      const uploadFile: File = fileList[0]
      this.setState({ uploadFile })
    }
  }

  private renderImportSummary = () => {
    const { classes, className } = this.props
    const { importSummary, expanded } = this.state

    return (
      <Dialog
        open={true}
        onClose={() =>
          this.setState({
            isShowImportSummary: false,
            importSummary: null,
            expanded: false,
          })
        }
      >
        <DialogTitle>{t('Import Summary')}</DialogTitle>

        <DialogContent>
          <div className={classes.ImportSummaryContent}>
            <b>{importSummary!.numberOfImported} </b>
            {t('rows affected of')}
            <b> {importSummary!.totalNumber}</b> {t('rows processed in file.')} <br /> <br />
            {(importSummary!.alreadyImported > 0 ||
              importSummary!.contractNotFound > 0 ||
              importSummary!.unableToFindVehicleOrContract > 0 ||
              importSummary!.vehicleNotFound > 0) && (
              <div>
                {t('Where')}: <br />
              </div>
            )}
            {importSummary!.alreadyImported > 0 && (
              <div>
                - <b>{importSummary!.alreadyImported!}</b> {t('rows skipped (already imported/exists)')}
              </div>
            )}
            {importSummary!.contractNotFound > 0 && (
              <div>
                - <b>{importSummary!.contractNotFound}</b> {t('rows failed (missing contract)')}
              </div>
            )}
            {importSummary!.unableToFindVehicleOrContract > 0 && (
              <div>
                - <b>{importSummary!.unableToFindVehicleOrContract}</b>
                {t('rows failed (vehicle or contract not found)')}
              </div>
            )}
            {importSummary!.vehicleNotFound > 0 && (
              <div>
                - <b>{importSummary!.vehicleNotFound}</b> {t('rows failed (vehicle not found)')}
              </div>
            )}
          </div>
        </DialogContent>

        <div className={classNames(classes.root, className)}>
          <ExpansionPanel
            elevation={0}
            expanded={expanded}
            // tslint:disable-next-line jsx-no-lambda
            onChange={(e: any, expanded: boolean) => {
              this.setState({ expanded })
            }}
          >
            <ExpansionPanelSummary classes={{ content: classes.summary }}>
              <Button>
                <Typography variant="body3">{expanded ? t('Close') : t('View detailed information')}</Typography>
                <ExpandMoreIcon
                  className={classNames(classes.iconRight, expanded ? classes.shrinkIcon : classes.expandIcon)}
                />
              </Button>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.details }}>
              <div className={classes.detailListItem}>{this.renderDetailedList(importSummary!.allRows!)}</div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>

        <DialogActions>
          <Button
            onClick={() =>
              this.setState({
                isShowImportSummary: false,
                importSummary: null,
                expanded: false,
              })
            }
            variant="contained"
            color="secondary"
          >
            {t('Ok')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  private renderDetailedList(rows: IImportStatusReport[]) {
    const { classes } = this.props

    let updatedRows: IImportStatusReport[] = this.updateReasonCode(rows)

    const listItems = updatedRows
      ? updatedRows.map((item) => (
          <li className={item.isImported ? classes.listItemImported : classes.listItemNotImported} key={item.line}>
            {t('Row') + ': ' + item.line.toString() + ' ' + responseCodeText(item.reasonCode)}
          </li>
        ))
      : null

    return <div>{listItems}</div>
  }

  private updateReasonCode(rows: IImportStatusReport[]): IImportStatusReport[] {
    return rows.map((item: IImportStatusReport) => {
      switch (item.reasonCode) {
        case 'workshopData.messages.alreadyImported':
          return { ...item, reasonCode: 'Already_imported' }
        case 'workshopData.messages.contractNotFound':
          return { ...item, reasonCode: 'Contract_not_found' }
        case 'workshopData.messages.vehicleNotFound':
          return { ...item, reasonCode: 'Vehicle_not_ found' }
        case 'workshopData.messages.unableToFindVehicleOrContract':
          return { ...item, reasonCode: 'Unable_to_find_vehicle_or_contract' }
        default:
          return item
      }
    })
  }

  private handleFileUpload = async () => {
    const { uploadFile } = this.state

    // if file selected
    if (uploadFile) {
      const filetype: string = this.getFileType(uploadFile.name)
      trackEvent('File import', 'Workshop operations', filetype)
      this.setState({ isLoading: true })

      const formData: FormData = new FormData()
      formData.append('workshopdata', uploadFile)

      try {
        let allRows: IImportStatusReport[] | null = null
        let totalNumber: number = 0
        let numberOfImported: number = 0
        let alreadyImported: number = 0
        let contractNotFound: number = 0
        let vehicleNotFound: number = 0
        let unableToFindVehicleOrContract: number = 0

        await uploadContractWorkshopOperationFile(filetype, this.props.contractObjectType, formData)
          .then((response) => response!.json())
          .then(function (result: IImportStatusReport[]) {
            allRows = result
            totalNumber = result.length
            numberOfImported = result.filter((item) => item.isImported === true).length
            alreadyImported = result.filter((item) => item.reasonCode === 'workshopData.messages.alreadyImported')
              .length
            contractNotFound = result.filter((item) => item.reasonCode === 'workshopData.messages.contractNotFound')
              .length
            vehicleNotFound = result.filter((item) => item.reasonCode === 'workshopData.messages.vehicleNotFound')
              .length
            unableToFindVehicleOrContract = result.filter(
              (item) => item.reasonCode === 'workshopData.messages.unableToFindVehicleOrContract',
            ).length
          })

        this.setState({
          isShowImportSummary: true,
          importSummary: {
            totalNumber: totalNumber,
            numberOfImported: numberOfImported,
            alreadyImported: alreadyImported,
            contractNotFound: contractNotFound,
            vehicleNotFound: vehicleNotFound,
            unableToFindVehicleOrContract: unableToFindVehicleOrContract,
            allRows: allRows,
          },
        })
      } catch (e) {
        // notify.error({ message: e as any })
        console.error(e)
      }
    }

    this.setState({ isLoading: false, uploadFile: undefined }, this.props.onClose)
  }
}

export default withStyles(styles)(DialogWorkshopOperationsUpload)
