import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core'
import {
  WarningSharp as FailureIcon,
  SettingsBackupRestore as RefundIcon,
  SaveAlt as SaveAltIcon,
  CheckCircle as SuccessIcon,
} from '@material-ui/icons'
import { formatCurrency } from '@omnicar/sam-format'
import { IInvoice as IInvoiceRecord, InvoiceOrderBy as TInvoiceOrderBy } from '@fragus/sam-types'
import { getContractInvoiceList, invoiceDownloadUrl } from 'api/api'
import cn from 'classnames'
import { withContractDisplayConfig } from 'components/ContractDisplayConfig/withContractDisplayConfig'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import Paper from 'components/Mui/Paper'
import TableLoadingIndicator from 'components/TableLoadingIndicator'
import React, { ReactNode } from 'react'
import ReactTable, { Column, RowInfo, SortingRule } from 'react-table'
import 'react-table/react-table.css'
import { compose } from 'recompose'
import { theme } from 'theme'
import { t } from 'translations/translationFunctions'
import { formatYearMonthDate } from 'utils/date'
import {
  createQueryFromTable,
  createTableStateFromPagination,
  defaultTableState,
  ITableQuery,
  ITableState,
} from 'utils/react-table'
import RetryButton from './RetryButton'
import { IContractDisplayConfig } from 'reducers/contractDisplayConfig/initialState'

interface IQuery extends ITableQuery<TInvoiceOrderBy> {}

interface IProps {
  id: string
  allowRetryPayment: boolean
  locale: string
}

type TProps = IProps & WithStyles<typeof styles> & IContractDisplayConfig

interface IState {
  edit: boolean
  loading: boolean
  records: IInvoiceRecord[]
  tableState: ITableState
  tableQuery: IQuery
}

// eslint-disable-next-line
const styles = ({ spacing }: Theme) =>
  createStyles({
    invoiceUrl: {
      color: theme.palette.text.regular,
    },
    cellContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginRight: spacing(2),
    },
    smallCellContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
    },
    unpaidState: {
      color: theme.palette.error[500],
    },
    stateIcon: {
      marginRight: spacing(2),
      display: 'flex',
    },
    paidStatusOverride: {
      color: theme.palette.context.attention[300],
    },
  })

class ContractDetailsInvoiceList extends React.Component<TProps, IState> {
  public getColumns = ({ hiddenVAT }: TProps): Column[] => [
    {
      Header: t('ID'),
      accessor: 'invoiceId',
      show: false,
    },
    {
      Header: t('Date'),
      accessor: 'dateIssuedAt',
      headerClassName: 'ContractDetailsInvoiceList__table-header',
      Cell: ({ value }: { value: string }) => {
        const date = formatYearMonthDate(value)
        return <div className="ContractDetailsInvoiceList__table-header-date">{date}</div>
      },
    },
    {
      Header: t('Status'),
      accessor: 'state',
      headerClassName: 'ContractDetailsInvoiceList__table-header',
      Cell: this.resolveStateCell,
    },
    {
      Header: t(hiddenVAT ? 'Amount' : 'Amount (ex. VAT)'),
      accessor: hiddenVAT ? 'amount.priceInclVat' : 'amount.price',
      headerClassName: 'ContractDetailsInvoiceList__table-header',
      Cell: ({ value }: { value: string }) => {
        const priceValue = value || 0
        const price = formatCurrency(+priceValue, { symbolDisplayType: 'APPEND' })
        return <div className="ContractDetailsInvoiceList__table-header-amount">{price}</div>
      },
    },
    {
      Header: t('Invoice'),
      accessor: 'url',
      headerClassName: 'ContractDetailsInvoiceList__table-header',
      Cell: this.resolveInvoiceCell,
    },
  ]

  public state: IState = {
    edit: false,
    loading: false,
    records: [],
    tableState: {
      ...defaultTableState(5),
      resized: [],
      sorted: [{ desc: true, id: 'dateIssuedAt' }],
    },
    tableQuery: {
      search: '',
      pagination: {
        limit: 5,
        offset: 0,
        orderBy: 'dateIssuedAt',
        orderDirection: 'DESC',
      },
    },
  }

  public componentDidMount() {
    this.loadData()
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.id !== prevProps.id) {
      this.loadData()
    }
  }

  public render() {
    const { tableState, records, loading } = this.state
    const tableColumns = this.getColumns(this.props)

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>{t('Payments')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Paper className="ContractDetailsInvoiceListTable">
            <ReactTable
              className="react-table"
              columns={tableColumns}
              data={records}
              defaultPageSize={10}
              defaultSorted={tableState.sorted}
              loading={loading}
              LoadingComponent={TableLoadingIndicator}
              noDataText={!loading ? t('No Results Found') : ''}
              manual={true}
              onPageChange={this.handlePageChange}
              onPageSizeChange={this.handlePageSizeChange}
              onSortedChange={this.handleSortChange}
              page={tableState.page}
              pageSize={tableState.pageSize}
              pages={tableState.pages}
              showPaginationBottom={records.length > 0}
              minRows={3}
              pageText={t('Page')}
              ofText={t('of')}
              rowsText={t('rows')}
            />
          </Paper>
        </PanelContent>
      </Panel>
    )
  }

  private resolveStateCell = (cellProps: RowInfo) => {
    const { state, amount } = cellProps.original as IInvoiceRecord
    const processedState = amount.price > 0 ? state : 'Refund'
    const { smallCellContainer, stateIcon, unpaidState } = this.props.classes
    let Icon: ReactNode

    switch (processedState) {
      case 'Refund':
        Icon = <RefundIcon color="secondary" />
        break
      case 'Paid':
        Icon = <SuccessIcon color="primary" />
        break
      case 'Pending':
        Icon = <FailureIcon color="error" />
        break

      default:
        break
    }

    return (
      <div
        className={cn(
          'ContractDetailsInvoiceList__table-header-status',
          smallCellContainer,
          state === 'Pending' ? unpaidState : '',
        )}
      >
        <div className={stateIcon}>{Icon}</div>
        <div>{t(processedState)}</div>
      </div>
    )
  }

  private resolveInvoiceCell = (cellProps: RowInfo) => {
    const { classes, allowRetryPayment } = this.props
    const { url, state } = cellProps.original as IInvoiceRecord

    return (
      <div className={cn('ContractDetailsInvoiceList__table-header-url', classes.cellContainer)}>
        {state !== 'Pending' && (
          <a href={invoiceDownloadUrl(url)} className={classes.invoiceUrl} target="blank">
            <SaveAltIcon />
          </a>
        )}
        <span>
          {state === 'Pending' && allowRetryPayment ? (
            <RetryButton
              invoice={cellProps.original as IInvoiceRecord}
              onSucceededPayment={this.handleUpdateInvoiceOnRetry}
            />
          ) : (
            ''
          )}
        </span>
      </div>
    )
  }

  private loadData = async () => {
    const { loading, tableQuery } = this.state
    const { id } = this.props

    if (!loading) {
      this.setState({ loading: true })

      const response = await getContractInvoiceList(id, tableQuery)

      if (response.data) {
        const { result, pagination } = response.data
        const newTableState = createTableStateFromPagination(pagination)
        const tableState = { ...this.state.tableState, ...newTableState }

        this.setState({ loading: false, records: result, tableState })
      } else {
        this.setState({ loading: false })
      }
    }
  }

  private handlePageChange = (page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handlePageSizeChange = (pageSize: number, page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page, pageSize } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handleSortChange = (sorted: SortingRule[]) => {
    const tableState: ITableState = { ...this.state.tableState, sorted }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handleUpdateInvoiceOnRetry = (invoice: IInvoiceRecord) =>
    this.setState(({ records }) => ({
      records: records.map((r) => (r.invoiceId === invoice.invoiceId ? { ...r, state: 'Paid' } : r)),
    }))
}

export default compose<TProps, IProps>(withContractDisplayConfig, withStyles(styles))(ContractDetailsInvoiceList)
