import { getLocale as getTranslationLocale } from '@omnicar/sam-translate'
import { getUserLocale, getLocaleRecords } from 'api/api'
import { IsoLocale } from '@fragus/sam-types'
import { isLoggedIn, getProviderLocale } from 'utils/localStorage'

export const LOCALE_CODE_DEFAULT: IsoLocale = 'en'

// export const getPreferredUserLocale = async (email: string) => {
//   const response2 = await getAvailableLocales()
//   const availableLocales: string[] = !response2 ? [LOCALE_CODE_DEFAULT] : response2
//   const localeOrLanguage = await getPreferredUserLocale(username, availableLocales as string[])

//   let locale
//   if (localeOrLanguage.length === 2) {
//     locale = languageToLocale(localeOrLanguage, availableLocales)
//   } else {
//     locale = localeOrLanguage
//   }

//   if (!locale) {
//     setLocale(providerLocale)
//   } else {
//     setLocale(locale)
//   }
// }

/**
 * Note:
 * Locale is a string in the form 'xx-yy', where:
 *   xx = two letter language code
 *   yy = two letter country/region code
 * Language is only the two letter language code.
 */
export const getPreferredUserLocale = async (email: string, availableLocales: string[] | undefined = undefined) => {
  if (!availableLocales) {
    const response = await getAvailableLocales()
    availableLocales = !response ? [LOCALE_CODE_DEFAULT] : response
  }

  const isAllowed: any = []

  // Contruct a hashmap with allowed locales ('xx-yy') and languages ('xx').
  availableLocales.forEach((locale: string) => {
    const key = locale.toLowerCase()
    isAllowed[key] = true
    isAllowed[localeToLanguage(key)] = true
  })

  // *** Return possible USER SELECTED language/locale code (from DB saved by user). ***
  //
  // Note: Supports only locales codes in the form ('xx-yy') and two
  // letter language codes ('xx')
  if (isLoggedIn() && email) {
    let retValue = ''
    await getUserLocale(email).then((userSelectedLocaleCode) => {
      if (userSelectedLocaleCode !== '') {
        retValue = userSelectedLocaleCode
      }
    })
    if (retValue) {
      // Check if this locale is allowed (supported).
      if (isAllowed[retValue.toLowerCase()]) {
        return retValue
      }

      // Then check if the 2 letter language code is allowed (supported).
      retValue = localeToLanguage(retValue)
      if (isAllowed[retValue.toLowerCase()]) {
        return retValue
      }
    }
  }

  // *** Return possible PROVIDER locale/language code. ***
  //
  // Note: At the time being, the LanguageSelector is not available (shown on the website)
  // for normal users, therefore prioritize to set the provider's locale over browser's
  // locale (as emails, TOS, templates, etc probably are this language) since the user
  // can't change the language by themself (since LanguageSelector is not shown.).
  //
  {
    const providerLocale = getProviderLocale()
    const providerLanguage = localeToLanguage(providerLocale as string)

    if (providerLocale && isAllowed[providerLocale.toLowerCase()]) {
      return providerLocale
    } else if (providerLanguage && isAllowed[providerLanguage.toLowerCase()]) {
      return providerLanguage
    }
  }

  // TODO: Return the PROVIDER COUNTRY's default locale/language code.

  // *** Return possible BROWSER locale/language code. ***
  {
    const browserLocale = getBrowserLocale()
    const browserLanguage = localeToLanguage(browserLocale)

    if (browserLocale && isAllowed[browserLocale.toLowerCase()]) {
      return browserLocale
    } else if (browserLanguage && isAllowed[browserLanguage.toLowerCase()]) {
      return browserLanguage
    }
  }

  // *** Return possible SAM system locale/language code. ***
  {
    const localeSAM = getTranslationLocale()
    if (localeSAM) {
      const languageSAM = localeToLanguage(localeSAM as string)

      if (localeSAM && isAllowed[localeSAM.toLowerCase()]) {
        return localeSAM
      } else if (languageSAM && isAllowed[languageSAM.toLowerCase()]) {
        return languageSAM
      }
    }
  }

  // *** Return the default locale code. ***
  return LOCALE_CODE_DEFAULT
}

/**
 * Note:
 * Locale is a string in the form 'xx-yy', where:
 *   xx = two letter language code
 *   yy = two letter country/region code
 * @returns Returns a sorted array with all available locales found in the database.
 */
export const getAvailableLocales = async () => {
  const arrayLocales: IsoLocale[] = []
  const response: any = await getLocaleRecords()

  if (response && response.data) {
    const localeRecordsObj = JSON.parse(response.data)
    const localeRecords = localeRecordsObj.localeRecords

    if (localeRecords) {
      // Array with languages to show.
      for (const key of Object.keys(localeRecords)) {
        if (key) {
          arrayLocales.push(key as IsoLocale)
        }
      }
    }
  }

  if (!arrayLocales.includes(LOCALE_CODE_DEFAULT)) {
    arrayLocales.push(LOCALE_CODE_DEFAULT)
  }

  if (arrayLocales.length === 0) {
    return [LOCALE_CODE_DEFAULT]
  }

  return arrayLocales.sort()
}

/**
 * Note:
 * Locale is a string in the form 'xx-yy', where:
 *   xx = two letter language code
 *   yy = two letter country/region code
 * Language is only the two letter language code.
 *
 * @param locale A locale code in the form 'xx-yy'.
 * @returns A two letter language code (short locale) from the provided locale.
 */
export const localeToLanguage = (locale: string): string => {
  if (!locale) {
    return ''
  } else {
    return locale.substr(0, 2)
  }
}

/**
 *  Maps a country to that country's default language.
 *
 *  This following method is a work around until sam-format: init method
 *  is refactored to take a country.
 *
 *  @note: In futere the values should probably be in the database unless
 *  this method is deleted all together when/if not needed anymore.
 */
export const countryToLocale = (country: string | null): IsoLocale => {
  if (!country) return 'en-GB'

  // ==== START workaround =================================================================
  interface ICountryToLocale {
    int: IsoLocale // International
    dk: IsoLocale
    se: IsoLocale
    fi: IsoLocale
    no: IsoLocale
  }

  const COUNTRY_TO_LOCALE: ICountryToLocale = {
    int: 'en-GB',
    dk: 'da-DK',
    se: 'sv-SE',
    fi: 'fi-FI',
    no: 'nb-NO',
  }

  let locale: IsoLocale = 'en-GB'
  if (country && COUNTRY_TO_LOCALE[country.toLowerCase()]) {
    locale = COUNTRY_TO_LOCALE[country.toLowerCase()]
  } else {
    throw new Error('countryToLocale: Failed map country: ' + country)
  }
  // ==== END workaround ===================================================================

  return locale
}

/**
 * Note:
 * Locale is a string in the form 'xx-yy', where:
 *   xx = two letter language code
 *   yy = two letter country/region code
 * Language is only the two letter language code.
 *
 * @param locale A locale code in the form 'xx-yy'.
 * @param availableLocales An array with locales in the form of 'xx-yy'.
 * @returns A locale in the form of 'xx-yy' from a two letter language,
 *          based on the provided array availableLocales.
 */
export const languageToLocale = (language: string, availableLocales: IsoLocale[]): IsoLocale | null => {
  const lang = language.toLowerCase()

  for (const locale of availableLocales) {
    if (lang === localeToLanguage(locale)) {
      return locale as IsoLocale
    }
  }

  return null
}

/**
 * Note:
 * Locale is a string in the form 'xx-yy', where:
 *   xx = two letter language code
 *   yy = two letter country/region code
 *
 * @returns The two letter language code found in the browser's locale settings.
 */
export const getBrowserLanguage = (): string => {
  try {
    return navigator.language.substr(0, 2)
  } catch (err) {
    return ''
  }
}

/**
 * Note:
 * Locale is a string in the form 'xx-yy', where:
 *   xx = two letter language code
 *   yy = two letter country/region code
 *
 * @returns The string found in the browser's locale settings, should be in the
 *          form of 'xx-yy' but in can also sometimes just be the two letter
 *          language code 'xx'.
 */
export const getBrowserLocale = (): string => {
  try {
    return navigator.language.substr(0, 5)
  } catch (err) {
    return ''
  }
}
