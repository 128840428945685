import { formatDate } from '@omnicar/sam-format'
import { ContractState, IContractActionResponse, IPaymentFailureReason } from '@fragus/sam-types'
import { getPaymentFailureReason, getSettlementPaymentFailureReason } from 'api/api'
import React from 'react'

export interface ISuspendReasonProps {
  reason: IPaymentFailureReason
  nextAttempt?: string
}

interface IProps {
  action?: IContractActionResponse
  prettyId: string
  contractState?: ContractState
}

interface IState {
  reason: IPaymentFailureReason | undefined
  nextAttempt?: string
}

export const withSuspendReason = (WrappedComponent: React.SFC<ISuspendReasonProps>): React.ComponentClass<IProps> => {
  return class extends React.Component<IProps, IState> {
    public state: IState = {
      reason: undefined,
    }

    public componentDidMount() {
      this.getReason(WrappedComponent)
    }

    render() {
      const { reason, nextAttempt } = this.state
      return reason ? <WrappedComponent reason={reason} nextAttempt={nextAttempt} {...this.props} /> : <></>
    }

    getReason = async (component: React.SFC<ISuspendReasonProps>) => {
      const { prettyId, action, contractState } = this.props

      if (contractState === ContractState.Suspended || component.name === 'SuspendReasonCode') {
        const response = await getPaymentFailureReason(prettyId, action?.id)
        if (response.statusCode === 200 && response.data) {
          const reason = response.data as IPaymentFailureReason
          this.setState({ reason: reason.paymentFailureReason && reason })
        }
      }

      if (contractState && [ContractState.Terminated, ContractState.Expired].includes(contractState)) {
        const response = await getSettlementPaymentFailureReason(prettyId)
        const reason = response.data?.reason
        const nextAttempt = response.data?.nextAttempt

        response.statusCode === 200 &&
          reason &&
          this.setState({
            reason,
            nextAttempt: nextAttempt && formatDate(new Date(Number(nextAttempt) * 1000)),
          })
      }
    }
  }
}
