import { tDot } from 'utils/string'
import { IOwnProps } from '../types'

export const getWarrantyErrorMessage = ({ contract, warrantyCreated }: IOwnProps): string => {
  const CK_WARRANTY_ALREADY_CREATED =
    'You can not create a contract type or option with warranty because you have already created a warranty for this vehicle'

  if (warrantyCreated && contractHasWarranty(contract)) {
    return tDot(CK_WARRANTY_ALREADY_CREATED)
  }
  return ''
}

const contractHasWarranty = ({ template, options }: IOwnProps['contract']): boolean => {
  if (template && template.properties.some((p) => (p.warranty ? true : false))) {
    return true
  }
  return options.some((o) => (o.warranty ? true : false))
}
