import { Button, createStyles, Theme, Typography, withStyles, WithStyles } from '@material-ui/core'
import { FiberSmartRecord as ProductIcon, DirectionsCar as VehicleIcon } from '@material-ui/icons'
import { TContractObject } from '@fragus/sam-types'
import DialogWorkshopOperationsUpload from 'components/admin/Contract/List/Dialog/WorkshopOperationsUpload'
import React from 'react'

interface IProps {
  className: string
  text: string
  onClick: (open?: boolean) => void
  contractObjectType: TContractObject
  validImportHeaders?: string[]
}

interface IState {
  dialogOpen: boolean
}

const styles = ({ spacing }: Theme) =>
  createStyles({
    buttonIcon: {
      fontSize: 16,
      marginRight: spacing(2),
      color: 'white',
    },
    buttonText: {
      color: 'white',
    },
  })

type TProps = IProps & WithStyles<typeof styles>

class UploadWorkshopButton extends React.Component<TProps, IState> {
  public state: IState = {
    dialogOpen: false,
  }

  render() {
    const { classes, className, text, contractObjectType, validImportHeaders } = this.props
    const { dialogOpen } = this.state

    return (
      <>
        <div>
          <Button
            variant="contained"
            className={className}
            // tslint:disable-next-line jsx-no-lambda
            onClick={this.handleOnClick}
            data-e2e="AdministrationPage__Reports__{}"
          >
            {contractObjectType === 'Vehicle' ? (
              <VehicleIcon className={classes.buttonIcon} />
            ) : (
              <ProductIcon className={classes.buttonIcon} />
            )}
            <Typography variant="button" className={classes.buttonText}>
              {text}
            </Typography>
          </Button>
        </div>
        <DialogWorkshopOperationsUpload
          open={dialogOpen}
          // tslint:disable-next-line jsx-no-lambda
          onClose={this.handleOnClick}
          contractObjectType={contractObjectType}
          validImportHeaders={validImportHeaders}
        />
      </>
    )
  }

  private handleOnClick = () => {
    this.setState({ dialogOpen: !this.state.dialogOpen })
    this.props.onClick()
  }
}

export default withStyles(styles)(UploadWorkshopButton)
