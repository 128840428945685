import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { IColumnUserConfiguration, TContractObject } from '@fragus/sam-types'
import SpinnerButton from 'components/Mui/SpinnerButton'
import Typography from 'components/Typography'
import React from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { Column } from 'react-table'
import { contractStateStyles } from 'theme/styles/contractState'
import { t } from 'translations/translationFunctions'
import { Accessors, headerKeys } from 'types/contractFilterTypes'
import { trackEvent } from 'utils/analytics'
import { getColumns } from '../..'
import Item from './Item'

interface IOwnProps {
  open: boolean
  saving: boolean
  columns: IColumnUserConfiguration[]
  activeType: TContractObject
  onClose: () => void
  onSave: (columns: IColumnUserConfiguration[]) => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  columns: IColumnUserConfiguration[]
  minCountAlert: boolean
}

const styles = (theme: Theme) =>
  createStyles({
    ...contractStateStyles({ color: true }),
    title: {
      marginBottom: theme.spacing(1),
    },
  })

export class DialogColumnsManagment extends React.Component<TProps, IState> {
  private tableColumns: Column[] = []
  private minColumnCount = 3

  constructor(props: TProps) {
    super(props)
    this.tableColumns = getColumns(this.props.classes, Accessors[props.activeType])
    this.state = {
      minCountAlert: false,
      columns: props.columns,
      // hasAccessToProducts: false,
    }
  }

  public componentDidUpdate(prevProps: TProps) {
    const { props } = this
    // Copy columns when dialog is opened
    if (!prevProps.open && props.open) {
      this.setState({ columns: props.columns, minCountAlert: false })
    }
  }

  public render() {
    const { getTitle } = this
    const { open, saving, classes } = this.props
    const { columns } = this.state

    return (
      <React.Fragment>
        <Dialog open={open} onClose={this.handleClose} data-e2e={'dialogColumnsManagement'}>
          <DialogTitle>
            <Typography className={classes.title} variant="title">
              {t('Column Management')}
            </Typography>
            <Typography variant="subtitle">
              {t('Click on a column to toggle between disabling/enabling it')}
              <br />
              {t('Drag and drop a column to change the sort order')}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DragDropContext onDragEnd={this.handleColumnDragEnd}>
              <Droppable droppableId="ContractListDialogColumnsManagmentDroppable">
                {(provided) => (
                  <div ref={provided.innerRef}>
                    {columns.length > 0 &&
                      columns.map((column, index) => {
                        const title = getTitle(column)

                        return (
                          <Draggable
                            key={`ContractListDialogColumnsManagmentDraggable-${column.name}-${index}`}
                            draggableId={`${column.name}-${index}`}
                            index={index}
                          >
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                <Item
                                  title={t(title)}
                                  column={column}
                                  onEnabledChange={this.handleColumnEnabledChange}
                                  index={index}
                                />
                              </div>
                            )}
                          </Draggable>
                        )
                      })}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </DialogContent>
          <DialogActions>
            <SpinnerButton onClick={this.handleSave} showSpinner={saving}>
              {t('Save')}
            </SpinnerButton>
            <Button onClick={this.handleReset} disabled={saving}>
              {t('Reset')}
            </Button>
            <Button onClick={this.handleClose} disabled={saving}>
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.minCountAlert} onClose={this.handleAlertClose} aria-labelledby="alert-dialog-title">
          <DialogTitle id="alert-dialog-title">
            {t('At least %count columns must be selected', { count: this.minColumnCount })}
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleAlertClose} color="primary" autoFocus={true}>
              {t('OK')}
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }

  private getTitle = (column: IColumnUserConfiguration) => {
    const { tableColumns } = this
    const foundColumn = tableColumns.find((c) => c.accessor === column.name)
    if (foundColumn && typeof foundColumn.accessor === 'string') {
      return headerKeys[foundColumn.accessor].tran
    } else {
      return ''
    }
  }

  private handleColumnEnabledChange = (column: IColumnUserConfiguration, index: number) => {
    const columns: IColumnUserConfiguration[] = [...this.state.columns]

    columns.splice(index, 1)
    columns.splice(index, 0, column)

    this.setState({ columns })
  }

  private handleColumnDragEnd = (result: DropResult) => {
    // Dropped outside the list - bail out
    if (!result.destination) {
      return
    }

    const columns: IColumnUserConfiguration[] = [...this.state.columns]
    const { source, destination } = result
    const column = columns[source.index]

    columns.splice(source.index, 1)
    columns.splice(destination.index, 0, column)

    this.setState({ columns })
  }

  private getEnabledCount = () => {
    return this.state.columns.filter((col) => col.enabled).length
  }

  private handleSave = () => {
    const enabledCount = this.getEnabledCount()
    if (enabledCount >= this.minColumnCount) {
      trackEvent('Contract columns', 'Columns changed', undefined, enabledCount)
      this.props.onSave(this.state.columns)
    } else {
      this.setState({
        minCountAlert: true,
      })
    }
  }

  private handleReset = () => {
    const columns: IColumnUserConfiguration[] = []

    getColumns(this.props.classes, Accessors[this.props.activeType]).forEach((column) => {
      columns.push({
        name: column.accessor as string,
        enabled: column.accessor !== 'createdByProvider',
      })
    })

    this.setState({ columns })
  }

  private handleAlertClose = () => {
    this.setState({ minCountAlert: false })
  }

  private handleClose = () => {
    this.props.onClose()
  }
}

export default withStyles(styles)(DialogColumnsManagment)
