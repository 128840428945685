import { IContractFilterParams } from '@fragus/sam-types'

export interface IContractFilters {
  params: IContractFilterParams
  activeStates: string[]
}
export const contractFilterInitialState: IContractFilters = {
  params: {
    contractStates: [],
    templates: [],
    options: [],
    sellers: [],
    subProviders: [],
    created: {
      min: '',
      max: '',
    },
    expired: {
      min: '',
      max: '',
    },
    activeTypes: [],
    contractObjectType: 'Vehicle',
  },
  activeStates: [],
}
