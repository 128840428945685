import { Dialog, DialogContent, Divider, Fade, Link, Tooltip } from '@material-ui/core'
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import React, { Component } from 'react'
import { palette } from 'theme'

interface ISection {
  title: string
  value: string
}

interface IOwnProps {
  infoSections: ISection[]
  title: string
  showAsPopup?: boolean
}

interface IState {
  open: boolean
}

const styles = ({ spacing, palette: themePalette }: Theme) =>
  createStyles({
    tooltipWrapper: { marginLeft: spacing(0.3) },
    link: {
      color: '#eee',
      textDecoration: 'underline',
      fontSize: spacing(1.75),
      cursor: 'pointer',
      verticalAlign: 'middle',
      paddingLeft: spacing(0.3),
      fontWeight: 500,
    },
    dialog: {
      marginBottom: '20%',
    },
    tooltip: {
      marginBottom: spacing(8),
      marginTop: spacing(8),
      maxWidth: spacing(75),
      padding: spacing(2),
      fontSize: spacing(1.5),
    },
    divider: {
      marginTop: spacing(1),
      marginBottom: spacing(1),
      backgroundColor: palette.text.lighter,
    },
    title: {
      fontWeight: 'bolder',
    },
  })

const InfoSection: React.SFC<ISection & WithStyles<typeof styles>> = ({ classes, title, value }) => (
  <span>
    <span className={classes.title}>{`${title} `}</span>
    <span>{value}</span>
  </span>
)

type TProps = WithStyles<typeof styles> & IOwnProps

class InfoTooltip extends Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)

    this.state = {
      open: false,
    }
  }

  render() {
    const { classes, showAsPopup, title } = this.props
    return (
      <div className={classes.tooltipWrapper}>
        {showAsPopup ? (
          <>
            <Link className={classes.link} onClick={this.handleClick}>
              {title}
            </Link>
            <Dialog open={this.state.open} onClose={this.handleClose} className={classes.dialog}>
              <DialogContent>{this.renderSections()}</DialogContent>
            </Dialog>
          </>
        ) : (
          <Tooltip
            title={this.renderSections()}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            classes={{ tooltip: classes.tooltip }}
          >
            <Link className={classes.link}>{title}</Link>
          </Tooltip>
        )}
      </div>
    )
  }
  private renderSections = () => {
    const { infoSections, classes } = this.props
    return (
      <>
        {infoSections.map(({ title, value }) => (
          <React.Fragment key={title}>
            <InfoSection {...{ title, value, classes }} />
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </>
    )
  }

  private handleClick = () => this.setState({ open: true })

  private handleClose = () => this.setState({ open: false })
}

type TClassKey = keyof ReturnType<typeof styles>

export default withStyles<TClassKey, {}, IOwnProps>(styles)(InfoTooltip)
