import {
  Collapse,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import { t } from 'translations/translationFunctions'
import { ISettlementInvoicesBalance } from '@fragus/sam-types'
import classnames from 'classnames'
import React from 'react'
import { formatToNumberWithSign } from 'utils/regex'

interface IOwnProps {
  expanded: boolean
  settlementInvoices: ISettlementInvoicesBalance
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ spacing, palette }: Theme) =>
  createStyles({
    rootTable: {
      borderLeft: `1px solid ${palette.divider}`,
    },
    rootRow: {
      height: spacing(3.75),
      '& > *': {
        borderBottom: 'unset',
      },
    },
    rootCell: {
      fontSize: spacing(1.75),
      paddingTop: 0,
      paddingBottom: 0,
      paddingRight: 0,
    },
    hide: {
      height: 0,
    },
    negative: {
      color: palette.error.main,
    },
    positive: {
      color: palette.secondary.dark,
    },
  })

const StatisticsCollapseItems: React.SFC<TProps> = ({ expanded, settlementInvoices, classes }) => {
  const resolveAmountClassName = (value: number) => (value > 0 ? classes.positive : value === 0 ? '' : classes.negative)

  return (
    <TableRow classes={{ root: classnames(classes.rootRow, !expanded ? classes.hide : '') }}>
      <TableCell colSpan={2}>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Table classes={{ root: classes.rootTable }}>
            <TableBody>
              {Object.entries(settlementInvoices).map(([key, value]) => (
                <TableRow key={key} classes={{ root: classes.rootRow }}>
                  <TableCell
                    classes={{ root: classnames(classes.rootCell, resolveAmountClassName(value.priceInclVat)) }}
                  >
                    {formatToNumberWithSign(value.priceInclVat)}
                  </TableCell>
                  <TableCell classes={{ root: classes.rootCell }}>
                    {t(key as keyof ISettlementInvoicesBalance)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

export default withStyles(styles)(StatisticsCollapseItems)
