import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatDate, formatMileage } from '@omnicar/sam-format'
import { IContractInfo } from '@fragus/sam-types'
import cn from 'classnames'
import IncludedOptionsList from 'components/IncludedOptionsList'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import Paper from 'components/Mui/Paper'
import Typography from 'components/Typography'
import React from 'react'
import { t } from 'translations/translationFunctions'
import { valueTypeTranslations } from 'translations/ValueTypeTranslations'

const styles = (theme: Theme) =>
  createStyles({
    page: {
      padding: theme.spacing(3),
    },
    sectionItem: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    sectionItemLarger: {
      marginBottom: theme.spacing(1),
      fontSize: 18,
    },
    sectionItemLabel: { marginRight: theme.spacing(2) },
    sectionItemValue: {
      fontWeight: theme.typography.fontWeightMedium,
      whiteSpace: 'nowrap',
    },
    contractName: {
      color: theme.palette.secondary[600],
    },
    descSectionWrapper: {
      marginTop: theme.spacing(2),
    },
    optionsSubtitle: {
      marginTop: theme.spacing(4),
    },
    optionsWrapper: {
      marginTop: theme.spacing(1),
    },
    itemWrapper: {
      alignItems: 'center',
    },
    termsButton: {
      marginLeft: 'auto',
    },
    checkmarkIcon: {
      color: theme.palette.secondary[600],
    },
  })

interface IProps {
  contract: IContractInfo
}

type TProps = IProps & WithStyles<typeof styles>

class ContractInfo extends React.Component<TProps> {
  public render() {
    const { contract, classes } = this.props
    const { valueType, startValueType } = contract
    const translation = valueTypeTranslations[valueType || 'Mileage'] || undefined
    const value = contract.value || contract.mileage || 0
    const startValueTranslations = startValueType ? valueTypeTranslations[startValueType] : undefined

    return (
      <Panel>
        <PanelHeader>
          <PanelTitle>{t('Contract')}</PanelTitle>
        </PanelHeader>
        <PanelContent>
          <Paper className={classes.page}>
            <Typography className={classes.contractName} variant="title">
              {contract.templateName}
            </Typography>
            <div className={classes.descSectionWrapper}>
              <div className={cn(classes.sectionItem, classes.sectionItemLarger)}>
                <span className={classes.sectionItemLabel}>
                  {translation && contract.value ? t(translation.durationAndValueTotal) + ':' : t('Duration')}
                </span>
                <span className={classes.sectionItemValue}>
                  {`${contract.duration} ${t('mos.')} ${
                    translation && value ? `/ ${formatMileage(value)} ${t(translation.shortValue)}` : ''
                  }`}
                </span>
              </div>
              {startValueType !== 'Services' && (
                <div className={classes.sectionItem}>
                  <span className={classes.sectionItemLabel}>
                    {startValueTranslations && t(startValueTranslations.startValue)}:
                  </span>
                  <span className={classes.sectionItemValue}>
                    {contract.startValue} {startValueTranslations && t(startValueTranslations.shortValue)}
                  </span>
                </div>
              )}
              <div className={classes.sectionItem}>
                <span className={classes.sectionItemLabel}>{t('Start Date')}:</span>
                <span className={classes.sectionItemValue}>{formatDate(contract.startDate)}</span>
              </div>
              <div className={classes.sectionItem}>
                <span className={classes.sectionItemLabel}>{t('End Date')}:</span>
                <span className={classes.sectionItemValue}>{formatDate(contract.endDate)}</span>
              </div>
            </div>
            {!contract.additionalOptions.length ? null : (
              <>
                <Typography className={classes.optionsSubtitle} variant="subtitle" tag="h2">
                  {t('Options purchased to the subscription')}
                </Typography>
                <div className={classes.optionsWrapper}>
                  <IncludedOptionsList
                    options={contract.additionalOptions}
                    showAbbreviation={true}
                    itemsWrapperClassName={classes.itemWrapper}
                    termsButtonClassName={classes.termsButton}
                    checkmarkIconClassName={classes.checkmarkIcon}
                  />
                </div>
              </>
            )}
            {!contract.includedOptions.length ? null : (
              <>
                <Typography className={classes.optionsSubtitle} variant="subtitle" tag="h2">
                  {t('Included in subscription')}
                </Typography>
                <div className={classes.optionsWrapper}>
                  <IncludedOptionsList
                    options={contract.includedOptions}
                    checkmarkIconClassName={classes.checkmarkIcon}
                  />
                </div>
              </>
            )}
          </Paper>
        </PanelContent>
      </Panel>
    )
  }
}

export default withStyles(styles)(ContractInfo)
