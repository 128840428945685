import React from 'react'
import { Attachment } from '@fragus/sam-types/types/admin/contract/internalContractNotes'
import { createPanelStyles, palette } from 'theme'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import { Button } from '@material-ui/core'
import { Cancel as DeleteIcon, ArrowDropDown as DropDownIcon, ArrowDropUp as DropUpIcon } from '@material-ui/icons'
import { t } from 'translations/translationFunctions'
import { PdfLinkButton } from 'components/Mui/LinkButton'
import { noteAttachmentUrl } from 'api/api'

interface IOwnProps {
  imageAttachments: Attachment[]
  pdfAttachments: Attachment[]
  editing: boolean
  initialDisplayAttachments: boolean
  onRemoveAttachment?: (attachmentUrl: string) => void
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      padding: '5px',
    },
    flexWrap: {
      flexWrap: 'wrap',
    },
    boldLabel: {
      fontWeight: 'bold',
    },
    attachments: {
      position: 'relative',
      width: 'fit-content',
    },
    indent: {
      paddingLeft: theme.spacing(2),
    },
    attachmentsImage: {
      maxWidth: '90%',
      height: 'fit-content',
      marginRight: '10px',
      marginBottom: '10px',
      verticalAlign: 'top',
    },
    attachmentsButton: {
      position: 'absolute',
      left: '5px',
      top: '5px',
      backgroundColor: 'white',
      border: '1px solid lightgray',
      borderRadius: '5px',
      '&:hover': {
        backgroundColor: 'lightgray',
      },
    },
    deleteIcon: {
      color: palette.context.warning[500],
      '&:hover': {
        color: palette.context.warning[700],
      },
    },
    attachmentsContainer: {
      paddingTop: theme.spacing(3),
    },
  })

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  displayAttachments: boolean
}

class InternalNoteAttachments extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.state = {
      displayAttachments: !!props.initialDisplayAttachments,
    }
  }

  public render() {
    const { imageAttachments, pdfAttachments, editing, classes } = this.props
    const { displayAttachments } = this.state

    return (
      <div className={classes.attachmentsContainer}>
        <div className={classNames(classes.flexRow, classes.flexWrap)}>
          {!!pdfAttachments.length &&
            pdfAttachments.map((a, i) => (
              <div key={`NoteInputForm-${a.fileName}-pdf-attachments_${i}`}>
                {editing ? (
                  <Button
                    className={classNames(classes.boldLabel)}
                    onClick={() => this.handleRemoveAttachment(a.url)}
                    title={t('Remove attached file')}
                  >
                    <DeleteIcon className={classNames(classes.panelActionsButtonIcon, classes.deleteIcon)} />
                    {a.originalFileName}
                  </Button>
                ) : (
                  <PdfLinkButton
                    key={`NotePDFDownload-${a.fileName}_${i}`}
                    caption={a.originalFileName}
                    href={noteAttachmentUrl(a.url)}
                  />
                )}
              </div>
            ))}
        </div>
        {!!imageAttachments && !!imageAttachments.length && (
          <div>
            <Button className={classes.boldLabel} onClick={this.handleDisplayAttachmentsClick} variant="outlined">
              {displayAttachments ? (
                <>
                  <DropUpIcon />
                  {t('Hide attachments')}
                </>
              ) : (
                <>
                  <DropDownIcon />
                  {`${t('Show attachments')} (${imageAttachments.length})`}
                </>
              )}
            </Button>
            {displayAttachments && (
              <div
                className={classNames(classes.flexRow, classes.attachmentsContainer, classes.flexWrap, classes.indent)}
              >
                {imageAttachments.map((a, i) => {
                  const handleRemove = () => {
                    this.handleRemoveAttachment(a.url)
                  }

                  return (
                    <div key={`NoteInputForm-${a.fileName}-image-attachments_${i}`} className={classes.attachments}>
                      <img
                        className={classNames(classes.brandLogo, classes.attachmentsImage)}
                        src={a.url}
                        alt="Attachment"
                      />
                      {editing && (
                        <Button
                          className={classNames(classes.boldLabel, classes.attachmentsButton)}
                          onClick={handleRemove}
                          title={t('Remove attached file')}
                        >
                          <DeleteIcon className={classNames(classes.panelActionsButtonIcon, classes.deleteIcon)} />
                          {a.originalFileName}
                        </Button>
                      )}
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  private handleRemoveAttachment = (fileUrl: string) => {
    const { onRemoveAttachment } = this.props
    onRemoveAttachment && onRemoveAttachment(fileUrl)
  }

  private handleDisplayAttachmentsClick = () => {
    this.setState({ displayAttachments: !this.state.displayAttachments })
  }
}

export default withStyles(styles)(InternalNoteAttachments)
