import { hashToArr } from 'utils/redux'
import { IRootState } from '../reducers/initialState'
import { ContractActionType } from '@fragus/sam-types'
import { createSelector } from 'reselect'
import moment from 'moment'

export const selectBalanceStatistics = (state: IRootState) => state.contractSettlement.balanceStatistics

export const selectSettleActions = (state: IRootState) => hashToArr(state.contractSettlement.settleActions)

export const selectSettlementAttachments = (state: IRootState) => hashToArr(state.contractSettlement.attachments)

export const selectSettlementInfo = (state: IRootState) => state.contractSettlement.balanceStatistics.settlementInfo

// to get a list of possible options in select input
export const selectActionTypeOptions = (state: IRootState) => {
  const optionsSet = new Set<ContractActionType>()
  const actions = selectSettleActions(state)
  for (const action of actions) {
    optionsSet.add(action.type)
  }
  return Array.from(optionsSet)
}

export const selectSettlementActionFilters = (state: IRootState) => state.contractSettlement.actionFilters

export const selectActionsByType = createSelector([selectSettlementActionFilters], (filters) => filters.type)
export const selectActionsBySharedComments = createSelector(
  [selectSettlementActionFilters],
  (filters) => filters.sharedComments,
)
export const selectActionsByUnsharedComments = createSelector(
  [selectSettlementActionFilters],
  (filters) => filters.unsharedComments,
)

export const selectFilteredSettlementActions = createSelector(
  [selectSettleActions, selectActionsByType, selectActionsBySharedComments, selectActionsByUnsharedComments],
  (actions, types, shared, unshared) => {
    const actionsByTypes = types.length ? actions.filter((a) => types.includes(a.type)) : actions
    const actionsByComments =
      shared !== unshared ? actionsByTypes.filter((a) => (shared ? a.shareComment : !a.shareComment)) : actionsByTypes
    return actionsByComments.sort((a, b) => moment(b.created).diff(a.created))
  },
)

export const selectNumberOfFilters = (state: IRootState) => {
  const typeFiltersNum = selectActionsByType(state).length
  const shareFilter = selectActionsBySharedComments(state) ? 1 : 0
  const unsharedFilter = selectActionsByUnsharedComments(state) ? 1 : 0

  return typeFiltersNum + shareFilter + unsharedFilter
}
