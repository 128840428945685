import { Button, CardContent, FormControl, TextField } from '@material-ui/core'
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { t } from 'translations/translationFunctions'
import { Brand, FuelType, Model, ContractType, Other, ProductAlongItsContracts } from '@fragus/sam-types'
import { Panel, PanelContent, PanelHeader, PanelTitle } from 'components/Mui/Panel'
import React, { ChangeEvent, FocusEvent } from 'react'
import { createPanelStyles, theme as customTheme } from 'theme'
import isEqualWith from 'lodash.isequalwith'
import SearchField from './SearchField'
import { SearchFieldDataType } from '../../Vehicle/types'
import classNames from 'classnames'
import { REQUIRED_VIN_LENGTH } from 'utils/regex'
import { Card } from '../../../../..//Mui/Card'
import moment, { Moment } from 'moment'
import DatePicker from '../../../../../DatePicker'
import { RemoveSharp as ReduceIcon, Add as AddIcon } from '@material-ui/icons'
import { SvgIconProps } from '@material-ui/core/SvgIcon'
import { getItemSerialNumberLookup } from 'api/api'
import errors from 'utils/notify/errors'
import notify from 'utils/notify/notify'

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    filler: {
      paddingRight: '50px',
    },
    date: {
      marginLeft: 0,
      marginRight: 0,
    },
    icon: {
      color: theme.palette.text.secondary,
      top: -3,
    },
    noOverflow: {
      overflow: 'visible',
    },
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    shiftRight: {
      float: 'right',
    },
    smallbtn: {
      width: '5%',
      minWidth: '30px',
    },
    iconbtn: {
      position: 'absolute',
      bottom: '1vh',
      transition: 'bottom 200ms',
      right: '0.5%',
      zIndex: 10,
    },
    relative: {
      position: 'relative',
    },
    shiftDown: {
      transition: 'bottom 200ms',
      bottom: '-4.5vh',
    },
    shortItem: {
      marginTop: '5%',
    },
  })

type SearchErrors = Record<Partial<InputTypes>, string | undefined>

type InputTypes = SearchFieldDataType | 'serialNumber'

interface IOwnProps {
  productAlongItsContracts: ProductAlongItsContracts
  contractType: ContractType
  fuelTypes?: FuelType[]
  freeContract: boolean
  onChange: (product: Other, valid: boolean) => void
  onTemplatesLookup: (product: Other) => void
  onFormReset: () => void
  resetIcon: React.ComponentType<SvgIconProps>
  showHeader: boolean
  freeContractActiveClass: string
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  brandInput: string
  activeBrand?: Brand
  brands: Brand[]
  modelInput: string
  activeModel?: Model
  models: Model[]
  fuelTypeInput: string
  fuelTypes: FuelType[]
  activeFuelType?: FuelType
  touched: Record<InputTypes, boolean>
  dateFocused: boolean
  haveLookedUpTemplate: boolean
  brandRef: React.Ref<any>
  showSecondItemNr: boolean
  showSecondSerialNr: boolean
  oldValues: {
    itemNumber: string
    itemNumber2: string | undefined
    serialNumber: string
    serialNumber2: string | undefined
  }
}

const minYear: number = 1950
const maxYear: number = moment().year() + 1

class ContractFlowProductForm extends React.Component<TProps, IState> {
  constructor(props: TProps) {
    super(props)
    const product: Other = props.productAlongItsContracts as Other
    this.state = {
      brandInput: product.brand.name,
      brands: [],
      modelInput: product.model.name,
      models: [],
      fuelTypeInput: (product.fuelType && product.fuelType.name) || '',
      fuelTypes: [],
      touched: {
        brand: false,
        model: false,
        fuelType: false,
        serialNumber: false,
      },
      dateFocused: false,
      haveLookedUpTemplate: false,
      oldValues: { itemNumber: '', itemNumber2: undefined, serialNumber: '', serialNumber2: undefined },
      brandRef: React.createRef(),
      showSecondItemNr: false,
      showSecondSerialNr: false,
    }
  }

  public componentDidUpdate(prevProps: TProps) {
    const { activeBrand, brands } = this.state
    const self = this
    const prevProduct = prevProps.productAlongItsContracts

    const currProduct = this.props.productAlongItsContracts
    if (brands.length > 0) {
      // Check that active brand actually has a valid name
      if (!currProduct.brand.name && activeBrand && activeBrand.name) {
        self.handleChange({
          ...currProduct,
          brand: prevProduct.brand && activeBrand && activeBrand.name ? activeBrand : { name: '' },
          model: { name: '' },
          fuelType: { name: '' },
          regDate: undefined,
        })
      }
    }
  }

  public render() {
    const {
      handleBlur,
      handleFuelTypeChange,
      fuelTypeInputChange,
      handleClearForm,
      handleModelYearChange,
      handleChangeItemNumber,
      handleChangeSecondItemNumber,
      handleChangeSerialNumber,
      handleChangeSecondSerialNumber,
      handleChangeProductBrand,
      handleChangeProductModel,
      handleChangeUnitName,
    } = this
    const {
      classes,
      productAlongItsContracts,
      contractType,
      fuelTypes,
      freeContract,
      showHeader,
      freeContractActiveClass,
    } = this.props
    const { fuelTypeInput, showSecondItemNr, showSecondSerialNr } = this.state
    const errors = this.getErrorMessages()

    let inputCaption = fuelTypeInput

    let selectedFueltype =
      fuelTypes && fuelTypes.includes((f: FuelType) => f.name === inputCaption) ? fuelTypeInput : ''

    if (fuelTypes) {
      for (const fuelType of fuelTypes) {
        if (fuelTypeInput === fuelType.name) {
          // Get possible attached value (caption/"label").
          const item: any = fuelType
          inputCaption = !item.caption ? '' : item.caption
        }
      }
    }

    return (
      <Panel>
        {showHeader && (
          <PanelHeader>
            <PanelTitle>{t('Product')}</PanelTitle>
          </PanelHeader>
        )}
        <PanelContent>
          <Card
            className={classNames(
              classes.topItem,
              classes.cardActive,
              classes.noOverflow,
              freeContract && freeContractActiveClass,
            )}
            data-e2e="ContractFlowOtherForm"
          >
            <CardContent>
              {contractType === 'CUSTOM' && (
                <React.Fragment>
                  <Button
                    className={classNames(classes.shiftRight, classes.smallbtn)}
                    type="reset"
                    title={t('Clear form')}
                    onClick={handleClearForm}
                  >
                    <this.props.resetIcon className={classes.btnIcon} />
                  </Button>
                  <div className={classNames(classes.relative, classes.shortItem, classes.paddingLeft)}>
                    <TextField
                      className={classNames(classes.relative)}
                      label={t('Item Number')}
                      margin="normal"
                      value={productAlongItsContracts.itemNumber}
                      fullWidth={true}
                      data-e2e={'ContractFlowProductForm__itemnum'}
                      // tslint:disable-next-line jsx-no-lambda
                      onChange={handleChangeItemNumber}
                      onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        if (e.target.value.length > 0) {
                          this.searchItemAndSerialNumber({
                            ...productAlongItsContracts,
                            itemNumber: e.target.value.trim().toUpperCase(),
                          })
                        }
                      }}
                      inputProps={{
                        maxLength: 255,
                      }}
                    />
                    <Button
                      tabIndex={50}
                      className={classNames(
                        classes.shiftRight,
                        classes.smallbtn,
                        classes.iconbtn,
                        showSecondItemNr && classes.shiftDown,
                      )}
                      type="button"
                      title={showSecondItemNr ? t('Hide second item number row') : t('Show second item number row')}
                      onClick={() => {
                        this.setState({ showSecondItemNr: !showSecondItemNr })
                      }}
                    >
                      {showSecondItemNr ? (
                        <ReduceIcon className={classes.btnIcon} />
                      ) : (
                        <AddIcon className={classes.btnIcon} />
                      )}
                    </Button>
                  </div>

                  {showSecondItemNr && (
                    <TextField
                      label={t('Secondary Item Number')}
                      margin="dense"
                      value={productAlongItsContracts.itemNumber2 || ''}
                      fullWidth={true}
                      data-e2e={'ContractFlowProductForm__itemnum2'}
                      // tslint:disable-next-line jsx-no-lambda
                      onChange={handleChangeSecondItemNumber}
                      onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        if (e.target.value.length > 0) {
                          this.searchItemAndSerialNumber({
                            ...productAlongItsContracts,
                            itemNumber2: e.target.value.trim().toUpperCase(),
                          })
                        }
                      }}
                    />
                  )}
                  <div className={classNames(classes.relative, classes.paddingLeft)}>
                    <TextField
                      label={t('Serial Number')}
                      margin="dense"
                      value={productAlongItsContracts.serialNumber}
                      fullWidth={true}
                      error={errors.serialNumber ? true : false}
                      helperText={errors.serialNumber}
                      data-e2e={'ContractFlowProductForm__serialnum'}
                      // tslint:disable-next-line jsx-no-lambda
                      onChange={handleChangeSerialNumber}
                      onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        if (e.target.value.length > 0) {
                          this.searchItemAndSerialNumber({
                            ...productAlongItsContracts,
                            serialNumber: e.target.value.trim().toUpperCase(),
                          })
                        }
                      }}
                    />

                    <Button
                      tabIndex={51}
                      className={classNames(
                        classes.shiftRight,
                        classes.smallbtn,
                        classes.iconbtn,
                        showSecondSerialNr && classes.shiftDown,
                      )}
                      type="button"
                      title={
                        showSecondSerialNr ? t('Hide second serial number row') : t('Show second serial number row')
                      }
                      onClick={() => {
                        this.setState({ showSecondSerialNr: !showSecondSerialNr })
                      }}
                    >
                      {showSecondSerialNr ? (
                        <ReduceIcon className={classes.btnIcon} />
                      ) : (
                        <AddIcon className={classes.btnIcon} />
                      )}
                    </Button>
                  </div>

                  {showSecondSerialNr && (
                    <TextField
                      label={t('Secondary Serial Number')}
                      margin="dense"
                      value={productAlongItsContracts.serialNumber2 || ''}
                      fullWidth={true}
                      data-e2e={'ContractFlowProductForm__serialnum2'}
                      // tslint:disable-next-line jsx-no-lambda
                      onChange={handleChangeSecondSerialNumber}
                      onBlur={(e: FocusEvent<HTMLInputElement>) => {
                        if (e.target.value.length > 0) {
                          this.searchItemAndSerialNumber({
                            ...productAlongItsContracts,
                            serialNumber2: e.target.value.trim().toUpperCase(),
                          })
                        }
                      }}
                    />
                  )}
                </React.Fragment>
              )}
              <TextField
                label={t('Product Brand')}
                margin="dense"
                value={productAlongItsContracts.brand.name}
                fullWidth={true}
                data-e2e={'ContractFlowProductForm__brandName'}
                // tslint:disable-next-line jsx-no-lambda
                onChange={handleChangeProductBrand}
              />
              <TextField
                label={t('Product Model')}
                margin="dense"
                value={productAlongItsContracts.model.name}
                fullWidth={true}
                data-e2e={'ContractFlowProductForm__modelName'}
                // tslint:disable-next-line jsx-no-lambda
                onChange={handleChangeProductModel}
              />
              <TextField
                label={t('Unit Name')}
                margin="dense"
                value={productAlongItsContracts.typeName}
                fullWidth={true}
                data-e2e={'ContractFlowProductForm__typeName'}
                // tslint:disable-next-line jsx-no-lambda
                onChange={handleChangeUnitName}
              />
              <SearchField
                type={'fuelType'}
                inputLabel={t('Energy source')}
                inputValue={fuelTypeInput}
                inputCaption={inputCaption}
                options={fuelTypes}
                onChange={handleFuelTypeChange}
                onInputValueChange={fuelTypeInputChange}
                // tslint:disable-next-line:jsx-no-lambda
                itemToString={() => fuelTypeInput}
                selectedItem={selectedFueltype as any}
                errorMessage={errors.fuelType}
                skipFiltering={true}
                data-e2e={'ContractFlowProductForm__fuelType'}
                onBlur={handleBlur}
                // onClear={() => this.fuelTypeInputChange('')}
                onClear={fuelTypeInputChange}
              />
              <FormControl fullWidth={true} margin="dense" data-e2e={'ContractFlowProductForm__modelYear'}>
                <TextField
                  label={t('Model Year')}
                  margin="dense"
                  value={productAlongItsContracts.modelYear}
                  fullWidth={true}
                  data-e2e={'ContractFlowProductForm__modelYear'}
                  type="number"
                  InputProps={{ inputProps: { min: minYear, max: maxYear } }}
                  // tslint:disable-next-line jsx-no-lambda
                  onChange={handleModelYearChange}
                />
              </FormControl>
              <FormControl fullWidth={true} margin="dense" data-e2e={'ContractFlowProductForm__datePicker'}>
                <DatePicker
                  id="contract-flow-regdate-date-picker"
                  date={productAlongItsContracts.regDate ? moment(productAlongItsContracts.regDate) : null}
                  data-e2e={'ContractFlowVehicleForm__datePicker'}
                  onDateChange={this.onDateChange}
                  showLabel={true}
                  labelText={t('First Registration Date')}
                />
              </FormControl>
            </CardContent>
          </Card>
        </PanelContent>
      </Panel>
    )
  }

  private handleClearForm = () => {
    this.props.onFormReset()
    this.handleClearBrand()
  }

  private handleClearBrand = () => {
    this.setState({
      activeBrand: { name: '' },
      brandInput: '',
      activeModel: { name: '' },
      modelInput: '',
      activeFuelType: { name: '' },
      fuelTypeInput: '',
      haveLookedUpTemplate: false,
    })
  }

  private handleModelYearChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = Number(event.target.value)
    const { productAlongItsContracts } = this.props
    const newProduct = { ...productAlongItsContracts, modelYear: val || productAlongItsContracts.modelYear }
    this.handleChange(newProduct)
  }

  public onDateChange = (val: Moment | null): void => {
    const { productAlongItsContracts } = this.props
    const newProduct = { ...productAlongItsContracts, regDate: val ? val.toDate().toISOString() : undefined }
    this.handleChange(newProduct)
  }

  private handleBlur = (fieldType: InputTypes) => {
    const touched = { ...this.state.touched }
    touched[fieldType] = true
    this.setState({ touched })
  }

  private handleFuelTypeChangeLocal = (fuelType: FuelType, fn?: () => void) => {
    const { activeFuelType } = this.state

    if (fuelType && (!activeFuelType || fuelType.name !== activeFuelType.name)) {
      this.setState(
        {
          fuelTypeInput: fuelType.name,
          activeFuelType: fuelType,
        },
        () => {
          fn && fn()
        },
      )
    }
  }

  private handleFuelTypeChange = (newFuelType: any | null) => {
    const self = this
    const { productAlongItsContracts } = this.props
    const { fuelTypes } = this.props
    let fuelType: any

    if (!fuelTypes) {
      return
    }

    if (newFuelType === null) {
      fuelType = { name: '', caption: '' }
    } else {
      fuelType = newFuelType
    }

    for (const item of fuelTypes) {
      const f1: any = item
      if (fuelType.caption === f1.caption) {
        fuelType.name = f1.name
        fuelType.id = f1.id
      }
    }

    this.handleFuelTypeChangeLocal(fuelType, () => {
      self.handleChange({
        ...productAlongItsContracts,
        fuelType: fuelType,
      })
    })
  }

  private fuelTypeInputChange = (val: string = '') => {
    this.handleFuelTypeChange({ caption: val, name: val })
  }

  private getErrorMessages = () => {
    const errors: SearchErrors = { brand: undefined, model: undefined, fuelType: undefined, serialNumber: undefined }
    const { contractType /*vehicle */ } = this.props
    const product: Other = this.props.productAlongItsContracts as Other
    const { touched } = this.state

    if (contractType === 'CUSTOM') {
      if (touched.serialNumber && (!product.itemNumber || product.itemNumber.length !== REQUIRED_VIN_LENGTH)) {
        errors.serialNumber = 'Invalid serial number'
      }

      return errors
    }

    if (touched.brand && !product.brand.id) {
      errors.brand = t('You must select a supported brand')
    }
    if (touched.model && !product.model.id) {
      errors.model = t('You must select a supported model')
    }

    return errors
  }

  private searchItemAndSerialNumber = async (product: ProductAlongItsContracts) => {
    if (!product || (!product.itemNumber && !product.itemNumber2 && !product.serialNumber && !product.serialNumber2)) {
      this.handleChange(product)
      console.warn('Bad data')
      return
    }

    const { itemNumber, itemNumber2, serialNumber, serialNumber2 } = product
    const { oldValues } = this.state

    if (
      itemNumber &&
      serialNumber &&
      (oldValues.itemNumber !== itemNumber ||
        oldValues.itemNumber2 !== itemNumber2 ||
        oldValues.serialNumber !== serialNumber ||
        oldValues.serialNumber2 !== serialNumber2)
    ) {
      this.setState({ oldValues: { itemNumber, itemNumber2, serialNumber, serialNumber2 } })
      const result = await getItemSerialNumberLookup({ itemNumber, itemNumber2, serialNumber, serialNumber2 })

      if (result.errorData) {
        const errorMessage = result.errorData.message
        const localizedErrorMessage = (errorMessage && errors[errorMessage] && t(errors[errorMessage])) || errorMessage

        if (errorMessage === 'ITEM_SERIALNO_ACTIVE_CONTRACT_EXISTS') {
          notify.warning({ message: localizedErrorMessage })
        }
      }
    }
  }

  private checkValid = (product: Other) => {
    const baseValidation = !!(product.regDate && product.brand.name && product.model.name)

    const validSerialNum = product.serialNumber && product.serialNumber.length > 1 ? true : false
    const validItemNum = product.itemNumber && product.itemNumber.length > 1 ? true : false
    return baseValidation && validSerialNum && validItemNum
  }

  private handleChange = (productAlongItsContracts: ProductAlongItsContracts) => {
    const valid = this.checkValid(productAlongItsContracts)
    if (
      productAlongItsContracts.regDate &&
      productAlongItsContracts.brand.name &&
      productAlongItsContracts.model.name
    ) {
      // Only do a lookup if relevant info has changed
      // Scope hax are required to remove vin/regNumber from vehicle objects
      let product1: Partial<Other>
      let product2: Partial<Other>
      {
        const { serialNumber, itemNumber, ...oldProduct } = { ...this.props.productAlongItsContracts }
        product1 = oldProduct
      }
      {
        const { serialNumber, itemNumber, ...newProduct } = { ...productAlongItsContracts }
        product2 = newProduct
      }

      // only lookup if values acually changed
      if (valid && !isEqualWith(product1, product2)) {
        this.setState({ haveLookedUpTemplate: true })
        // this.props.onTemplatesLookup(productAlongItsContracts)
      }
    }

    this.props.onChange(productAlongItsContracts, valid)
  }

  private handleChangeItemNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const { productAlongItsContracts } = this.props

    this.handleChange({
      ...productAlongItsContracts,
      itemNumber: e.target.value.trim().toUpperCase(),
    })
  }

  private handleChangeSecondItemNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const { productAlongItsContracts } = this.props

    this.handleChange({
      ...productAlongItsContracts,
      itemNumber2: e.target.value.trim().toUpperCase(),
    })
  }

  private handleChangeSerialNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const { productAlongItsContracts } = this.props

    this.handleChange({
      ...productAlongItsContracts,
      serialNumber: e.target.value.trim().toUpperCase(),
    })
  }

  private handleChangeSecondSerialNumber = (e: ChangeEvent<HTMLInputElement>) => {
    const { productAlongItsContracts } = this.props

    this.handleChange({
      ...productAlongItsContracts,
      serialNumber2: e.target.value.trim().toUpperCase(),
    })
  }

  private handleChangeProductBrand = (e: ChangeEvent<HTMLInputElement>) => {
    const { productAlongItsContracts } = this.props

    this.handleChange({
      ...productAlongItsContracts,
      brand: { name: e.target.value },
    })
  }

  private handleChangeProductModel = (e: ChangeEvent<HTMLInputElement>) => {
    const { productAlongItsContracts } = this.props

    this.handleChange({
      ...productAlongItsContracts,
      model: { name: e.target.value },
    })
  }

  private handleChangeUnitName = (e: ChangeEvent<HTMLInputElement>) => {
    const { productAlongItsContracts } = this.props

    this.handleChange({
      ...productAlongItsContracts,
      typeName: e.target.value,
    })
  }
}

export default withStyles(styles)(ContractFlowProductForm)
