import { CardContent, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles'
import { ContractValueType, DurationOptions, IContractTemplateResponse } from '@fragus/sam-types'
import classNames from 'classnames'
import React, { ChangeEvent } from 'react'
import { TranslationKey } from 'translations/translationTypes'
import { valueTypeTranslations } from 'translations/ValueTypeTranslations'
import { Card } from '../../../../../components/Mui/Card'
import { Panel, PanelContent, PanelHeader, PanelTitle } from '../../../../../components/Mui/Panel'
import { createPanelStyles, theme as customTheme } from '../../../../../theme'
import { t } from '../../../../../translations/translationFunctions'
import { ContractFlowActivePanel, IContractFlowMileageDuration } from '../../../../../types/contractFlow'
import { getClosestAllowedValue, getMileageList, getMileageValues } from '../../../../../utils/durationMileage'
import { zeroOrNumber } from '../../../../../utils/regex'

export interface IDurationHourServiceValues {
  duration: IContractFlowMileageDuration
  selectedValue: IContractFlowMileageDuration | undefined
  valueType: ContractValueType
}

interface IOwnProps {
  active: ContractFlowActivePanel
  activeEnum: ContractFlowActivePanel
  durations: DurationOptions[]
  freeContract: boolean
  template: IContractTemplateResponse | undefined
  value: IDurationHourServiceValues

  onChange: (values: IDurationHourServiceValues, valid: boolean, clearedValueType: boolean) => void
}

const highServiceWarningLimit = 100
const highMilegaWarningLimit = 299999

type TProps = WithStyles<typeof styles> & IOwnProps

interface IState {
  clearedValueType: boolean
}

const defaultState: IState = {
  clearedValueType: false,
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    freeContractActive: {
      borderLeftColor: customTheme.palette.freeContract[500],
    },
    textField: {
      width: '70%',
      minWidth: '180px',
    },
    dropDown: {
      width: '100%',
      heigth: '100%',
    },
    oneThirdWidth: {
      marginTop: '0.34em',
      width: '30%',
      minWidth: '100px',
    },
    highMileageWarning: {
      color: customTheme.palette.context.attention[700],
    },
    highMileageWarningInput: {
      color: customTheme.palette.context.attention[700],
      '&:before': {
        borderBottomColor: customTheme.palette.context.attention[700],
      },
      '&:after': {
        borderBottomColor: customTheme.palette.context.attention[700],
      },
      '&:hover:before': {
        borderBottomColor: `${customTheme.palette.context.attention[700]} !important`,
      },
    },
  })

class ContractFlowDurationHourService extends React.Component<TProps, IState> {
  public constructor(props: TProps) {
    super(props)
    this.state = defaultState
  }

  public render() {
    const { classes, freeContract, value } = this.props
    const { duration, selectedValue, valueType } = value
    const { handleSelectedValueChange, handleDurationChange } = this
    const highServiceWarning = this.highServiceWarning()
    const highMileageWarning = this.highMileageWarning()

    const isActive = this.isActive()
    const valueLabel = this.getValueLabel(valueType)
    const menuItems: { key: TranslationKey; value?: ContractValueType }[] = [
      { key: 'Annual services', value: 'Services' },
      { key: 'Hours' },
      { key: 'Mileage' },
    ]
    const noneValueType: TranslationKey = 'None'

    return (
      <React.Fragment>
        <Panel disabled={!isActive}>
          <PanelHeader>
            <PanelTitle>{t('Duration')}</PanelTitle>
          </PanelHeader>
          <PanelContent>
            <Card
              className={isActive ? classNames(classes.cardActive, freeContract && classes.freeContractActive) : ''}
              data-e2e={'ContractFlowDurationMileage'}
            >
              <CardContent>
                <TextField
                  fullWidth={true}
                  disabled={!isActive}
                  value={duration ? duration.value || '' : ''}
                  margin="dense"
                  label={t('Select duration (months)')}
                  data-e2e={'ContractFlowDurationMileage__freeContract-duration'}
                  // tslint:disable-next-line:jsx-no-lambda
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length <= 10) {
                      handleDurationChange(zeroOrNumber(e.target.value))
                    }
                  }}
                  InputProps={{
                    inputProps: { min: 0 },
                  }}
                  type="number"
                />
                <React.Fragment>
                  <FormControl className={classes.oneThirdWidth}>
                    <InputLabel>{t('Value type')}</InputLabel>
                    <Select
                      fullWidth={false}
                      className={classNames()}
                      value={valueType || ''}
                      onChange={(e) => this.handleValueTypeChange(e.target.value as ContractValueType)}
                    >
                      {menuItems.map((m) => {
                        return (
                          <MenuItem key={`DurationValueType-${m.key}`} value={m.value || m.key}>
                            {t(m.key)}
                          </MenuItem>
                        )
                      })}
                      <MenuItem value={undefined}>{t(noneValueType)}</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    className={classes.textField}
                    fullWidth={false}
                    disabled={!isActive || (!duration && !selectedValue)}
                    value={selectedValue && selectedValue.value >= 0 ? selectedValue.value : ''}
                    margin="dense"
                    InputProps={{
                      classes: {
                        underline: highServiceWarning ? classes.highMileageWarningInput : '',
                      },
                      inputProps: { min: -1 },
                    }}
                    label={valueLabel || t('Value')}
                    data-e2e={'ContractFlowDurationMileage__freeContract-mileage'}
                    // tslint:disable-next-line:jsx-no-lambda
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length <= 10) {
                        handleSelectedValueChange(zeroOrNumber(e.target.value))
                      }
                    }}
                    type="number"
                  />
                  {valueType === 'Services' && highServiceWarning && (
                    <div className={classes.highMileageWarning}>
                      {t('Please note that you have chosen a very high service allowance')}
                    </div>
                  )}
                  {valueType === 'Mileage' && highMileageWarning && (
                    <div className={classes.highMileageWarning}>
                      {t('Please note that you have chosen a very high mileage')}
                    </div>
                  )}
                </React.Fragment>
              </CardContent>
            </Card>
          </PanelContent>
        </Panel>
      </React.Fragment>
    )
  }

  private getValueLabel = (valueType: ContractValueType) => {
    if (valueType) {
      return t(valueTypeTranslations[valueType].selectTotalValue)
    }

    return ''
  }

  private handleValueTypeChange = (valueType: ContractValueType) => {
    const { value } = this.props
    const newValues = {
      ...value,
      valueType: valueType || undefined,
      selectedValue: valueType ? value.selectedValue : undefined,
    }

    if (this.state.clearedValueType !== !valueType) {
      this.setState({ clearedValueType: !this.state.clearedValueType }, () => this.handleValueChange(newValues))
      return
    }

    this.handleValueChange(newValues)
  }

  private isActive = (): boolean => {
    const { freeContract, active, value, activeEnum } = this.props
    const { duration, selectedValue } = value
    if (freeContract) {
      return active >= activeEnum
    } else {
      return duration.max > 0 || !!(selectedValue && selectedValue.max > 0)
    }
  }

  private handleDurationChange = (value: number) => {
    const { props } = this
    const { freeContract } = props

    const mileageList = getMileageList(props.durations, value, props.template)
    const mileage = getMileageValues(mileageList)

    const values = {
      ...props.value,
      duration: {
        ...props.value.duration,
        value,
        wantedValue: value,
      },
    }

    if (!freeContract && props.value.selectedValue) {
      const realValue =
        props.value.selectedValue.value > 0
          ? props.value.selectedValue.value
          : getClosestAllowedValue(props.value.selectedValue.value, mileageList)

      values.selectedValue = {
        ...mileage,
        value: realValue,
        wantedValue: props.value.selectedValue.wantedValue,
      }
    }

    this.handleValueChange(values)
  }

  private handleSelectedValueChange = (value: number) => {
    const { props } = this

    const values = {
      ...props.value,
      selectedValue: {
        ...(props.value.selectedValue || { min: 0, max: 0, wantedMax: 0, step: 0 }),
        value,
        wantedValue: value,
      },
    }

    this.handleValueChange(values)
  }

  private highServiceWarning = () => {
    const { value } = this.props
    return value.selectedValue && value.valueType === 'Services' && value.selectedValue.value > highServiceWarningLimit
  }

  private handleValueChange = (values: IDurationHourServiceValues) => {
    const isValid = this.checkValid({
      duration: values.duration.value,
      selectedValue: values.selectedValue ? values.selectedValue.value : undefined,
      valueType: values.valueType,
    })

    this.props.onChange(values, isValid, this.state.clearedValueType)
  }

  private checkValid = (
    values: { duration?: number; selectedValue?: number; valueType?: ContractValueType } = {
      duration: this.props.value.duration.value,
      selectedValue: this.props.value.selectedValue && this.props.value.selectedValue.value,
      valueType: this.props.value.valueType,
    },
  ) => {
    let isValid = true

    const duration = values.duration === undefined ? this.props.value.duration.value : values.duration
    const selectedValue =
      values.selectedValue === undefined
        ? (this.props.value.selectedValue && this.props.value.selectedValue.value) || undefined
        : values.selectedValue
    const valueType = values.valueType || this.props.value.valueType

    if (!duration || (!selectedValue && valueType) || (selectedValue && !valueType)) {
      isValid = false
    }

    return isValid
  }

  private highMileageWarning = () => {
    const { value } = this.props
    return value.selectedValue && value.selectedValue.value > highMilegaWarningLimit
  }
}

export default withStyles(styles)(ContractFlowDurationHourService)
