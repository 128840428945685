import { tUntyped } from '@omnicar/sam-translate'
import { PaymentFailureReason } from '@fragus/sam-types'
import Tooltip from 'components/admin/Contract/Details/Tooltip'
import React from 'react'
import { t } from 'translations/translationFunctions'
import { getPaymentFailureReasonDescription } from 'utils/contract'
import { ISuspendReasonProps, withSuspendReason } from './withSuspendReason'

const SuspendTooltip: React.SFC<ISuspendReasonProps> = ({ reason, nextAttempt }) => {
  const additionalSections = [
    { title: t('Stripe reason'), value: reason.stripeDeclineReason },
    { title: t('Description'), value: getPaymentFailureReasonDescription(reason.paymentFailureReason) },
  ]

  nextAttempt && additionalSections.push({ title: t('Next attempt'), value: nextAttempt })

  return (
    <Tooltip
      title={t('Why?')}
      infoSections={[
        {
          title: t('Reason'),
          value: tUntyped(PaymentFailureReason[reason.paymentFailureReason]),
        },
        ...additionalSections,
      ]}
      showAsPopup
    />
  )
}

export default withSuspendReason(SuspendTooltip)
