import {
  ContractValueType,
  IAdminContractAdjustmentResponse,
  IAdminContractOfferCopyResponse,
  IContractOptionResponse,
  TContractObject,
  Vehicle,
  VehicleAlongItsContracts,
  VehiclePartial,
} from '@fragus/sam-types'
import pick from 'lodash.pick'
import {
  ContractFlowActivePanel,
  ContractFlowActiveStep,
  IContractFlow,
  IContractFlowMileageDuration,
  ICustomPrice,
} from 'types/contractFlow'
import { getDurationList, getDurationValues, getMileageList, getMileageValues } from 'utils/durationMileage'

export function mapContractOfferCopy(
  contract: IAdminContractOfferCopyResponse,
  payments: ICustomPrice,
): Partial<IContractFlow> {
  const { options, duration, mileage, value } = prepareContractForContractFlow(contract)
  const copy: Partial<IContractFlow> = {
    flowType: 'CREATE',
    contractType: contract.type,
    product: contract.product,
    contractObjectType: 'vin' in contract.product ? 'Vehicle' : 'Product',
    vehicleLookedUp: ('vin' in contract.product && mapVehicleToVehiclePartial(contract.product)) || undefined,
    vehicleAlongItsContracts: contract.product as VehicleAlongItsContracts,
    isCopiedContract: true,
    paymentGateway: 'Stripe',
    v4SupportedPaymentTypes: ['CustomerSubscription'],
    template: contract.contractTemplate,
    options,
    startMileage: contract.startMileage,
    startValue: contract.startValue,
    startValueType: contract.startValueType,
    customer: { ...contract.customer, id: contract.customerId },
    value: value,
    valueType: contract.valueType,
    duration: {
      ...duration,
      value: contract.duration,
      wantedValue: contract.duration,
    },
    mileage: {
      ...mileage,
      value: contract.mileage,
      wantedValue: contract.mileage,
    },
    state: {
      templates: contract.availableTemplatesExtension
        ? contract.availableTemplatesExtension
        : [contract.contractTemplate],
      options: contract.additionalOptions,
      durations: contract.availableDurationsExtension ? contract.availableDurationsExtension : contract.durations,
      mileageDurationsMap: contract.mileageDurationsMap,
      licenseLookup: true,
      licenseSkip: false,
      customerSearched: true,
      active: ContractFlowActivePanel.options,
      step: ContractFlowActiveStep.details,
      startMileage: false,
      valid: { customer: true },
      isMileageDisabled:
        'vin' in contract.product && mapVehicleToVehiclePartial(contract.product)?.vehicleType === 'Caravan'
          ? true
          : false,
      originalEnginePowerKWFromLookup: (contract.product as Vehicle).cylinderVolume || null,
    },
    reference: contract.reference,
    customTerms: contract.customTerms,
    customMessage: '',
    payments: {
      ...payments,
      ...contract.payments,
    },
  }

  return copy
}

function mapVehicleToVehiclePartial(v: Vehicle): VehiclePartial {
  return pick({ ...v, vin: v.vin || '' }, [
    'id',
    'vin',
    'regNumber',
    'regDate',
    'brand',
    'model',
    'fuelType',
    'modelYear',
  ])
}

export function mapAdjustContract(contract: IAdminContractAdjustmentResponse): IContractFlow {
  const { options, duration, mileage } = prepareContractForContractFlow(contract)

  let contractObjectType: TContractObject = 'Vehicle'
  let valueType: ContractValueType = 'Mileage'

  if ('itemNumber' in contract.product) {
    contractObjectType = 'Product'
    valueType = contract.valueType
  }
  return {
    flowType: 'ADJUST',
    contractType: contract.type,
    v4ProductType: null,
    v4SupportedPaymentTypes: [],
    product: contract.product,
    contractObjectType,
    vehicleAlongItsContracts: contract.product as VehicleAlongItsContracts,
    template: contract.contractTemplate,
    options,
    paymentGateway: contract.paymentGateway,
    payments: {
      ...contract.payments,
      customAmountPrPayment: contract.type === 'CUSTOM' ? contract.payments.amountPrPayment.priceInclVat : 0,
      noPayment: false,
      providerShare: { price: 0, priceInclVat: 0, vatShare: 0, currency: 'DKK' },
    }, // we have to set the providerShare to 0 as the discount from the old contract is already counted as income on the old
    startMileage: contract.startMileage,
    customer: { ...contract.customer, id: contract.customerId },
    invoiceCustomer: { ...contract.invoiceCustomer, id: contract.invoiceCustomerId },
    duration: {
      ...duration,
      value: contract.duration,
      wantedValue: contract.duration,
    },
    mileage: {
      ...mileage,
      value: contract.mileage,
      wantedValue: contract.mileage,
    },
    contractStartDateISO: contract.contractStartDateISO,
    value: { value: contract.value || 0, min: 0, max: 0, step: 0, wantedMax: 0, wantedValue: contract.value || 0 },
    valueType,
    startValueType: contract.startValueType,
    startValue: contract.startValue || 0,
    state: {
      templates: contract.availableTemplatesExtension
        ? contract.availableTemplatesExtension
        : [contract.contractTemplate],
      options: contract.additionalOptions,
      durations: contract.availableDurationsExtension ? contract.availableDurationsExtension : contract.durations,
      mileageDurationsMap: null, //contract.mileageDurationsMap,
      licenseLookup: true,
      licenseSkip: false,
      customerSearched: false,
      active: ContractFlowActivePanel.paymentGateway,
      step: ContractFlowActiveStep.details,
      startMileage: false,
      valid: { customer: true },
      isMileageDisabled:
        'vin' in contract.product && mapVehicleToVehiclePartial(contract.product)?.vehicleType === 'Caravan'
          ? true
          : false,
      originalEnginePowerKWFromLookup: (contract.product as Vehicle).cylinderVolume || null,
    },
    reference: '',
    customTerms: '',
    customMessage: '',
  }
}

function prepareContractForContractFlow(
  contract: IAdminContractAdjustmentResponse | IAdminContractOfferCopyResponse,
): {
  options: IContractOptionResponse[]
  duration: IContractFlowMileageDuration
  mileage: IContractFlowMileageDuration
  value: IContractFlowMileageDuration | undefined
} {
  const removedOptions: IContractOptionResponse[] = []

  // We have to intervene here and run the options through since we
  // want the default status for free options to be selected
  const options = contract.additionalOptions.filter((opt) => opt.price.price === 0)
  contract.includedAdditionalOptions.forEach((option) => {
    const foundOption = contract.additionalOptions.find((includedOption) => includedOption.id === option.id)

    if (foundOption) {
      // Avoid duplicates
      if (!options.find((opt) => opt.id === foundOption.id)) {
        options.push(option)
      }
    } else {
      // @TODO - In future we need this array to show those options
      // that no longer exist
      removedOptions.push(option)
    }
  })

  const durationList = getDurationList(contract.durations, contract.contractTemplate)
  const duration = getDurationValues(durationList)
  const mileageList = getMileageList(contract.durations, duration.value, contract.contractTemplate)
  const mileage = getMileageValues(mileageList)
  const value =
    contract.value !== undefined && contract.value !== null
      ? { value: contract.value || 0, min: 0, max: 0, step: 0, wantedValue: contract.value || 0, wantedMax: 0 }
      : undefined

  return { options, duration, mileage, value }
}

export const checkIfOptionsHasWarranty = (options: IContractFlow['options'] | undefined): boolean =>
  !!options?.some((option) => option.warranty)
