import React from 'react'
import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles'
import { Button, Paper } from '@material-ui/core'
import Typography from 'components/Typography'
import classNames from 'classnames'
import {
  IContractProviderInfo,
  ContractProviderOrderBy as TContractProviderOrderBy,
} from '@fragus/sam-types/types/admin/contractProvider'
import ReactTable, { Column, SortingRule } from 'react-table'
import { t } from 'translations/translationFunctions'
import { TranslationKey } from 'translations/translationTypes'
import TableLoadingIndicator from 'components/TableLoadingIndicator'
import {
  createQueryFromTable,
  defaultTableState,
  ITableState,
  ITableQuery,
  createTableStateFromPagination,
} from 'utils/react-table'
import { ReactNode } from 'react-redux'
import { exportSubProviderlistFileUrl, getSubProviders } from 'api/api'
import { LayoutActions, LayoutActionsRight } from 'components/Mui/Layout'
import { Input as DownloadIcon } from '@material-ui/icons'

interface IQuery extends ITableQuery<TContractProviderOrderBy> {}

interface IOwnProps {
  mainProviderId: number
}

type TProps = IOwnProps & WithStyles<typeof styles>

const styles = ({ breakpoints, spacing }: Theme) =>
  createStyles({
    tableWrapper: {
      width: '100%',
      [breakpoints.down('md')]: {
        paddingTop: '20px',
      },
    },
    subProviderTable: {
      maxHeight: '270px',
      maxWidth: '100%',
    },
    header: {
      marginBottom: '8px',
    },
    icon: {
      marginRight: spacing(1),
      fontSize: 14,
    },
    btnExport: {
      marginBottom: spacing(1),
    },
  })

export const headerKeys: {
  [key: string]: { tran: TranslationKey; replacer?: { key: string; replacement: string } }
} = {
  providerId: { tran: 'Provider ID' },
  administrativeName: { tran: 'Name' },
  itemNumber: { tran: 'Item Number' },
  inactive: { tran: 'State' as TranslationKey },
}

const headerText = (data: any): string | ReactNode => {
  if (data && data.column && data.column.id && typeof data.column.id === 'string' && headerKeys[data.column.id]) {
    const headerKey = headerKeys[data.column.id]
    return headerKey.replacer
      ? t(headerKey.tran, { [headerKey.replacer.key]: headerKey.replacer.replacement })
      : t(headerKey.tran)
  } else {
    return ''
  }
}

export const getColumns = (classes: Record<string, string>): Column[] => [
  {
    Header: headerText,
    accessor: 'providerId',
    headerClassName: 'e2e__header__prettyIdentifier',
  },
  {
    Header: headerText,
    accessor: 'administrativeName',
    headerClassName: 'e2e__header__administrativeName',
  },
  {
    Header: headerText,
    accessor: 'inactive',
    headerClassName: 'e2e__header__providerinactive',
    Cell: ({ value }) => <div>{value ? t('Inactive') : t('Active')}</div>,
  },
]

interface IState {
  subProviders: IContractProviderInfo[]
  subProviderColumns: Column[]
  tableState: ITableState
  tableQuery: IQuery
  loading: boolean
}

class SubProviderTable extends React.Component<TProps, IState> {
  state: Readonly<IState> = {
    subProviders: [],
    subProviderColumns: [],
    loading: false,
    tableState: {
      ...defaultTableState(50),
      sorted: [{ asc: true, id: 'providerId' }],
      resized: [],
    },
    tableQuery: {
      search: '',
      pagination: {
        limit: 10,
        offset: 0,
        orderBy: 'providerId',
        orderDirection: 'ASC',
      },
    },
  }

  componentDidMount(): void {
    this.createTableColumns()
    this.loadData()
  }

  render() {
    const { classes, mainProviderId } = this.props
    const { subProviders, subProviderColumns, tableState, loading } = this.state
    return (
      <div className={classes.tableWrapper}>
        <LayoutActions>
          <Typography variant="body1" className={classes.header}>
            {t('Sub dealers')}
          </Typography>
          <LayoutActionsRight>
            <Button
              disabled={!subProviders.length}
              color="primary"
              variant="contained"
              className={classes.btnExport}
              href={exportSubProviderlistFileUrl(mainProviderId, 'xls')}
            >
              <DownloadIcon className={classes.icon} />
              {t('Export to xls')}
            </Button>
          </LayoutActionsRight>
        </LayoutActions>
        <Paper>
          <ReactTable
            className={classNames('react-table ', classes.subProviderTable)}
            columns={subProviderColumns}
            data={subProviders}
            defaultSorted={tableState.sorted}
            loading={loading}
            LoadingComponent={TableLoadingIndicator}
            noDataText={!loading ? t('No Results Found') : ''}
            manual={true}
            minRows={3}
            sorted={[...tableState.sorted]}
            showPaginationBottom={subProviders.length > 0}
            onPageChange={this.handlePageChange}
            onPageSizeChange={this.handlePageSizeChange}
            onSortedChange={this.handleSortChange}
            page={tableState.page}
            pageSize={tableState.pageSize}
            pages={tableState.pages}
            pageText={t('Page')}
            ofText={t('of')}
            rowsText={t('rows')}
          />
        </Paper>
      </div>
    )
  }

  private createTableColumns = () => {
    const subProviderColumns = getColumns(this.props.classes)
    this.setState({ subProviderColumns })
  }

  private loadData = async () => {
    const { loading, tableQuery } = this.state
    if (!loading) {
      this.setState({ loading: true })
      const res = await getSubProviders(tableQuery)
      if (res.data) {
        const { result, pagination } = res.data
        const newTableState = createTableStateFromPagination(pagination)
        const tableState = { ...this.state.tableState, ...newTableState }

        this.setState({ subProviders: result, tableState })
      }

      this.setState({ loading: false })
    }
  }

  private handleSortChange = (sorted: SortingRule[]) => {
    const tableState: ITableState = { ...this.state.tableState, sorted }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handlePageChange = (page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }

  private handlePageSizeChange = (pageSize: number, page: number) => {
    const tableState: ITableState = { ...this.state.tableState, ...{ page, pageSize } }
    const tableQuery = createQueryFromTable(tableState, this.state.tableQuery)

    this.setState({ tableState, tableQuery }, this.loadData)
  }
}

export default withStyles(styles)(SubProviderTable)
