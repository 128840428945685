import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import { formatDate } from '@omnicar/sam-format'
import { ContractState, IChartDateValue, IChartInvoiceValue, IPaymentChart } from '@fragus/sam-types'
import Tooltip from 'components/admin/Contract/Details/EconomyOverview/ChartDateTooltip'
import Typography from 'components/Typography'
import moment from 'moment'
import React, { Component } from 'react'
import {
  DiscreteColorLegend,
  FlexibleWidthXYPlot,
  Hint,
  HorizontalGridLines,
  LineMarkSeries,
  LineMarkSeriesPoint,
  XAxis,
  YAxis,
} from 'react-vis'
import 'react-vis/dist/style.css'
import { compose } from 'recompose'
import { theme } from 'theme'
import { t } from 'translations/translationFunctions'
import InvoiceTooltip from './InvoiceTooltip'

interface IOwnProps {
  data: IPaymentChart
  contractState: ContractState
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  invoiceTooltipValue: IChartInvoiceValue | undefined
  tooltipValue: IChartDateValue | undefined
}

interface ILegendItem {
  title: string
  color: string
  strokeWidth: number
  strokeStyle?: string
  strokeDasharray?: string
  disabled?: boolean
}

interface ILegendItems extends Array<ILegendItem> {}

const styles = () =>
  createStyles({
    root: {},
    tooltip: {
      display: 'flex',
    },
    exVat: {
      textAlign: 'right',
      color: theme.palette.text.light,
      fontStyle: 'italic',
    },
    legend: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })

class PaymentsChart extends Component<TProps, IState> {
  public state: IState = {
    tooltipValue: undefined,
    invoiceTooltipValue: undefined,
  }

  public render() {
    const { classes, data, contractState } = this.props
    const { tooltipValue, invoiceTooltipValue } = this.state
    const curveName = 'curveMonotoneX'

    // transform data
    const earnings = data ? this.transform(data.seriesEarnings) : []
    const costs = data ? this.transform(data.seriesCosts) : []
    const expectedEarnings = data ? this.transform(data.seriesExpectedEarnings) : []

    const legendItems: ILegendItems = [
      {
        title: t('Payments'),
        color: theme.palette.secondary[500],
        strokeWidth: 3,
      },
      {
        title: t('Expected payments'),
        color: '#ccc',
        strokeStyle: 'dashed',
        strokeWidth: 3,
      },
      {
        title: t('Workshop costs'),
        color: '#ED7C33',
        strokeWidth: 3,
      },
    ]

    return (
      <div className={classes.root}>
        <DiscreteColorLegend items={legendItems} orientation="horizontal" />

        <FlexibleWidthXYPlot
          margin={{ left: 60 }}
          height={300}
          xType="time"
          xDomain={[new Date(moment(data.contractStartDate).startOf('month').toDate()), new Date(data.contractEndDate)]}
        >
          <YAxis />
          <XAxis tickTotal={0} />
          <HorizontalGridLines />
          <LineMarkSeries
            data={expectedEarnings}
            stroke="#ccc"
            fill="#ccc"
            strokeWidth={3}
            strokeStyle="dashed"
            size={3}
            curve={curveName}
          />
          <LineMarkSeries
            data={costs}
            stroke="#ED7C33"
            fill="#ED7C33"
            strokeWidth={3}
            size={3}
            curve={curveName}
            onValueMouseOver={(value) => {
              this.setState({ tooltipValue: value as IChartDateValue })
            }}
            onValueMouseOut={() => {
              this.setState({ tooltipValue: undefined })
            }}
          />
          <LineMarkSeries
            data={earnings}
            colorType="literal"
            lineStyle={{ stroke: theme.palette.secondary[500] }}
            strokeWidth={3}
            size={3}
            curve={curveName}
            onValueMouseOver={(value) => {
              this.setState({ invoiceTooltipValue: value as IChartInvoiceValue })
            }}
            onValueMouseOut={() => {
              this.setState({ invoiceTooltipValue: undefined })
            }}
          />
          {tooltipValue && (
            <Hint value={tooltipValue}>
              <Tooltip value={tooltipValue} variant="payment" />
            </Hint>
          )}
          {invoiceTooltipValue && (
            <Hint value={invoiceTooltipValue}>
              <InvoiceTooltip value={invoiceTooltipValue} />
            </Hint>
          )}
        </FlexibleWidthXYPlot>
        <div className={classes.legend}>
          <div>
            <Typography variant="subheading">{t('Startdate')}</Typography>
            <Typography variant="body2">
              {!data.contractStartDate ? '–' : formatDate(data.contractStartDate)}
            </Typography>
          </div>
          <div>
            <Typography variant="subheading">{t('Duration')}</Typography>
            <Typography variant="body2">{`${data.duration.current} / ${data.duration.total}`}</Typography>
          </div>

          {![ContractState.ActivePrePaid, ContractState.SettledPrePaid].includes(contractState) && (
            <div>
              <Typography variant="subheading">{t('Unpaid payments until today')}</Typography>
              <Typography variant="body2">{data.failedPaymentsToDate}</Typography>
            </div>
          )}
          <div>
            <Typography variant="subheading">{t('Enddate')}</Typography>
            <Typography variant="body2">{!data.contractEndDate ? '–' : formatDate(data.contractEndDate)}</Typography>
          </div>
        </div>
      </div>
    )
  }

  private isChartInvoiceValue = (value: IChartDateValue | IChartInvoiceValue): value is IChartInvoiceValue =>
    (value as IChartInvoiceValue).tooltipData !== undefined

  private calcPointColor = (value: IChartDateValue | IChartInvoiceValue): string =>
    this.isChartInvoiceValue(value) && value.tooltipData.state === 1
      ? theme.palette.error[500]
      : theme.palette.secondary[500]

  /**
   * Transforms x from string > Date
   */

  private transform = (list: (IChartDateValue | IChartInvoiceValue)[]): LineMarkSeriesPoint[] =>
    list.map(
      (value): LineMarkSeriesPoint => ({
        ...value,
        x: new Date(value.x),
        color: this.calcPointColor(value),
      }),
    )
}
export default compose<TProps, IOwnProps>(withStyles(styles))(PaymentsChart)
