import { withStyles, WithStyles } from '@material-ui/core/styles'
import React from 'react'
import styles from './styles'
import { Button, Dialog, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import {
  NewReleases as NewReleasesIcon,
  //Help as SupportIcon,
  ContactSupport as SupportIcon,
  Phone as PhoneIcon,
  Mail as MailIcon,
  Link as URLIcon,
  ExpandLess,
  ExpandMore,
  Policy as PolicyIcon,
  //OpenInBrowser as URLIcon,
} from '@material-ui/icons'
import browserHistory from 'utils/history'
import { releaseNotesPath } from 'routes/paths'
import classNames from 'classnames'
import Typography from 'components/Typography'
import { getContactInformation } from 'api/api'
import { IContractProviderInfo } from '@fragus/sam-types'
import { t } from 'translations/translationFunctions'
import CookiesPolicyPopup from 'components/admin/CookiesPolicy'

interface IOwnProps {
  open: boolean
  onToggle: () => void
  providerInfo: IContractProviderInfo | undefined
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  contactInfoDropDownOpen: boolean
  contactMail: string
  contactPhone: string
  contactUrl: string
  showCookiesPolicy: boolean
}

class InformationMenu extends React.Component<TProps, IState> {
  state: Readonly<IState> = {
    contactInfoDropDownOpen: false,
    contactMail: '',
    contactPhone: '',
    contactUrl: '',
    showCookiesPolicy: false,
  }

  public componentDidMount() {
    this.fetchContactInformation()
  }

  render() {
    const { onToggle, open, classes } = this.props
    const { contactInfoDropDownOpen, contactPhone, contactMail, contactUrl, showCookiesPolicy } = this.state

    return (
      <>
        <Dialog
          onClose={onToggle}
          open={open}
          classes={{ scrollPaper: classes.optionsScrollPaper, paper: classes.optionsScrollPaper }}
          style={{ padding: '0', paddingTop: '16px', left: '-20px', position: 'absolute' }}
          data-e2e={'AppHeader__account-dialog'}
        >
          <div style={{ padding: '0' }} className={classes.infoWrapper}>
            <List style={{ width: '200px', padding: '0' }} component="nav">
              <ListItem
                className={classes.optionsMenuItem}
                button={true}
                disableGutters={true}
                onClick={this.handleReleaseIconClick}
              >
                <ListItemIcon classes={{ root: classes.menuItemIcon }}>
                  <NewReleasesIcon className={classes.primaryIcons} />
                </ListItemIcon>
                <ListItemText primary={t(`What's new`) + '...'} />
              </ListItem>
              <div className={classes.seperatingLine} />
              <ListItem
                className={classes.optionsMenuItem}
                button={true}
                disableGutters={true}
                onClick={this.handlePolicyIconClick}
              >
                <ListItemIcon classes={{ root: classes.menuItemIcon }}>
                  <PolicyIcon className={classes.primaryIcons} />
                </ListItemIcon>
                <ListItemText primary={t('Cookies Policy')} />
              </ListItem>
              <div className={classes.seperatingLine} />
              <ListItem
                className={classes.optionsMenuItem}
                button={true}
                disableGutters={true}
                onClick={this.toggleContactInfoDropDown}
              >
                <ListItemIcon classes={{ root: classes.menuItemIcon }}>
                  <SupportIcon className={classes.primaryIcons} />
                </ListItemIcon>
                <ListItemText primary={t('Contact us')} />
                <div className={classes.expandMoreLess}>
                  {contactInfoDropDownOpen ? <ExpandLess /> : <ExpandMore />}
                </div>
              </ListItem>

              {!!contactInfoDropDownOpen && (
                <div onClick={() => this.setState({ contactInfoDropDownOpen: false })}>
                  {contactPhone && (
                    <ListItem
                      className={classNames(classes.optionsMenuItem, classes.indented)}
                      button={true}
                      disableGutters={true}
                    >
                      <a className={classes.link} href={`tel:${contactPhone}`}>
                        <Button className={classes.button} color="primary" variant="contained">
                          <PhoneIcon className={classes.buttonIcon} />
                          <Typography>{contactPhone}</Typography>
                        </Button>
                      </a>
                      <IconButton
                        className="material-icons"
                        title={t('Copy to clipboard')}
                        onClick={() => this.copyToClipboard(contactPhone)}
                        style={{ fontSize: '16px' }}
                      >
                        content_copy
                      </IconButton>
                    </ListItem>
                  )}
                  {contactMail && (
                    <ListItem
                      className={classNames(classes.optionsMenuItem, classes.indented)}
                      button={true}
                      disableGutters={true}
                    >
                      <a className={classes.link} href={`mailto:${contactMail}`}>
                        <Button className={classes.button} color="primary" variant="contained">
                          <MailIcon className={classes.buttonIcon} />
                          <Typography>{contactMail}</Typography>
                        </Button>
                      </a>
                    </ListItem>
                  )}
                  {contactUrl && (
                    <ListItem
                      className={classNames(classes.optionsMenuItem, classes.indented)}
                      button={true}
                      disableGutters={true}
                    >
                      <a className={classes.link} target="_blank" rel="noopener noreferrer" href={contactUrl}>
                        <Button className={classes.button} color="primary" variant="contained">
                          <URLIcon className={classes.buttonIcon} />
                          <Typography>{'Fragus.com'}</Typography>
                        </Button>
                      </a>
                    </ListItem>
                  )}
                </div>
              )}
            </List>
          </div>
        </Dialog>
        <CookiesPolicyPopup onClose={this.handleClosePolicy} open={showCookiesPolicy} />
      </>
    )
  }

  copyToClipboard = (e: any) => {
    navigator.clipboard.writeText(e)
  }

  fetchContactInformation = async () => {
    const { statusCode, data } = await getContactInformation(this.props.providerInfo?.locale!)

    statusCode === 200 &&
      data &&
      this.setState({ contactMail: data.email, contactPhone: data.phone, contactUrl: data.url })
  }

  private handlePolicyIconClick = () => {
    this.setState({ showCookiesPolicy: true })
    this.props.onToggle()
  }

  private handleClosePolicy = () => this.setState({ showCookiesPolicy: false })

  private handleReleaseIconClick = () => {
    browserHistory.push(releaseNotesPath)
    this.props.onToggle()
  }

  private toggleContactInfoDropDown = () => {
    this.setState({ contactInfoDropDownOpen: !this.state.contactInfoDropDownOpen })
  }
}

export default withStyles(styles)(InformationMenu)
