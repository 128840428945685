import { IAdminCustomTermsRecord, PaymentGateway } from '@fragus/sam-types'
import {
  lookupUser,
  pdfDownloadUrl,
  postActiveContract,
  postContractAdjustDraft,
  postContractAdjustOffer,
  postContractDraft,
  postContractOffer,
  postContractPrint,
  postCustomTerms,
  TContractCreationRequest,
} from 'api/api'
import ContractFlowCustomer from 'components/admin/Contract/Flow/Customer'
import ContractFlowCustomTerms from 'components/admin/Contract/Flow/CustomTerms'
import ContractFlowPaymentGateway from 'components/admin/Contract/Flow/PaymentGateway'
import ContractFlowReference from 'components/admin/Contract/Flow/Reference'
import ContractFlowSendDialog from 'components/admin/Contract/Flow/SendDialog'
import ContractFlowSummary from 'components/admin/Contract/Flow/Summary'
import DatePicker from 'components/DatePicker'
import LoadingOverlay from 'components/LoadingOverlay'
import { LayoutBlock, LayoutColumn, LayoutRow } from 'components/Mui/Layout'
import moment from 'moment'
import React, { ChangeEvent } from 'react'
import { contractDetailsPath } from 'routes/paths'
import { AppContext } from 'store/appContext'
import { ContractFlowActivePanel } from 'types/contractFlow'
import { trackEvent } from 'utils/analytics'
import browserHistory from 'utils/history'
import notify from 'utils/notify/notify'
import { AllTermsCheckedOnContract } from 'utils/sessionStorage'
import ConfirmationDialog from './components/ConfirmationDialog'
import ContractFlowPaymentDialog from './components/PaymentDialog'
import PurchaseButtons from './components/PurchaseButtons'
import { IOwnProps, IState } from './types'
import {
  createAdjustOfferRequest,
  createCreateOfferRequest,
  createPrintOfferRequest,
  createProductPrintOfferRequest,
} from './utils/contractRequestBuilders'

class ContractFlowPagePayment extends React.Component<IOwnProps, IState> {
  public constructor(props: IOwnProps) {
    super(props)
    // Check that all payment gateways are valid.
    const providerPaymentGateways = this.props.creationData ? this.props.creationData.payment.paymentGateways : []
    providerPaymentGateways.forEach((item: string) => {
      if (!['Stripe', 'B2B', 'V4', 'NONE', 'Mock'].includes(item)) {
        notify.warning({ message: 'Warning: Unknown payment gateway: ' + item })
      }
    })

    this.state = {
      finalizationError: false,
      finalizationErrorMessage: '',
      finalizationTransaction: false,
      prettyIdentifier: '',
      sendDialog: false,
      purchaseDialog: false,
      isCreating: false,
      isLoading: false,
      contractPaymentGateway: 'Stripe',
      offerExpiresAt: moment().add(14, 'days'),
      confirmDialogType: undefined,
    }
  }

  public componentDidUpdate(oldProps: Readonly<IOwnProps>): void {
    const newProps: IOwnProps = this.props

    const newPaymentGateway = newProps.contract.paymentGateway
    const oldPaymentGateway = oldProps.contract.paymentGateway

    if (newPaymentGateway !== oldPaymentGateway) {
      this.setState({ contractPaymentGateway: newPaymentGateway })
    }
  }

  public render() {
    const {
      valid,
      activePanel,
      contract,
      creationData,
      freeContract,
      providerInfo,
      onCustomerChange,
      onCustomerLockedChange,
      contractUpdates,
    } = this.props
    const { flowType, paymentGateway } = contract
    const {
      isLoading,
      sendDialog,
      finalizationError,
      finalizationErrorMessage,
      finalizationTransaction,
      purchaseDialog,
      prettyIdentifier,
      confirmDialogType,
    } = this.state
    const paymentGateways = creationData?.payment.paymentGateways.filter((g: PaymentGateway) => g !== 'NONE') || []
    // Hides the "Payment Methods" card when isUsingV4PricingTool.
    const isShowCardPaymentGateway: boolean =
      !providerInfo!.isUsingV4PricingTool && !providerInfo!.isUseV4PTOnlyForSigning

    return (
      <>
        <LoadingOverlay open={isLoading} />
        <LayoutRow columns={2}>
          <LayoutColumn>
            <ContractFlowCustomer
              active={activePanel}
              activeEnum={ContractFlowActivePanel.customer}
              flow={flowType}
              locked={contract.state.customerSearched}
              onChange={onCustomerChange}
              onLockedChange={onCustomerLockedChange}
              value={contract.customer}
              valid={valid.customer}
              freeContract={freeContract}
            />
            {isShowCardPaymentGateway && creationData && paymentGateways.length > 1 && paymentGateway !== 'NONE' && (
              <ContractFlowPaymentGateway
                freeContract={freeContract}
                active={activePanel >= ContractFlowActivePanel.paymentGateway}
                value={paymentGateway}
                onChange={this.handlePaymentGatewayChange}
                paymentGateways={paymentGateways}
                flow={flowType}
              />
            )}
            <ContractFlowReference
              active={activePanel > ContractFlowActivePanel.customer}
              onChange={this.handleReferenceChange}
              value={contract.reference}
              freeContract={freeContract}
            />
            <ContractFlowCustomTerms
              active={activePanel > ContractFlowActivePanel.customer}
              onBlur={this.handleCustomTermsChange}
              value={contract.customTerms}
              freeContract={freeContract}
            />
          </LayoutColumn>

          <LayoutColumn>
            <LayoutBlock dense={true}>
              <ContractFlowSummary
                freeContract={freeContract}
                contract={contract}
                creationData={creationData!}
                providerInfo={providerInfo}
                printDisabled={activePanel < ContractFlowActivePanel.customer}
                onPrint={this.handlePrintOffer}
                contractUpdates={contractUpdates}
              />
            </LayoutBlock>
            <PurchaseButtons
              confirmStripeBuyNow={() => this.handleConfirmBuyNow('Stripe')}
              pageProps={this.props}
              pageState={this.state}
              openSendOffer={() => this.setState({ confirmDialogType: 'ContractOffer' })}
              openActiveContract={() => this.setState({ confirmDialogType: 'ActiveContract' })}
              openBuyNowV4={() => this.setState({ confirmDialogType: 'BuyNowV4' })}
              openBuyNowStripe={() => this.setState({ confirmDialogType: 'BuyNowStripe' })}
            />
          </LayoutColumn>
        </LayoutRow>
        {/* This Dialog is for two cases: 1. When contract creation succeeded. 2. When contract creation failed. */}
        <ContractFlowSendDialog
          open={sendDialog}
          error={finalizationError}
          errorMessage={finalizationErrorMessage}
          onClose={this.handleDialogClose}
          onToOffer={this.handleToContract}
          onCreateNew={this.handleCreateNewContractFlow}
          contract={contract}
          sending={finalizationTransaction}
          paymentGateway={paymentGateway}
        />
        {/* Confirmation Dialog for different actions based on confirmDialogType */}
        <ConfirmationDialog
          flowType={flowType}
          type={confirmDialogType}
          onClose={this.handleCloseConfirmationDialog}
          onConfirmSetting={{
            ContractOffer: this.handleSendContractOffer,
            ActiveContract: this.handleSendActiveContract,
            BuyNowV4: () => this.handleConfirmBuyNow('V4'),
            BuyNowStripe: () => this.handleConfirmBuyNow('Stripe'),
          }}
          renderOfferChildren={this.renderOfferExpireDatePicker}
        />
        {/* Dialog for Stripe purchase. Also, it shows errors for purchase */}
        {purchaseDialog && (
          <AppContext.Consumer>
            {({ locale }) => (
              <ContractFlowPaymentDialog
                onBack={this.handleDialogClose}
                contractPrettyIdentifier={prettyIdentifier}
                onPreviewContract={this.handleToContract}
                locale={locale}
              />
            )}
          </AppContext.Consumer>
        )}
      </>
    )
  }

  /**
   * Renders the offer expiration date picker.
   */
  private renderOfferExpireDatePicker = () => (
    <DatePicker
      id={'datepicker-contract'}
      onDateChange={(e): void => {
        this.setState({ offerExpiresAt: e })
      }}
      date={this.state.offerExpiresAt || moment()}
      initialDate={this.state.offerExpiresAt || moment()}
      minDate={moment().add(1, 'days')}
      datePickerWithoutInput
    />
  )
  /**
   * Handles the confirmation of opening the purchase dialog and contract draft creation.
   * @param paymentGateway
   */
  private handleConfirmBuyNow = (paymentGateway: 'V4' | 'Stripe') =>
    this.setState(
      {
        isLoading: true,
        confirmDialogType: undefined,
      },
      () => this.handlePurchaseContract(paymentGateway),
    )

  /**
   * Handles the reset of the contract flow state for a new contract creation.
   */
  private handleCreateNewContractFlow = () => {
    this.props.contractFlowReset()

    this.setState({
      finalizationError: false,
      finalizationErrorMessage: '',
      finalizationTransaction: false,
      prettyIdentifier: '',
      isCreating: false,
      isLoading: false,
    })
  }

  /**
   * Sends creation request for contract offer.
   */
  private handleSendContractOffer = async () => {
    const { contract, onFinalStep } = this.props
    const { flowType } = contract

    onFinalStep(true)
    this.setState({
      finalizationTransaction: true,
      sendDialog: true,
      confirmDialogType: undefined,
      // clean up the error message before sending the request
      finalizationError: false,
      finalizationErrorMessage: '',
    })

    if (flowType === 'CREATE') {
      trackEvent('Contract offer', 'Send offer')
      await this.sendCreateContract(postContractOffer)
    } else {
      trackEvent('Contract offer', 'Send adjustment offer')
      await this.sendAdjustOfferOrDraft(true)
    }
  }

  /**
   * Sends creation request for active contract. Only allowed for flowType CREATE.
   * Should be used only for contracts where NONE or B2B payment gateway is used.
   */
  private handleSendActiveContract = async () => {
    const { contract, onFinalStep } = this.props
    const { flowType } = contract

    onFinalStep(true)
    this.setState({
      finalizationTransaction: true,
      sendDialog: true,
      confirmDialogType: undefined,
    })

    // allow active contract creation only, when flowType is CREATE
    // that's why there are no error handling for ADJUST flowType
    if (flowType === 'CREATE') {
      trackEvent('Contract draft', 'Activate contract')
      await this.sendCreateContract(postActiveContract)
    }
  }

  /**
   * Sends creation request for adjusted contract(ONLY flowType ADJUST) with state Offer or Draft.
   */
  private sendAdjustOfferOrDraft = async (sendOffer: boolean = false): Promise<string | undefined> => {
    const { prettyIdentifier, contract } = this.props

    if (prettyIdentifier) {
      const terms: IAdminCustomTermsRecord = { customTerms: contract.customTerms }
      const postFunction = sendOffer ? postContractAdjustOffer : postContractAdjustDraft
      const request = createAdjustOfferRequest(this.props, this.state.offerExpiresAt)
      const response = await postFunction(request, prettyIdentifier)

      if (response.data) {
        const { prettyIdentifier: prettyIdentifierNew } = response.data

        terms.customTerms && (await postCustomTerms(response.data.prettyIdentifier, terms))
        this.setState({
          finalizationTransaction: false,
          finalizationError: false,
          finalizationErrorMessage: '',
          prettyIdentifier: prettyIdentifierNew,
        })

        return prettyIdentifierNew
      } else {
        const finalizationErrorMessage = (response.errorData && response.errorData.message) || ''
        this.setState({
          finalizationTransaction: false,
          finalizationError: true,
          finalizationErrorMessage,
          prettyIdentifier: '',
        })
      }
    }

    return
  }

  /*
   * Sends creation request for new contract (only CREATE flowType).
   * @param apiRequestFn - API request function
   * @param newContractPaymentGateway - Optional new payment gateway
   * @returns {Promise<string | undefined>} - Pretty identifier of the created contract if successful
   */
  private sendCreateContract = async (
    apiRequestFn: TContractCreationRequest,
    newContractPaymentGateway?: PaymentGateway,
  ): Promise<undefined | string> => {
    const request = createCreateOfferRequest(this.props, this.state.offerExpiresAt, newContractPaymentGateway)
    const response = await apiRequestFn(request)

    if (response.data) {
      const terms: IAdminCustomTermsRecord = { customTerms: this.props.contract.customTerms }
      const { prettyIdentifier } = response.data

      terms.customTerms && (await postCustomTerms(prettyIdentifier, terms))
      this.setState({
        finalizationTransaction: false,
        finalizationError: false,
        finalizationErrorMessage: '',
        prettyIdentifier,
      })

      return prettyIdentifier
    } else {
      const finalizationErrorMessage = (response.errorData && response.errorData.message) || ''
      this.setState({
        finalizationTransaction: false,
        finalizationError: true,
        finalizationErrorMessage,
        prettyIdentifier: '',
        // we use this dialog(ContractFlowSendDialog) for errors as well
        sendDialog: true,
        confirmDialogType: undefined,
      })
    }

    return
  }

  /**
   * Handles the closing of the ConfirmationDialog dialog.
   */
  private handleCloseConfirmationDialog = () => this.setState({ confirmDialogType: undefined })

  /**
   * Handles the closing of the Payment or ContractFlowSendDialog dialog (when Back button pressed).
   * And sets the customer object if it was NEWLY created.
   */
  private handleDialogClose = async () => {
    // IMPORTANT! We need to get user_id for NEWLY created user object, so we won't create it again
    if (!this.props.contract.customerId && this.props.contract.customer.email) {
      await this.setUserIdByEmail(this.props.contract.customer.email)
    }
    this.setState({
      isCreating: false,
      purchaseDialog: false,
      sendDialog: false,
      finalizationError: false,
      finalizationTransaction: false,
      finalizationErrorMessage: '',
      prettyIdentifier: '',
    })
  }

  /**
   * Sets the user_id for the customer object. Required after new customer creation.
   * @param email
   */
  private setUserIdByEmail = async (email: string) => {
    // Check if the email address is already in use
    const response = await lookupUser(email)
    if (response.data) {
      const customer = response.data
      this.props.onCustomerChange(customer, true)
      this.props.onCustomerLockedChange(true)
    }
  }

  /**
   * Handles the transition to the created contract page
   */
  private handleToContract = () => {
    this.props.onResetContract()
    browserHistory.push(contractDetailsPath(this.state.prettyIdentifier))
  }

  /**
   * Opens the purchase dialog when contract draft is created
   * @param contractPaymentGateway
   */
  private handlePurchaseContract = async (contractPaymentGateway?: PaymentGateway) => {
    this.setState({ isCreating: true })

    const { flowType } = this.props.contract
    let prettyIdentifier: string | undefined

    this.handlePaymentGatewayChange(contractPaymentGateway || 'Stripe')

    if (flowType === 'CREATE') {
      trackEvent('Contract draft', 'Buy now')
      prettyIdentifier = await this.sendCreateContract(postContractDraft, contractPaymentGateway)
    } else {
      trackEvent('Contract draft', 'Send adjustment offer')
      prettyIdentifier = await this.sendAdjustOfferOrDraft()
    }

    if (prettyIdentifier) {
      AllTermsCheckedOnContract.setAllChecked(prettyIdentifier)
      this.setState({ purchaseDialog: true, isCreating: false, isLoading: false })
    } else {
      this.setState({ isCreating: false, isLoading: false })
    }
  }

  /**
   * Handles the change of payment gateway
   * @param paymentGateway
   */
  private handlePaymentGatewayChange = (paymentGateway: PaymentGateway) => {
    this.props.onChange({ paymentGateway })
    this.props.onContractUpdate()
  }

  /**
   * Handles the change of reference
   * @param event
   */
  private handleReferenceChange = (event: ChangeEvent<HTMLInputElement>) => {
    // We limit the length to 128 (simple way of limiting the input length)
    const reference = event.target.value.substr(0, 128)
    this.props.onChange({ reference })
    this.props.onContractUpdate()
  }

  /**
   * Handles the change of custom terms
   * @param event
   */
  private handleCustomTermsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const customTerms = event.target.value.substr(0, 380)
    this.props.onChange({ customTerms })
    this.props.onContractUpdate()
  }

  /**
   * Handles the change of custom terms
   * @param event
   */
  private handlePrintOffer = async (): Promise<string> => {
    trackEvent('Contract offer', 'Print')
    const { contract } = this.props
    const { offerExpiresAt } = this.state
    const request =
      contract.contractObjectType === 'Vehicle'
        ? createPrintOfferRequest(this.props, offerExpiresAt)
        : createProductPrintOfferRequest(this.props, offerExpiresAt)

    const result = await postContractPrint(request)
    if (result.data) {
      const pdfUrl = pdfDownloadUrl(result.data.token)
      return pdfUrl
    } else {
      // @TODO display an error
      return 'Error'
    }
  }
}

export default ContractFlowPagePayment
