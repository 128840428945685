import React, { Component } from 'react'
import { WithStyles, withStyles, TextField, Typography, Theme } from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'
import { t } from 'translations/translationFunctions'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { ICustomerPasswordChangeRequest } from '@fragus/sam-types'
import SpinnerButton from 'components/Mui/SpinnerButton'
import { createPanelStyles } from 'theme'
import { preventEventTrigger } from 'utils/formik'

interface IProps extends WithStyles<typeof styles> {
  onSubmit: (body: ICustomerPasswordChangeRequest) => boolean
}

const styles = (theme: Theme) =>
  createPanelStyles(theme, {
    subheading: {
      marginTop: theme.spacing(3),
      marginBottom: 0,
    },
    button: {
      marginLeft: theme.spacing(2),
    },
    inputError: {
      color: theme.palette.error.main,
      paddingTop: theme.spacing(2),
    },
    submitButton: {
      marginTop: theme.spacing(5),
    },
  })

class CustomerPasswordForm extends Component<IProps> {
  public validationSchema = Yup.object().shape({
    new: Yup.string()
      .min(6, 'Password must have at least %count characters')
      .matches(/(?=.*[a-z])(?=.*[A-Z])/, 'Password must contain at least 1 lower case and 1 upper case character')
      .required('Field is required'),
    confirm: Yup.string()
      .oneOf([Yup.ref('new')], 'Passwords do not match')
      .required('Field is required'),
  })

  public render() {
    const { classes, onSubmit } = this.props
    return (
      <Formik
        initialValues={{ new: '', confirm: '' }}
        validationSchema={this.validationSchema}
        // tslint:disable-next-line jsx-no-lambda
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const body = {
            newPassword: values.new,
          }

          setSubmitting(true)
          const result = await onSubmit(body)
          if (result) {
            resetForm()
          }
          setSubmitting(false)
        }}
        enableReinitialize={true}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
          return (
            <>
              <Form className={classes.form}>
                <TextField
                  name="new"
                  type="password"
                  autoComplete="off"
                  label={t('New password')}
                  className={classes.input}
                  value={values.new}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onCut={preventEventTrigger}
                  onCopy={preventEventTrigger}
                  onPaste={preventEventTrigger}
                  inputProps={{
                    autocomplete: 'new-password',
                    // form: {
                    //   autocomplete: 'off'
                    // },
                  }}
                  fullWidth={true}
                />
                <Typography variant="body2" className={classes.inputError}>
                  {errors.new && touched.new && t(errors.new as any, { count: 6 })}
                </Typography>
                <TextField
                  name="confirm"
                  type="password"
                  autoComplete="off"
                  label={t('Re-type password')}
                  className={classes.input}
                  value={values.confirm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onCut={preventEventTrigger}
                  onCopy={preventEventTrigger}
                  onPaste={preventEventTrigger}
                  inputProps={{
                    autoComplete: 'new-password',
                    // form: {
                    //   autocomplete: 'off'
                    // },
                  }}
                  fullWidth={true}
                />
                <Typography variant="body2" className={classes.inputError}>
                  {errors.confirm && touched.confirm && t(errors.confirm as any)}
                </Typography>
                <SpinnerButton
                  type="submit"
                  className={classes.submitButton}
                  variant="contained"
                  color="primary"
                  IconComponent={CheckIcon}
                  showSpinner={isSubmitting}
                >
                  {t('Change password')}
                </SpinnerButton>
              </Form>
            </>
          )
        }}
      </Formik>
    )
  }
}
export default withStyles(styles)(CustomerPasswordForm)
