import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import { IBalanceChart, IChartDateValue } from '@fragus/sam-types'
import React from 'react'
import {
  Crosshair,
  CustomSVGSeries,
  CustomSVGSeriesPoint,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  LineMarkSeries,
  LineMarkSeriesPoint,
  RVNearestXData,
  XAxis,
  YAxis,
} from 'react-vis'
import 'react-vis/dist/style.css'
import { Card, Typography } from '@material-ui/core'
import { formatCurrency, formatDate, formatLocalizedDate } from '@omnicar/sam-format'
import classNames from 'classnames'
import * as _ from 'lodash'
import { compose } from 'recompose'
import { AppContext } from 'store/appContext'
import { theme } from 'theme'
import { tCurrency } from 'translations/translationFunctions'
import { ITransformNumberResponse } from 'types/overviewTypes'
import { getCurrency } from 'utils/localStorage'
import { firstCharUpper } from 'utils/string'
import { IBalanceLines } from '..'
import SVGMark, { BalanceChartShape } from './SVGMark'

interface ICustomBalanceChart {
  title: string
  monthAsTitle?: boolean
  lineData: IBalanceLines[]
  chartData: IBalanceChart
}

interface IOwnProps {
  data: ICustomBalanceChart[]
  economyTooltipChartData: IChartDateValue[][]
  transformNumber: (v: number) => ITransformNumberResponse
  onGraphHover?: (date: Date, values: { title: IBalanceLines; value: IChartDateValue }[]) => void
}

type TProps = IOwnProps & WithStyles<typeof styles>

interface IState {
  tooltipValue: IChartDateValue | undefined
  crosshairValues: IChartDateValue[]
  control: number
  currency: string
  hoverDate: Date | undefined
}

const styles = ({ spacing, breakpoints }: Theme) =>
  createStyles({
    crosshairContent: {
      width: '300px',
      [breakpoints.up(900)]: {
        display: 'flex',
        flexDirection: 'row',
      },
    },
    cardContent: {
      padding: spacing(1),
      [breakpoints.up(900)]: {
        display: 'block',
        textAlign: 'left',
        width: '100%',
      },
    },
    title: {
      color: theme.palette.text.light,
      marginBottom: spacing(1) / 4,
    },
    valuTitle: {
      height: spacing(5),
      width: spacing(10),
      whiteSpace: 'nowrap',
    },
    bold: {
      fontWeight: 'bold',
    },
    value: {
      marginBottom: spacing(1),
    },
  })

const defaultstrokeWidth = 4
const defaultCurveName = 'curveMonotoneX'

class EconomyBalanceChart extends React.Component<TProps, IState> {
  static contextType = AppContext
  public state: IState = {
    tooltipValue: undefined,
    crosshairValues: [],
    control: 0,
    currency: !getCurrency() ? '' : getCurrency(),
    hoverDate: undefined,
  }

  public render() {
    const { data, classes } = this.props
    const maxValues = this.getMaxValues(data)
    const maxY = Math.max(
      5,
      ((maxValues.cost && maxValues.cost.y) || 0) * 1.1,
      ((maxValues.invoice && maxValues.invoice.y) || 0) * 1.1,
    )
    const currency = this.state.currency

    let index = 0
    return (
      <FlexibleWidthXYPlot onMouseLeave={this.handleMouseLeave} height={300} xType="time-utc" yDomain={[0, maxY]}>
        <XAxis
          tickFormat={this.YAxisTickFormat}
          tickLabelAngle={-45}
          tickTotal={data[0].chartData.seriesInvoiced.months.length}
        />
        <YAxis tickPadding={1} tickFormat={this.XAxisTickFormat} />
        <HorizontalGridLines />
        {data.map((d) => {
          let series = [d.chartData.seriesInvoiced, d.chartData.seriesCosts]
          return d.lineData.map((l, i) => {
            const customSVGData = this.getCustomSVGComponents(series[i].months, l.color, l.shape)
            return [
              <LineMarkSeries
                key={`${l.title} ${i}`}
                data={series[i].months}
                stroke={l.color}
                fill={l.color}
                strokeWidth={defaultstrokeWidth}
                size={defaultstrokeWidth}
                curve={defaultCurveName}
                onNearestX={this.handleOnNearestX}
              />,
              <CustomSVGSeries key={`${l.title} ${i}_svg-mark`} data={customSVGData} xType="time-utc" />,
            ]
          })
        })}
        <Crosshair values={this.state.crosshairValues}>
          <div className={classes.crosshairContent}>
            {data.map((d, i) => {
              // Set chart title to current hover month
              d.monthAsTitle && this.setChartTitleToHoverMonth(d)
              return (
                <Card key={`${d.title} ${i} card`} className={classes.cardContent}>
                  <Typography
                    key={`${d.title} invoice title`}
                    variant="body2"
                    className={classNames(classes.title, classes.bold)}
                  >
                    {d.title}
                  </Typography>
                  <Typography
                    key={`${d.title} ${i} invoice title`}
                    variant="body2"
                    className={classNames(classes.title)}
                  >
                    {d.lineData[0].title}
                  </Typography>
                  <Typography
                    key={`${d.title} ${i} invoice value`}
                    variant="body1"
                    className={classNames(classes.value, classes.valuTitle)}
                  >
                    {this.state.crosshairValues[index] && formatCurrency(this.state.crosshairValues[index].y)}{' '}
                    <span className={classNames(classes.title)}>{tCurrency(currency)}</span>
                  </Typography>
                  <Typography key={`${d.title} ${i} cost title`} variant="body2" className={classNames(classes.title)}>
                    {d.lineData[1].title}
                  </Typography>
                  <Typography
                    key={`${d.title} ${i} cost value`}
                    variant="body1"
                    className={classNames(classes.value, classes.valuTitle)}
                  >
                    {this.state.crosshairValues[++index] && formatCurrency(this.state.crosshairValues[index++].y)}{' '}
                    <span className={classNames(classes.title)}>{tCurrency(currency)}</span>
                  </Typography>
                </Card>
              )
            })}
          </div>
        </Crosshair>
      </FlexibleWidthXYPlot>
    )
  }

  private setChartTitleToHoverMonth(chart: ICustomBalanceChart) {
    const { hoverDate } = this.state
    if (hoverDate) {
      chart.title = firstCharUpper(formatLocalizedDate(hoverDate, 'MMM', this.context.locale))
    }
  }

  private YAxisTickFormat = (d: Date) => {
    return (
      <tspan>
        <AppContext.Consumer>
          {({ locale }) => (
            <tspan y="2" x="-3">
              {firstCharUpper(formatLocalizedDate(d, 'MMM', locale))}
            </tspan>
          )}
        </AppContext.Consumer>
        <tspan x="0" dy="1em">
          {formatDate(d, { rawFormat: 'yyyy' })}
        </tspan>
      </tspan>
    )
  }

  private XAxisTickFormat = (value: number) => {
    const tick = this.props.transformNumber(value)
    return (
      <tspan>
        <tspan y="0" x="0">
          {tick && tick.value} {tick && tick.text}
        </tspan>
      </tspan>
    )
  }

  private getCustomSVGComponents = (
    months: IChartDateValue[],
    color: string,
    shape: BalanceChartShape | undefined,
  ): CustomSVGSeriesPoint[] => {
    return months.map((s) => {
      return {
        x: new Date(s.x).getTime(),
        y: s.y,
        customComponent: (_row: any, positionInPixels: { x: number; y: number }) => {
          return (
            <SVGMark
              fill={color}
              positionInPixels={positionInPixels}
              shape={shape}
              strokeWidth={defaultstrokeWidth}
              hollow={true}
            />
          )
        },
      }
    })
  }

  private handleOnNearestX = (_value: LineMarkSeriesPoint, { index }: RVNearestXData<LineMarkSeriesPoint>) => {
    const { economyTooltipChartData, data } = this.props
    this.setState({ crosshairValues: economyTooltipChartData.map((d) => d[index]) })

    let hoverValues = economyTooltipChartData.map((d, i) => {
      return { title: data[0].lineData[i], value: d[index] }
    })

    let date = economyTooltipChartData[0][index]
    this.setState({ hoverDate: new Date(date.x) })
    this.props.onGraphHover && this.props.onGraphHover(new Date(date.x), hoverValues)
  }

  private handleMouseLeave = () => {
    this.setState({ crosshairValues: [] })
  }

  private getMaxValues(
    charts: ICustomBalanceChart[],
  ): { invoice: IChartDateValue | undefined; cost: IChartDateValue | undefined } {
    let maxInvoices = charts.map((c) => this.getMaxInvoiced(c))
    let maxCosts = charts.map((c) => this.getMaxCost(c))
    return { invoice: _.maxBy(maxInvoices, 'y'), cost: _.maxBy(maxCosts, 'y') }
  }

  private getMaxInvoiced(chart: ICustomBalanceChart): IChartDateValue | undefined {
    return _.maxBy(chart.chartData.seriesInvoiced.months, 'y')
  }

  private getMaxCost(chart: ICustomBalanceChart): IChartDateValue | undefined {
    return _.maxBy(chart.chartData.seriesCosts.months, 'y')
  }
}

export default compose<TProps, IOwnProps>(withStyles(styles))(EconomyBalanceChart)
