import React from 'react'
import { connect } from 'react-redux'
import { IRootState } from '../../reducers/initialState'
import { selectHiddenVAT, selectShowMileageInfo } from '../../selectors/contractDisplayConfig'
import { IContractDisplayConfig } from 'reducers/contractDisplayConfig/initialState'

export const withContractDisplayConfig = <T extends IContractDisplayConfig>(Component: React.ComponentType<T>) => {
  const ConnectedContractDisplayConfigComponent = (props: T) => {
    return <Component {...props} />
  }

  const mapStateToProps = (state: IRootState) => {
    return {
      hiddenVAT: selectHiddenVAT(state),
      showMileageInfo: selectShowMileageInfo(state),
    }
  }

  return connect(mapStateToProps)(ConnectedContractDisplayConfigComponent)
}
