import { IContractDisplayConfig } from 'reducers/contractDisplayConfig/initialState'
import ActionTypeKeys from './ActionTypeKeys'

export interface IContractDisplayConfigSetHiddenVAT {
  readonly type: ActionTypeKeys.SET_HIDDEN_VAT
  readonly props: Partial<IContractDisplayConfig>
}

export interface IContractDisplayConfigSetShowMileageInfo {
  readonly type: ActionTypeKeys.SET_SHOW_MILEAGE_INFO
  readonly props: Partial<IContractDisplayConfig>
}

export const setHiddenVAT = (hiddenVAT: boolean): IContractDisplayConfigSetHiddenVAT => {
  return {
    type: ActionTypeKeys.SET_HIDDEN_VAT,
    props: { hiddenVAT },
  }
}

export const setShowMileageInfo = (showMileageInfo: boolean): IContractDisplayConfigSetShowMileageInfo => {
  return {
    type: ActionTypeKeys.SET_SHOW_MILEAGE_INFO,
    props: { showMileageInfo },
  }
}
