import { Button, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import {
  ArrowBackIos as ArrowBackIosIcon,
  RemoveShoppingCart as CannotBuyIcon,
  Send as SendIcon,
  ShoppingCart as ShoppingCartIcon,
} from '@material-ui/icons'
import classNames from 'classnames'
import WarningMessage from 'components/admin/Contract/Flow/WarningMessage'
import { LayoutActions, LayoutActionsLeft, LayoutActionsRight } from 'components/Mui/Layout'
import React from 'react'
import { compose } from 'recompose'
import { t } from 'translations/translationFunctions'
import { ContractFlowActivePanel } from 'types/contractFlow'
import { IOwnProps as IPageProps, IState as IPageState } from '../types'
import { getWarrantyErrorMessage } from '../utils'

interface IProps {
  pageProps: IPageProps
  pageState: IPageState
  confirmStripeBuyNow: () => void
  openBuyNowV4: () => void
  openBuyNowStripe: () => void
  openSendOffer: () => void
  openActiveContract: () => void
}

type TProps = IProps & WithStyles<typeof styles>

type TButtonTypes = 'buyNowV4' | 'buyNowStripe' | 'sendOffer' | 'createActiveContract'

const styles = ({ spacing }: Theme) =>
  createStyles({
    button: {
      marginLeft: spacing(1),
    },
    buttonIcon: {
      marginRight: spacing(1),
      fontSize: 16,
    },
    buyNowButtonsDiv: {
      paddingTop: -4 * spacing(1),
      textAlign: 'center',
      paddingBotton: 4 * spacing(1),
    },
    sameWidth: {
      width: 38 * spacing(1),
    },
    buttonColorBuyDealerPaid: {
      color: '#111',
      backgroundColor: '#5bb5c2',
    },
    cursorWait: {
      cursor: 'wait',
    },
    errorDiv: {
      fontSize: 16,
      backgroundColor: 'orange',
    },
    pullRight: {
      textAlign: 'right',
    },
    buttonsContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonSection: {
      marginBottom: '1rem',
    },
  })

const PurchaseButtons: React.SFC<TProps> = (props: TProps) => {
  const { isCreating } = props.pageState
  const { activePanel, onBack, contract } = props.pageProps
  const warningMessage = getWarrantyErrorMessage(props.pageProps)
  const disableActions = warningMessage ? true : activePanel < ContractFlowActivePanel.paymentGateway
  const { buyNowStripe, buyNowV4, sendOffer, createActiveContract } = getAvailableButtons(props)
  const { classes } = props
  // IMPORTANT: contract.state.licenseSkip is used to determine if the buttons should be shown(if TRUE then NO buttons are shown)
  if (contract.state.licenseSkip || !(buyNowStripe || buyNowV4 || sendOffer || createActiveContract)) {
    return (
      <div className={classNames(classes.errorDiv, classes.pullRight)}>
        <CannotBuyIcon className={classes.buttonIcon} />
        --No allowed Payment Gateway set up on this Provider, matches any of the payment types for this
        contract/template. Please contract support--
      </div>
    )
  }

  return (
    <>
      <div className={classes.buttonsContainer}>
        <div className={classNames(classes.buyNowButtonsDiv, isCreating && classes.cursorWait)}>
          {buyNowV4 && (
            <LayoutActions>
              <LayoutActionsRight>
                <div className={classes.buttonSection}>
                  <span title={t('Buy one-time dealer payment')}>
                    <Button
                      className={classNames(
                        classes.button,
                        classes.sameWidth,
                        classes.buttonColorBuyDealerPaid,
                        isCreating && classes.cursorWait,
                      )}
                      size="small"
                      variant="contained"
                      disabled={disableActions}
                      onClick={buyNowV4}
                    >
                      <ShoppingCartIcon className={classes.buttonIcon} />
                      {t('One-time Dealer Payment')}
                    </Button>
                  </span>
                </div>
              </LayoutActionsRight>
            </LayoutActions>
          )}
          {buyNowStripe && (
            <LayoutActions>
              <LayoutActionsRight>
                <div className={classes.buttonSection}>
                  <span title={t('Buy customer monthly subscription')}>
                    <Button
                      className={classNames(classes.button, classes.sameWidth, isCreating && classes.cursorWait)}
                      size="small"
                      color="secondary"
                      variant="contained"
                      disabled={disableActions}
                      onClick={buyNowStripe}
                    >
                      <ShoppingCartIcon className={classes.buttonIcon} />
                      {t('Customer Monthly Subscription')}
                    </Button>
                  </span>
                </div>
              </LayoutActionsRight>
            </LayoutActions>
          )}

          <LayoutActions>
            <LayoutActionsLeft>
              <span title={t('Back to previous page')}>
                <Button onClick={onBack} size="small" variant="outlined">
                  <ArrowBackIosIcon className={classes.buttonIcon} />
                  {t('Back')}
                </Button>
              </span>
            </LayoutActionsLeft>
            {sendOffer && (
              <LayoutActionsRight>
                <div className={classes.buttonSection}>
                  <span title={t('Send offer')}>
                    <Button
                      className={classNames(classes.button, classes.sameWidth)}
                      size="small"
                      color="primary"
                      variant="contained"
                      disabled={disableActions}
                      onClick={sendOffer}
                    >
                      <SendIcon className={classes.buttonIcon} />
                      {contract.flowType === 'CREATE' ? t('Send Offer to Customer') : t('Send Adjustment Offer')}
                    </Button>
                  </span>
                </div>
              </LayoutActionsRight>
            )}
            {createActiveContract && (
              <LayoutActionsRight>
                <div className={classes.buttonSection}>
                  <span title={t('Create contract')}>
                    <Button
                      className={classNames(classes.button, classes.sameWidth)}
                      size="small"
                      color="secondary"
                      variant="contained"
                      disabled={disableActions}
                      onClick={createActiveContract}
                    >
                      <ShoppingCartIcon className={classes.buttonIcon} />
                      {t('Create contract')}
                    </Button>
                  </span>
                </div>
              </LayoutActionsRight>
            )}
          </LayoutActions>
        </div>
      </div>

      {warningMessage && <WarningMessage message={warningMessage} />}
    </>
  )
}

/**
 * Gets the available buttons based on the contract and provider settings.
 * The values are either a function to handle the button click or null if the button should not be shown.
 * IMPORTANT: contract.state.licenseSkip is used to determine if the buttons should be shown(if TRUE then NO buttons are shown), we check this in render function.
 * @param props
 * @param confirmStripeBuyNow
 * @param openConfirmDialog
 */
const getAvailableButtons = (
  props: IProps,
): {
  [key in TButtonTypes]: (() => void) | null
} => {
  const { providerInfo, contract, creationData } = props.pageProps
  const isProviderUsingV4PricingTool = providerInfo!.isUsingV4PricingTool || providerInfo!.isUseV4PTOnlyForSigning
  const providerPaymentGateways = creationData?.payment.paymentGateways || []
  const { confirmStripeBuyNow, openActiveContract, openBuyNowStripe, openBuyNowV4, openSendOffer } = props

  if (isProviderUsingV4PricingTool) {
    // Note: Only contracts that has 'Subscription' payment can send offers (which
    // in turn can be opened by end-customer for payment with credit card.)
    const isSendOfferButtonVisible = contract.v4SupportedPaymentTypes.includes('CustomerSubscription')
    // One-time Dealer Payment.
    const isBuyNowV4ButtonVisible =
      providerPaymentGateways.includes('V4') && contract.v4SupportedPaymentTypes.includes('DealerPaid')
    // Customer Payment Card / Customer Monthly Subscription.
    const isBuyNowStripeButtonVisible = providerPaymentGateways.includes('Stripe') && isSendOfferButtonVisible

    return {
      buyNowV4: isBuyNowV4ButtonVisible ? openBuyNowV4 : null,
      buyNowStripe: isBuyNowStripeButtonVisible ? openBuyNowStripe : null,
      sendOffer: isSendOfferButtonVisible ? openSendOffer : null,
      createActiveContract: null,
    }
  } else {
    const isBuyNowButtonVisible = contract.paymentGateway === 'Stripe'
    // The NONE gateway is not shown in the ContractFlowPaymentGateway component but we use it when Discount is 100%
    // activateButtonVisible is only for contracts where Stripe payment is not required
    const activateButtonVisible = ['NONE', 'B2B'].includes(contract.paymentGateway) && contract.flowType === 'CREATE'
    // we hide the send offer button if active contract button is visible
    const sendButtonVisible = !activateButtonVisible

    return {
      buyNowV4: null,
      buyNowStripe: isBuyNowButtonVisible ? confirmStripeBuyNow : null,
      sendOffer: sendButtonVisible ? openSendOffer : null,
      createActiveContract: activateButtonVisible ? openActiveContract : null,
    }
  }
}

export default compose<TProps, IProps>(withStyles(styles))(PurchaseButtons)
